import { Fragment, memo } from 'react';
import { ProfileCard } from '@/components/styles/projects/ProfileCard';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';

type Props = {
  data: UserBasicInfo[];
  setUserId: (id: number) => void;
};

export const UserList: React.FC<Props> = memo((props) => {
  const { data, setUserId } = props;

  return (
    <>
      {data.map((user) => (
        <Fragment key={user.id}>
          <ProfileCard user={user} onClickUser={(id) => setUserId(id)} />
        </Fragment>
      ))}
    </>
  );
});
