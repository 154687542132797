import { memo, useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { useDeleteBoards } from '@/apis';
import Edit from '@/assets/icons/edit.svg';
import Trash from '@/assets/icons/trash_red.svg';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { useBasicModal, useSnackbar } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  boardId: number;
  onClickEdit: () => void;
  refetch: () => Promise<void>;
};

export const BoardMenuModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, boardId, onClickEdit, refetch } = props;

  const { isMale, isFreezed, member } = useAtomValue(meFlagAtom);

  const { onCloseModal, boardDeleteModal, isMeFreezedModal, boardPremiumGuidanceMessageModal } = useBasicModal();
  const { openSnackbar } = useSnackbar();

  const { deleteBoard } = useDeleteBoards();

  const onEdit = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
      onClose();
    } else if (isMale && member === 'free') {
      boardPremiumGuidanceMessageModal();
      onClose();
    } else {
      onClickEdit();
    }
  }, [boardId]);

  const onDelete = useCallback(async () => {
    await deleteBoard({ id: boardId });
    await refetch();

    onClose();
    onCloseModal();
    openSnackbar({
      type: 'toast',
      text: '削除しました。'
    });
  }, [boardId]);

  const handleClickDelete = useCallback(() => {
    boardDeleteModal(onDelete);
  }, [boardId]);

  return (
    <HalfModal id='boardMenuModal' isOpen={isOpen} onClose={onClose} title='メニュー' isInner>
      <div className={components['gap-wrapper']}>
        <Button color='white' icon={Edit} onClick={onEdit}>
          編集
        </Button>
        <Button color='white' icon={Trash} onClick={handleClickDelete}>
          <span className={components['red-text']}>削除</span>
        </Button>
        <Button color='clear' onClick={onClose}>
          <span className={components['black-text']}>キャンセル</span>
        </Button>
      </div>
    </HalfModal>
  );
});
