import { BaseSyntheticEvent, memo, useCallback, useRef, useState } from 'react';
import { usePostFavorite } from '@/apis';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/favoriteModal.module.scss';

type Props = {
  id: number;
  isOpen: boolean;
  value: number;
  onClose: () => void;
  refetchChat: () => void;
};

export const FavoriteModal: React.FC<Props> = memo((props) => {
  const { id, isOpen, value, onClose, refetchChat } = props;

  const [favoriteRank, setFavoriteRank] = useState(value);

  const star1 = useRef<HTMLInputElement>(null);
  const star2 = useRef<HTMLInputElement>(null);
  const star3 = useRef<HTMLInputElement>(null);

  const { postFavorite } = usePostFavorite();

  const handleClickStar = useCallback(
    (e: BaseSyntheticEvent) => {
      const { value } = e.target;

      if (Number(value) === favoriteRank) {
        if (star1.current) {
          star1.current.checked = false;
        }
        if (star2.current) {
          star2.current.checked = false;
        }
        if (star3.current) {
          star3.current.checked = false;
        }
        setFavoriteRank(0);
      } else if (Number(value) === 1) {
        if (star1.current) {
          star1.current.checked = true;
        }
        if (star2.current) {
          star2.current.checked = false;
        }
        if (star3.current) {
          star3.current.checked = false;
        }
        setFavoriteRank(1);
      } else if (Number(value) === 2) {
        if (star1.current) {
          star1.current.checked = true;
        }
        if (star2.current) {
          star2.current.checked = true;
        }
        if (star3.current) {
          star3.current.checked = false;
        }
        setFavoriteRank(2);
      } else if (Number(value) === 3) {
        if (star1.current) {
          star1.current.checked = true;
        }
        if (star2.current) {
          star2.current.checked = true;
        }
        if (star3.current) {
          star3.current.checked = true;
        }
        setFavoriteRank(3);
      }
    },
    [favoriteRank]
  );

  const handleClickSubmit = useCallback(async () => {
    try {
      await postFavorite({ id, favoriteRank });
      refetchChat();
      onClose();
    } catch {
      //
    }
  }, [id, favoriteRank]);

  return (
    <HalfModal
      id='favoriteModal'
      isOpen={isOpen}
      onClose={onClose}
      title='お気に入り設定'
      isInner
      footer={
        <Button color='black' onClick={handleClickSubmit}>
          保存
        </Button>
      }
    >
      <div className={components['align-center']}>
        <p className={components['basic-text']}>お気に入りを3段階で設定できます。</p>
        <div className={styles['star-wrapper']}>
          <label htmlFor='star1' className={styles.star}>
            <input
              id='star1'
              ref={star1}
              type='checkbox'
              value={1}
              onChange={handleClickStar}
              checked={favoriteRank !== 0}
            />
          </label>
          <label htmlFor='star2' className={styles.star}>
            <input
              id='star2'
              ref={star2}
              type='checkbox'
              value={2}
              onChange={handleClickStar}
              checked={favoriteRank > 1}
            />
          </label>
          <label htmlFor='star3' className={styles.star}>
            <input
              id='star3'
              ref={star3}
              type='checkbox'
              value={3}
              onChange={handleClickStar}
              checked={favoriteRank > 2}
            />
          </label>
        </div>
      </div>
    </HalfModal>
  );
});
