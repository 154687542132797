import { memo } from 'react';
import { useAtomValue } from 'jotai';
import Female01 from '@/assets/coaching/board_female_01.png';
import Female02 from '@/assets/coaching/board_female_02.png';
import Female03 from '@/assets/coaching/board_female_03.png';
import Female04 from '@/assets/coaching/board_female_04.png';
import Male01 from '@/assets/coaching/board_male_01.png';
import Male02 from '@/assets/coaching/board_male_02.png';
import Male03 from '@/assets/coaching/board_male_03.png';
import Male04 from '@/assets/coaching/board_male_04.png';
import { SettingStep } from '@/components/features/coaching/SettingStep';
import { ModalSwipe } from '@/components/features/ModalSwipe';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { Button } from '@/components/styles/uis/Button';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/coaching.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isMenu?: boolean;
};

export const BoardCoaching: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, isMenu } = props;

  const stepLength = isMenu ? 4 : 5;
  const { isMale } = useAtomValue(meFlagAtom);

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='how_to_use_boards'>
      <ModalSwipe stepLength={stepLength}>
        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male01 : Female01} alt='募集ですぐ会える' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>募集ですぐ会える</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                「今すぐ会いたい」「この日に会いたい」など、希望のシーンや条件でお相手を探したり、募集をすることができます。
              </p>
              <p className={components['basic-text']}>
                マッチングしてから会うまでが、よりスムーズなのが募集の特徴です。
              </p>
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male02 : Female02} alt='絞り込みで気になる募集を探す' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>絞り込みで気になる募集を探す</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>エリアや目的、お相手の特徴などの条件で募集の検索ができます。</p>
              <p className={components['basic-text']}>
                素敵な出会いを見逃さないように、募集をこまめにチェックしましょう。
              </p>
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male03 : Female03} alt='興味がある募集に応募' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>興味がある募集に応募</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>条件に合う募集をみつけたら応募のメッセージを送りましょう。</p>
              <p className={components['basic-text']}>
                ライバルとの先約で埋まってしまうことがあるので、早めの応募がおすすめです。
              </p>
              {isMale && <p className={components['sub-text']}>※募集への応募にはポイントが必要です</p>}
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male04 : Female04} alt='気軽に募集を投稿してみよう' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>気軽に募集を投稿してみよう</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                「急に予定が空いた」「この日に誰かと会いたい」そんな時こそ、募集を投稿してみましょう。
              </p>
              <p className={components['basic-text']}>応募率UPには、画像の添付がおすすめです。</p>
              {isMale && <p className={components['sub-text']}>※有料会員登録、およびポイントが必要です</p>}
            </div>
          </div>
        </li>
        {!isMenu && <SettingStep />}
      </ModalSwipe>
      <div className={styles['button-wrapper']}>
        <Button color='black' onClick={onClose}>
          {isMenu ? '閉じる' : 'さっそく使ってみる'}
        </Button>
      </div>
    </BasicModal>
  );
});
