import { memo, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useScrollLock } from '@/functions/hooks';
import styles from '@/styles/uis/videoModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  src: string;
};

export const VideoModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, src } = props;

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  useEffect(() => {
    if (document.getElementById('root')) {
      ReactModal.setAppElement('#root');
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      addScrollPreventElement('videoModal');
    } else {
      removeScrollPreventElement('videoModal');
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.content}
      overlayClassName={styles.overlay}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      bodyOpenClassName=''
      closeTimeoutMS={200}
      portalClassName='video-modal'
    >
      <button type='button' onClick={onClose} className={styles['close-button']} aria-label='close' />
      <video muted autoPlay loop controlsList='nodownload'>
        <source src={src} />
      </video>
    </ReactModal>
  );
});
