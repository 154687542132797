import { memo, useCallback } from 'react';
import styles from '@/styles/uis/indicator.module.scss';

type Props = {
  value: number;
  type: 'percentage' | 'total';
  totalLength?: number;
};

export const Indicator: React.FC<Props> = memo((props) => {
  const { value, type, totalLength } = props;

  const getValue = useCallback((value: number) => {
    if (type === 'total' && totalLength) {
      const ratio = (value / totalLength) * 100;
      const val = ratio * 1.8;
      return 314 - val;
    }

    const val = value * 1.8;
    return 314 - val;
  }, []);

  return (
    <div className={styles.wrapper}>
      <svg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' className={styles.svg}>
        <circle cx={32} cy={32} r={29} className={styles.bg} />
        <circle cx={32} cy={32} r={29} strokeDashoffset={getValue(value)} className={styles.filled} />
      </svg>
      <span className={styles.label}>
        {type === 'percentage' && (
          <>
            <span className={styles.value}>{value}</span>
            <span className={styles.unit}>%</span>
          </>
        )}

        {type === 'total' && (
          <>
            <span className={styles.value}>{value}</span>
            <span className={styles.unit}>/{totalLength}</span>
          </>
        )}
      </span>
    </div>
  );
});
