import { ReactNode, useEffect, useRef } from 'react';
import clsx from 'clsx';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/uis/tooltip.module.scss';

type Props = {
  content: string;
  children: ReactNode;
  location: 'top' | 'bottom';
  isShow: boolean;
  onClose: () => void;
};

export const Tooltip: React.FC<Props> = ({ content, location, children, isShow, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    const tooltipElement = tooltipRef.current;

    if (!rootElement || !tooltipElement) {
      return;
    }

    const rootElementRect = rootElement.getBoundingClientRect();
    const tooltipElementRect = tooltipElement.getBoundingClientRect();

    const relativePositionLeft = tooltipElementRect.left - rootElementRect.left;
    const relativePositionRight = rootElementRect.right - tooltipElementRect.right;

    const margin = 10;

    if (relativePositionLeft < 0) {
      tooltipElement.style.left = `${Math.abs(relativePositionLeft) + margin}px`;
    } else if (relativePositionRight < 0) {
      tooltipElement.style.left = `-${Math.abs(relativePositionRight) + margin}px`;
    }
  }, [isShow]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={styles.container} data-location={isShow && location} ref={ref}>
      {children}
      {isShow && (
        <div className={clsx(styles.content, styles[location], components['basic-text'])} ref={tooltipRef}>
          {content}
        </div>
      )}
    </div>
  );
};
