import { memo, useEffect } from 'react';
import ReactModal from 'react-modal';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useScrollLock } from '@/functions/hooks';
import styles from '@/styles/features/modal/imgModal.module.scss';

type Props = {
  img: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ImgModal: React.FC<Props> = memo((props) => {
  const { img, isOpen, onClose } = props;

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  useEffect(() => {
    if (document.getElementById('root')) {
      ReactModal.setAppElement('#root');
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      addScrollPreventElement('imgShowModal');
    } else {
      removeScrollPreventElement('imgShowModal');
    }
  }, [isOpen]);

  return (
    <ReactModal
      id='imgShowModal'
      isOpen={isOpen}
      className={styles.content}
      overlayClassName={styles.overlay}
      onRequestClose={onClose}
      bodyOpenClassName=''
      closeTimeoutMS={200}
      portalClassName='full-modal'
    >
      <div className={styles.wrapper}>
        <div className={styles.header} />
        <div className={styles['img-wrapper']}>
          <TransformWrapper doubleClick={{ mode: 'toggle', step: 1 }}>
            <TransformComponent
              wrapperStyle={{ height: '100%', width: '100%', touchAction: 'auto' }}
              contentStyle={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                touchAction: 'auto'
              }}
            >
              <img src={img} alt='' className={styles.img} />
            </TransformComponent>
          </TransformWrapper>
        </div>
        <div className={styles.footer}>
          <button type='button' onClick={onClose} aria-label='close' />
        </div>
      </div>
    </ReactModal>
  );
});
