import { memo, useCallback } from 'react';
import styles from '@/styles/projects/onlineStatus.module.scss';

type Props = {
  time: string;
  isLabel?: boolean;
  isSmall?: boolean;
};

export const OnlineStatus: React.FC<Props> = memo((props) => {
  const { time, isLabel, isSmall } = props;

  const getStatus = useCallback((hours: number) => {
    if (hours <= 1) {
      return 'online';
    }
    if (hours <= 12) {
      return 'under12';
    }
    if (hours <= 24) {
      return 'under24';
    }
    if (hours <= 72) {
      return 'under72';
    }

    return 'over72';
  }, []);

  const getLabel = useCallback(
    (hours: number) => {
      if (!isLabel) return null;

      if (hours <= 1) {
        return 'オンライン';
      }
      if (hours <= 12) {
        return '12時間以内';
      }
      if (hours <= 24) {
        return '24時間以内';
      }
      if (hours <= 72) {
        return '3日以内';
      }

      return '3日以上';
    },
    [isLabel]
  );

  const getBadge = useCallback(
    (time: string) => {
      const diff = new Date().getTime() - new Date(time).getTime();
      const diffHours = diff / (60 * 60 * 1000);

      return (
        <>
          <span className={styles.badge} data-status={getStatus(diffHours)} data-small={isSmall} />
          {getLabel(diffHours)}
        </>
      );
    },
    [time, isSmall]
  );

  return getBadge(time);
});
