import FemaleImg from '@/assets/img_default_female.png';
import MaleImg from '@/assets/img_default_male.png';
import RoyalImg from '@/assets/img_default_royal.png';
import { weekDays } from '@/functions/constants/common';
import { GENDER, LOCAL_STORAGE } from '@/functions/constants/enums';
import { messageTemplates } from '@/functions/constants/messageTemplate';
import { MeetupLocations } from '@/functions/types/meetupLocation';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';

export const getDefaultUserImage = (isMale: boolean, isRoyal: boolean) => {
  if (isMale) {
    if (isRoyal) {
      return RoyalImg;
    }
    return MaleImg;
  }
  return FemaleImg;
};

// TODO: 型変更
export const getUserImage = (user: any, isBeginner: boolean | null) => {
  if (!user) return '';

  if (user.top_image.id) {
    return user.top_image.image_url;
  }

  const isMale = user.gender === GENDER.MALE;
  const isRoyal = !isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal');
  return getDefaultUserImage(isMale, isRoyal);
};

export const getObjValue = (obj: Object, _key: string | number) => {
  const target = Object.entries(obj).find(([key]) => key === String(_key));
  return target?.[1] ?? undefined;
};

export const generateQueryParameter = (obj: Object) => {
  const isObject = (value: unknown): value is object => {
    const type = typeof value;
    return value !== null && (type === 'object' || type === 'function');
  };

  const queryItems = Object.entries(obj).map(([key1, value1]) => {
    if (Array.isArray(value1)) {
      return `${encodeURIComponent(key1)}=[${encodeURIComponent(value1.join(','))}]`;
    }

    if (isObject(value1)) {
      const objItem = Object.entries(value1)
        .map(([key2, value2]) => {
          if (key2 === 'meetup_locations') {
            const meetupLocations = Object.entries(value2).map(([area, cities]) => {
              if (Array.isArray(cities)) {
                const arrItems = cities.map((item) => `"${item}"`);
                return `"${area}": [${arrItems.join(',')}]`;
              }
              return null;
            });

            return `"${key2}": {${meetupLocations}}`;
          }

          if (Array.isArray(value2)) {
            const arrItems = value2.map((item) => `"${item}"`);
            return `"${key2}": [${arrItems.join(',')}]`;
          }

          if (isObject(value2)) {
            const objItems = Object.entries(value2).map(([key3, value3]) => `"${key3}": ${value3}`);
            return `"${key2}": {${objItems.join(',')}}`;
          }

          if (typeof value2 === 'boolean') {
            return `"${key2}": ${value2}`;
          }

          if (typeof value2 === 'string') {
            return `"${key2}": "${value2}"`;
          }

          return `"${key2}": ${value2}`;
        })
        .join(',\n');

      return `${encodeURIComponent(key1)}=${encodeURIComponent(`{${objItem}}`)}`;
    }

    if (typeof value1 === 'string') {
      return `${encodeURIComponent(key1)}=${value1}`;
    }

    return `${encodeURIComponent(key1)}=${encodeURIComponent(value1)}`;
  });

  return queryItems.join('&');
};

export const updateMeetupLocationData = (locations: MeetupLocations) => {
  const updateDate: any = {};

  // eslint-disable-next-line
  for (const key in locations) {
    if (locations[key]) {
      updateDate[key] = locations[key].map((v) => Object.keys(v)[0]);
    }
  }

  return updateDate;
};

export const maskValue = (value: string, length: number = 4) => {
  // メールアドレスの場合
  if (value.includes('@')) {
    const [username, domain] = value.split('@');
    const maskedUsername =
      username.length <= length
        ? '*'.repeat(username.length - 1) + username.slice(-1)
        : '*'.repeat(length) + username.slice(-1);
    return `${maskedUsername}@${domain}`;
  }

  // 電話番号の場合
  if (/^\d+$/.test(value)) {
    const maskedNumber = '*'.repeat(length) + value.slice(-length);
    return maskedNumber;
  }

  const maskText = value.substring(length).padStart(value.length, '*');
  return maskText;
};

export const replaceMessageTemplates = (userName: string, meName: string) => {
  return messageTemplates.map((v) => {
    return v.replace('{username}', userName).replace('{mename}', meName);
  });
};

export const zeroPadding = (value: number, digit: number) => {
  return value.toString().padStart(digit, '0');
};

export const getDiffTime = (createdAt: string) => {
  const createDate = new Date(createdAt);
  const now = new Date();
  const diff = now.getTime() - createDate.getTime();
  const progress = new Date(diff);

  if (progress.getUTCFullYear() - 1970) {
    return `${progress.getUTCFullYear() - 1970}年前`;
  }
  if (progress.getUTCMonth()) {
    return `${progress.getUTCMonth()}ヶ月前`;
  }
  if (progress.getUTCDate() - 1) {
    return `${progress.getUTCDate() - 1}日前`;
  }
  if (progress.getUTCHours()) {
    return `${progress.getUTCHours()}時間前`;
  }
  if (progress.getUTCMinutes()) {
    return `${progress.getUTCMinutes()}分前`;
  }
  return `${progress.getUTCSeconds()}秒前`;
};

export const checkNew = (createdAt: string) => {
  const diff = new Date().getTime() - new Date(createdAt).getTime();
  const diffDate = diff / (24 * 60 * 60 * 1000);

  return diffDate <= 30;
};

export const getBoardDayItems = () => {
  const todayDate = new Date();
  return [...Array(weekDays.length)].map((_, i) => {
    todayDate.setDate(todayDate.getDate() + (i === 0 ? 0 : 1));
    return {
      week: weekDays[todayDate.getDay()],
      day: todayDate.getDate(),
      value: `${todayDate.getFullYear()}-${zeroPadding(todayDate.getMonth() + 1, 2)}-${zeroPadding(
        todayDate.getDate(),
        2
      )}`
    };
  });
};

// NOTE: ローカルストレージの一括削除
export const removeLocalStorage = (type: String) => {
  switch (type) {
    case 'logout':
      Object.entries(LOCAL_STORAGE).forEach(([, value]) => {
        if (value.REMOVE_ON_LOGOUT) {
          localStorage.removeItem(value.KEY);
        }
      });
      break;
    case 'delete':
      Object.entries(LOCAL_STORAGE).forEach(([, value]) => {
        localStorage.removeItem(value.KEY);
      });
      break;
    default:
  }
};

// NOTE: オブジェクトが空かどうかを判定する
export const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

// NOTE: 約束と募集が重複した際のアラートテキストを取得する
export const getDuplicateAlertText = (board: boolean, appointment: boolean) => {
  if (appointment) {
    return '同日時に別のお約束があります。直前のキャンセル等はトラブルの原因となるため、今一度ご確認ください。';
  }
  if (board) {
    return '同日の募集があります。予定の重複にお気をつけください。';
  }

  return undefined;
};

type DateFormatOptions = {
  hideYear?: boolean;
  hideMonth?: boolean;
  hideDay?: boolean;
  hideTime?: boolean;
  hideWeekday?: boolean;
};

/**
 * 指定された日付をフォーマットする
 *
 * @param {(Date | string)} value - フォーマットする日付
 * @param {DateFormatOptions} [option={}] - フォーマットオプション。hideYear, hideMonth, hideDay, hideTime, hideWeekdayのフラグを設定できます。
 * @returns {string} - フォーマットされた日付。例："2022/12/31(金) 23:59"
 */
export const formatDate = (value: Date | string, option: DateFormatOptions = {}): string => {
  const date = typeof value === 'string' ? new Date(value) : value;

  let formattedDate = '';

  if (!option.hideYear) {
    formattedDate += `${date.getFullYear()}/`;
  }

  if (!option.hideMonth) {
    formattedDate += `${zeroPadding(date.getMonth() + 1, 2)}/`;
  }

  if (!option.hideDay) {
    formattedDate += `${zeroPadding(date.getDate(), 2)}`;
  }

  if (!option.hideWeekday) {
    formattedDate += `(${weekDays[date.getDay()]})`;
  }

  if (!option.hideTime) {
    formattedDate += ` ${zeroPadding(date.getHours(), 2)}:${zeroPadding(date.getMinutes(), 2)}`;
  }

  return formattedDate;
};

/**
 * 指定された開始日と終了日をフォーマットする
 *
 * @param {(Date | string)} startValue - 開始日時
 * @param {(Date | string | null)} endValue - 終了日時。nullの場合、終了時間は表示されません。
 * @param {DateFormatOptions} [option={}] - フォーマットオプション。hideYear, hideMonth, hideDay, hideTime, hideWeekdayのフラグを設定できます。
 * @returns {string} フォーマットされた日付範囲。例："2022/12/31(金) 23:59~00:59"
 */
export const formatDateRange = (
  startValue: Date | string,
  endValue: Date | string | null,
  option: DateFormatOptions = {}
): string => {
  const startDate = typeof startValue === 'string' ? new Date(startValue) : startValue;

  // endDateがないパターンも考慮
  let endDate = null;
  if (endValue) {
    endDate = typeof endValue === 'string' ? new Date(endValue) : endValue;
  }

  let formattedDate = '';

  if (!option.hideYear) {
    formattedDate += `${startDate.getFullYear()}/`;
  }

  if (!option.hideMonth) {
    formattedDate += `${zeroPadding(startDate.getMonth() + 1, 2)}/`;
  }

  if (!option.hideDay) {
    formattedDate += `${zeroPadding(startDate.getDate(), 2)}`;
  }

  if (!option.hideWeekday) {
    formattedDate += `(${weekDays[startDate.getDay()]})`;
  }

  if (!option.hideTime) {
    const startHour = zeroPadding(startDate.getHours(), 2);
    const startMinute = zeroPadding(startDate.getMinutes(), 2);
    formattedDate += ` ${startHour}:${startMinute}~`;

    if (endDate) {
      const endHour = zeroPadding(endDate.getHours(), 2);
      const endMinute = zeroPadding(endDate.getMinutes(), 2);
      formattedDate += `${endHour}:${endMinute}`;
    }
  }

  return formattedDate;
};

/**
 * バッチの表示数をフォーマットする
 *
 * @param {number} badge - バッチの表示数
 * @returns {string | number} フォーマットされたバッチ表示数
 */
export const displayBadge = (badge: number): string | number => {
  if (badge > 99) {
    return '99+';
  }

  return badge;
};

export const shuffleArray = (array: UserBasicInfo[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
