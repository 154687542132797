import { memo } from 'react';
import { useAtomValue } from 'jotai';
import { meAtom } from '@/contexts/atoms/me';
import styles from '@/styles/projects/floatHackBanner.module.scss';

export const FloatHackBanner: React.FC = memo(() => {
  const me = useAtomValue(meAtom);

  return (
    <div className={styles.wrapper}>
      <p>
        {me.id}:{me.property?.nickname}さんとして実行中
      </p>
    </div>
  );
});
