import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { client } from '@/apis/client';
import { MasterTypes, masterTypesAtom } from '@/contexts/atoms/masterTypes';

type Response = MasterTypes;

const getTypes = (): Promise<Response> => {
  return client.get('v1/types');
};

/**
 * マスターの取得
 */

export const useGetTypes = () => {
  const setMasterTypes = useSetAtom(masterTypesAtom);

  const fetchTypes = useCallback(async () => {
    const response = await getTypes();
    setMasterTypes(response);
  }, []);

  return { fetchTypes };
};
