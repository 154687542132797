import * as z from 'zod';

export const appointmentSchema = z.object({
  chat_room_id: z.number(),
  start_at: z.string(),
  end_at: z.string(),
  place: z.string().nonempty()
});

export type Schema = z.infer<typeof appointmentSchema>;

export const defaultValue: Schema = {
  chat_room_id: 0,
  start_at: '',
  end_at: '',
  place: ''
};
