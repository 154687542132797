import { Fragment, ReactNode, memo, useCallback } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import card1Img from '@/assets/lp/royal/card1.png';
import card2Img from '@/assets/lp/royal/card2.png';
import card3Img from '@/assets/lp/royal/card3.png';
import card4Img from '@/assets/lp/royal/card4.png';
import card5Img from '@/assets/lp/royal/card5.png';
import card6_1Img from '@/assets/lp/royal/card6-1.png';
import card6_2Img from '@/assets/lp/royal/card6-2.png';
import kv from '@/assets/lp/royal/kv.png';
import { meAtom } from '@/contexts/atoms/me';
import { basePath } from '@/functions/constants/common';
import { slideVariants } from '@/functions/constants/framerMotion';
import styles from '@/styles/features/lp/royal.module.scss';
import account from '@/styles/pages/account.module.scss';

const PlanList: React.FC = () => {
  const { id } = useAtomValue(meAtom);

  const handleClickPlan = useCallback((month: number) => {
    window.location.href = `${basePath}/stripe/royal_option_subscriptions/${month}?user_id=${id}&new_app=true`;
  }, []);

  return (
    <div className={styles['plan-block']}>
      <h2 className={styles['plan-heading']}>プランを選択</h2>
      <ul className={styles['plan-list']}>
        <li className={styles['plan-item']} data-recommend>
          <span className={styles['plan-item-label']}>1番お得！</span>
          <button type='button' onClick={() => handleClickPlan(6)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>6</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>35,880</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <p className={styles['plan-item-discount-price']}>
              <span>6,000</span>円お得
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                <span className={styles['plan-item-point-detail-num']}>300</span>PT
              </p>
            </div>
          </button>
        </li>
        <li className={styles['plan-item']}>
          <span className={styles['plan-item-label']}>人気No.1</span>
          <button type='button' onClick={() => handleClickPlan(3)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>3</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>18,840</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <p className={styles['plan-item-discount-price']}>
              <span>2,100</span>円お得
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                <span className={styles['plan-item-point-detail-num']}>150</span>PT
              </p>
            </div>
          </button>
        </li>
        <li className={styles['plan-item']}>
          <span className={styles['plan-item-label']}>まずはお試し</span>
          <button type='button' onClick={() => handleClickPlan(1)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>1</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>6,980</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                <span className={styles['plan-item-point-detail-num']}>50</span>PT
              </p>
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
};

type FeatureProps = {
  index?: number;
  title: string;
  children: ReactNode;
};

const featureItems: FeatureProps[] = [
  {
    title: 'メッセージの既読が\nわかる',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>あなたのメッセージをお相手が読んだか分かるようになります。</p>
          <p>もう、メッセージをチェックしているかの心配をする必要はありません。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={card1Img} alt='メッセージの既読がわかる' />
        </div>
      </>
    )
  },
  {
    title: 'クオリティチェックを\n無制限で利用可能',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>通常、1回15PT消費するクオリティチェックを無制限でご利用いただけます。</p>
          <p>デートの前にお相手の近況を欠かさずチェックできます。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={card2Img} alt='クオリティチェックを無制限で利用可能' />
        </div>
      </>
    )
  },
  {
    title: '好みのお相手を\nさらに探しやすく',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>お相手を探す際に、フリーワードでの検索が可能になります。</p>
          <p>好みのキーワードを活用して、スムーズなお相手探しをご体験ください。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={card3Img} alt='好みのお相手をさらに探しやすく' />
        </div>
      </>
    )
  },
  {
    title: '専用バッチでお相手の\n注目を集める',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>ロイヤル会員限定の特別バッチが付与されます。</p>
          <p>どの画面でもあなたがロイヤル仕様になり、より一層お相手の目に留まります。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={card4Img} alt='専用バッチでお相手の注目を集める' />
        </div>
      </>
    )
  },
  {
    title: '検索画面であなたを\n上位に表示',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>お相手の検索画面でロイヤル会員は優先表示されます。</p>
          <p>上位掲載されることで、より多くの注目を集めることができます。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={card5Img} alt='検索画面であなたを上位に表示' />
        </div>
      </>
    )
  },
  {
    title: 'あなたの「いいね!」が\n埋もれない',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>ロイヤル会員が送る「いいね!」は、お相手に優先表示されます。</p>
          <p>たくさんの「いいね!」を貰っている人気な女性でも、あなたからの「いいね!」が埋もれることがありません。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={card6_1Img} alt='あなたの「いいね!」が埋もれない' />
        </div>
        <div className={styles['feature-img']}>
          <img src={card6_2Img} alt='ロイヤル会員の限定特典' />
        </div>
      </>
    )
  }
];

const Feature: React.FC<FeatureProps> = (props) => {
  const { index, title, children } = props;

  return (
    <li className={styles.feature}>
      <div className={styles['feature-header']}>
        <span className={styles['feature-header-label']}>MERIT {index}</span>
        <h3 className={styles['feature-title']}>{title}</h3>
      </div>
      <div className={styles['feature-content']}>{children}</div>
    </li>
  );
};

const Notes: React.FC = () => (
  <div className={styles.notes}>
    <h2 className={styles['notes-heading']}>注意事項</h2>
    <p className={styles['notes-text']}>・ロイヤル会員のご利用には有料会員登録を行う必要があります。</p>
    <p className={styles['notes-text']}>・複数月プランのお支払いは一括払いとなります。</p>
    <p className={styles['notes-text']}>・ポイントは毎月分割で50PTずつ付与されます。</p>
    <p className={styles['notes-text']}>
      ・クレジットカードのご利用明細には「paddy」ではなく「PDDAY」「ピーディーペイ」のいずれかが表示されます。
    </p>
    <p className={styles['notes-text']}>
      ・ご購入月によるサービス提供日数のずれを防ぐため、いずれのプランも1ヶ月を30日としています。
    </p>
    <p className={styles['notes-text']}>・自動継続課金は期限が切れる24時間以内に自動的に更新される仕組みです。</p>
    <p className={styles['notes-text']}>・継続利用を希望されない場合は、ご自身でのご解約が必要となります。</p>
    <p className={styles['notes-text']}>・ロイヤル会員のご解約には、有料会員とは別でお手続きが必要となります。</p>
    <p className={styles['notes-text']}>
      ・ご解約後も期限を過ぎるまで、ロイヤル会員としてサービスをご利用いただけます。
    </p>
    <p className={styles['notes-text']}>
      ・ロイヤル会員ご契約中に、有料会員を解約するとロイヤル会員としてご利用いただけなくなります。
    </p>
    <p className={styles['notes-text']}>・サービス性質上、月割り・日割りでのご返金は行なっておりません。</p>
    <p className={styles['notes-text']}>・ご解約の手順は「設定」＞「購読の解約」をご確認ください。</p>
    <div className={styles['notes-link']}>
      <a href='https://www.paddy67.today/terms' target='_blank' rel='noreferrer'>
        利用規約
      </a>
      <a href='https://www.paddy67.today/privacy_policy' target='_blank' rel='noreferrer'>
        プライバシーポリシー
      </a>
    </div>
  </div>
);

type Props = {
  onClose: () => void;
};
export const RoyalLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_royal' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={clsx(account['submodal-header'], styles['submodal-header'])}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={clsx(account['submodal-header-back-button'], styles['submodal-header-back-button'])}
                aria-label='close'
              />
              <p className={clsx(account['submodal-header-title'], styles['submodal-header-title'])}>
                ロイヤル会員登録
              </p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles['royal-contents']}>
              <div className={styles.kv}>
                <img src={kv} alt='ロイヤル会員のご案内' />
              </div>

              <PlanList />

              <div className={styles['feature-block']}>
                <div className={styles['feature-inner']}>
                  <div className={styles['feature-heading-label']}>
                    <span>MERIT</span>
                  </div>
                  <h2 className={styles['feature-heading']}>
                    ロイヤル会員だけが
                    <br />
                    得られる6つの<span className={styles.accent}>メリット</span>
                  </h2>
                  <ul className={styles['feature-list']}>
                    {featureItems.map((feature, index) => (
                      <Fragment key={feature.title}>
                        <Feature {...feature} index={index + 1} />
                      </Fragment>
                    ))}
                  </ul>
                </div>
              </div>

              <PlanList />

              <Notes />
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
