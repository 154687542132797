import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

const api = (params: Params): Promise<Response> => {
  return client.delete(`v1/hides/${params.id}`);
};

type Props = Params;

/**
 * ユーザーの非表示を解除する
 */

export const useDeleteHideUser = () => {
  const deleteHideUser = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { deleteHideUser };
};
