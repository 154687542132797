import { memo } from 'react';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import female from '@/assets/lp/selfIntroduction/female.png';
import kv from '@/assets/lp/selfIntroduction/kv.png';
import male from '@/assets/lp/selfIntroduction/male.png';
import { slideVariants } from '@/functions/constants/framerMotion';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/selfIntroduction.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const SelfIntroductionLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_self_introduction' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
          style={{ zIndex: 15 }} // NOTE: $zHalfModal + 1
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>自己紹介の書き方</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.inner}>
              <div className={styles.kv}>
                <img src={kv} alt='自己紹介の書き方' />
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-heading']}>仕事や頑張っていること</h2>
                <div className={styles.card}>
                  <div className={styles['card-header']}>
                    <div className={styles['card-img']}>
                      <img src={male} alt='男性の場合' />
                    </div>
                    <p>男性の場合</p>
                  </div>
                  <p className={styles['card-message']} data-male>
                    IT系の会社を複数経営しています。
                    <br />
                    比較的時間の融通がききます。
                  </p>
                </div>
                <div className={styles.card}>
                  <div className={styles['card-header']}>
                    <div className={styles['card-img']}>
                      <img src={female} alt='女性の場合' />
                    </div>
                    <p>女性の場合</p>
                  </div>
                  <p className={styles['card-message']} data-female>
                    カフェで働いています。将来は自分のお店を持ちたい夢があります。
                  </p>
                </div>
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-heading']}>性格や内面</h2>
                <div className={styles.card}>
                  <div className={styles['card-header']}>
                    <div className={styles['card-img']}>
                      <img src={male} alt='男性の場合' />
                    </div>
                    <p>男性の場合</p>
                  </div>
                  <p className={styles['card-message']} data-male>
                    温厚な性格で話を聞くのが好きです。楽しいこと・悩みごとを本音で共有できる関係になりたいです。
                  </p>
                </div>
                <div className={styles.card}>
                  <div className={styles['card-header']}>
                    <div className={styles['card-img']}>
                      <img src={female} alt='女性の場合' />
                    </div>
                    <p>女性の場合</p>
                  </div>
                  <p className={styles['card-message']} data-female>
                    明るく人懐っこい性格で、お話したり・お話聞いたりするのが大好きです。
                  </p>
                </div>
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-heading']}>趣味や休日の過ごし方</h2>
                <div className={styles.card}>
                  <div className={styles['card-header']}>
                    <div className={styles['card-img']}>
                      <img src={male} alt='男性の場合' />
                    </div>
                    <p>男性の場合</p>
                  </div>
                  <p className={styles['card-message']} data-male>
                    休日ドライブしながら美味しいお店にご飯を食べにいくのが好きです。
                  </p>
                </div>
                <div className={styles.card}>
                  <div className={styles['card-header']}>
                    <div className={styles['card-img']}>
                      <img src={female} alt='女性の場合' />
                    </div>
                    <p>女性の場合</p>
                  </div>
                  <p className={styles['card-message']} data-female>
                    休みの時間は料理を作ったり、趣味のカフェ巡りをしています。ご一緒していただける方と出会えると嬉しいです。
                  </p>
                </div>
              </div>

              <div className={styles.prohibition}>
                <div className={styles['prohibition-header']}>
                  <h2>プロフィールの禁止事項</h2>
                </div>
                <div className={styles['prohibition-text']}>
                  <p className={components['basic-text']}>
                    みなさまに安心してご利用いただくため、プロフィールに以下内容の記載を禁止しています。
                  </p>
                  <p className={components['basic-text']}>
                    記載の場合は、運営審査で否認・修正されますのでご了承ください。
                  </p>
                </div>
                <hr />

                <div className={styles['prohibition-annotation']}>
                  <p className={components['basic-text']}>電話番号・メールアドレス・住所・URLなどの連絡先情報</p>
                  <p className={components['basic-text']}>サービスのアカウント情報、またはそれを想起させる表現</p>
                  <p className={components['basic-text']}>
                    特定できてしまう居場所（今〇〇にいます）や勤務先（〇〇店）など
                  </p>
                  <p className={components['basic-text']}>アダルトな表現（隠語、否定表現含む）</p>
                  <p className={components['basic-text']}>
                    相手が見て不快に感じられる文章（差別用語、薬物用語、攻撃的な表現など）
                  </p>
                  <p className={components['basic-text']}>商業的な広告、政治的な広告、勧誘</p>
                  <p className={components['basic-text']}>登録年齢と異なる、18歳未満の利用と判断される内容</p>
                  <p className={components['basic-text']}>具体的な金銭、公序良俗に反する可能性のある内容</p>
                  <p className={components['basic-text']}>援助を想起させる内容（隠語含む）</p>
                  <p className={components['basic-text']}>その他、paddyの利用規約に反するもの</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
