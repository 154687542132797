import { cloneElement, memo, useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';
import { badgeKeys } from '@/apis/queryKeys';
import { PhoneVerifyModal } from '@/components/features/modal/PhoneVerifyModal';
import { FloatHackBanner } from '@/components/styles/projects/FloatHackBanner';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { Snackbar } from '@/components/styles/uis/Snackbar';
import { basicModalAtom } from '@/contexts/atoms/basicModal';
import { phoneVerifyModalAtom } from '@/contexts/atoms/phoneVerifyModal';
import { scrollLockAtom } from '@/contexts/atoms/scrollLock';
import { snackbarAtom } from '@/contexts/atoms/snackbar';
import {
  firstSubElementAtom,
  fourthSubElementAtom,
  secondSubElementAtom,
  thirdSubElementAtom
} from '@/contexts/atoms/subElement';
import { publicPaths } from '@/functions/constants/common';
import { LOCAL_STORAGE } from '@/functions/constants/enums';
import { usePhoneVerify, useSubElement } from '@/functions/hooks';
import '@/styles/styles.scss';

export const AppLayout: React.FC = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useAtomValue(snackbarAtom);
  const basicModal = useAtomValue(basicModalAtom);
  const firstSubElement = useAtomValue(firstSubElementAtom);
  const secondSubElement = useAtomValue(secondSubElementAtom);
  const thirdSubElement = useAtomValue(thirdSubElementAtom);
  const fourthSubElement = useAtomValue(fourthSubElementAtom);
  const phoneVerifyModal = useAtomValue(phoneVerifyModalAtom);
  const [scrollLockElement, setScrollLockElement] = useAtom(scrollLockAtom);

  const queryClient = useQueryClient();

  const { handleCloseSubElement } = useSubElement();
  const { handleClosePhoneVerify } = usePhoneVerify();

  /**
   * ログイン状態に応じてリダイレクトを行う
   */
  const redirectByLoginStatus = useCallback(() => {
    if (publicPaths.some((v) => v === location.pathname)) {
      if (localStorage.getItem(LOCAL_STORAGE.PARIV_TOKEN.KEY)) {
        // ログイン済みの場合はホームに遷移させる
        // その時、クエリパラメータを引き継ぐ
        navigate(`/home${location.search}`);
      }
    } else if (localStorage.getItem(LOCAL_STORAGE.PARIV_TOKEN.KEY) === null) {
      // ログインしていない場合はログインページに遷移させる
      navigate('');
    }
  }, [location.pathname]);

  useEffect(() => {
    redirectByLoginStatus();

    queryClient.invalidateQueries(badgeKeys.all);

    if (scrollLockElement.length) {
      setScrollLockElement([]);
      if (document.body.classList.contains('scroll-prevent')) {
        document.body.classList.remove('scroll-prevent');
      }
    }
  }, [location.pathname]);

  return (
    <>
      <ScrollRestoration />

      {localStorage.getItem(LOCAL_STORAGE.PARIV_MANAGED_USER_ID.KEY) && <FloatHackBanner />}

      <Outlet />

      <AnimatePresence mode='wait'>
        {firstSubElement && cloneElement(firstSubElement.element, { onClose: () => handleCloseSubElement('first') })}
      </AnimatePresence>
      <AnimatePresence mode='wait'>
        {secondSubElement && cloneElement(secondSubElement.element, { onClose: () => handleCloseSubElement('second') })}
      </AnimatePresence>
      <AnimatePresence mode='wait'>
        {thirdSubElement && cloneElement(thirdSubElement.element, { onClose: () => handleCloseSubElement('third') })}
      </AnimatePresence>
      <AnimatePresence mode='wait'>
        {fourthSubElement && cloneElement(fourthSubElement.element, { onClose: () => handleCloseSubElement('fourth') })}
      </AnimatePresence>

      <PhoneVerifyModal isOpen={phoneVerifyModal.isOpen} onClose={handleClosePhoneVerify} />

      {snackbar && <Snackbar {...snackbar} />}

      <BasicModal isOpen={basicModal.isOpen} onClose={basicModal.onCloseModal} viewName={basicModal.viewName}>
        {basicModal.body}
      </BasicModal>
    </>
  );
});
