import { useCallback } from 'react';
import { client } from '@/apis/client';

type Response = {
  id: number;
  footprint: boolean;
  no_reply_chat: boolean;
  extra_time: boolean;
  email_stamp: boolean;
  email_get_message: boolean;
  email_footprint: boolean;
  email_other: boolean;
  camouflage: boolean;
  impression_survey_get_message: boolean;
};

const api = (): Promise<Response> => {
  return client.get('v1/notice_settings');
};

/**
 * 通知設定を取得する
 */

export const useGetNoticeSettings = () => {
  const fetchNoticeSettings = useCallback(async () => {
    const res = await api();

    return res;
  }, []);

  return { fetchNoticeSettings };
};
