import { ChangeEvent, memo, useCallback, useRef } from 'react';
import Camera from '@/assets/icons/camera.svg';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import components from '@/styles/components/index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (img: Blob) => void;
};

export const UploadImgModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, onUpload } = props;

  const cameraAppInput = useRef<HTMLInputElement>(null);

  const handleClickCamera = useCallback(() => {
    if (!cameraAppInput.current) return;
    cameraAppInput.current.click();
  }, []);

  const onChageImg = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files[0]) {
      onUpload(files[0]);
      onClose();
    }
  }, []);

  return (
    <HalfModal id='uploadImgModal' isOpen={isOpen} onClose={onClose} title='提出方法を選択' isInner>
      <div className={components['gap-wrapper']}>
        <Button color='white' onClick={handleClickCamera} icon={Camera}>
          <input
            ref={cameraAppInput}
            type='file'
            capture='user'
            accept='image/*'
            onChange={onChageImg}
            style={{ display: 'none' }}
          />
          写真を撮影
        </Button>

        <Button color='clear' onClick={onClose}>
          <span className={components['black-text']}>キャンセル</span>
        </Button>
      </div>
    </HalfModal>
  );
});
