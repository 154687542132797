import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id?: number;
  reserved_date: string;
  timeframe: string;
};

type Response = {
  board: boolean;
  appointment: boolean;
};

const api = (param: Params): Promise<Response> => {
  return client.post('v2/me/boards/check_duplicate', param);
};

/**
 * 募集と自身のお約束日の重複確認
 */

type Props = Params;

export const usePostCheckDuplicateBoards = () => {
  const postCheckDuplicate = useCallback(async (props: Props) => {
    const res = await api(props);

    return { board: res.board, appointment: res.appointment };
  }, []);

  return { postCheckDuplicate };
};
