import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

const api = (params: Params): Promise<Response> => {
  return client.delete(`v1/me/phrases/${params.id}`);
};

type Props = Params;

/**
 * 定型文を削除する
 */

export const useDeletePhrases = () => {
  const deletePhrases = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { deletePhrases };
};
