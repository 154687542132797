import { memo, useState, useCallback } from 'react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import InfiniteScroller from 'react-infinite-scroller';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetBlockUsers, useDeleteBlockUser } from '@/apis';
import { userKeys } from '@/apis/queryKeys';
import { ReactComponent as Empty } from '@/assets/icons/empty_block.svg';
import { ReportModal } from '@/components/features/modal/ReportModal';
import { UserImg } from '@/components/styles/projects/UserImg';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { getUserImage } from '@/functions/helpers';
import { useSnackbar, useBasicModal, useDisclosure } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/projects/blockUserList.module.scss';

type Props = {
  onClose: () => void;
};

export const BlockUserList: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const queryClient = useQueryClient();

  const [userId, setUserId] = useState<number | null>(null);

  const { openSnackbar } = useSnackbar();
  const { onCloseModal, commonModal } = useBasicModal();
  const reportModal = useDisclosure();

  const { updateUsersCache } = useCache();

  const { fetchBlockUsers } = useGetBlockUsers();
  const { deleteBlockUser } = useDeleteBlockUser();

  const { isBeginner } = useAtomValue(meFlagAtom);

  const { isLoading, isFetching, data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: userKeys.block(),
    queryFn: async ({ pageParam = 1 }) => {
      const datas = await fetchBlockUsers({ page: pageParam });
      return datas;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) return undefined;
      return allPage.length + 1;
    }
  });

  const handleClickReportButton = useCallback((userId: number) => {
    setUserId(userId);
    reportModal.open();
  }, []);

  const onSubmitUnblock = useCallback(async (userId: number) => {
    await deleteBlockUser({ id: userId });

    updateUsersCache(userId, 'unblock');

    onCloseModal();
    openSnackbar({
      type: 'toast',
      text: 'ブロックを解除しました。'
    });
  }, []);

  const handleClickUnlockButton = useCallback((userName: string, userId: number) => {
    commonModal({
      title: `${userName}さんのブロックを解除します。 よろしいですか？`,
      onClickLabel: '解除',
      onClick: () => onSubmitUnblock(userId)
    });
  }, []);

  const refetch = async () => {
    queryClient.invalidateQueries(userKeys.block());
  };

  return (
    <>
      <TrackPageView viewName='block_userlist' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>ブロックリスト</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={styles['center-wrapper']}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && data?.pages.flatMap((v) => v).length ? (
              <>
                <div className={styles.inner}>
                  <InfiniteScroller
                    pageStart={1}
                    hasMore={hasNextPage}
                    // @ts-ignore
                    loadMore={fetchNextPage}
                    loader={
                      isFetching ? (
                        <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                          <LoadingSpinner isSmall />
                        </div>
                      ) : undefined
                    }
                    useWindow={false}
                  >
                    <ul>
                      {data.pages
                        .flatMap((v) => v)
                        .map((user) => (
                          <li key={user.id} className={styles.card}>
                            <UserImg src={getUserImage(user, isBeginner)} size={88} />
                            <div className={styles['card-content']}>
                              <div className={styles['card-header']}>
                                <p className={components['text-bold']}>{user.property.nickname}</p>
                                <span className={(components['basic-text'], styles['card-property'])}>
                                  {user.age}歳 {user.property.residence_location}
                                </span>
                              </div>
                              {user.property.one_word && (
                                <p className={clsx(components['basic-text'], styles['card-text'])}>
                                  {user.property.one_word}
                                </p>
                              )}
                              <div className={styles['button-wrapper']}>
                                <button
                                  type='button'
                                  className={styles['report-button']}
                                  onClick={() => handleClickReportButton(user.id)}
                                  disabled={user.reported}
                                >
                                  {user.reported ? '通報済' : '通報'}
                                </button>
                                <button
                                  type='button'
                                  className={styles['block-button']}
                                  onClick={() => handleClickUnlockButton(user.property.nickname, user.id)}
                                  disabled={user.reported}
                                >
                                  ブロックを解除
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </InfiniteScroller>
                </div>
                {userId && (
                  <ReportModal
                    isOpen={reportModal.isOpen}
                    onClose={reportModal.close}
                    userId={userId}
                    refetch={refetch}
                  />
                )}
              </>
            ) : (
              <div className={styles['center-wrapper']}>
                <Empty />
                <p className={styles['empty-text']}>ブロックしたお相手はいません。</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
});
