import { memo } from 'react';
import { useAtomValue } from 'jotai';
import Female01 from '@/assets/coaching/home_female_01.png';
import Female02 from '@/assets/coaching/home_female_02.png';
import Female03 from '@/assets/coaching/home_female_03.png';
import Male01 from '@/assets/coaching/home_male_01.png';
import Male02 from '@/assets/coaching/home_male_02.png';
import Male03 from '@/assets/coaching/home_male_03.png';
import { SettingStep } from '@/components/features/coaching/SettingStep';
import { ModalSwipe } from '@/components/features/ModalSwipe';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { Button } from '@/components/styles/uis/Button';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/coaching.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isMenu?: boolean;
};

export const HomeCoaching: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, isMenu } = props;

  const stepLength = isMenu ? 3 : 4;
  const { isMale } = useAtomValue(meFlagAtom);

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='how_to_use_home'>
      <ModalSwipe stepLength={stepLength}>
        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male01 : Female01} alt='気になるお相手を探す' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>気になるお相手を探す</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                「ホーム」では、お相手を探すことができます。「おすすめ」や「ログイン順」から気になるお相手をみつけましょう。
              </p>
              <p className={components['basic-text']}>絞り込みを使って好みの条件での検索も可能です。</p>
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male02 : Female02} alt='いいね!やメッセージでアピール' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>{isMale ? 'いいね!やメッセージでアピール' : 'メッセージを送ってアピール'}</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                {isMale
                  ? '気になるお相手を見つけたらいいね!やメッセージを送りましょう。'
                  : '気になるお相手をみつけたらメッセージを送りましょう。'}
              </p>
              <p className={components['basic-text']}>
                {isMale
                  ? 'お相手もあなたにいいね!を送ることで、マッチしてやりとりが可能になります。'
                  : '他の人と被らない、あなただけの内容でのアピールがおすすめです。'}
              </p>
              {isMale && <p className={components['sub-text']}>※いいね!送信にはポイントが必要です</p>}
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male03 : Female03} alt='クオリティチェックでお相手を確認' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>クオリティチェックでお相手を確認</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>気になるお相手の近況をクオリティチェックで確認できます。</p>
              <p className={components['basic-text']}>マナー違反がある場合は、事前の把握が可能です。</p>
              <p className={components['sub-text']}>
                {isMale ? '※ご利用にはポイントが必要です' : '※ご利用にはプロフィール入力が必要です'}
              </p>
            </div>
          </div>
        </li>

        {!isMenu && <SettingStep />}
      </ModalSwipe>
      <div className={styles['button-wrapper']}>
        <Button color='black' onClick={onClose}>
          {isMenu ? '閉じる' : 'さっそく使ってみる'}
        </Button>
      </div>
    </BasicModal>
  );
});
