import { memo, useCallback, useState } from 'react';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selected: string;
  option: { [key: string]: string };
  onChange: (value: string) => void;
};

export const ResignReasonModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, selected, option, onChange } = props;

  const [temporarily, setTemporarily] = useState(selected);

  const handleClickSave = useCallback(() => {
    onChange(temporarily);
    onClose();
  }, [temporarily]);

  return (
    <HalfModal
      id='resignReasonModal'
      isOpen={isOpen}
      onClose={onClose}
      title='退会理由'
      footer={
        <Button color='black' onClick={handleClickSave} disabled={!temporarily}>
          決定
        </Button>
      }
    >
      <RadioGroup selected={temporarily} options={option} onChange={(e) => setTemporarily(e.target.value)} />
    </HalfModal>
  );
});
