import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  user_id: number;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v1/footprints', params);
};

/**
 * 足跡をつける
 */

export const usePostFootPrints = () => {
  const postFootPrints = useCallback(async (props: Params) => {
    await api(props);
  }, []);

  return { postFootPrints };
};
