import { memo, useCallback } from 'react';
import ja from 'date-fns/locale/ja';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import '@/styles/uis/datePicker.scss';

registerLocale('ja', ja);

const today = new Date();

type Props = {
  date: Date | null;
  setDate: (date: Date | null) => void;
};

export const DatePicker: React.FC<Props> = memo((props) => {
  const { date, setDate } = props;

  const getMaxDate = useCallback(() => {
    const now = new Date();
    const threeMonthAfterDate = now.setMonth(now.getMonth() + 3);
    return new Date(threeMonthAfterDate);
  }, [date]);

  return (
    <ReactDatePicker
      selected={date}
      onChange={(date) => setDate(date)}
      dateFormat='yyyy/MM/dd'
      locale='ja'
      open
      minDate={today}
      maxDate={getMaxDate()}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div className='react-datepicker-header'>
          <span className='react-datepicker-header-date'>
            {date.getFullYear()}年{date.getMonth() + 1}月
          </span>
          <div className='react-datepicker-header-buttons'>
            <button
              type='button'
              className='react-datepicker-header-button'
              onClick={decreaseMonth}
              aria-label='prev'
              disabled={prevMonthButtonDisabled}
            />
            <button
              type='button'
              className='react-datepicker-header-button'
              onClick={increaseMonth}
              aria-label='next'
              disabled={nextMonthButtonDisabled}
            />
          </div>
        </div>
      )}
    />
  );
});
