import { useCallback } from 'react';
import { client } from '@/apis/client';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';

type Params = {
  id: number;
};

type Response = UserBasicInfo[];

const api = (params: Params): Promise<Response> => {
  return client.get(`v1/males/${params.id}/similars`);
};

type Props = Params;

/**
 * 似ている男性ユーザーを取得
 */

export const useGetMaleSimilars = () => {
  const fetchMaleSimilars = useCallback(async (props: Props) => {
    const res = await api(props);

    return res;
  }, []);

  return { fetchMaleSimilars };
};
