import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  video: File;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v1/me/profile_movies', params, { headers: { 'Content-Type': 'multipart/form-data' } });
};

type Props = Params;

/**
 * プロフィール動画の登録
 */

export const usePostMovies = () => {
  const postMovies = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postMovies };
};
