import { atom } from 'jotai';

export type MasterTypeKey =
  | 'asset_types'
  | 'before_meeting_types'
  | 'board_areas'
  | 'board_purposes'
  | 'board_timeframes'
  | 'body_types'
  | 'business_types'
  | 'cigarette_types'
  | 'contact_general_categories'
  | 'contact_member_categories'
  | 'drinking_types'
  | 'education_types'
  | 'female_body_types'
  | 'holiday_types'
  | 'hometowns'
  | 'income_types'
  | 'last_login_times'
  | 'lookses'
  | 'male_body_types'
  | 'meetup_location_prefs'
  | 'meetup_locations'
  | 'ng_words'
  | 'personalities'
  | 'purpose_types'
  | 'register_reasons'
  | 'residence_locations'
  | 'report_categories'
  | 'unsubscribe_reasons'
  | 'faceweight';

export type MasterTypes = {
  [key in MasterTypeKey]: { [key: string]: string };
};

const initialValue: MasterTypes = {
  asset_types: {},
  before_meeting_types: {},
  board_areas: {},
  board_purposes: {},
  board_timeframes: {},
  body_types: {},
  business_types: {},
  cigarette_types: {},
  contact_general_categories: {},
  contact_member_categories: {},
  drinking_types: {},
  education_types: {},
  female_body_types: {},
  holiday_types: {},
  hometowns: {},
  income_types: {},
  last_login_times: {},
  lookses: {},
  male_body_types: {},
  meetup_location_prefs: {},
  meetup_locations: {},
  ng_words: {}, // NOTE: string[]
  personalities: {},
  purpose_types: {},
  register_reasons: {},
  residence_locations: {},
  report_categories: {},
  unsubscribe_reasons: {}, // NOTE: string[]
  faceweight: {}
};

export const masterTypesAtom = atom<MasterTypes>(initialValue);

masterTypesAtom.debugLabel = 'masterTypesAtom';
