import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { chanceTimeAtom } from '@/contexts/atoms/chanceTime';
import { isEmptyObject } from '@/functions/helpers';

export const useChanceTime = () => {
  const [chanceTime, setChanceTime] = useAtom(chanceTimeAtom);

  /**
   * チャンスタイム中かどうかのフラグ
   */
  const isChanceTime = useMemo(() => {
    // isChanceTime フラグは以下の条件をすべて満たす場合に true となります：
    // 1. chanceTimeAtom が空ではない
    // 2. counter が max_counter を下回っている
    // 3. end_at が現在の日時よりも後である
    return (
      !isEmptyObject(chanceTime) &&
      chanceTime.counter < chanceTime.max_counter &&
      new Date(chanceTime.end_at) > new Date()
    );
  }, [chanceTime]);

  return { chanceTime, isChanceTime, setChanceTime };
};
