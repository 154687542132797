import { Fragment, ReactNode, memo, useCallback, useRef, useState } from 'react';
import styles from '@/styles/uis/accordion.module.scss';

type Props = {
  title: string;
  texts: ReactNode[];
};

export const Accordion: React.FC<Props> = memo((props) => {
  const { title, texts } = props;

  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggleAccordion = useCallback(() => {
    if (!contentRef.current) return;

    if (isOpen) {
      contentRef.current.removeAttribute('style');
    } else {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight ?? 0}px`;
    }
    setIsOpen((prev) => !prev);
  }, [isOpen]);

  return (
    <div className={styles.wrapper} data-open={isOpen}>
      <button type='button' className={styles.heading} onClick={handleToggleAccordion}>
        <p className={styles['heading-title']}>{title}</p>
      </button>
      <div ref={contentRef} className={styles.content}>
        <div className={styles['content-inner']}>
          {texts.map((text, index) => (
            // eslint-disable-next-line
            <Fragment key={index}>{text}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
});
