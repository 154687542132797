import { useCallback } from 'react';
import { client } from '@/apis/client';
import { UserPhrase } from '@/functions/types/phrase';

type Response = UserPhrase[];

const api = (): Promise<Response> => {
  return client.get('v1/me/phrases');
};

/**
 * 定型文を取得する
 */

export const useGetPhrases = () => {
  const fetchPhrases = useCallback(async () => {
    const res = await api();

    return res;
  }, []);

  return { fetchPhrases };
};
