import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  user_property: {
    asset_type?: string;
    before_meeting?: string;
    body_type?: string;
    business_type?: string;
    cigarette_type?: string;
    desired_dating_place?: string;
    drinking_type?: string;
    education_type?: string;
    height?: string;
    holiday_type?: string;
    hometown?: string;
    income_type?: string;
    purpose?: string;
    one_word?: string;
    self_introduction?: string;
    looks?: [];
    personality?: [];
    residence_location?: string | null;
    meetup_location?: {};
  };
};

// NOTE: user_property 必要ならtypesファイルに移行
type Response = {
  id: number;
  user_id: number;
  height: number;
  employer: string;
  occupation_name: string;
  school_name: string;
  background_type: number;
  appearance: string;
  similar_entertainer: string;
  style: string;
  charm_point: string;
  favorite_clothing: string;
  talking_type: string;
  enable_talk_cateogry: string;
  enable_talk: string;
  want_hear: string;
  amenity_type: string;
  tension_type: string;
  boast: string;
  like_clothing: string;
  like_type: string;
  dislike_type: string;
  sm_type: string;
  area: string;
  hobby: string;
  holiday: string;
  favorite_meal: string;
  favorite_drinking: string;
  drinking_behavior: string;
  dream: string;
  contact_information_requirement: string;
  two_meet_requirement: string;
  created_at: string;
  updated_at: string;
  nickname: string;
  one_word: string;
  self_introduction: string;
  future_dream: string;
  desired_dating_place: string;
  body_type: string;
  bust_type: string;
  blood_type: string;
  residence_location: string;
  hometown: string;
  business_type: string;
  education_type: string;
  income_type: string;
  asset_type: string;
  cigarette_type: string;
  brother_type: string;
  sister_type: string;
  marital_type: string;
  child_type: string;
  thinking_marriage_type: string;
  want_child_type: string;
  housekeeping_type: string;
  sociability_type: string;
  living_together_type: string;
  holiday_type: string;
  drinking_type: string;
  purpose: string;
  before_meeting: string;
  looks: string;
  moods: string;
  personality: string[];
  register_reason: string[];
  meetup_location: {};
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/property', params);
};

type Props = Params;

/**
 * 自身のプロフィールを更新する
 */

export const usePutProperty = () => {
  const putProperty = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putProperty };
};
