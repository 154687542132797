import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  phone: string;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/phone', params);
};

type Props = Params;

/**
 * 電話番号を更新する
 */

export const usePutPhone = () => {
  const putPhone = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putPhone };
};
