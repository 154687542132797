import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  id: number;
  body: {
    favorite_rank: number;
  };
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post(`v1/chat_rooms/${params.id}/favorite`, params.body);
};

/**
 * チャットルームのお気に入り設定
 */

type Props = {
  id: number;
  favoriteRank: number;
};

export const usePostFavorite = () => {
  const { openSnackbar } = useSnackbar();

  const postFavorite = useCallback(async (props: Props) => {
    const { id, favoriteRank } = props;

    await api({ id, body: { favorite_rank: favoriteRank } });

    openSnackbar({
      type: 'toast',
      text: 'お気に入り設定しました。',
      bottom: 92
    });
  }, []);

  return { postFavorite };
};
