export type ViewName =
  | 'splash'
  | 'gender_input'
  | 'nickname_input'
  | 'birthdate_input'
  | 'residence_input'
  | 'email_input_or_apple_signin'
  | 'signup_email_code_input'
  | 'inquiry_top'
  | 'inquiry_preview'
  | 'match_settings'
  | 'profile_image_input'
  | 'style_input'
  | 'occupation_input'
  | 'meetup_location_input'
  | 'tag_input'
  | 'like_five_female'
  | 'login_or_apple_signin'
  | 'login_code_code_input'
  | 'home'
  | 'user_detail'
  | 'boards_top'
  | 'boards_detail'
  | 'boards_create'
  | 'like_top'
  | 'questionlike_create'
  | 'message_top'
  | 'message_detail'
  | 'account_top'
  | 'profile_edit'
  | 'membership_status'
  | 'notice_list'
  | 'notice_detail'
  | 'footprint'
  | 'appointment'
  | 'my_boards_top'
  | 'settings'
  | 'notification_settings'
  | 'secredmode_settings'
  | 'point_history'
  | 'hidden_userlist'
  | 'block_userlist'
  | 'deleted_userlist'
  | 'how_to_use_paddy_top'
  | 'how_to_use_home'
  | 'how_to_use_tag'
  | 'how_to_use_boards'
  | 'how_to_use_like'
  | 'how_to_use_message'
  | 'how_to_use_account'
  | 'lp_premium'
  | 'lp_royal'
  | 'lp_point'
  | 'lp_richlike'
  | 'lp_submit_income'
  | 'lp_asset_income'
  | 'lp_age_verification'
  | 'lp_self_introduction'
  | 'lp_questionlike'
  | 'lp_secretmode'
  | 'lp_how_to_use_paddy'
  | 'lp_safety_initiatives'
  | 'popover_chancetime_start'
  | 'popover_chancetime_complete'
  | 'popover_daily_swipe'
  | 'popover_appointment_reminder'
  | 'daily_login_bonus'
  | 'popover_appeal_phone_auth_start'
  | 'popover_appeal_phone_auth_complete'
  | 'popover_appeal_premium'
  | 'popover_appeal_royal'
  | 'modal_phone_input'
  | 'modal_phone_code_input'
  | 'dialog_phone_verification'
  | 'dialog_age_verification'
  | 'dialog_point_shortage'
  | 'dialog_richlike_shortage'
  | 'dialog_opponent_is_freezed'
  | 'dialog_i_am_freezed'
  | 'dialog_internet_unstable'
  | 'dialog_internet_offline'
  | 'dialog_app_forced_shutdown'
  | 'dialog_web_forced_shutdown'
  | 'dialog_forced_update'
  | 'dialog_restore'
  | 'dialog_logout';

export const viewEvent: Record<ViewName, string> = {
  splash: 'スプラッシュ',
  gender_input: '性別入力',
  nickname_input: '新規登録_ニックネーム入力',
  birthdate_input: '新規登録_生年月日入力',
  residence_input: '新規登録_お住まい入力',
  email_input_or_apple_signin: '新規登録_アドレス入力/Appleサインイン',
  signup_email_code_input: '新規登録_認証コード入力',
  inquiry_top: 'お問い合わせTOP',
  inquiry_preview: 'お問い合わせプレビュー',
  match_settings: '新規登録_マッチング設定へ',
  profile_image_input: '新規登録_プロフ画像設定',
  style_input: '新規登録_スタイル入力',
  occupation_input: '新規登録_お仕事入力',
  meetup_location_input: '新規登録_会えるエリア入力',
  tag_input: '新規登録_タグ入力',
  like_five_female: '新規登録_5人いいね!',
  login_or_apple_signin: 'ログイン/Appleサインイン',
  login_code_code_input: 'ログイン_認証コード入力',
  home: 'ホーム',
  user_detail: 'ユーザー詳細',
  boards_top: '募集TOP',
  boards_detail: '募集詳細',
  boards_create: '募集投稿',
  like_top: 'いいねTOP',
  questionlike_create: '質問付きいいね!設定',
  message_top: 'メッセージTOP',
  message_detail: 'メッセージ詳細',
  account_top: 'アカウントTOP',
  profile_edit: 'プロフィール編集',
  membership_status: '会員ステータス',
  notice_list: 'お知らせ一覧',
  notice_detail: 'お知らせ詳細',
  footprint: '足あと',
  appointment: 'お約束',
  my_boards_top: 'アカウント_募集',
  settings: '設定TOP',
  notification_settings: '通知設定',
  secredmode_settings: 'シークレットモード設定',
  point_history: 'ポイント履歴',
  hidden_userlist: '非表示リスト',
  block_userlist: 'ブロックリスト',
  deleted_userlist: '退会者リスト',
  how_to_use_paddy_top: 'paddyの使い方TOP',
  how_to_use_home: 'ホームの使い方',
  how_to_use_tag: 'タグの使い方',
  how_to_use_boards: '募集の使い方',
  how_to_use_like: 'いいねの使い方',
  how_to_use_message: 'メッセージの使い方',
  how_to_use_account: 'アカウントの使い方',
  lp_premium: '有料会員LP',
  lp_royal: 'ロイヤル会員LP',
  lp_point: 'ポイントLP',
  lp_richlike: 'リッチいいね!LP',
  lp_submit_income: '年収証明LP',
  lp_asset_income: '資産証明LP',
  lp_age_verification: '年齢確認LP',
  lp_self_introduction: '自己紹介LP',
  lp_questionlike: '質問付きいいね!設定LP',
  lp_secretmode: 'シークレットモードLP',
  lp_how_to_use_paddy: 'paddyの使い方LP',
  lp_safety_initiatives: '安心・安全取り組みLP',
  popover_chancetime_start: 'チャンスタイム_開始',
  popover_chancetime_complete: 'チャンスタイム_完了',
  popover_daily_swipe: 'デイリースワイプ',
  popover_appointment_reminder: '約束リマインド',
  daily_login_bonus: 'ログインボーナス',
  popover_appeal_phone_auth_start: '電話認証訴求popover_開始',
  popover_appeal_phone_auth_complete: '電話認証訴求popover_完了',
  popover_appeal_premium: '有料訴求ダイアログ',
  popover_appeal_royal: 'ロイヤル訴求ダイアログ',
  modal_phone_input: '電話認証_入力モーダル',
  modal_phone_code_input: '電話認証_認証コード入力モーダル',
  dialog_phone_verification: '電話認証ダイアログ',
  dialog_age_verification: '年確ダイアログ',
  dialog_point_shortage: 'ポイント不足ダイアログ',
  dialog_richlike_shortage: 'リッチいいね!不足ダイアログ',
  dialog_opponent_is_freezed: '相手が凍結ダイアログ',
  dialog_i_am_freezed: '自分が凍結ダイアログ',
  dialog_internet_unstable: 'ネット接続が不安定ダイアログ',
  dialog_internet_offline: 'ネット接続がオフラインダイアログ',
  dialog_app_forced_shutdown: 'paddyを終了しますダイアログ_app',
  dialog_web_forced_shutdown: 'paddyを終了しますダイアログ_web',
  dialog_forced_update: '強制アップデートダイアログ_app',
  dialog_restore: '購読を復元ダイアログ_app',
  dialog_logout: 'ログアウトダイアログ'
};
