import { BaseSyntheticEvent, Fragment, memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock-upgrade';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import {
  useGetChatRoom,
  usePostReadAll,
  usePostTalks,
  usePutAppointments,
  usePostCheckDuplicateAppointments
} from '@/apis';
import { badgeKeys, chatRoomKeys } from '@/apis/queryKeys';
import { ReactComponent as Photo } from '@/assets/icons/photo.svg';
import { MessageTemplate } from '@/components/features/MessageTemplate';
import { AddImgModal } from '@/components/features/modal/AddImgModal';
import { AppointmentConfirmModal } from '@/components/features/modal/AppointmentConfirmModal';
import { AppointmentModal } from '@/components/features/modal/AppointmentModal';
import { FavoriteModal } from '@/components/features/modal/favoriteModal';
import { ImgModal } from '@/components/features/modal/ImgModal';
import { MenuModal } from '@/components/features/modal/MenuModal';
import { NoteModal } from '@/components/features/modal/NoteModal';
import { SwipeShow } from '@/components/features/show/SwipeShow';
import { UserImg } from '@/components/styles/projects/UserImg';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { MessageInput } from '@/components/styles/uis/MessageInput';
import { SendSubmitButton } from '@/components/styles/uis/SendSubmitButton';
import { draftMessageAtom } from '@/contexts/atoms/draftMessage';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { formatDate, formatDateRange, getDuplicateAlertText, getUserImage } from '@/functions/helpers';
import { useBasicModal, useDatabase, useDisclosure, useSnackbar } from '@/functions/hooks';
import { useVirtualViewportHeight } from '@/functions/hooks/useVirtualViewportHeight';
import { ChatRoom } from '@/functions/types/chatRoom';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/pages/chat.module.scss';

type Props = {
  chatRoom: ChatRoom;
  onClose: () => void;
  setRefetchChatFlag?: (flag: boolean) => void;
};

export const Chat: React.FC<Props> = memo((props) => {
  const { chatRoom: initialChatRoom, onClose, setRefetchChatFlag } = props;

  const [draftMessage, setDraftMessage] = useAtom(draftMessageAtom);

  const [messageText, setMessageText] = useState(draftMessage[initialChatRoom.id] || '');
  const [isInpputting, setIsInpputting] = useState(false);
  const [messageImgs, setMessageImgs] = useState<string[]>([]);
  const [zoomImg, setZoomImg] = useState('');
  const [iconMenu, setIconMenu] = useState(true);
  const [footerMenu, setFooterMenu] = useState(false);
  const [messageTemplateOpen, setMessageTemplateOpen] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);

  const chatRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const scrollOnce = useRef(true);
  const appointmentModalRefOnce = useRef(true);

  const me = useAtomValue(meAtom);
  const { member, isMale, isMaleFree, isBeginner, isAgeConfirm, isPhoneVerify } = useAtomValue(meFlagAtom);

  const queryClient = useQueryClient();

  const favoriteModal = useDisclosure();
  const menuModal = useDisclosure();
  const addImgModal = useDisclosure();
  const imgModal = useDisclosure();
  const appointmentModal = useDisclosure();
  const appointmentConfirmModal = useDisclosure();
  const noteModal = useDisclosure();
  const {
    onCloseModal,
    messagePremiumGuidanceMessageModal,
    messageRoyalGuidanceModal,
    appointmentSuggestionModal,
    appointmentDecideModal,
    ageConfirmationModal,
    phoneVerificationModal
  } = useBasicModal();
  const { openSnackbar } = useSnackbar();

  const { isLoading, roomData, setRoomData, fetchRoomData } = useDatabase();

  const { postReadAll } = usePostReadAll();
  const { postTalks } = usePostTalks();
  const { fetchChatRoom } = useGetChatRoom();
  const { putAppointments } = usePutAppointments();
  const { postCheckDuplicate } = usePostCheckDuplicateAppointments();

  /**
   * チャットルーム詳細のデータを取得
   */
  const { data } = useQuery({
    queryKey: chatRoomKeys.detail(initialChatRoom.id),
    queryFn: async () => {
      if (!initialChatRoom.id) return null;
      const data = await fetchChatRoom({ chatRoomId: initialChatRoom.id });
      return data;
    }
  });

  /**
   * チャットルーム詳細からデータが取得できていない場合は、
   * 初期値（一覧からpropsで受け取ったChatRoom）を使用する
   */
  const chatRoom = useMemo(() => {
    if (data) {
      return data;
    }

    return initialChatRoom;
  }, [data]);

  const user = useMemo(() => {
    return chatRoom.users[0];
  }, [chatRoom]);

  const flatRoomData = useMemo(() => Object.values(roomData).flat(), [roomData]);

  const getSender = useCallback(
    (id: number) => {
      return id === me.id ? 'me' : 'you';
    },
    [me.id]
  );

  const setImg = useCallback((img: string | Blob) => {
    setMessageImgs((prev) => [...prev, img as string]);
  }, []);

  const handleClickMosaic = useCallback(() => {
    if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    }
  }, [isPhoneVerify, isAgeConfirm]);

  const handleClickImg = useCallback(
    (img: string) => {
      handleClickMosaic();
      if (isPhoneVerify && isAgeConfirm) {
        setZoomImg(img);
        imgModal.open();
      }
    },
    [isPhoneVerify, isAgeConfirm]
  );

  const onCloseImgModal = useCallback(() => {
    setZoomImg('');
    imgModal.close();
  }, []);

  const isMosaic = useMemo(() => {
    return !isPhoneVerify || !isAgeConfirm;
  }, [isPhoneVerify, isAgeConfirm]);

  /**
   * 添付画像を削除
   */
  const handleRemoveImg = useCallback(
    (e: BaseSyntheticEvent) => {
      const { value } = e.target;
      const filterImgs = messageImgs.filter((v) => v !== value);
      setMessageImgs(filterImgs);
    },
    [messageImgs]
  );

  /**
   * firebase に送信完了する前に、roomDataを更新する
   * 送信完了後、firebase からのデータを取得して、roomData を再度更新する
   */
  const pushRoomData = useCallback((message?: string, imgs?: string[]) => {
    const todayKey = formatDate(new Date(), { hideYear: true, hideTime: true });

    if (message && !imgs?.length) {
      setRoomData((prev) => {
        return {
          ...prev,
          [todayKey]: prev[todayKey]
            ? [...prev[todayKey], { id: prev[todayKey].length + 1, message, sender: me.id }]
            : [{ id: 1, message, sender: me.id }]
        };
      });
    }
    if (imgs?.length) {
      setRoomData((prev) => {
        return {
          ...prev,
          [todayKey]: prev[todayKey]
            ? [
                ...prev[todayKey],
                ...imgs.map((img, i) => ({
                  id: prev[todayKey].length + i + 1,
                  image_url: img,
                  sender: me.id
                }))
              ]
            : imgs.map((img, i) => ({
                id: i + 1,
                image_url: img,
                sender: me.id
              }))
        };
      });
    }
  }, []);

  /**
   * メッセージ送信
   */
  const handleSubmitMessage = useCallback(async () => {
    setMessageLoading(true);
    setMessageImgs([]);
    if (!messageImgs.length) setMessageText('');

    pushRoomData(messageText, messageImgs);

    if (messageText && !messageImgs.length) {
      await postTalks({
        chat_room_id: chatRoom.id,
        free_text: messageText
      });
    }

    if (messageImgs.length) {
      await Promise.all(
        messageImgs.map(async (img) => {
          await postTalks({
            chat_room_id: chatRoom.id,
            image_url: img
          });
        })
      );
    }

    setMessageLoading(false);
    if (setRefetchChatFlag) setRefetchChatFlag(true);
  }, [chatRoom.id, messageText, messageImgs]);

  /**
   * refetchChatFlag が true になると、Chatモーダルを閉じた時に ChatRoom のデータを再取得する
   */
  const refetchChat = useCallback(() => {
    if (setRefetchChatFlag) setRefetchChatFlag(true);
    queryClient.invalidateQueries(chatRoomKeys.detail(chatRoom.id));
  }, []);

  /**
   * フッターメニューを開閉
   */
  const handleClickFooterMenu = useCallback(() => {
    if (footerMenu) {
      setFooterMenu(false);
      setMessageTemplateOpen(false);
    } else {
      setFooterMenu(true);
    }
  }, [footerMenu]);

  const checkIsFirst = useCallback(() => {
    const isFirst =
      flatRoomData.length === 0 || !flatRoomData.some((v) => v.sender === me.id && !v.content && !!v.message);

    return isFirst;
  }, [me.id, flatRoomData]);

  const handleClickAgree = useCallback(
    async (appointmentId: number) => {
      await putAppointments({ id: appointmentId, body: { state: 'agreed' } });

      refetchChat();

      onCloseModal();
      setTimeout(() => {
        appointmentDecideModal(
          <UserImg
            src={getUserImage(user, isBeginner)}
            size={72}
            isRoyal={!isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')}
          />,
          <UserImg src={getUserImage(me, isBeginner)} size={72} isRoyal={!isBeginner && member === 'royal'} />
        );
      }, animateDuration);
    },
    [user, isBeginner, chatRoom.id]
  );

  const handleClickAppointment = useCallback(
    (status?: 'pending' | 'agreed') => {
      if (!isPhoneVerify) {
        phoneVerificationModal();
        return;
      }
      if (!isAgeConfirm) {
        ageConfirmationModal();
        return;
      }
      if (isMaleFree) {
        messagePremiumGuidanceMessageModal();
        return;
      }

      if (status && chatRoom.appointment) {
        appointmentConfirmModal.open();
      } else {
        appointmentModal.open();
      }
    },
    [chatRoom, isPhoneVerify, isAgeConfirm, isMaleFree]
  );

  const handleClickCancel = useCallback(async (appointmentId: number) => {
    await putAppointments({ id: appointmentId, body: { state: 'cancelled' } });
    refetchChat();
    onCloseModal();
    appointmentConfirmModal.close();
    openSnackbar({
      type: 'toast',
      text: '約束をキャンセルしました。',
      bottom: 152
    });
  }, []);

  const handleClickDisagree = useCallback(async (appointmentId: number) => {
    await putAppointments({ id: appointmentId, body: { state: 'disagreed' } });
    refetchChat();
    onCloseModal();
    openSnackbar({
      type: 'toast',
      text: '約束をお断りしました。',
      bottom: 152
    });
  }, []);

  const openAppointmentSuggestionModal = useCallback(async () => {
    if (
      !chatRoom.appointment ||
      !isPhoneVerify ||
      !isAgeConfirm ||
      isMaleFree ||
      chatRoom.appointment.send_user_id === me.id ||
      chatRoom.appointment.state !== 'pending'
    )
      return;

    const { board, appointment } = await postCheckDuplicate({ id: chatRoom.appointment.id });
    const dateTime = formatDateRange(chatRoom.appointment.start_at, chatRoom.appointment.end_at);

    appointmentSuggestionModal(
      dateTime,
      chatRoom.appointment.place,
      () => handleClickAgree(chatRoom.appointment!.id),
      () => handleClickDisagree(chatRoom.appointment!.id),
      getDuplicateAlertText(board, appointment)
    );
  }, [chatRoom]);

  const handleClickSuggestion = useCallback(async () => {
    if (!isPhoneVerify) {
      phoneVerificationModal();
      return;
    }
    if (!isAgeConfirm) {
      ageConfirmationModal();
      return;
    }
    if (isMaleFree) {
      messagePremiumGuidanceMessageModal();
      return;
    }

    openAppointmentSuggestionModal();
  }, [chatRoom, isPhoneVerify, isAgeConfirm, isMaleFree]);

  const handleOpenNoteModal = useCallback(() => {
    if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (isMaleFree) {
      messagePremiumGuidanceMessageModal();
    } else {
      noteModal.open();
    }
  }, [isPhoneVerify, isAgeConfirm, isMaleFree]);

  const handleOpenTemplate = useCallback(() => {
    if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (isMaleFree) {
      messagePremiumGuidanceMessageModal();
    } else {
      setMessageTemplateOpen(true);
    }
  }, [isPhoneVerify, isAgeConfirm, isMaleFree]);

  const handleOpenImgModal = useCallback(() => {
    if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (isMaleFree) {
      messagePremiumGuidanceMessageModal();
    } else {
      setFooterMenu(false);
      addImgModal.open();
    }
  }, [isPhoneVerify, isAgeConfirm, isMaleFree]);

  const handleClickMessage = useCallback(
    (e: BaseSyntheticEvent) => {
      if (!isPhoneVerify) {
        e.target.blur();
        phoneVerificationModal();
      } else if (!isAgeConfirm) {
        e.target.blur();
        ageConfirmationModal();
      } else if (isMaleFree) {
        e.target.blur();
        messagePremiumGuidanceMessageModal();
      } else {
        setFooterMenu(false);
        setIconMenu(false);
      }
    },
    [isPhoneVerify, isAgeConfirm, isMaleFree]
  );

  const handleClickUser = useCallback((userId: number) => {
    setUserId(userId);
  }, []);

  const linkify = useCallback((text: string) => {
    const urlPattern = /\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%_=~|]/gi;
    return text.replace(
      urlPattern,
      '<a href="$&" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">$&</a>'
    );
  }, []);

  // チャットルーム一覧の unread_num を 0 に更新
  const updateUnreadNum = useCallback(async (id: number) => {
    queryClient.setQueriesData(chatRoomKeys.lists(), (prevData: any) => {
      // ページごとにチャットルームを検索し、該当するチャットルームの unread_num を 0 に設定
      const updatedData = prevData.pages.map((page: any[]) =>
        page.map((chatRoom: any) => (chatRoom.id === id ? { ...chatRoom, unread_num: 0 } : chatRoom))
      );

      return { ...prevData, pages: updatedData };
    });
  }, []);

  useEffect(() => {
    if (!chatRoom.id) return;

    // 約束確認待ちダイアログを表示
    if (appointmentModalRefOnce.current) {
      setTimeout(() => {
        openAppointmentSuggestionModal();
        appointmentModalRefOnce.current = false;
      }, animateDuration);
    }

    fetchRoomData(chatRoom.id);

    (async () => {
      // チャットを開いたタイミングで既読にする
      await postReadAll({ id: chatRoom.id });

      // チャットルーム一覧の unread_num を 0 にする
      await updateUnreadNum(chatRoom.id);

      // badge の再取得
      await queryClient.invalidateQueries(badgeKeys.all);
    })();
  }, [chatRoom.id]);

  useEffect(() => {
    // footerRef の 高さが変わるタイミングで ChatRef の padding-bottom を更新する
    const firstTextHeight = !isLoading && checkIsFirst() ? 70 : 0;
    if (chatRef.current && footerRef.current) {
      chatRef.current.style.paddingBottom = `${footerRef.current.clientHeight + firstTextHeight}px`;
    }

    if (!isLoading && flatRoomData.length > 0) {
      // チャット送信時 もしくは footerRef の 高さが変わるタイミング にスクロールを一番下にする
      if (scrollOnce.current) {
        // 初回レンダリング時はアニメーションさせない
        chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
        scrollOnce.current = false;
      } else {
        // 初回レンダリング以降はアニメーションさせる
        chatRef.current?.scrollTo({
          top: chatRef.current?.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [isLoading, footerMenu, messageTemplateOpen, flatRoomData, messageImgs, messageText]);

  useEffect(() => {
    // 相手から送信されたメッセージを受信したら、既読にする & チャットルームのデータを再取得
    if (flatRoomData.length && flatRoomData[flatRoomData.length - 1].sender !== me.id) {
      refetchChat();
      (async () => {
        postReadAll({ id: chatRoom.id });
      })();
    }
  }, [flatRoomData]);

  useEffect(() => {
    return () => {
      setDraftMessage({ ...draftMessage, [chatRoom.id]: messageText });
    };
  }, [chatRoom.id, messageText]);

  useEffect(() => {
    if (!userId) {
      disableBodyScroll(chatRef.current!); // chatRefのみスクロールを許可する
    } else {
      clearAllBodyScrollLocks(); // ユーザー詳細が開いている時はスクロールを許可する
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [chatRoom.id, userId]);

  useVirtualViewportHeight();

  return (
    <>
      <TrackPageView viewName='message_detail' />

      <div className={styles.wrapper}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={styles['modal-wrapper']}
        >
          <div className={styles.header}>
            <div className={styles['header-inner']}>
              <button type='button' onClick={onClose} className={components['back-link-button']} aria-label='close' />
              <div className={styles['header-user']}>
                <UserImg
                  src={getUserImage(user, isBeginner)}
                  size={32}
                  isRoyal={!isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')}
                  id={user.id}
                  onClick={handleClickUser}
                />
                <span>{chatRoom.attached_name || user.property.nickname}</span>
              </div>
              <div className={styles['button-wrapper']}>
                <button
                  type='button'
                  className={
                    chatRoom.favorite_rank === 0
                      ? styles['header-favorite-button-star-outline']
                      : styles['header-favorite-button-star-fill']
                  }
                  onClick={favoriteModal.open}
                  aria-label='favorite'
                >
                  {chatRoom.favorite_rank === 0 ? '' : chatRoom.favorite_rank}
                </button>
                <button
                  type='button'
                  className={styles['header-menu-button']}
                  onClick={menuModal.open}
                  aria-label='menu'
                />
              </div>
            </div>

            {chatRoom.appointment?.state === 'pending' && chatRoom.appointment?.send_user_id !== me.id && (
              <div className={styles['toast-wrapper']} data-appointment>
                <p>確認待ちのお約束があります。</p>
              </div>
            )}
          </div>

          {roomData ? (
            <div className={styles.contents} ref={chatRef}>
              <ul>
                <li className={styles['match-message']}>
                  <div className={styles['match-message-inner']}>
                    <div className={styles['match-message-icon']}>
                      <UserImg
                        src={getUserImage(user, isBeginner)}
                        size={32}
                        noWrap
                        isRoyal={!isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')}
                      />
                      <UserImg
                        src={getUserImage(me, isBeginner)}
                        size={32}
                        noWrap
                        isRoyal={!isBeginner && member === 'royal'}
                      />
                    </div>
                    <p>
                      <span className={components['text-bold']}>{user.property.nickname}さんとマッチしました</span>
                      <br />
                      メッセージを送りましょう
                    </p>
                  </div>
                </li>

                {Object.entries(roomData).map(([date, messages]) => (
                  <Fragment key={date}>
                    <li className={styles['message-date']}>{date}</li>
                    {messages.map((message) => (
                      <Fragment key={message.id}>
                        {!message.stamp_id && message.content !== 'match' && (
                          <li key={message.id} className={styles.message} data-sender={getSender(message.sender)}>
                            {getSender(message.sender) === 'you' && (
                              <UserImg
                                src={getUserImage(user, isBeginner)}
                                size={32}
                                isRoyal={
                                  !isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')
                                }
                                id={user.id}
                                onClick={handleClickUser}
                              />
                            )}
                            {message.image_url && (
                              <div
                                className={styles['message-img']}
                                data-mosaic={isMosaic}
                                data-sender={getSender(message.sender)}
                              >
                                {isMosaic && getSender(message.sender) === 'you' && (
                                  <button
                                    type='button'
                                    className={styles['mosaic-button']}
                                    onClick={() => handleClickMosaic()}
                                  >
                                    確認
                                  </button>
                                )}

                                <button type='button' onClick={() => handleClickImg(message.image_url!)}>
                                  <img src={message.image_url} alt='' width={134} height={134} />
                                </button>
                              </div>
                            )}
                            {message.message && (
                              <div className={styles['message-text-block']} data-mosaic={isMosaic}>
                                {isMosaic && getSender(message.sender) === 'you' && (
                                  <button
                                    type='button'
                                    className={styles['mosaic-button']}
                                    onClick={() => handleClickMosaic()}
                                  >
                                    内容を確認
                                  </button>
                                )}
                                {message.content === 'board' && (
                                  <p className={styles['message-board-ttl']}>募集への応募</p>
                                )}
                                <p
                                  className={components['text-break']}
                                  dangerouslySetInnerHTML={{ __html: linkify(message.message) }} // eslint-disable-line
                                />
                              </div>
                            )}
                            <div className={styles['message-info']}>
                              {isMale &&
                                getSender(message.sender) === 'me' &&
                                (member === 'royal'
                                  ? message.read_at && <span className={styles['message-read']} />
                                  : member === 'premium' && (
                                      <button
                                        type='button'
                                        onClick={messageRoyalGuidanceModal}
                                        className={styles['message-read']}
                                        data-lock
                                        aria-label='lock'
                                      />
                                    ))}
                              {message.created_at && (
                                <span className={styles['message-time']}>
                                  {formatDate(message.created_at, {
                                    hideYear: true,
                                    hideMonth: true,
                                    hideWeekday: true,
                                    hideDay: true
                                  })}
                                </span>
                              )}
                            </div>
                          </li>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </ul>
            </div>
          ) : (
            <div className={components['center-container']}>
              <LoadingSpinner />
            </div>
          )}

          <div className={styles.footer} ref={footerRef}>
            {!isLoading && checkIsFirst() && (
              <div className={styles['toast-wrapper']} data-first-message>
                <p>1通目でLINE ID等の個人情報は送信できません。</p>
              </div>
            )}

            {messageImgs.length ? (
              <div className={styles['footer-imgs']}>
                <div className={styles['footer-imgs-inner']}>
                  <ul className={styles['footer-imgs-list']}>
                    {messageImgs.map((img) => (
                      <li key={img} className={styles['footer-imgs-item']}>
                        <button
                          type='button'
                          onClick={() => handleClickImg(img)}
                          className={styles['footer-imgs-item-button']}
                        >
                          <img src={img} alt='' width={72} height={72} />
                        </button>
                        <button
                          type='button'
                          value={img}
                          onClick={handleRemoveImg}
                          className={styles['footer-imgs-remove']}
                          aria-label='remove'
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}

            <div className={styles['footer-inner']}>
              <div className={styles['button-wrapper']}>
                {iconMenu ? (
                  <motion.div initial={{ x: -5 }} animate={{ x: 0 }} exit={{ x: 5 }}>
                    {!messageImgs.length ? (
                      <motion.button
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        type='button'
                        className={styles['footer-menu-button']}
                        onClick={handleClickFooterMenu}
                        data-active={footerMenu}
                        data-appointment-status={chatRoom.appointment?.state}
                        aria-label='menu'
                      />
                    ) : (
                      <motion.button
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        type='button'
                        className={styles['footer-close-button']}
                        onClick={() => setMessageImgs([])}
                      />
                    )}
                    <motion.button
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      type='button'
                      className={styles['footer-img-button']}
                      onClick={() => handleOpenImgModal()}
                    >
                      <Photo width={28} height={28} />
                    </motion.button>
                  </motion.div>
                ) : (
                  <motion.button
                    type='button'
                    className={styles['footer-arrow-icon-button']}
                    onClick={() => setIconMenu(true)}
                    aria-label='arrow'
                    initial={{ opacity: 0.5, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0.5, scale: 0.8 }}
                  />
                )}
              </div>
              <div className={styles['footer-message-wrapper']}>
                {!messageImgs.length ? (
                  <MessageInput
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    onClick={(e) => handleClickMessage(e)}
                    onCompositionStart={() => setIsInpputting(true)}
                    onCompositionEnd={() => setIsInpputting(false)}
                    onSubmit={handleSubmitMessage}
                    isLoading={messageLoading}
                    placeholder='メッセージを入力'
                    disabled={(messageText.trim().length === 0 && messageImgs.length === 0) || isInpputting}
                    afterFocus
                  />
                ) : (
                  <div className={styles['footer-submit-button-selecting-image']}>
                    <SendSubmitButton onSubmit={handleSubmitMessage} />
                  </div>
                )}
              </div>
              {messageImgs.length > 0 && (
                <span className={clsx(components['basic-text'], styles['footer-text-selecting-image'])}>
                  {messageImgs.length}件選択中
                </span>
              )}
            </div>
            {footerMenu && (
              <>
                {!messageTemplateOpen && (
                  <div className={styles['footer-menu-inner']}>
                    {chatRoom.appointment ? (
                      <>
                        {chatRoom.appointment.state === 'pending' && (
                          <button
                            type='button'
                            className={styles['appointment-button']}
                            onClick={() =>
                              chatRoom.appointment?.send_user_id === me.id
                                ? handleClickAppointment('pending')
                                : handleClickSuggestion()
                            }
                            data-status='pending'
                          >
                            約束確認待ち
                          </button>
                        )}
                        {chatRoom.appointment.state === 'agreed' && (
                          <button
                            type='button'
                            className={styles['appointment-button']}
                            onClick={() => handleClickAppointment('agreed')}
                            data-status='agreed'
                          >
                            約束済み
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        type='button'
                        className={styles['appointment-button']}
                        onClick={() => handleClickAppointment()}
                      >
                        約束
                      </button>
                    )}
                    <button type='button' className={styles['memo-button']} onClick={() => handleOpenNoteModal()}>
                      メモ
                    </button>
                    <button type='button' className={styles['template-button']} onClick={() => handleOpenTemplate()}>
                      定型文
                    </button>
                  </div>
                )}

                {messageTemplateOpen && user && (
                  <MessageTemplate userName={user.property.nickname} setMessageText={(text) => setMessageText(text)} />
                )}
              </>
            )}
          </div>
        </motion.div>

        {zoomImg && <ImgModal img={zoomImg} isOpen={imgModal.isOpen} onClose={onCloseImgModal} />}

        <FavoriteModal
          id={chatRoom.id}
          isOpen={favoriteModal.isOpen}
          onClose={favoriteModal.close}
          value={chatRoom.favorite_rank}
          refetchChat={refetchChat}
        />

        <MenuModal
          isOpen={menuModal.isOpen}
          onClose={menuModal.close}
          userId={user.id}
          userImageId={user?.top_image.id}
          faceweight={user?.top_image.face_weight}
          nickname={user.property.nickname}
          attachedName={chatRoom.attached_name}
          isAppointment={!!chatRoom.appointment}
          onClosePage={onClose}
          chatRoomId={chatRoom.id}
          refetch={refetchChat}
        />

        <AddImgModal
          from='chat'
          chatRoomId={chatRoom.id}
          isOpen={addImgModal.isOpen}
          onClose={addImgModal.close}
          setImg={setImg}
        />

        <AppointmentModal
          isOpen={appointmentModal.isOpen}
          onClose={appointmentModal.close}
          chatRoomId={chatRoom.id}
          refetchChat={refetchChat}
        />

        {chatRoom.appointment && (
          <AppointmentConfirmModal
            appointment={chatRoom.appointment}
            isOpen={appointmentConfirmModal.isOpen}
            onClose={appointmentConfirmModal.close}
            onCancel={handleClickCancel}
          />
        )}

        <NoteModal
          isOpen={noteModal.isOpen}
          onClose={noteModal.close}
          chatRoomId={chatRoom.id}
          note={chatRoom.note}
          refetchChat={refetchChat}
        />

        <AnimatePresence mode='wait' initial={false}>
          {userId && <SwipeShow userId={userId} userIds={[userId]} setUserId={setUserId} isVisibleMenu={false} />}
        </AnimatePresence>
      </div>
    </>
  );
});
