import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  category: string;
  reason: string;
  to_user_id: number;
  snackbarBottom?: number;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v1/reports', params);
};

/**
 * ユーザー通報
 */

type Props = Params;

export const usePostReports = () => {
  const { openSnackbar } = useSnackbar();

  const postReports = useCallback(async (props: Props) => {
    await api(props);

    const { snackbarBottom } = props;

    openSnackbar({
      type: 'toast',
      text: '通報が完了しました。',
      bottom: snackbarBottom
    });
  }, []);

  return { postReports };
};
