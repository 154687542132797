import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  imgId: number;
};

const api = (params: Params): Promise<Response> => {
  return client.delete(`v1/me/images/${params.imgId}`);
};

type Props = Params;

/**
 * 自身のメイン・サブ画像を削除する
 */

export const useDeleteImages = () => {
  const deleteImages = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { deleteImages };
};
