import { memo, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import Lottie from 'lottie-react';
import { ReactComponent as ChatFilled } from '@/assets/icons/chat_filled.svg';
import { ReactComponent as ItsAMatch } from '@/assets/icons/its_a_match.svg';
import Matching from '@/assets/json/matching.json';
import { CheckBox } from '@/components/styles/uis/CheckBox';
import { FullModal } from '@/components/styles/uis/FullModal';
import { meAtom } from '@/contexts/atoms/me';
import { useMatchModal } from '@/functions/hooks/useMatchModal';
import styles from '@/styles/features/modal/matchModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userImg: string;
  onOpenChat: () => void;
  meImg: string;
};

export const MatchModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, userImg, onOpenChat, meImg } = props;

  const { id } = useAtomValue(meAtom);
  const { isShownMatchModal, setShownMatchModal } = useMatchModal();

  const [showLottie, setShowLottie] = useState(false);

  // Lottieの始動とmotion.divのdeleyのずれ解消の処理
  // setTimeoutとdeleyの開始タイミングを合わせる
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowLottie(true);
      }, 800);
    }
  }, [isOpen]);

  const onCloseMatchModal = () => {
    setShowLottie(false); // 再度表示されたときにアニメーションを初期化するための対応
    onClose();
  };

  // 下からフェードインする設定
  const variants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <FullModal id='matchModal' isOpen={isOpen} onClose={onCloseMatchModal}>
      <div className={styles.wrapper}>
        <AnimatePresence>
          <motion.div
            initial='hidden'
            animate='visible'
            variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            transition={{ duration: 1, delay: 3 }}
          >
            <button type='button' onClick={onCloseMatchModal} className={styles['close-button']} aria-label='close' />
          </motion.div>
        </AnimatePresence>

        <div className={styles.inner}>
          <AnimatePresence>
            <motion.div
              className={styles.heading}
              initial='hidden'
              animate='visible'
              variants={variants}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div className={styles['heading-img']}>
                <ItsAMatch />
                <p className={styles['heading-text']}>
                  マッチしました！
                  <br />
                  メッセージを送ってみましょう！
                </p>
              </div>
            </motion.div>
          </AnimatePresence>

          <div className={styles['img-block']}>
            <AnimatePresence>
              <motion.div
                className={styles['img-wrapper']}
                data-user
                initial='hidden'
                animate='visible'
                variants={{
                  hidden: { opacity: 0, x: -50, rotate: -40 },
                  visible: { opacity: 1, x: [0, 5, 0, 5], rotate: [-40, -25, -20, -15, -10, -15, -25, -15] }
                }}
                transition={{ duration: 0.75, delay: 0.8 }}
              >
                <img src={userImg} alt='' />
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                className={styles['img-wrapper']}
                data-me
                initial='hidden'
                animate='visible'
                variants={{
                  hidden: { opacity: 0, x: 50, rotate: 40 },
                  visible: { opacity: 1, x: [0, -5, 0, -5], rotate: [40, 25, 20, 15, 10, 15, 25, 15] }
                }}
                transition={{ duration: 0.75, delay: 0.8 }}
              >
                <img src={meImg} alt='' />
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                className={styles['center-icon']}
                initial='hidden'
                animate='visible'
                variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                transition={{ duration: 0.75, delay: 0.8 }}
              >
                {showLottie && <Lottie animationData={Matching} loop={0} />}
              </motion.div>
            </AnimatePresence>
          </div>

          <div className={styles['button-wrapper']}>
            <AnimatePresence>
              <motion.div initial='hidden' animate='visible' variants={variants} transition={{ duration: 1, delay: 2 }}>
                <button type='button' onClick={onOpenChat} className={styles['message-button']}>
                  <ChatFilled className={styles['message-icon']} />
                  メッセージを送る
                </button>
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                initial='hidden'
                animate='visible'
                variants={variants}
                transition={{ duration: 1, delay: 2.5 }}
              >
                <button type='button' onClick={onCloseMatchModal} className={styles['later-button']}>
                  閉じる
                </button>
              </motion.div>
            </AnimatePresence>
          </div>

          <AnimatePresence>
            <motion.div
              className={styles['checkbox-wrapper']}
              initial='hidden'
              animate='visible'
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              transition={{ duration: 1, delay: 3 }}
            >
              <CheckBox
                isReverse
                selected={!isShownMatchModal(id)}
                onChange={(e) => setShownMatchModal(id, !e.target.checked)}
              >
                <span>以降この画面を表示しない</span>
              </CheckBox>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </FullModal>
  );
});
