import { memo, useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePostReports } from '@/apis';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import { Select } from '@/components/styles/uis/Select';
import { TextareaInput } from '@/components/styles/uis/TextareaInput';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { animateDuration } from '@/functions/constants/framerMotion';
import { getObjValue } from '@/functions/helpers';
import { useDisclosure } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/menuModal.module.scss';

const schema = z.object({
  largeCategory: z.string().nonempty(),
  smallCategory: z.string().nonempty(),
  reason: z.string().nonempty(),
  to_user_id: z.number()
});

type Schema = z.infer<typeof schema>;

type Categories = {
  [key: string]: {
    name: string;
    small_category: {
      [key: string]: string;
    };
  };
};
type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClosePage?: () => void;
  userId: number;
  refetch?: () => Promise<void>;
  shouldUpdateUsersCache?: boolean;
  snackbarBottom?: number;
};

export const ReportModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, onClosePage, userId, refetch, shouldUpdateUsersCache, snackbarBottom } = props;

  const [temporarily, setTemporarily] = useState('');

  const [smallOptions, setSmallOptions] = useState({});

  // @ts-ignore
  const categories = useAtomValue(masterTypesAtom).report_categories as Categories;

  const largeOptionModal = useDisclosure();
  const smallOptionModal = useDisclosure();

  const { postReports } = usePostReports();

  const { updateUsersCache } = useCache();

  const largeOptions = useMemo(() => {
    const obj = {};
    Object.entries(categories).forEach(([key, value]) => {
      // @ts-ignore
      obj[key] = value.name;
    });

    return obj;
  }, []);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      largeCategory: '',
      smallCategory: '',
      reason: '',
      to_user_id: userId
    },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    await postReports({
      category: `${formData.largeCategory}_${formData.smallCategory}`,
      reason: formData.reason,
      to_user_id: formData.to_user_id,
      snackbarBottom
    });

    if (refetch) {
      await refetch();
    }
    if (shouldUpdateUsersCache) {
      updateUsersCache(userId, 'report');
    }
    reset();
    onClose();
    if (onClosePage) {
      setTimeout(() => {
        onClosePage();
      }, animateDuration);
    }
  };

  const setTemplateReason = useCallback(() => {
    const templateText = '【約束機能を使いましたか？（はい、いいえでご回答ください）】\n';
    setValue('reason', temporarily === 'todaycancel' ? templateText : '', { shouldValidate: true });
  }, [temporarily]);

  const handleOpenLarge = useCallback(() => {
    setTemporarily(getValues('largeCategory'));
    largeOptionModal.open();
  }, []);

  const handleSaveLargeOption = useCallback(() => {
    setValue('largeCategory', temporarily, { shouldValidate: true });
    setValue('smallCategory', '', { shouldValidate: true });
    setTemplateReason();
    setSmallOptions(categories[temporarily].small_category);
    largeOptionModal.close();
  }, [temporarily]);

  const handleOpenSmall = useCallback(() => {
    setTemporarily(getValues('smallCategory'));
    smallOptionModal.open();
  }, []);

  const handleSaveSmallOption = useCallback(() => {
    setValue('smallCategory', temporarily, { shouldValidate: true });
    smallOptionModal.close();
  }, [temporarily]);

  return (
    <>
      <HalfModal id='reportModal' isOpen={isOpen} onClose={onClose} title='通報' isInner>
        <div className={components['gap-wrapper']}>
          <Select
            value={getObjValue(largeOptions, getValues('largeCategory'))}
            placeholder='違反の種類'
            onClick={handleOpenLarge}
          />
          {getValues('largeCategory') && (
            <Select
              value={getObjValue(smallOptions, getValues('smallCategory'))}
              placeholder='場所・シーン'
              onClick={handleOpenSmall}
            />
          )}
          <TextareaInput
            id='reportText'
            value={watch('reason')}
            register={register('reason')}
            rows={5}
            placeholder='違反内容の詳細'
          />
          <div className={styles['annotation-block']}>
            <p>※通報するとお相手とメッセージできなくなります。</p>
            <p>※通報いただいた内容は当局で確認の上で処置を検討させて頂きます。</p>
          </div>
          <Button color='black' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            通報する
          </Button>
        </div>
      </HalfModal>

      <HalfModal
        id='reportCategory'
        isOpen={largeOptionModal.isOpen}
        onClose={largeOptionModal.close}
        title='違反の種類'
        footer={
          <Button color='black' onClick={handleSaveLargeOption} disabled={!temporarily}>
            決定
          </Button>
        }
      >
        <RadioGroup selected={temporarily} options={largeOptions} onChange={(e) => setTemporarily(e.target.value)} />
      </HalfModal>

      <HalfModal
        id='detailCategory'
        isOpen={smallOptionModal.isOpen}
        onClose={smallOptionModal.close}
        title='場所・シーン'
        footer={
          <Button color='black' onClick={handleSaveSmallOption} disabled={!temporarily}>
            決定
          </Button>
        }
      >
        <RadioGroup selected={temporarily} options={smallOptions} onChange={(e) => setTemporarily(e.target.value)} />
      </HalfModal>
    </>
  );
});
