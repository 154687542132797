export const basePath = import.meta.env.VITE_BASE_PATH;

export const apiPath = import.meta.env.VITE_API_PATH;

export const snackbarDuration = 2000;

export const weekDays = ['日', '月', '火', '水', '木', '金', '土'];

export const publicPaths = ['/', '/signup', '/login'];

export const otpLength = 6;
