import { memo } from 'react';
import Sample02 from '@/assets/img_sample_female_02.png';
import Sample03 from '@/assets/img_sample_female_03.png';
import Sample04 from '@/assets/img_sample_female_04.png';
import Sample12 from '@/assets/img_sample_female_12.png';
import Sample13 from '@/assets/img_sample_female_13.png';
import Sample14 from '@/assets/img_sample_female_14.png';
import Sample15 from '@/assets/img_sample_female_15.png';
import Sample16 from '@/assets/img_sample_female_16.png';
import Sample17 from '@/assets/img_sample_female_17.png';
import SampleMovie1 from '@/assets/sample/video_1.gif';
import SampleMovie2 from '@/assets/sample/video_2.gif';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/sampleMovieModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SampleMovieModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  return (
    <HalfModal id='sampleMovieModal' isOpen={isOpen} onClose={onClose} title='魅力的な動画の選び方' isInner>
      <p className={styles.heading}>適切な動画の例</p>
      <div className={styles.banner}>
        <p className={components['basic-text']}>投稿できる動画の秒数</p>
        <p className={styles['banner-time']}>
          <span>5~30</span>秒
        </p>
      </div>
      <div className={styles.examples}>
        <div className={styles.example}>
          <img src={SampleMovie1} alt='サンプル10' />
        </div>
        <div className={styles.example}>
          <img src={SampleMovie2} alt='サンプル11' />
        </div>
      </div>
      <div className={styles.cards}>
        <div className={styles.card} data-good>
          <p className={styles['card-title']}>登録できる動画</p>
          <ul className={styles['sample-list']}>
            <li className={styles['sample-item']}>
              <img src={Sample03} alt='サンプル3' />
              <p>顔とスタイルがわかる</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample02} alt='サンプル2' />
              <p>バストアップ</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample17} alt='サンプル17' />
              <p>お顔の一部を隠す</p>
            </li>
          </ul>
        </div>
        <div className={styles.card} data-bad>
          <p className={styles['card-title']}>登録できない動画</p>
          <ul className={styles['sample-list']} data-wrap>
            <li className={styles['sample-item']}>
              <img src={Sample12} alt='サンプル12' />
              <p>過度な加工フィルター</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample04} alt='サンプル4' />
              <p>複数人で写っている</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample13} alt='サンプル13' />
              <p>見えにくい動画</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample15} alt='サンプル15' />
              <p>他人の動画</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample14} alt='サンプル14' />
              <p>後ろ姿</p>
            </li>
            <li className={styles['sample-item']}>
              <img src={Sample16} alt='サンプル16' />
              <p>
                規約に反する文字や
                <br />
                音声
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Button color='black' onClick={onClose}>
        閉じる
      </Button>
    </HalfModal>
  );
});
