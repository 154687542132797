import { memo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePutNote } from '@/apis';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { TextareaInput } from '@/components/styles/uis/TextareaInput';
import components from '@/styles/components/index.module.scss';

const schema = z.object({
  note: z.string().max(100, '100文字以内で入力してください')
});

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  chatRoomId: number;
  refetchChat: () => void;
  note?: string;
};

export const NoteModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, chatRoomId, note, refetchChat } = props;

  const { putNote } = usePutNote();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      note
    },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    await putNote({
      id: chatRoomId,
      body: { ...formData }
    });
    refetchChat();
    onClose();
  };

  return (
    <HalfModal
      id='noteModal'
      isOpen={isOpen}
      onClose={onClose}
      title='メモ'
      isInner
      footer={
        <Button color='black' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          保存
        </Button>
      }
    >
      <p className={components['text-small']}>お相手に関するメモを自由に記入できます。</p>
      <p className={components['basic-text']} style={{ marginTop: '8px' }}>
        例）◯◯さん、△△月✕✕日に初めて会った。イタリアンが好き。今月中にもう1回ご飯行く。
      </p>
      <div className={components['mt-gutter']}>
        <TextareaInput
          id='note'
          value={watch('note') ?? ''}
          register={register('note')}
          placeholder='メモ'
          annotation={errors?.note?.message}
          count={100}
          isInvalid={'note' in { ...errors }}
        />
      </div>
    </HalfModal>
  );
});
