export const GENDER = {
  MALE: 1,
  FEMALE: 2
} as const;

export const CLIENT_HEADER = {
  TOKEN: 'X-Pariv-Token',
  UUID: 'X-Pariv-Uuid',
  MANAGED_USER_ID: 'X-Pariv-Managed-User-Id',
  APP_ID: 'X-Pariv-App-Id',
  APP_VERSION: 'X-Pariv-App-Version',
  APP_VERSION_CODE: 'X-Pariv-App-Version-Code',
  OS: 'X-Pariv-Os',
  OS_VERSION: 'X-Pariv-Os-Version',
  MODEL: 'X-Pariv-Model'
} as const;

export const LOCAL_STORAGE = {
  PARIV_TOKEN: {
    KEY: 'pariv-token', // NOTE: ローカルストレージのkey
    REMOVE_ON_LOGOUT: true // NOTE: ログアウト時に削除するかどうか
  },
  PARIV_UUID: {
    KEY: 'pariv-uuid',
    REMOVE_ON_LOGOUT: false
  },
  PARIV_MANAGED_USER_ID: {
    KEY: 'pariv-managed-user-id',
    REMOVE_ON_LOGOUT: true
  },
  HOME_SEARCH_QUERY: {
    KEY: 'home-search-query',
    REMOVE_ON_LOGOUT: true
  },
  BOARD_SEARCH_QUERY: {
    KEY: 'board-search-query',
    REMOVE_ON_LOGOUT: true
  },
  CHATROOM_SEARCH_QUERY: {
    KEY: 'chat-room-search-query',
    REMOVE_ON_LOGOUT: true
  },
  HOME_COACHING: {
    KEY: 'home-coaching',
    REMOVE_ON_LOGOUT: false
  },
  BOARD_COACHING: {
    KEY: 'board-coaching',
    REMOVE_ON_LOGOUT: false
  },
  LIKE_COACHING: {
    KEY: 'like-coaching',
    REMOVE_ON_LOGOUT: false
  },
  MESSAGE_COACHING: {
    KEY: 'message-coaching',
    REMOVE_ON_LOGOUT: false
  },
  ACCOUNT_COACHING: {
    KEY: 'account-coaching',
    REMOVE_ON_LOGOUT: false
  },
  TEMPORAL_SEARCH_USER_IMPRESSION: {
    KEY: 'temporal-search-user-impression',
    REMOVE_ON_LOGOUT: true
  },
  ALL_SEARCH_USER_IMPRESSION: {
    KEY: 'all-search-user-impression',
    REMOVE_ON_LOGOUT: true
  },
  WITHIN_12_HOURS_APPOINTMENTS: {
    KEY: 'within-12-hours-appointments',
    REMOVE_ON_LOGOUT: true
  },
  WITHIN_24_TO_48_HOURS_APPOINTMENTS: {
    KEY: 'within-24-48-hours-appointments',
    REMOVE_ON_LOGOUT: true
  },
  LAST_POST_DAILY_REQUEST_DATE: {
    KEY: 'last-post-daily-request-date',
    REMOVE_ON_LOGOUT: true
  },
  TITLE: {
    KEY: 'title',
    REMOVE_ON_LOGOUT: true
  },
  MATCH_MODAL_SHOWN: {
    KEY: 'match-modal-shown',
    REMOVE_ON_LOGOUT: false
  },
  SMS_CODE_SENT_AT: {
    KEY: 'sms-code-sent-at',
    REMOVE_ON_LOGOUT: false
  }
};
