import { ReactNode, memo, useEffect } from 'react';
import ReactModal from 'react-modal';
import { TrackPageView } from '@/analytics/TrackPageView';
import { ViewName } from '@/functions/constants/viewEvent';
import { useScrollLock } from '@/functions/hooks';
import styles from '@/styles/uis/fullModal.module.scss';

type Props = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  viewName?: ViewName;
};

export const FullModal: React.FC<Props> = memo((props) => {
  const { id, isOpen, onClose, children, viewName } = props;

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  useEffect(() => {
    if (document.getElementById('root')) {
      ReactModal.setAppElement('#root');
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      addScrollPreventElement(id);
    } else {
      removeScrollPreventElement(id);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && viewName && <TrackPageView viewName={viewName} />}

      <ReactModal
        id={id}
        isOpen={isOpen}
        className={styles.content}
        overlayClassName={styles.overlay}
        onRequestClose={onClose}
        bodyOpenClassName=''
        closeTimeoutMS={200}
        portalClassName='full-modal'
      >
        {children}
      </ReactModal>
    </>
  );
});
