import { memo, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetNoticeSettings, usePutNoticeSettings } from '@/apis';
import { settingKeys } from '@/apis/queryKeys';
import { Switch } from '@/components/styles/uis/Switch';
import { slideVariants } from '@/functions/constants/framerMotion';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/notification.module.scss';

type SettingKeyName = 'email_footprint' | 'email_stamp' | 'email_get_message' | 'email_other';

type SettingItem = {
  key: SettingKeyName;
  label: string;
};

const settingItems: SettingItem[] = [
  {
    key: 'email_footprint',
    label: '足あとがついた時'
  },
  {
    key: 'email_stamp',
    label: 'いいね!をもらった時'
  },
  {
    key: 'email_get_message',
    label: 'メッセージを受信した時'
  },
  {
    key: 'email_other',
    label: 'その他重要なお知らせ'
  }
];

type Props = {
  onClose: () => void;
};

export const Notification: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const queryClient = useQueryClient();

  const { fetchNoticeSettings } = useGetNoticeSettings();
  const { putNoticeSetteings } = usePutNoticeSettings();

  const { data } = useQuery({
    queryKey: settingKeys.notices(),
    queryFn: () => fetchNoticeSettings()
  });

  const handleChangeValue = useCallback(
    async (key: SettingKeyName) => {
      if (!data) return;

      await putNoticeSetteings({
        id: data.id,
        body: {
          id: data.id,
          setting: key
        }
      });
      queryClient.invalidateQueries(settingKeys.notices());
    },
    [data]
  );

  return (
    <>
      <TrackPageView viewName='notification_settings' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>通知設定</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.inner}>
              <p className={styles.heading}>メール通知</p>
              <ul>
                {settingItems.map((item) => (
                  <li key={item.key} className={styles.item}>
                    <p>{item.label}</p>
                    <Switch
                      id={item.key}
                      isActive={data ? data[item.key] : false}
                      onChange={() => handleChangeValue(item.key)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
