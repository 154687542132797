import { memo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from '@/styles/uis/radioGroup.module.scss';

type ElementProps = JSX.IntrinsicElements['input'];

type Props = {
  selected: string | number | null | undefined;
  options: Object;
  register?: UseFormRegisterReturn;
  isSeparate?: boolean;
} & ElementProps;

export const RadioGroup: React.FC<Props> = memo((_props) => {
  const { selected, options, register, isSeparate, ...props } = _props;

  return (
    <ul className={styles.wrapper} data-separate={isSeparate}>
      {Object.entries(options).map(([key, value]) => (
        <li key={key}>
          <label htmlFor={`radio-${key}`} className={styles.label} data-separate={isSeparate}>
            <input
              id={`radio-${key}`}
              type='radio'
              {...register}
              value={key}
              checked={key === String(selected)}
              {...props}
            />
            {value}
          </label>
        </li>
      ))}
    </ul>
  );
});
