import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
  body: {
    reserved_date: string;
    timeframe: string;
    area: string;
    purpose: string;
    hide_when_matched: boolean;
  };
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put(`v2/me/boards/${params.id}`, params.body, { headers: { 'Content-Type': 'multipart/form-data' } });
};

type Props = Params;

/**
 * 募集更新
 */

export const usePutBoards = () => {
  const updateBoard = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { updateBoard };
};
