export const faqs = [
  {
    title: '電話番号認証ができない',
    texts: [
      <p>以下の電話番号では、認証ができないためご確認ください。</p>,
      <p>
        ・日本以外の国で発行された電話番号
        <br />
        ・固定電話の番号
      </p>,
      <p>使用可能な電話番号にも関わらず登録できない場合は、お問い合わせフォームよりご連絡ください。</p>
    ]
  },
  {
    title: '画像アップロードができない',
    texts: [
      <p>画像をアップロードできない現象は複数の理由が考えられます。下記をご確認ください。</p>,
      <p>
        1．端末の設定画面より、paddyへのファイルやカメラのアクセス権限が許可されていることの確認
        <br />
        2．アプリの再インストール、端末の再起動、キャッシュの削除、ストレージの軽量化
      </p>,
      <p>
        【Android端末をご利用の場合】
        <br />
        以下の項目に当てはまる方は、Androidのシステムにて自動的にアクセスエラーが発生している可能性がございます。
      </p>,
      <p>
        ・一つの端末で複数のGoogleアカウントにログインしている
        <br />
        ・画像をアップロードするアプリがGoogleフォト
      </p>,
      <p>
        一つの端末にて複数のGoogleアカウントにログインしている場合、Googleのアカウント毎にGoogleフォトが存在するため、どのGoogleフォトから画像をアップロードしていいのか端末が判断できない状態となっております。
      </p>,
      <p>つきましては、以下の解決策をお試しください。</p>,
      <p>
        ・画像をアップロードするアプリを端末既存の「ファイル」や他アプリに変更する
        <br />
        ・メインでご利用になっているGoogleアカウント以外をログアウトする
      </p>,
      <p>
        【それでも解決しなかった場合】
        <br />
        上記をお試しいただいても画像のアップロードが出来ない場合、WEB版paddyにログインし、アップロードをお試しください。
      </p>,
      <p>
        ▼︎WEB版paddy
        <br />
        <a href='https://paddy67.today/web/' style={{ textDecoration: 'underline' }} target='_blank' rel='noreferrer'>
          https://paddy67.today/web/
        </a>
      </p>
    ]
  },
  {
    title: '非表示ユーザーの再表示',
    texts: [
      <p>いいね!をスキップ、または非表示に設定したお相手は、お客様ご自身で解除が可能です。 </p>,
      <p>下記の手順にて解除を行ってください。</p>,
      <p>
        1．画面下部「アカウント」をタップ
        <br />
        2．「設定」をタップ
        <br />
        3．「非表示リスト」をタップ
        <br />
        4．一覧から解除したいお相手を選択
      </p>
    ]
  },
  {
    title: 'お相手が利用停止されている',
    texts: [
      <p>利用停止中のお相手は、以下の理由によりサービスを使用できない状態となっております。</p>,
      <p>
        ・サービスの利用が不適切な可能性がある場合
        <br />
        ・一時的に本人確認が必要な場合
      </p>,
      <p>調査等が完了しましたら、利用停止措置は解除する方針といたしております。</p>,
      <p>
        しかしながら、著しく
        <br />
        ・利用規約に違反している
        <br />
        ・利用方法が適切ではない
        <br />
        と判断した場合は利用停止措置が解除されない可能性がございます。
      </p>,
      <p>
        お相手が利用停止されていることが原因で、お約束を守ることができない場合、現地で会うことができない場合、お客様にペナルティを付与することはございません。
      </p>
    ]
  },
  {
    title: 'トラブルがあった場合',
    texts: [
      <p>paddyの通報機能をご利用ください。</p>,
      <p>通報機能のご利用方法は、下記をご確認ください。</p>,
      <p>
        1．お相手のプロフィールを開く
        <br />
        2．画面右上の「･･･」をタップ
        <br />
        3．「通報」を選択
        <br />
        4．画面の手順に沿って手続きを行う
      </p>,
      <p>自由記載欄には、詳細をご記載いただけます。</p>,
      <p>
        また、paddy外でのやりとりがある場合、証拠のご提出をお願いする可能性がございます。その他、確認が必要となった場合は運営事務局より連絡差し上げる場合がございます。
      </p>
    ]
  },
  {
    title: '不具合が起きたら',
    texts: [
      <p>ご迷惑をおかけしており、誠に申し訳ございません。 </p>,
      <p>恐れ入りますが、一度、以下の4点をお試しください。機能が改善する可能性がございます。</p>,
      <p>
        1．アプリの再インストール
        <br />
        2．端末の再起動
        <br />
        3．キャッシュの削除
        <br />
        4．ストレージの軽量化
      </p>,
      <p>上記をお試しいただいても状況が改善しない場合、不具合の調査を行う為、下記宛先までご連絡ください。</p>,
      <p>
        ▼paddy運営事務局
        <br />
        <span>support@paddy67.today</span>
      </p>,
      <p>併せて、迅速な調査のため、下記項目の回答にご協力いただけますと幸いです。</p>,
      <p>
        1．冒頭にお伝えした4点は試したか
        <br />
        2．いつ頃からご不便を感じる状態になったか
        <br />
        3．アプリ、WEBどちらをご利用されているか
        <br />
        4．状況が確認出来る画面のスクリーンショット
        <br />
        5．どの場面でどのような不具合が発生しているか
        <br />
        6．ご利用端末機種(iPhone14等)、OS情報(iOS16等)
      </p>,
      <p>ご教示いただいた内容を元に、運営事務局にて調査いたします。</p>
    ]
  }
];
