import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';

type Params = string;

type PointRecord = {
  name: string;
  point: number;
  distribution: {
    number: number;
    name: string;
  };
  created_at: string;
};

type Response = PointRecord[];

const api = (query: Params): Promise<Response> => {
  return client.get(`v1/points/history?${query}`);
};

type Props = {
  page?: number;
};

/**
 * ポイント履歴の取得
 */

export const useGetPointHistory = () => {
  const fetchPointHistory = useCallback(async (props: Props) => {
    const { page = 1 } = props;

    const params = {
      page
    };

    const res = await api(generateQueryParameter(params));

    return res;
  }, []);

  return { fetchPointHistory };
};
