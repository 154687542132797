import { memo, useCallback, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Button } from '@/components/styles/uis/Button';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/trimmingModal.module.scss';
import 'react-image-crop/dist/ReactCrop.css';

const baseWidth = 150;

type Props = {
  isSquare: boolean;
  imgSrc: string;
  onClose: () => void;
  onTrimming: (img: Blob) => Promise<void>;
};

export const TrimmingModal: React.FC<Props> = memo((props) => {
  const { isSquare, imgSrc, onClose, onTrimming } = props;

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: baseWidth,
    height: isSquare ? baseWidth : baseWidth / 2
  });
  const [isLoading, setIsLoading] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleClickTrimming = useCallback(async () => {
    if (!imgRef.current) return;
    setIsLoading(true);

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    const canvas = new OffscreenCanvas(crop.width * scaleX, crop.height * scaleY);
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const blob = await canvas.convertToBlob({ type: 'image/webp', quality: 0.75 });
    await onTrimming(blob);
    setIsLoading(false);
  }, [imgSrc, crop]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <button type='button' className={components['back-link-button']} onClick={onClose} aria-label='close' />
      </div>
      <div className={styles.content}>
        <ReactCrop
          crop={crop}
          onChange={(crop) => setCrop(crop)}
          aspect={isSquare ? 1 : 2 / 1}
          style={{ width: '100%' }}
        >
          <img ref={imgRef} src={imgSrc} alt='' />
        </ReactCrop>
      </div>
      <div className={styles.footer}>
        <Button color='black' onClick={handleClickTrimming} isLoading={isLoading}>
          トリミング
        </Button>
      </div>
    </div>
  );
});
