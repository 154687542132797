import { atom } from 'jotai';

type SubElementAtomType = {
  target: string;
  element: JSX.Element;
};

export const parentElementsAtom = atom({
  first: '',
  second: '',
  third: '',
  fourth: ''
});
export const firstSubElementAtom = atom<SubElementAtomType | null>(null);
export const secondSubElementAtom = atom<SubElementAtomType | null>(null);
export const thirdSubElementAtom = atom<SubElementAtomType | null>(null);
export const fourthSubElementAtom = atom<SubElementAtomType | null>(null);

parentElementsAtom.debugLabel = 'parentElementsAtom';
firstSubElementAtom.debugLabel = 'firstSubElementAtom';
secondSubElementAtom.debugLabel = 'secondSubElementAtom';
thirdSubElementAtom.debugLabel = 'thirdSubElementAtom';
fourthSubElementAtom.debugLabel = 'fourthSubElementAtom';
