import { BaseSyntheticEvent, memo, useCallback, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { MultiCheckBox } from '@/components/styles/uis/MultiCheckBox';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useDisclosure } from '@/functions/hooks';
import { MeetupLocations } from '@/functions/types/meetupLocation';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/meetupLocationModal.module.scss';

const maxCount = 5;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: MeetupLocations) => void;
  selectData: MeetupLocations;
  isLimit?: boolean;
};

export const MeetupLocationModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, onSave, selectData, isLimit } = props;

  const [selectPref, setSelectPref] = useState('');
  const [selectCity, setSelectCity] = useState<MeetupLocations>({});

  const selectLength = Object.entries(selectCity).flatMap(([, value]) => value).length;

  useEffect(() => {
    if (!isOpen) return;

    setSelectCity(selectData);
  }, [isOpen, selectData]);

  const masterTypes = useAtomValue(masterTypesAtom);
  const cityModal = useDisclosure();

  const onCloseModal = useCallback(() => {
    setSelectCity({});
    onClose();
    cityModal.close();
  }, []);

  const handleCloseCityModal = useCallback(() => {
    cityModal.close();
    setSelectPref('');
  }, []);

  const handleClickSave = useCallback(() => {
    onSave(selectCity);
    onClose();
    cityModal.close();
  }, [selectCity]);

  const onDeleteCity = useCallback(
    (city: string) => {
      const data: any = {};

      // eslint-disable-next-line
      for (const key in selectCity) {
        if (selectCity[key]) {
          data[key] = selectCity[key].filter((v) => {
            return Object.keys(v)[0] !== city;
          });

          if (data[key].length === 0) {
            delete data[key];
          }
        }
      }

      setSelectCity({ ...data });
    },
    [selectCity]
  );

  const handleClickDelete = useCallback(
    (city: string) => {
      onDeleteCity(city);
    },
    [selectCity]
  );

  const onAddCity = useCallback(
    (pref: string, city: string) => {
      const datas = { ...selectCity };
      const cities = masterTypes.meetup_locations[pref];
      const value = Object.entries(cities).find(([key]) => key === city)![1];
      const data = { [city]: value };

      if (datas[pref]) {
        datas[pref].push(data);
        setSelectCity({ ...datas });
      } else {
        datas[pref] = [data];
        setSelectCity({ ...datas });
      }
    },
    [selectCity]
  );

  const handleSelectCity = useCallback(
    (pref: string, city: string) => {
      if (selectCity[pref] && selectCity[pref].some((obj) => Object.keys(obj)[0] === city)) {
        onDeleteCity(city);
      } else if (isLimit) {
        if (selectLength < maxCount) {
          onAddCity(pref, city);
        }
      } else {
        onAddCity(pref, city);
      }
    },
    [selectCity, selectLength]
  );

  const handleSelectPref = useCallback((e: BaseSyntheticEvent) => {
    setSelectPref(e.target.value);
    cityModal.open();
  }, []);

  const getPrefLabel = useCallback((pref: string) => {
    return masterTypes.meetup_location_prefs[pref];
  }, []);

  const getPrefKey = useCallback((locations: MeetupLocations) => {
    return Object.values(locations).flatMap((obj) => {
      return obj.map((v) => Object.keys(v)[0]);
    });
  }, []);

  const getPrefChip = useCallback(
    (keyName: string) => {
      const targetPref = Object.entries(selectCity).find(([key]) => key === keyName);

      return targetPref ? <span className={styles['area-chip']}>{targetPref[1].length}</span> : undefined;
    },
    [selectCity]
  );

  const formatData = useCallback(
    (datas: MeetupLocations) => {
      return Object.values(datas).flatMap((v) => v);
    },
    [selectCity]
  );

  return (
    <HalfModal
      id='meetupAreaModal'
      isOpen={isOpen}
      onClose={onCloseModal}
      title={`会えるエリア${selectPref && `(${getPrefLabel(selectPref)})`}`}
      footerHeight={128}
      footer={
        <div className={components['gap-wrapper']}>
          {formatData(selectCity).length ? (
            <div className={styles['area-modal-footer-wrapper']}>
              {isLimit && <span>あと{maxCount - selectLength}件</span>}
              <ul className={styles['selected-city-list']}>
                {formatData(selectCity).map((data) => (
                  <li key={Object.keys(data)[0]}>
                    {Object.values(data)[0]}
                    <button type='button' onClick={() => handleClickDelete(Object.keys(data)[0])} aria-label='delete' />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          <Button color='black' onClick={handleClickSave}>
            決定
          </Button>
        </div>
      }
    >
      <AnimatePresence mode='wait' initial={false}>
        {cityModal.isOpen ? (
          <motion.div key='cityModal' initial='right' animate='enter' exit='right' variants={slideVariants}>
            <button type='button' onClick={handleCloseCityModal} className={styles['city-modal-back-trigger']}>
              都道府県を選択
            </button>
            <MultiCheckBox
              selected={getPrefKey(selectCity)}
              options={masterTypes.meetup_locations[selectPref]}
              onChange={(e) => handleSelectCity(selectPref, e.target.value)}
            />
          </motion.div>
        ) : (
          <motion.div key='areaModal' initial='left' animate='enter' exit='left' variants={slideVariants}>
            <ul className={styles['area-list']}>
              {Object.entries(masterTypes.meetup_location_prefs).map(([key, value]) => (
                <li key={key} className={styles['area-item']}>
                  <button type='button' className={styles['area-trigger']} onClick={handleSelectPref} value={key}>
                    {value}
                    {getPrefChip(key)}
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </HalfModal>
  );
});
