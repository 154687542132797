import { useCallback } from 'react';
import { client } from '@/apis/client';
import { animateDuration } from '@/functions/constants/framerMotion';
import { removeLocalStorage } from '@/functions/helpers';
import { useBasicModal } from '@/functions/hooks';

type Params = {
  reason: string;
  reasonDetail: string;
  kaizen: string;
  function: string;
  etc: string;
};

const api = (params: Params): Promise<Response> => {
  return client.delete('v1/me', { params });
};

type Props = Params;

/**
 * 退会する
 */

export const useDeleteMe = () => {
  const { onCloseModal, resignCompleteModal } = useBasicModal();

  const deleteMe = useCallback(async (props: Props) => {
    await api(props);

    // NOTE: ローカルストレージの一括削除
    removeLocalStorage('delete');

    onCloseModal();

    setTimeout(() => {
      resignCompleteModal();
    }, animateDuration);
  }, []);

  return { deleteMe };
};
