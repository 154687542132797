import { ReactNode, useCallback } from 'react';
import { pushDataLayer } from '@/analytics/pushDataLayer';

type Props = {
  children: ReactNode;
  label: string;
};

/**
 * クリックイベントを送信するコンポーネント
 */
export const TrackOnClick: React.FC<Props> = (props) => {
  const { children, label } = props;

  const handleClick = useCallback(() => {
    pushDataLayer({
      event: 'click',
      click_label: label
    });
  }, [label]);

  return (
    <button type='button' onClick={handleClick}>
      {children}
    </button>
  );
};
