import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  to_user_id: number;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v1/blocks', params);
};

/**
 * ユーザーブロック
 */

type Props = {
  userId: number;
};

export const usePostBlocks = () => {
  const postBlocks = useCallback(async (props: Props) => {
    const { userId } = props;
    await api({ to_user_id: userId });
  }, []);

  return { postBlocks };
};
