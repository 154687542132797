import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  reserved_date: string;
  timeframe: string;
  area: string;
  area_detail?: string;
  purpose: string;
  free_text: string;
  hide_when_matched: boolean;
  image?: Blob;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v2/me/boards', params, { headers: { 'Content-Type': 'multipart/form-data' } });
};

type Props = Params;

/**
 * 募集作成
 */

export const usePostBoards = () => {
  const createBoard = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { createBoard };
};
