import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
  body: {
    state: 'agreed' | 'disagreed' | 'cancelled';
  };
};

type Response = { id: number };

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/me/appointments/${params.id}`, params.body);
};

/**
 * お約束のステータスを変更
 */

type Props = Params;

export const usePutAppointments = () => {
  const putAppointments = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putAppointments };
};
