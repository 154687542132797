import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  contact_category: string;
  title: string;
  content: string;
  email: string;
  phone: string;
};

type Response = { success: 1 };

const api = (params: Params): Promise<Response> => {
  return client.post('v1/contacts', params);
};

type Props = Params;

/**
 * お問い合わせ
 */

export const usePostContacts = () => {
  const postContacts = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postContacts };
};
