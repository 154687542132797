import { useCallback } from 'react';
import { client } from '@/apis/client';

type Question = {
  id: number;
  content: string;
  other_text: string;
  require: boolean;
};

type Params = {
  questions: Question[];
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/questions/update_all', params);
};

type Props = Params;

/**
 * 質問付きいいね!の設定の更新
 */

export const usePutQuestions = () => {
  const putQuestions = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putQuestions };
};
