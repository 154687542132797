import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  image: Blob;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v2/me/asset_proof_images', params, { headers: { 'Content-Type': 'multipart/form-data' } });
};

type Props = Params;

/**
 * 資産証明書を登録する
 */

export const usePostAssetProofImages = () => {
  const postAssetProofImages = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postAssetProofImages };
};
