import { memo } from 'react';
import { useAtomValue } from 'jotai';
import Female01 from '@/assets/coaching/account_female_01.png';
import Female02 from '@/assets/coaching/account_female_02.png';
import Female03 from '@/assets/coaching/account_female_03.png';
import Male01 from '@/assets/coaching/account_male_01.png';
import Male02 from '@/assets/coaching/account_male_02.png';
import Male03 from '@/assets/coaching/account_male_03.png';
import { SettingStep } from '@/components/features/coaching/SettingStep';
import { ModalSwipe } from '@/components/features/ModalSwipe';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { Button } from '@/components/styles/uis/Button';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/coaching.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isMenu?: boolean;
};

export const AccountCoaching: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, isMenu } = props;

  const stepLength = isMenu ? 3 : 4;
  const { isMale } = useAtomValue(meFlagAtom);

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='how_to_use_account'>
      <ModalSwipe stepLength={stepLength}>
        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male01 : Female01} alt='自分のアカウント設定などを確認' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>自分のアカウント設定などを確認</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                「アカウント」では、あなたのプロフィールや各種設定を確認できます。
              </p>
              <p className={components['basic-text']}>足あとや大事なお知らせなどがある場合、通知が表示されます。</p>
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male02 : Female02} alt='プロフィール登録でマッチ率UP' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>プロフィール登録でマッチ率UP</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>まだ入力が完了していないプロフィールはありませんか？</p>
              <p className={components['basic-text']}>
                あなたの写真や自己紹介文・基本情報を充実させることで、マッチ率が大幅にUPします。
              </p>
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male03 : Female03} alt='足あとからお相手を見つける' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>足あとからお相手を見つける</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>あなたのプロフィールに訪れたお相手が足あとに表示されます。</p>
              <p className={components['basic-text']}>気になるお相手がいたら、いいね!を送ってアピールしましょう。</p>
              {isMale && <p className={components['sub-text']}>※いいね!送信にはポイントが必要です</p>}
            </div>
          </div>
        </li>
        {!isMenu && <SettingStep />}
      </ModalSwipe>
      <div className={styles['button-wrapper']}>
        <Button color='black' onClick={onClose}>
          {isMenu ? '閉じる' : 'さっそく使ってみる'}
        </Button>
      </div>
    </BasicModal>
  );
});
