import { ReactNode, memo, useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { TrackPageView } from '@/analytics/TrackPageView';
import { ViewName } from '@/functions/constants/viewEvent';
import { useScrollLock } from '@/functions/hooks';
import styles from '@/styles/uis/halfModal.module.scss';

type Props = {
  id: string;
  isOpen: boolean;
  isPrev?: boolean;
  title?: string;
  children: ReactNode;
  isInner?: boolean;
  footer?: ReactNode;
  footerHeight?: number;
  isMaxHeight?: boolean;
  onClose: () => void;
  onClickOther?: ReactNode;
  isHomeSearch?: boolean;
  viewName?: ViewName;
};

export const HalfModal: React.FC<Props> = memo((props) => {
  const {
    id,
    isOpen,
    title,
    children,
    isInner,
    footer,
    footerHeight,
    isMaxHeight,
    onClose,
    isPrev,
    onClickOther,
    isHomeSearch,
    viewName
  } = props;

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  useEffect(() => {
    if (document.getElementById('root')) {
      ReactModal.setAppElement('#root');
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      addScrollPreventElement(id);
    } else {
      removeScrollPreventElement(id);
    }
  }, [isOpen]);

  const getSubstanceHeight = useCallback((isFooter: boolean) => {
    const headerHeight = 56;
    const defaultFooterHeight = 81;
    const calcFooterHeight = isFooter ? footerHeight ?? defaultFooterHeight : 0;

    return {
      height: isMaxHeight ? '100dvh' : undefined,
      maxHeight: `calc(100dvh - ${headerHeight + calcFooterHeight}px)`
    };
  }, []);

  return (
    <>
      {isOpen && viewName && <TrackPageView viewName={viewName} />}

      <ReactModal
        id={id}
        isOpen={isOpen}
        className={styles.content}
        overlayClassName={isHomeSearch ? styles['overlay-home-search'] : styles.overlay}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
        bodyOpenClassName=''
        closeTimeoutMS={200}
        portalClassName='half-modal'
      >
        <div className={styles.header}>
          <button
            type='button'
            className={isPrev ? styles['prev-icon'] : styles['close-icon']}
            onClick={onClose}
            aria-label={isPrev ? 'prev' : 'close'}
          />
          {title}
          {onClickOther}
        </div>
        <div className={isInner ? styles['substance-inner'] : styles.substance} style={getSubstanceHeight(!!footer)}>
          {children}
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </ReactModal>
    </>
  );
});
