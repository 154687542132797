import { memo, useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useDeleteImages, useGetUsers, usePutDiscloseImages, usePutImages, usePutProperty } from '@/apis';
import { meKeys, userKeys } from '@/apis/queryKeys';
import { AddImgModal } from '@/components/features/modal/AddImgModal';
import { AddMovieModal, DiscloseType } from '@/components/features/modal/AddMovieModal';
import { HeightSelectModal } from '@/components/features/modal/HeightSelectModal';
import { MeetupLocationModal } from '@/components/features/modal/MeetupLocationModal';
import { RadioPropertyModal } from '@/components/features/modal/RadioPropertyModal';
import { SampleImgModal } from '@/components/features/modal/SampleImgModal';
import { SampleMovieModal } from '@/components/features/modal/SampleMovieModal';
import { SelectPropertyModal } from '@/components/features/modal/SelectPropertyModal';
import { ShowInfo } from '@/components/features/show/ShowInfo';
import { ShowProfile } from '@/components/features/show/ShowProfile';
import { ShowButton } from '@/components/styles/projects/ShowButton';
import { TabSwitch } from '@/components/styles/uis/TabSwitch';
import { VideoModal } from '@/components/styles/uis/VideoModal';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { OptionElementKeyTypes } from '@/functions/constants/optionElements';
import { updateMeetupLocationData } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useSnackbar, useSubElement } from '@/functions/hooks';
import { MeetupLocations } from '@/functions/types/meetupLocation';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/profile.module.scss';

type OtherKeyTypes = 'height' | 'future_dream';

type Section = {
  heading: string;
  noBeginnerOnly?: boolean;
  items: {
    target: OptionElementKeyTypes | OtherKeyTypes;
    label: string;
    isMaleOnly?: boolean;
    noBeginnerOnly?: boolean;
    disable?: boolean;
  }[];
};

type ImgPropsType = {
  imgId: number | null;
  order: number;
  disclose: string | null;
};

type Props = {
  onClose: () => void;
};

const showTabs = [
  {
    value: 'profile',
    label: 'プロフィール'
  }
];

const appearanceItems: Section = {
  heading: '外見・容姿',
  items: [
    {
      target: 'height',
      label: '身長'
    },
    {
      target: 'body_type',
      label: 'スタイル'
    },
    {
      target: 'looks',
      label: '雰囲気',
      isMaleOnly: false
    },
    {
      target: 'personality',
      label: '性格・タイプ',
      isMaleOnly: false
    }
  ]
};

const lifeItems: Section = {
  heading: '生活',
  items: [
    {
      target: 'drinking_type',
      label: 'お酒'
    },
    {
      target: 'cigarette_type',
      label: 'タバコ'
    }
  ]
};

const dateItems: Section = {
  heading: 'デート',
  items: [
    {
      target: 'purpose',
      label: 'デートでしたいこと'
    },
    {
      target: 'before_meeting',
      label: 'デートまでの希望'
    }
  ]
};

const otherItems: Section = {
  heading: 'その他',
  noBeginnerOnly: true,
  items: [
    {
      target: 'register_reason',
      label: '登録した理由'
    },
    {
      target: 'future_dream',
      label: '将来の夢'
    }
  ]
};

const infoItems: Section = {
  heading: '基本情報',
  items: [
    {
      target: 'hometown',
      label: '出身地'
    }
  ]
};

export const Profile: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const [imgProps, setImgProps] = useState<ImgPropsType | null>(null);
  const [selectTarget, setSelectTarget] = useState<string | null>(null);
  const [activeShowTab, setActiveShowTab] = useState(showTabs[0].value); // eslint-disable-line @typescript-eslint/no-unused-vars

  const me = useAtomValue(meAtom);
  const { member, isMale, isBeginner } = useAtomValue(meFlagAtom);
  const queryClient = useQueryClient();

  const { openSnackbar } = useSnackbar();
  const sampleImgModal = useDisclosure();
  const sampleMovieModal = useDisclosure();
  const addImgModal = useDisclosure();
  const addMovieModal = useDisclosure();
  const meetupLocationModal = useDisclosure();
  const radioPropertyModal = useDisclosure();
  const selectPropertyModal = useDisclosure();
  const previewModal = useDisclosure();
  const heightModal = useDisclosure();
  const videoModal = useDisclosure();

  const { proofReviewingModal, proofCompletedModal } = useBasicModal();
  const { handleOpenSubElement } = useSubElement();

  const { putImages } = usePutImages();
  const { deleteImages } = useDeleteImages();
  const { putDiscloseImages } = usePutDiscloseImages();
  const { putProperty } = usePutProperty();
  const { fetchUser } = useGetUsers();

  const businessItems: Section = useMemo(() => {
    return {
      heading: 'お仕事・学歴',
      items: [
        {
          target: 'education_type',
          label: '学歴'
        },
        {
          target: 'business_type',
          label: 'お仕事'
        },
        {
          target: 'holiday_type',
          label: '休日'
        },
        {
          target: 'income_type',
          label: '年収',
          isMaleOnly: true,
          noBeginnerOnly: true,
          disable: me.income_proof?.status === 'pass' || me.income_proof?.status === 'inreview'
        },
        {
          target: 'asset_type',
          label: '資産',
          isMaleOnly: true,
          noBeginnerOnly: true,
          disable: me.asset_proof?.status === 'pass' || me.asset_proof?.status === 'inreview'
        }
      ]
    };
  }, [me.income_proof, me.asset_proof]);

  const { data: user } = useQuery({
    queryKey: userKeys.detail(me.id),
    queryFn: () => fetchUser({ id: me.id })
  });

  const imgLabels = isMale
    ? ['メイン', '趣味', '料理', 'アピール', '任意', '任意', '任意', '任意', '任意']
    : ['メイン', '全身', '笑顔', '趣味', '料理', 'アピール', '任意', '任意', '任意'];

  const getImg = useCallback(
    (imgId?: number, imgUrl?: string) => {
      if (imgId) {
        return imgUrl;
      }

      return '';
    },
    [member, isMale]
  );

  const imgItems = useMemo(() => {
    return imgLabels.map((label, index) => {
      const img = me.images.find((v) => v.order === index);

      return {
        id: img?.id ?? null,
        order: index,
        src: getImg(img?.id, img?.image_url),
        status: img?.aasm_state ?? null,
        disclose: img?.disclose ?? null,
        label
      };
    });
  }, [me]);

  const profileItems = useMemo(() => {
    const items = isMale
      ? [businessItems, appearanceItems, lifeItems, dateItems, infoItems]
      : [appearanceItems, lifeItems, businessItems, dateItems, otherItems, infoItems];

    const noBeginnerItems = items.filter((item) => item.noBeginnerOnly === undefined || !isBeginner);

    const filterItems = noBeginnerItems.map((section) => {
      return {
        heading: section.heading,
        items: section.items.filter(
          (item) =>
            (item.isMaleOnly === undefined || item.isMaleOnly === isMale) &&
            (item.noBeginnerOnly === undefined || !isBeginner)
        )
      };
    });

    return filterItems;
  }, [isMale, isBeginner]);

  const refetchMe = useCallback(async () => {
    queryClient.invalidateQueries(meKeys.all);
  }, []);

  const handleOpenPreview = useCallback(() => {
    queryClient.invalidateQueries(userKeys.detail(me.id));
    previewModal.open();
  }, []);

  const handleEditImg = useCallback((props: ImgPropsType) => {
    setImgProps({ ...props });
    addImgModal.open();
  }, []);

  const onSaveImg = useCallback(async (img: string | Blob, order?: number | null) => {
    if (!img || typeof order !== 'number') return;

    await putImages({
      image: {
        image_url: img as string,
        order
      }
    });
    await refetchMe();
    openSnackbar({
      type: 'popup',
      title: 'アップロードしました。',
      text: '審査完了後に公開されます。'
    });
  }, []);

  const onDeleteImg = useCallback(async (imgId: number) => {
    await deleteImages({ imgId });
    await refetchMe();
    openSnackbar({
      type: 'toast',
      text: '画像を削除しました。'
    });
    addImgModal.close();
  }, []);

  const onChangeDisclose = useCallback(async (imgId: number, disclose: number) => {
    await putDiscloseImages({
      image_id: imgId,
      disclose
    });
    await refetchMe();
    openSnackbar({
      type: 'toast',
      text: '公開範囲を変更しました。'
    });
  }, []);

  const getValue = useCallback((value: string | number | string[]) => {
    if (Array.isArray(value) && value.length) {
      return true;
    }
    if (typeof value === 'string' || typeof value === 'number') {
      return true;
    }

    return false;
  }, []);

  const getValueLabel = useCallback((value: string | number | string[]) => {
    if (!value) return null;

    if (Array.isArray(value)) {
      return value.join(',');
    }

    return value;
  }, []);

  const getProofLabel = useCallback(
    (target: 'asset_type' | 'income_type') => {
      const type = target === 'asset_type' ? me.property.asset_type : me.property.income_type;
      const proof = target === 'asset_type' ? me.asset_proof : me.income_proof;

      if (!proof) return null;

      if (proof.status === 'inreview') {
        return (
          <span className={styles['proof-label']} data-status='inreview' data-margin={!!type}>
            審査中
          </span>
        );
      }

      if (proof.status === 'pass') {
        return (
          <span className={styles['proof-label']} data-status='pass' data-margin={!!type}>
            証明済み
          </span>
        );
      }

      return null;
    },
    [me.asset_proof, me.income_proof]
  );

  const getMeetupLocationValue = useCallback((locations: MeetupLocations) => {
    return Object.values(locations).flatMap((obj) => {
      return obj.map((v) => Object.values(v)[0]);
    });
  }, []);

  const onSaveMeetupLocation = useCallback(async (locations: MeetupLocations) => {
    await putProperty({
      user_property: {
        meetup_location: updateMeetupLocationData(locations)
      }
    });
    await refetchMe();
    queryClient.invalidateQueries(userKeys.meetupLocation());

    meetupLocationModal.close();
    openSnackbar({
      type: 'toast',
      text: '保存しました。'
    });
  }, []);

  const handleOpenOptionModal = useCallback(
    (target: string) => {
      if (target === 'height') {
        heightModal.open();
      } else if (target === 'future_dream') {
        handleOpenSubElement('futureDream', 'profile');
      } else if (target === 'looks' || target === 'personality' || target === 'register_reason') {
        setSelectTarget(target);
        selectPropertyModal.open();
      } else {
        setSelectTarget(target);
        radioPropertyModal.open();
      }
    },
    [me]
  );

  const onCloseOptionModal = useCallback(() => {
    if (radioPropertyModal.isOpen) {
      radioPropertyModal.close();
    }
    if (selectPropertyModal.isOpen) {
      selectPropertyModal.close();
    }
    setTimeout(() => {
      setSelectTarget(null);
    }, 200);
  }, [radioPropertyModal, selectPropertyModal]);

  const onClickEdit = useCallback(
    (target: string, disable: boolean | undefined) => {
      if (disable) {
        const proofType = target === 'income_type' ? 'income' : 'asset';
        const proofStatus = target === 'income_type' ? me.income_proof?.status : me.asset_proof?.status;

        if (proofStatus === 'inreview') {
          proofReviewingModal(proofType);
        } else if (proofStatus === 'pass') {
          proofCompletedModal(proofType);
        }

        return;
      }

      handleOpenOptionModal(target);
    },
    [me.income_proof, me.asset_proof]
  );

  const onSaveProperty = useCallback(
    async (value: { [key: string]: string | number | null } | string[]) => {
      await putProperty({
        // @ts-ignore
        user_property: Array.isArray(value) ? { [selectTarget]: value } : { ...value }
      });
      await refetchMe();

      if (radioPropertyModal.isOpen || selectPropertyModal.isOpen) {
        onCloseOptionModal();
      }
      if (heightModal.isOpen) {
        heightModal.close();
      }
      openSnackbar({
        type: 'toast',
        text: '保存しました。'
      });
    },
    [RadioPropertyModal, radioPropertyModal, selectPropertyModal]
  );

  return (
    <>
      <TrackPageView viewName='profile_edit' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>プロフィール編集</p>
              <button type='button' onClick={handleOpenPreview} className={styles['preview-button']}>
                プレビュー
              </button>
            </div>
          </div>

          <div className={components['parent-wrapper']}>
            <div className={account['submodal-contents']}>
              <ul className={styles.list}>
                <li className={styles.item}>
                  <div className={styles['item-inner']}>
                    <div className={styles['item-header']}>
                      <p className={styles['item-title']}>プロフィール画像</p>
                      <button type='button' onClick={sampleImgModal.open} className={styles['how-to-button']}>
                        写真の選び方
                      </button>
                    </div>
                    {me.images.length !== 9 ? (
                      <div className={styles['hint-toast']} data-img>
                        <p>設定するほどマッチ率UP！</p>
                      </div>
                    ) : null}
                    <ul className={styles['img-list']}>
                      {imgItems.map((img) => (
                        <li key={img.order} className={styles['img-item']} data-status={img.status}>
                          {img.src && <img src={img.src} alt='' />}
                          <button
                            type='button'
                            onClick={() => handleEditImg({ imgId: img.id, order: img.order, disclose: img.disclose })}
                            className={styles['img-item-button']}
                            aria-label='edit'
                          />
                          <span className={styles['img-item-label']}>{img.label}</span>
                          {img.disclose === 'to_message' && <span className={styles['disclose-icon']} />}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>

                {!isMale && (
                  <li className={styles.item}>
                    <div className={styles['item-inner']}>
                      <div className={styles['item-header']}>
                        <p className={styles['item-title']}>アピール動画</p>
                        <button type='button' onClick={sampleMovieModal.open} className={styles['how-to-button']}>
                          動画の選び方
                        </button>
                      </div>
                      {!me.profile_movie.movie_url && (
                        <div className={styles['hint-toast']} data-movie>
                          <p>動画でさらにマッチ率UP！</p>
                        </div>
                      )}
                      <div
                        className={styles['movie-item']}
                        data-status={
                          me.profile_movie.id && me.profile_movie.thumbnail_url
                            ? me.profile_movie.aasm_state
                            : undefined
                        }
                      >
                        {me.profile_movie.thumbnail_url && (
                          <>
                            <button
                              type='button'
                              className={styles['play-icon']}
                              onClick={videoModal.open}
                              aria-label='play'
                            />
                            <img src={me.profile_movie.thumbnail_url} alt='' />
                          </>
                        )}

                        {me.profile_movie.id && !me.profile_movie.movie_url ? (
                          <span className={styles['uploading-icon']} />
                        ) : (
                          <button
                            type='button'
                            onClick={addMovieModal.open}
                            className={styles['movie-button']}
                            aria-label='edit'
                          />
                        )}
                        <span className={styles['movie-label']}>
                          {me.profile_movie.id && !me.profile_movie.movie_url ? 'アップロード処理中' : 'アピール動画'}
                        </span>
                        {me.profile_movie.id && me.profile_movie.disclose === 'like' && (
                          <span className={styles['disclose-icon']} />
                        )}
                      </div>
                    </div>
                  </li>
                )}

                <li className={styles.item}>
                  <button
                    type='button'
                    onClick={() => handleOpenSubElement('basicInfo', 'profile')}
                    className={styles['item-button']}
                  >
                    <div className={styles['item-header']}>
                      <p className={styles['item-title']}>
                        {me.property.nickname} {me.age}歳 {me.property.residence_location}
                      </p>
                    </div>
                    <div className={styles['item-content']}>
                      {me.property.one_word ? (
                        <p className={styles['item-text']}>{me.property.one_word}</p>
                      ) : (
                        <p className={styles['item-text']} data-system>
                          今日のひとことを設定してマッチ率UP
                        </p>
                      )}
                    </div>
                  </button>
                </li>

                <li className={styles.item}>
                  <button
                    type='button'
                    onClick={() => handleOpenSubElement('selfIntroduction', 'profile')}
                    className={styles['item-button']}
                  >
                    <div className={styles['item-header']}>
                      <p className={styles['item-title']}>自己紹介</p>
                    </div>
                    <div className={styles['item-content']}>
                      {me.property.self_introduction ? (
                        <p className={styles['item-text']}>{me.property.self_introduction}</p>
                      ) : (
                        <p className={styles['item-text']} data-system>
                          設定してマッチ率UP
                        </p>
                      )}
                    </div>
                  </button>
                </li>

                <li className={styles.item}>
                  <button type='button' className={styles['item-edit-button']} onClick={meetupLocationModal.open}>
                    <div className={styles['item-header']}>
                      <p className={styles['item-title']}>会えるエリア</p>
                      <span className={styles['edit-button']} />
                    </div>
                    {Object.entries(me.property.meetup_location).length ? (
                      <ul className={styles['meetup-list']}>
                        {getMeetupLocationValue(me.property.meetup_location).map((v) => (
                          <li key={v}>{v}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className={styles['item-text']} data-system>
                        設定してマッチ率UP
                      </p>
                    )}
                  </button>
                </li>

                {profileItems.map(({ heading, items }) => (
                  <li key={heading} className={styles.item}>
                    <div className={styles['item-header']}>
                      <p className={styles['item-title-list']}>{heading}</p>
                    </div>
                    <ul className={styles['border-list']}>
                      {items.map((item) => (
                        <li key={item.target} className={styles['select-item']}>
                          <button
                            type='button'
                            className={
                              item.target === 'future_dream'
                                ? styles['item-button-list']
                                : styles['item-edit-button-list']
                            }
                            onClick={() => onClickEdit(item.target, item.disable)}
                            data-disabled={item.disable}
                          >
                            <p className={styles['select-item-title']}>{item.label}</p>
                            {/* @ts-ignore */}
                            {getValue(me.property[item.target]) ||
                            (item.target === 'income_type' && me.income_proof) ||
                            (item.target === 'asset_type' && me.asset_proof) ? (
                              <p className={clsx(styles['select-item-label'], styles['item-text'])}>
                                {/* @ts-ignore */}
                                {getValueLabel(me.property[item.target])}
                                {item.target === 'height' && <span>cm</span>}
                                {(item.target === 'asset_type' || item.target === 'income_type') &&
                                  getProofLabel(item.target)}
                              </p>
                            ) : (
                              <p className={clsx(styles['select-item-label'], styles['item-text'])} data-system>
                                設定してマッチ率UP
                              </p>
                            )}
                            {item.target !== 'future_dream' && <span className={styles['edit-button']} data-li />}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>

        <SampleImgModal isOpen={sampleImgModal.isOpen} onClose={sampleImgModal.close} />

        <SampleMovieModal isOpen={sampleMovieModal.isOpen} onClose={sampleMovieModal.close} />

        <AddImgModal
          from='profile'
          setImg={onSaveImg}
          isOpen={addImgModal.isOpen}
          onClose={addImgModal.close}
          {...imgProps}
          onDelete={onDeleteImg}
          onChangeDisclose={onChangeDisclose}
        />

        <AddMovieModal
          isOpen={addMovieModal.isOpen}
          onClose={addMovieModal.close}
          refetch={refetchMe}
          movieId={me.profile_movie.id}
          disclose={me.profile_movie.disclose as DiscloseType}
        />

        {me.profile_movie.movie_url && (
          <VideoModal isOpen={videoModal.isOpen} onClose={videoModal.close} src={me.profile_movie.movie_url} />
        )}

        <MeetupLocationModal
          isOpen={meetupLocationModal.isOpen}
          onClose={meetupLocationModal.close}
          onSave={onSaveMeetupLocation}
          selectData={me.property.meetup_location}
          isLimit
        />

        {selectTarget && (
          <RadioPropertyModal
            isOpen={radioPropertyModal.isOpen}
            onClose={onCloseOptionModal}
            target={selectTarget}
            // @ts-ignore
            value={me.property[selectTarget]}
            onSave={onSaveProperty}
          />
        )}

        {selectTarget && (
          <SelectPropertyModal
            isOpen={selectPropertyModal.isOpen}
            onClose={onCloseOptionModal}
            target={selectTarget}
            // @ts-ignore
            value={me.property[selectTarget]}
            onSave={onSaveProperty}
            isProfile
          />
        )}

        <HeightSelectModal
          isOpen={heightModal.isOpen}
          onClose={heightModal.close}
          height={me.property.height}
          onSave={onSaveProperty}
        />

        <AnimatePresence mode='wait' initial={false}>
          {previewModal.isOpen && (
            <motion.div
              initial='bottom'
              animate='enter'
              exit='bottom'
              variants={slideVariants}
              className={styles['preview-modal']}
            >
              <ShowButton onCloseShow={previewModal.close} />
              <ShowInfo user={user} isPreview />
              <TabSwitch name='show' tabs={showTabs} isActive={activeShowTab} onChange={() => null} />
              <div className={styles['basic-panel-inner']}>
                <ShowProfile user={user} isPreview />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
});
