import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post(`v1/chat_rooms/${params.id}/hide`);
};

/**
 * チャットルームユーザー非表示
 */

type Props = {
  chatRoomId: number;
};

export const usePostHide = () => {
  const postHide = useCallback(async (props: Props) => {
    const { chatRoomId } = props;

    await api({ id: chatRoomId });
  }, []);

  return { postHide };
};
