import { memo } from 'react';
import styles from '@/styles/projects/chipLabel.module.scss';

const getColor = (color?: ColorType) => {
  switch (color) {
    case 'white':
      return styles['white-chip'];
    case 'black':
      return styles['black-chip'];
    case 'red':
      return styles['red-chip'];
    default:
      return '';
  }
};

type ColorType = 'white' | 'black' | 'red';

type Props = {
  label: string;
  color?: 'white' | 'black' | 'red';
};

export const ChipLabel: React.FC<Props> = memo((props) => {
  const { label, color = 'white' } = props;

  return <span className={getColor(color)}>{label}</span>;
});
