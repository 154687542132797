import { memo, useCallback, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import WSparkHeart from '@/assets/icons/heart_w_spark.svg';
import step_1Img from '@/assets/lp/common/richlike_step1.png';
import step_2Img from '@/assets/lp/common/richlike_step2.png';
import benefitsStep from '@/assets/lp/richLike/benefits_step.png';
import desc from '@/assets/lp/richLike/desc.png';
import kv from '@/assets/lp/richLike/kv.png';
import kv0 from '@/assets/lp/richLike/kv0.png';
import point1 from '@/assets/lp/richLike/point_01.png';
import point2 from '@/assets/lp/richLike/point_02.png';
import { Button } from '@/components/styles/uis/Button';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { formatDate } from '@/functions/helpers';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/richLike.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const RichLikeLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const me = useAtomValue(meAtom);
  const { rickLikeLength, isBeginner } = useAtomValue(meFlagAtom);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);

  const handleClickButton = useCallback(() => {
    if (!wrapperRef.current || !aboutRef.current) return;

    const headerHeight = 48;

    wrapperRef.current.scrollTo({
      top: aboutRef.current.offsetTop - headerHeight,
      behavior: 'smooth'
    });
  }, []);

  const elementStatus = () => {
    const premiumInfo = me.in_time_subscriptions.premium_subscription?.next_rich_like_point_info;
    const royalInfo = me.in_time_subscriptions.royal_subscription?.next_rich_like_point_info;

    const elements = [];
    elements.push({
      name: '保有リッチいいね!',
      isVisibleIcon: true,
      value: rickLikeLength ?? '-'
    });
    elements.push({
      name: '次の付与数（有料会員）',
      isVisibleIcon: true,
      value: !premiumInfo?.amount || premiumInfo?.amount === 0 ? '-' : premiumInfo?.amount
    });
    elements.push({
      name: '次の付与日（有料会員）',
      isVisibleIcon: false,
      value: premiumInfo?.grant_day ? formatDate(premiumInfo?.grant_day, { hideWeekday: true, hideTime: true }) : '-'
    });
    elements.push({
      name: '有料会員の継続状況',
      isVisibleIcon: false,
      value:
        !me.in_time_subscriptions.premium_subscription?.continuation_month ||
        me.in_time_subscriptions.premium_subscription?.continuation_month === 0
          ? '-'
          : `${me.in_time_subscriptions.premium_subscription?.continuation_month}ヶ月目`
    });
    if (!isBeginner) {
      elements.push({
        name: '次の付与数（ロイヤル会員）',
        isVisibleIcon: true,
        value: !royalInfo?.amount || royalInfo?.amount === 0 ? '-' : royalInfo?.amount
      });
      elements.push({
        name: '次の付与日（ロイヤル会員）',
        isVisibleIcon: false,
        value: royalInfo?.grant_day ? formatDate(royalInfo?.grant_day, { hideWeekday: true, hideTime: true }) : '-'
      });
    }

    return elements.map((v) => (
      <li className={styles['section-status-li']} key={v.name}>
        <span className={clsx(components['basic-text'], styles['section-status-name'])}>{v.name}</span>
        {v.isVisibleIcon && <img src={WSparkHeart} alt='♡' className={styles['section-status-heart']} />}
        <span className={clsx(components['basic-text'], styles['section-status-value'])}>{v.value}</span>
      </li>
    ));
  };

  const targetText = useMemo(() => {
    return isBeginner ? '有料会員' : '有料会員・ロイヤル会員';
  }, []);

  return (
    <>
      <TrackPageView viewName='lp_richlike' />

      <div className={account.submodal}>
        <motion.div
          ref={wrapperRef}
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>リッチいいね!</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.kv}>
              <img src={isBeginner ? kv0 : kv} alt='リッチいいね!' />
            </div>

            <div className={styles.inner}>
              <div className={styles['desc-block']}>
                <div className={styles['desc-block-img']}>
                  <img src={desc} alt={`「リッチいいね!」は、${targetText}のみが使える特別ないいね!です。`} />
                </div>
                <div>
                  <p className={styles['desc-block-text']}>
                    「リッチいいね!」は、{targetText}のみが使える特別ないいね!です。
                  </p>
                  <Button color='black' size='submin' onClick={handleClickButton}>
                    詳細をみる
                  </Button>
                </div>
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-title']}>ステータス</h2>
                <ul className={styles['section-status-ul']}>{elementStatus()}</ul>
                <p className={styles['annotation-text']}>
                  ※有料会員を解約すると、保有している「リッチいいね!」が失効します。
                </p>
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-title']}>有料会員の特典</h2>
                <p className={clsx(components['basic-text'], components['align-center'])}>
                  継続期間に応じて「リッチいいね!」を付与
                </p>
                <div className={styles['section-img']}>
                  <img src={step_1Img} alt='継続期間に応じて「リッチいいね!」を付与' />
                </div>
                <p className={styles['section-center-text']}>さらに</p>
                <div className={styles['section-img']} data-narrow>
                  <img src={step_2Img} alt='10ヶ月目以降毎月獲得' />
                </div>
                <p className={styles['annotation-text']}>
                  ※有料会員の継続が途切れると、付与サイクルがリセットされます。
                </p>
              </div>

              {!isBeginner && (
                <div className={styles.section}>
                  <h2 className={styles['section-title']}>ロイヤル会員の特典</h2>
                  <p className={clsx(components['basic-text'], components['align-center'])}>
                    有料会員でもらえる「リッチいいね!」に加えて、
                    <br />
                    ロイヤル会員はさらに「リッチいいね!」を付与
                  </p>
                  <div className={styles['section-img']}>
                    <img src={benefitsStep} alt='加入直後から1ヶ月ごとに2つもらえる' />
                  </div>
                  <p className={styles['section-center-text']}>加入直後から1ヶ月ごとに2つもらえる</p>
                </div>
              )}

              <div ref={aboutRef} className={styles.section}>
                <h2 className={styles['section-title']}>リッチいいね!とは</h2>

                <ul className={styles['point-list']}>
                  <li className={styles['point-block']}>
                    <div className={styles['point-block-header']}>
                      <span className={styles['point-block-header-label']}>POINT 1</span>
                      <h3 className={styles['point-block-title']}>あなたの「リッチいいね!」を優先表示</h3>
                    </div>

                    <div className={styles['point-block-text']}>
                      <p>通常の「いいね!」よりも優先して、あなたの「リッチいいね!」がお相手に表示されます。</p>
                      <p>お相手の目に留まりやすくなるので、よりアピールしたい方に送るのがおすすめ！</p>
                    </div>
                    <div className={styles['point-block-img']}>
                      <img src={point1} alt='あなたの「リッチいいね!」を優先表示' />
                    </div>
                  </li>

                  <li className={styles['point-block']}>
                    <div className={styles['point-block-header']}>
                      <span className={styles['point-block-header-label']}>POINT 2</span>
                      <h3 className={styles['point-block-title']}>マッチしなければ返却</h3>
                    </div>

                    <div className={styles['point-block-text']}>
                      <p>
                        「リッチいいね!」を送ってから3日間でマッチングしなかった場合は、「リッチいいね!」が返却されます。
                      </p>
                      <p>気になるお相手にどんどん送りましょう</p>
                    </div>
                    <div className={styles['point-block-img']}>
                      <img src={point2} alt='マッチしなければ返却' />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
