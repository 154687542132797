import { ReactNode, memo } from 'react';
import styles from '@/styles/uis/checkBox.module.scss';

type ElementProps = JSX.IntrinsicElements['input'];

type Props = {
  selected?: boolean;
  isReverse?: boolean;
  children: ReactNode;
} & ElementProps;

export const CheckBox: React.FC<Props> = memo((_props) => {
  const { id, selected, isReverse, children, ...props } = _props;

  return (
    <div className={styles.wrapper} data-reverse={isReverse}>
      <div className={styles.text}>{children}</div>
      <label htmlFor={id} className={styles.checkbox}>
        <input id={id} type='checkbox' checked={selected} {...props} />
      </label>
    </div>
  );
});
