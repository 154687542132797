import { BaseSyntheticEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import InfiniteScroller from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetAppointments, useGetChatRoom } from '@/apis';
import { appointmentKeys, badgeKeys, chatRoomKeys } from '@/apis/queryKeys';
import { ReactComponent as EmptySchedule } from '@/assets/icons/schedule.svg';
import { AppointmentDetailModal } from '@/components/features/modal/AppointmentDetailModal';
import { UserImg } from '@/components/styles/projects/UserImg';
import { Button } from '@/components/styles/uis/Button';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { TabSwitch, TabType } from '@/components/styles/uis/TabSwitch';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { checkNew, formatDate, formatDateRange, getUserImage } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useSubElement } from '@/functions/hooks';
import { Appointments } from '@/functions/types/appointments';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';
import { Chat } from '@/pages/Chat';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/appointment.module.scss';

const tabs: TabType[] = [
  {
    value: 'future',
    label: 'これから'
  },
  {
    value: 'previous',
    label: '過去'
  }
];

type Props = {
  onClose: () => void;
};

export const Appointment: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [chatRoomId, setChatRoomId] = useState<number | null>(null);
  const [refetchChatFlag, setRefetchChatFlag] = useState(false);
  const [selectAppointment, setSelectAppointment] = useState<{
    appointment: Appointments['appointment'] | null;
    user: UserBasicInfo | null;
  }>({
    appointment: null,
    user: null
  });

  const queryClient = useQueryClient();

  const { isMaleFree, isAgeConfirm, isFreezed, isBeginner, isPhoneVerify } = useAtomValue(meFlagAtom);

  const appointmentDetailModal = useDisclosure();
  const chatModal = useDisclosure();
  const {
    appointmentPremiumGuidanceModal,
    ageConfirmationModal,
    isMeFreezedModal,
    isUserFreezedModal,
    phoneVerificationModal
  } = useBasicModal();
  const { handleCloseSubElement } = useSubElement();

  const { fetchAppointments } = useGetAppointments();
  const { fetchChatRoom } = useGetChatRoom();

  const { isLoading, isFetching, data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: appointmentKeys.list(activeTab),
    queryFn: async ({ pageParam = 1 }) => {
      const datas = await fetchAppointments({ page: pageParam, type: activeTab });
      return datas;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) return undefined;
      return allPage.length + 1;
    }
  });

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(appointmentKeys.lists());
  }, []);

  const { data: chatRoom } = useQuery({
    queryKey: chatRoomKeys.detail(chatRoomId),
    queryFn: async () => {
      if (!chatRoomId) return null;
      const chatRoom = await fetchChatRoom({ chatRoomId });

      return chatRoom;
    }
  });

  const appointmentData = useMemo(() => {
    if (!data) return [];
    const groupData: { date: string; data: Appointments[] }[] = [];

    data.pages
      .flatMap((v) => v.flatMap((v) => v))
      .forEach((d) => {
        const startDate = new Date(d.appointment.start_at);
        const date = formatDate(startDate, { hideTime: true });
        const target = groupData.find((v) => v.date === date);

        if (target) {
          target.data.push(d);
        } else {
          groupData.push({
            date,
            data: [d]
          });
        }
      });

    return groupData;
  }, [data]);

  const handleClickTab = useCallback(
    async (e: BaseSyntheticEvent) => {
      const { value } = e.target;
      setActiveTab(value);
    },
    [activeTab]
  );

  const handleClickUser = useCallback(
    (chatRoomId: number, user: UserBasicInfo) => {
      if (isFreezed) {
        isMeFreezedModal();
      } else if (user.is_freezed) {
        isUserFreezedModal();
      } else {
        setChatRoomId(chatRoomId);
        chatModal.open();
      }
    },
    [isFreezed]
  );

  /**
   * チャットルームモーダルを閉じる
   */
  const onCloseChatModal = useCallback(() => {
    setChatRoomId(null);
    chatModal.close();

    /**
     * チャットルームに変更があった場合、refetchChatFlagがtrueになる
     * 基本チャットルームが閉じられたタイミングでrefetchするが、変更がない場合はrefetchしない
     */
    if (refetchChatFlag) {
      queryClient.invalidateQueries(chatRoomKeys.detail(chatRoomId));
      setRefetchChatFlag(false);
    }
  }, [refetchChatFlag]);

  const handleClickAppointment = useCallback(
    (appointment: Appointments['appointment'], user: UserBasicInfo) => {
      if (isFreezed) {
        isMeFreezedModal();
      } else if (user.is_freezed) {
        isUserFreezedModal();
      } else if (!isPhoneVerify) {
        phoneVerificationModal();
      } else if (!isAgeConfirm) {
        ageConfirmationModal();
      } else if (isMaleFree) {
        appointmentPremiumGuidanceModal();
      } else {
        setSelectAppointment({
          appointment,
          user
        });
        appointmentDetailModal.open();
      }
    },
    [isFreezed, isPhoneVerify, isAgeConfirm, isMaleFree]
  );

  const handleCloseAppointment = useCallback(() => {
    appointmentDetailModal.close();

    setTimeout(() => {
      setSelectAppointment({
        appointment: null,
        user: null
      });
    }, animateDuration);
  }, []);

  const handleClickMessage = useCallback(() => {
    handleCloseSubElement('first');
    navigate('/message');
  }, []);

  const handleClickPremium = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else {
      appointmentPremiumGuidanceModal();
    }
  }, [isPhoneVerify, isAgeConfirm]);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(badgeKeys.all);
    };
  }, []);

  return (
    <>
      <TrackPageView viewName='appointment' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']} style={{ borderBottom: 'none' }}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>お約束</p>
            </div>
            <TabSwitch name='schedule' tabs={tabs} isActive={activeTab} onChange={handleClickTab} />
          </div>

          <div className={styles['submodal-contents']}>
            {isLoading && (
              <div className={styles['center-wrapper']}>
                <LoadingSpinner />
              </div>
            )}

            {!isLoading && (
              <>
                {appointmentData.length !== 0 && (
                  <div className={components['parent-wrapper']} data-children-open={chatModal.isOpen}>
                    <div className={styles.inner}>
                      <InfiniteScroller
                        pageStart={1}
                        hasMore={hasNextPage}
                        // @ts-ignore
                        loadMore={fetchNextPage}
                        loader={
                          isFetching ? (
                            <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                              <LoadingSpinner isSmall />
                            </div>
                          ) : undefined
                        }
                        useWindow={false}
                      >
                        <ul>
                          {appointmentData.map((group) => (
                            <li key={group.date}>
                              <p className={styles['date-title']}>{group.date}</p>
                              <ul>
                                {group.data.map(({ id, appointment, users }) => (
                                  <li key={users[0].id} className={styles.card}>
                                    <UserImg
                                      id={users[0].id}
                                      size={88}
                                      onClick={() => handleClickUser(id, users[0])}
                                      src={getUserImage(users[0], isBeginner)}
                                      lastActionTime={users[0].last_action_time}
                                      isNew={checkNew(users[0].created_at)}
                                      isRoyal={
                                        !isBeginner &&
                                        (users[0].patch_status === 'force_royal' || users[0].patch_status === 'royal')
                                      }
                                    />
                                    <div className={styles['card-content']}>
                                      {activeTab === 'future' && (
                                        <>
                                          {appointment.state === 'agreed' && (
                                            <span className={styles['card-label']} data-status='agreed'>
                                              約束済み
                                            </span>
                                          )}
                                          {appointment.state === 'pending' && (
                                            <span className={styles['card-label']} data-status='pending'>
                                              約束確認待ち
                                            </span>
                                          )}
                                        </>
                                      )}
                                      <div className={styles['card-header']}>
                                        <span className={components['text-bold']}>{users[0].property.nickname}</span>
                                        <span className={styles['card-property']}>
                                          {users[0].age}歳 {users[0].property.residence_location}
                                        </span>
                                      </div>
                                      <button
                                        type='button'
                                        onClick={() => handleClickAppointment(appointment, users[0])}
                                        className={styles['card-detail']}
                                        data-future={activeTab === 'future'}
                                      >
                                        <p>
                                          <span className='calendar' />
                                          {formatDateRange(appointment.start_at, appointment.end_at, {
                                            hideYear: true,
                                            hideMonth: true,
                                            hideDay: true,
                                            hideWeekday: true
                                          })}
                                        </p>
                                        <p>
                                          <span className='map' />
                                          {appointment.place}
                                        </p>
                                      </button>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </InfiniteScroller>
                    </div>
                  </div>
                )}

                {appointmentData.length === 0 && (
                  <>
                    {activeTab === 'future' && (
                      <div className={styles['center-wrapper']}>
                        <EmptySchedule />
                        <p className={clsx(components['heading-1'], components['mt-gutter'])}>
                          今後のお約束はありません。
                        </p>
                        {isMaleFree ? (
                          <p className={clsx(components['basic-text'], components['align-center'])}>
                            メッセージでお約束の作成ができます。
                            <br />
                            有料会員に登録して、
                            <br />
                            お相手とやりとりをはじめましょう。
                          </p>
                        ) : (
                          <p className={clsx(components['basic-text'], components['align-center'])}>
                            気になるお相手が見つかったら、
                            <br />
                            メッセージでお約束を作成しましょう。
                          </p>
                        )}
                        <div className={styles['empty-button-wrapper']}>
                          {isMaleFree ? (
                            <Button color='black' onClick={handleClickPremium}>
                              有料会員の詳細へ
                            </Button>
                          ) : (
                            <Button color='black' onClick={handleClickMessage}>
                              メッセージ一覧へ
                            </Button>
                          )}
                        </div>
                      </div>
                    )}

                    {activeTab === 'previous' && (
                      <div className={styles['center-wrapper']}>
                        <EmptySchedule />
                        <p className={clsx(components['heading-1'], components['mt-gutter'])}>
                          過去のお約束はありません。
                        </p>
                        {isMaleFree ? (
                          <p className={clsx(components['basic-text'], components['align-center'])}>
                            メッセージでお約束の作成ができます。
                            <br />
                            有料会員に登録して、
                            <br />
                            お相手とやりとりをはじめましょう。
                          </p>
                        ) : (
                          <p className={clsx(components['basic-text'], components['align-center'])}>
                            終了したお約束がある場合、
                            <br />
                            こちらに表示されます。
                          </p>
                        )}
                        <div className={styles['empty-button-wrapper']}>
                          {isMaleFree ? (
                            <Button color='black' onClick={handleClickPremium}>
                              有料会員の詳細へ
                            </Button>
                          ) : (
                            <Button color='black' onClick={handleClickMessage}>
                              メッセージ一覧へ
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </motion.div>

        {selectAppointment.appointment && selectAppointment.user && (
          <AppointmentDetailModal
            isOpen={appointmentDetailModal.isOpen}
            onClose={handleCloseAppointment}
            appointment={selectAppointment.appointment}
            user={selectAppointment.user}
            refetch={refetch}
          />
        )}

        <AnimatePresence mode='wait' initial={false}>
          {chatRoom && chatModal.isOpen && (
            <Chat chatRoom={chatRoom} onClose={onCloseChatModal} setRefetchChatFlag={setRefetchChatFlag} />
          )}
        </AnimatePresence>
      </div>
    </>
  );
});
