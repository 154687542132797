import { memo } from 'react';
import styles from '@/styles/uis/snackbar.module.scss';

export type LevelType = 'success' | 'error';

export type SnackbarProps = {
  type: 'toast' | 'popup';
  title?: string;
  text: string;
  level?: LevelType;
  bottom?: number;
};

export type Props = {
  isOpen: boolean;
} & SnackbarProps;

export const Snackbar: React.FC<Props> = memo((props) => {
  const { isOpen, type, title, text, level = 'success', bottom = 16 } = props;

  return (
    <div
      className={styles.snackbar}
      style={{ bottom: `${bottom}px` }}
      data-type={type}
      data-level={level}
      data-open={isOpen}
    >
      {type === 'popup' && title && <p className={styles.title}>{title}</p>}
      <p className={styles.text}>{text}</p>
    </div>
  );
});
