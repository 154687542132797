import { memo, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { usePostQualityCheck, usePostUsePoints } from '@/apis';
import { userKeys } from '@/apis/queryKeys';
import { QualityCheck } from '@/apis/users/postQualityCheck';
import { Button } from '@/components/styles/uis/Button';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { GENDER } from '@/functions/constants/enums';
import { useBasicModal, useSubElement } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import { MeetupLocations } from '@/functions/types/meetupLocation';
import { UsersShow } from '@/functions/types/usersShow';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/show/showProfile.module.scss';

type Props = {
  user?: UsersShow;
  isPreview?: boolean;
};

export const ShowProfile: React.FC<Props> = memo((props) => {
  const { user, isPreview } = props;

  const [qualityCheckData, setQualityCheckData] = useState<QualityCheck>([]);

  const [meetupLocation, setMeetupLocation] = useState<{ commonality: string[]; other: string[] }>({
    commonality: [],
    other: []
  });

  const me = useAtomValue(meAtom);
  const { member, isMale, basicPointLength, isBeginner } = useAtomValue(meFlagAtom);
  const isRoyal = !isBeginner && member === 'royal';

  const { onCloseModal, commonModal, pointLackModal } = useBasicModal();
  const { handleOpenSubElement } = useSubElement();

  const { postPoints } = usePostUsePoints();
  const { postQualityCheck } = usePostQualityCheck();

  const { updatePointsCache } = useCache();

  const queryClient = useQueryClient();

  const onMoveProfile = useCallback(() => {
    onCloseModal();
    handleOpenSubElement('profile', 'show');
  }, []);

  const updateQualityCheckCache = useCallback((userId: number) => {
    queryClient.setQueriesData(userKeys.detail(userId), (prevDate: UsersShow | undefined) => {
      if (!prevDate) return prevDate;
      return { ...prevDate, point_required_for_quality_check: false };
    });
  }, []);

  const handleClickQualityCheck = useCallback(async () => {
    if (!user) return;

    if (user.point_required_for_quality_check && (basicPointLength ?? 0) < 15) {
      pointLackModal(
        <>クオリティチェックを行うためには15PTが必要です。ポイントをご購入の上、再度お試しください。</>,
        basicPointLength ?? 0
      );
    } else if (!isMale && me.profile_input_rate !== 100) {
      commonModal({
        title: 'プロフィールが未完成のためご利用いただけません。',
        text: 'プロフィール項目を全て埋めると利用可能になります。まずはプロフィールを充実させましょう。',
        onClickLabel: 'プロフィール編集',
        onClick: onMoveProfile
      });
    } else {
      if (user.point_required_for_quality_check) {
        await postPoints({
          amount: 15,
          purpose: 'quality_check',
          to_user_id: user.id,
          user_id: me.id
        });

        updatePointsCache(15);
        updateQualityCheckCache(user.id);
      }

      const data = await postQualityCheck({ userId: user.id });
      setQualityCheckData(data);
    }
  }, [user]);

  const getQualityCheckText = useCallback(() => {
    if (!isMale) {
      return `${user?.property.nickname}さんの違反行為や受けた通報の内容を確認することができます。`;
    }
    if (isRoyal) {
      return `${user?.property.nickname}さんの違反行為や受けた通報の内容を、ロイヤル会員ならいつでも確認することができます。`;
    }
    return `${user?.property.nickname}さんの違反行為や受けた通報の内容を、開封から1週間確認することができます。`;
  }, [user]);

  const getQualityCheckButtonText = useCallback(() => {
    if (!isMale) {
      return <>確認する</>;
    }
    if (isRoyal) {
      return (
        <>
          <span className={styles['quality-check-button-royal-badge']} />
          確認する
        </>
      );
    }
    if (user?.point_required_for_quality_check) {
      return (
        <>
          確認する
          <span className={styles['quality-check-button-label']}>15PT</span>
        </>
      );
    }
    return <>開く</>;
  }, [user]);

  const getCommonalityArea = useCallback(
    (locations: MeetupLocations) => {
      const getLabel = (locations: MeetupLocations) => {
        return Object.values(locations).flatMap((obj) => {
          return obj.map((v) => Object.values(v)[0]);
        });
      };

      const userLocations = getLabel(locations);
      const myLocations = !isPreview ? getLabel(me.property.meetup_location) : [];

      const isCommonality = userLocations.filter((v) => myLocations.includes(v)) as string[];
      const notCommonality = userLocations.filter((v) => !myLocations.includes(v)) as string[];
      setMeetupLocation({
        commonality: isCommonality,
        other: notCommonality
      });
    },
    [isPreview]
  );

  useEffect(() => {
    if (!user) return;

    if (Object.keys(user.property.meetup_location).length) {
      getCommonalityArea(user.property.meetup_location);
    }
  }, [user]);

  const elementSelfIntroduction = () => {
    if (!user?.property.self_introduction) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>自己紹介</h2>
        <p className={components['text-break']}>{user?.property.self_introduction}</p>
      </li>
    );
  };

  const elementQualityCheck = () => {
    if (isBeginner || isPreview) {
      return null;
    }

    return (
      <li className={styles['quality-check-block']}>
        <h2 className={styles['quality-check-heading']}>クオリティチェック</h2>

        <p className={components['basic-text']}>{getQualityCheckText()}</p>

        <div className={styles['quality-check-body']} data-open={!!qualityCheckData.length}>
          {qualityCheckData.length ? (
            qualityCheckData.map((category) => (
              <div key={category.name} className={styles['quality-check-section']}>
                <p className={styles['quality-check-body-heading']}>{category.name}</p>
                {category.small_category.length ? (
                  <ul className={styles['quality-check-list']}>
                    {category.small_category.map(({ name, value }) => (
                      <li key={name}>
                        {name}
                        <span className={styles['quality-check-value']}>
                          <span>{value}</span>件
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles['quality-check-empty-text']}>違反はありません</p>
                )}
              </div>
            ))
          ) : (
            <Button
              className={styles['quality-check-button']}
              data-royal={isRoyal}
              data-label={isMale && user?.point_required_for_quality_check}
              onClick={handleClickQualityCheck}
            >
              {getQualityCheckButtonText()}
            </Button>
          )}
        </div>
      </li>
    );
  };

  const elementMeetup = () => {
    if (!user?.property.meetup_location || Object.keys(user?.property.meetup_location).length === 0) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>会えるエリア</h2>
        <div className={styles['meetup-block']}>
          {meetupLocation.commonality.length !== 0 && (
            <>
              <p className={styles['meetup-sub-title']}>あなたと共通のエリア</p>
              <ul className={styles['meetup-list']} data-commonality>
                {meetupLocation.commonality.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </>
          )}
          {meetupLocation.other.length !== 0 && (
            <ul className={styles['meetup-list']}>
              {meetupLocation.other.map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          )}
        </div>
      </li>
    );
  };

  const elementBusinessEducation = () => {
    if (
      !user?.property.education_type &&
      !user?.property.business_type &&
      !user?.property.holiday_type &&
      !user?.property.income_type &&
      !user?.property.asset_type
    ) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>お仕事・学歴</h2>
        <dl>
          {user?.property.education_type && (
            <>
              <dt>学歴</dt>
              <dd>{user?.property.education_type}</dd>
            </>
          )}
          {user?.property.business_type && (
            <>
              <dt>お仕事</dt>
              <dd>{user?.property.business_type}</dd>
            </>
          )}
          {user?.property.holiday_type && (
            <>
              <dt>休日</dt>
              <dd>{user?.property.holiday_type}</dd>
            </>
          )}
          {!isBeginner && user?.property.income_type && (
            <>
              <dt>年収</dt>
              <dd>{user?.property.income_type}</dd>
            </>
          )}
          {!isBeginner && user?.property.asset_type && (
            <>
              <dt>資産</dt>
              <dd>{user?.property.asset_type}</dd>
            </>
          )}
        </dl>
      </li>
    );
  };

  const elementLooks = () => {
    if (
      !user?.property.height &&
      !user?.property.body_type &&
      user?.property.looks.length === 0 &&
      user?.property.personality.length === 0
    ) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>外見・容姿</h2>
        <dl>
          {user?.property.height && (
            <>
              <dt>身長</dt>
              <dd>{user?.property.height}cm</dd>
            </>
          )}
          {user?.property.body_type && (
            <>
              <dt>スタイル</dt>
              <dd>{user?.property.body_type}</dd>
            </>
          )}
          {user?.property.looks.length !== 0 && (
            <>
              <dt>雰囲気</dt>
              <dd>{user?.property.looks.join(',')}</dd>
            </>
          )}
          {user?.property.personality.length !== 0 && (
            <>
              <dt>性格・タイプ</dt>
              <dd>{user?.property.personality.join(',')}</dd>
            </>
          )}
        </dl>
      </li>
    );
  };

  const elementLifestyle = () => {
    if (!user?.property.drinking_type && !user?.property.cigarette_type) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>生活</h2>
        <dl>
          {user?.property.drinking_type && (
            <>
              <dt>お酒</dt>
              <dd>{user?.property.drinking_type}</dd>
            </>
          )}
          {user?.property.cigarette_type && (
            <>
              <dt>タバコ</dt>
              <dd>{user?.property.cigarette_type}</dd>
            </>
          )}
        </dl>
      </li>
    );
  };

  const elementDate = () => {
    if (!user?.property.purpose && !user?.property.before_meeting) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>デート</h2>
        <dl>
          {user?.property.purpose && (
            <>
              <dt>デートでしたいこと</dt>
              <dd>{user?.property.purpose}</dd>
            </>
          )}
          {user?.property.before_meeting && (
            <>
              <dt>デートまでの希望</dt>
              <dd>{user?.property.before_meeting}</dd>
            </>
          )}
        </dl>
      </li>
    );
  };

  const elementOther = () => {
    if (isBeginner) {
      return null;
    }
    if (user?.property.register_reason.length === 0 && !user?.property.future_dream) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>その他</h2>
        <dl>
          {user?.property.register_reason.length !== 0 && (
            <>
              <dt>登録した理由</dt>
              <dd>{user?.property.register_reason.join(',')}</dd>
            </>
          )}
          {user?.property.future_dream && (
            <>
              <dt>将来の夢</dt>
              <dd className={components['text-break']}>{user?.property.future_dream}</dd>
            </>
          )}
        </dl>
      </li>
    );
  };

  const elementBasicInfo = () => {
    if (!user?.property.residence_location && !user?.property.hometown) {
      return null;
    }

    return (
      <li>
        <h2 className={components['heading-1']}>基本情報</h2>
        <dl>
          {user?.property.residence_location && (
            <>
              <dt>居住地</dt>
              <dd>{user?.property.residence_location}</dd>
            </>
          )}
          {user?.property.hometown && (
            <>
              <dt>出身地</dt>
              <dd>{user?.property.hometown}</dd>
            </>
          )}
        </dl>
      </li>
    );
  };

  const createProfileContents = () => {
    const profileContents = [];
    if (user?.gender === GENDER.MALE) {
      profileContents.push(elementSelfIntroduction()); // 自己紹介
      profileContents.push(elementQualityCheck()); // クオリティチェック
      profileContents.push(elementMeetup()); // 会えるエリア
      profileContents.push(elementBusinessEducation()); // お仕事・学歴
      profileContents.push(elementLooks()); // 外見・容姿
      profileContents.push(elementLifestyle()); // 生活
      profileContents.push(elementDate()); // デート
      profileContents.push(elementOther()); // その他
      profileContents.push(elementBasicInfo()); // 基本情報
    } else {
      profileContents.push(elementSelfIntroduction()); // 自己紹介
      profileContents.push(elementQualityCheck()); // クオリティチェック
      profileContents.push(elementMeetup()); // 会えるエリア
      profileContents.push(elementLooks()); // 外見・容姿
      profileContents.push(elementLifestyle()); // 生活
      profileContents.push(elementBusinessEducation()); // お仕事・学歴
      profileContents.push(elementDate()); // デート
      profileContents.push(elementOther()); // その他
      profileContents.push(elementBasicInfo()); // 基本情報
    }

    return profileContents;
  };

  return <ul className={styles['profile-list']}>{createProfileContents()}</ul>;
});
