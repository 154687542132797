import { useCallback, useState } from 'react';
import { animateDuration } from '@/functions/constants/framerMotion';

export const useStep = (initial = 1) => {
  const [step, setStep] = useState<number>(initial);
  const [isNext, setIsNext] = useState(true);
  const [isAnimate, setIsAnimate] = useState(false);

  const nextStep = useCallback(() => {
    if (isAnimate) return;

    setIsNext(true);
    setStep((prev) => prev + 1);
    setIsAnimate(true);

    setTimeout(() => {
      setIsAnimate(false);
    }, animateDuration);
  }, [isAnimate]);

  const backStep = useCallback(() => {
    if (isAnimate) return;

    setIsNext(false);
    setStep((prev) => prev - 1);
    setIsAnimate(true);

    setTimeout(() => {
      setIsAnimate(false);
    }, animateDuration);
  }, [isAnimate]);

  return { step, setStep, isNext, nextStep, backStep };
};
