import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { activityKeys, chatRoomKeys, goodKeys, meKeys, pointKeys, userKeys } from '@/apis/queryKeys';
import { getMeFlag, meFlagAtom } from '@/contexts/atoms/meFlag';
import { useChanceTime } from '@/functions/hooks/useChanceTime';

type Action = 'like' | 'richLike' | 'message' | 'block' | 'unblock' | 'hide' | 'unhide' | 'report';

export const useCache = () => {
  const queryClient = useQueryClient();
  const setMeFlag = useSetAtom(meFlagAtom);
  const { isMale } = useAtomValue(meFlagAtom);
  const { isChanceTime } = useChanceTime();

  const userListQueryKeys = [
    userKeys.lists(),
    userKeys.meetupLocation(),
    chatRoomKeys.lists(),
    goodKeys.receive(),
    goodKeys.give(),
    activityKeys.lists(),
    userKeys.block(),
    userKeys.hide()
  ];

  const actionQueryKeys: Record<Action, (readonly string[])[]> = {
    like: [userKeys.lists(), userKeys.meetupLocation(), goodKeys.give()],
    richLike: [userKeys.lists(), userKeys.meetupLocation(), goodKeys.give()],
    message: [userKeys.lists(), userKeys.meetupLocation(), goodKeys.give()],
    block: userListQueryKeys,
    hide: userListQueryKeys,
    report: userListQueryKeys,
    // 以下はもう少し精査が必要
    // ブロック/非表示解除後に userData や meetupLocationUserData も invalidate が必要なのか等
    unblock: userListQueryKeys,
    unhide: userListQueryKeys
  };

  const isEqualArray = useCallback((array1: readonly string[], array2: readonly string[]) => {
    return JSON.stringify(array1) === JSON.stringify(array2);
  }, []);

  const getUserId = (queryKey: readonly string[], data: any) => {
    if (
      isEqualArray(queryKey, chatRoomKeys.lists()) ||
      isEqualArray(queryKey, goodKeys.receive()) ||
      isEqualArray(queryKey, goodKeys.give())
    ) {
      return data.users[0].id;
    }
    if (isEqualArray(queryKey, activityKeys.lists())) {
      return data.user.id;
    }
    return data.id;
  };

  /**
   * 引数でもらった userId を持つデータをクエリキャッシュから削除
   * 一覧からユーザーを削除する際に使用（ブロック、非表示、ホームでいいねした等が該当する）
   * @param userId 対象のユーザーID
   */
  const removeUserActiveCache = (action: Action, userId: number) => {
    actionQueryKeys[action].forEach((queryKey) => {
      // type: 'active' でフィルタリングしているため、active なクエリキャッシュのみが対象となる
      queryClient.setQueriesData({ queryKey, type: 'active' }, (prevData: any) => {
        if (prevData && prevData.pages) {
          const newData = prevData.pages.map((innerArray: any) => {
            return innerArray.filter((data: any) => getUserId(queryKey, data) !== userId);
          });
          return { ...prevData, pages: newData };
        }
        if (prevData && prevData.length) {
          return prevData.filter((data: any) => getUserId(queryKey, data) !== userId);
        }
        return prevData;
      });
    });
  };

  /**
   * ユーザーがとったアクションによって、一覧のキャッシュを無効化
   * @param action いいね、ブロック等のユーザーがとったアクションの種類
   */
  const invalidateUserInactiveCache = (action: Action) => {
    actionQueryKeys[action].forEach((queryKey) => {
      // 以下の key のクエリはなぜか invalidate が効かないため remove する
      // remove の場合、invalidate と異なりローディングが走ってしまうため、原因が分かり次第 invalidate で統一する
      if (isEqualArray(queryKey, userKeys.block()) || isEqualArray(queryKey, userKeys.hide())) {
        queryClient.removeQueries({ queryKey, type: 'inactive' });
        return;
      }

      // refetchType: 'none' でフィルタリングしているため、inactive なクエリキャッシュのみが対象となる
      queryClient.invalidateQueries({ queryKey, refetchType: 'none' });
    });
  };

  /**
   * 引数でもらった userId を持つデータをクエリキャッシュから削除し、action に関連するクエリキャッシュを無効化
   * @param userId 対象のユーザーID
   * @param action いいね、ブロック等のユーザーがとったアクションの種類（actionによって無効化するクエリキャッシュを決定する）
   */
  const updateUsersCache = (userId: number, action: Action) => {
    removeUserActiveCache(action, userId);
    invalidateUserInactiveCache(action);
  };

  /**
   * ポイント関連のキャッシュを更新する
   */
  const updatePointsCache = (requirePoint: number) => {
    if (!isMale || isChanceTime) return;

    // ポイント履歴のキャッシュを削除する
    queryClient.removeQueries(pointKeys.histories());

    // 引数で受け取ったポイントを使って、me のポイント情報を更新する
    queryClient.setQueryData(meKeys.all, (prevData: any) => {
      const meData = {
        ...prevData,
        points: {
          basic: { current_amount: prevData.points.basic.current_amount - requirePoint },
          rich_like: { ...prevData.points.rich_like }
        }
      };
      setMeFlag(getMeFlag(meData));
      return meData;
    });
  };

  /**
   * リッチライクポイント関連のキャッシュを更新する
   */
  const updateRichLikePointsCache = (requirePoint: number) => {
    if (!isMale) return;

    // ポイント履歴のキャッシュを削除する
    queryClient.removeQueries(pointKeys.histories());

    // 引数で受け取ったポイントを使って、me のポイント情報を更新する
    queryClient.setQueryData(meKeys.all, (prevData: any) => {
      const meData = {
        ...prevData,
        points: {
          basic: { ...prevData.points.basic },
          rich_like: { in_time_amount: prevData.points.rich_like.in_time_amount - requirePoint }
        }
      };
      setMeFlag(getMeFlag(meData));
      return meData;
    });
  };

  return { updateUsersCache, updatePointsCache, updateRichLikePointsCache };
};
