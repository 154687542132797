import { BaseSyntheticEvent, ReactNode, memo, useCallback } from 'react';
import clsx from 'clsx';
import { TabSwitch, TabType } from '@/components/styles/uis/TabSwitch';
import styles from '@/styles/projects/basicPanel.module.scss';

type Props = {
  height: number;
  footerHeight?: number;
  children: ReactNode;
  isFlex?: boolean;
  tab?: {
    name: string;
    tabs: TabType[];
    activeTab: string;
    onChange: (e: BaseSyntheticEvent) => void;
  };
};

export const BasicPanel: React.FC<Props> = memo((props) => {
  const { height, footerHeight, children, isFlex, tab } = props;

  const getWrapperProps = useCallback(() => {
    return {
      height: `calc(100dvh - ${height}px)`
    };
  }, []);

  const getInnerProps = useCallback((tab: boolean) => {
    const tabHeight = tab ? 43 : 0;

    return {
      height: `calc(100% - ${tabHeight}px)`,
      paddingBottom: footerHeight ? `${footerHeight + 16}px` : undefined
    };
  }, []);

  return (
    <div className={styles.wrapper} style={getWrapperProps()}>
      {tab && <TabSwitch name={tab.name} tabs={tab.tabs} isActive={tab.activeTab} onChange={tab.onChange} />}
      <div className={clsx(styles.inner, isFlex && styles['flex-wrapper'])} style={getInnerProps(!!tab)}>
        {children}
      </div>
    </div>
  );
});
