import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  movieId: number;
  body: {
    disclose: 'public' | 'like';
  };
};

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/me/profile_movies/${params.movieId}`, params.body);
};

type Props = Params;

/**
 * プロフィール動画の公開設定変更
 */

export const usePutDiscloseMovies = () => {
  const putDiscloseMovies = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putDiscloseMovies };
};
