import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import {
  AllSearchUserImpressionKey,
  TemporalSearchUserImpressionKey,
  allImpressionAtom,
  tempImpressionAtom
} from '@/contexts/atoms/impression';

// NOTE: 初期化されるまで値が初期値のためチェックを入れる https://zenn.dev/kikiki_kiki/scraps/ec8fc796dee306
const checkLocalStorage = (list: number[], key: string): boolean => {
  const storageList = localStorage.getItem(key);
  const isReady = storageList === null ? true : storageList === JSON.stringify(list);

  return isReady;
};

export const useImpression = () => {
  const tempImpression = useAtomValue(tempImpressionAtom);
  const allImpression = useAtomValue(allImpressionAtom);
  const setTempImpression = useSetAtom(tempImpressionAtom);
  const setAllImpression = useSetAtom(allImpressionAtom);
  const isReady =
    checkLocalStorage(tempImpression, TemporalSearchUserImpressionKey) &&
    checkLocalStorage(allImpression, AllSearchUserImpressionKey);

  const setUserId = (id: number) => {
    if (allImpression.indexOf(id) === -1) {
      if (tempImpression.indexOf(id) === -1) {
        setTempImpression((oldValue) => [...oldValue, id]);
      }
      setAllImpression((oldValue) => [...oldValue, id]);
    }
  };

  const clearTempList = () => {
    setTempImpression(RESET);
  };

  return {
    tempImpression,
    allImpression,
    isReady,
    setUserId,
    clearTempList
  };
};
