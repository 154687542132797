import { RefObject, memo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from '@/styles/uis/textInput.module.scss';

type ElementProps = JSX.IntrinsicElements['input'];

type Props = {
  id?: string;
  inputRef?: RefObject<HTMLInputElement>;
  register?: UseFormRegisterReturn;
  placeholder?: string;
  annotation?: string;
  count?: number;
  isInvalid?: boolean;
  defaultValue?: string;
  icon?: string;
  onClickIcon?: () => void;
} & ElementProps;

export const TextInput: React.FC<Props> = memo((_props) => {
  const { id, register, placeholder, defaultValue, annotation, count, isInvalid, icon, onClickIcon, ...props } = _props;

  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={id}>
        <input
          id={id}
          {...register}
          type='text'
          className={styles.input}
          placeholder={defaultValue}
          data-invalid={isInvalid}
          {...props}
        />
        <span className={styles.placeholder} data-invalid={isInvalid}>
          {placeholder}
        </span>
        {icon && onClickIcon && (
          <button type='button' onClick={onClickIcon} className={styles.icon}>
            <img src={icon} alt='' />
          </button>
        )}
      </label>
      <div className={styles['annotation-wrapper']}>
        {annotation && (
          <span className={styles.annotation} data-invalid={isInvalid}>
            {annotation}
          </span>
        )}
        {count && (
          <span className={styles.counter} data-invalid={isInvalid}>
            {String(props.value).length}/{count}
          </span>
        )}
      </div>
    </div>
  );
});
