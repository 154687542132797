import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';
import { Notices } from '@/functions/types/notices';

type Params = string;

type Response = Notices;

const api = (query: Params): Promise<Response> => {
  return client.get(`v1/notices?${query}`);
};

type Props = {
  page: number;
};

/**
 * お知らせ一覧を取得する
 */

export const useGetNotices = () => {
  const fetchNotices = useCallback(async (props: Props) => {
    const { page = 1 } = props;

    const params = {
      page
    };

    const res = await api(generateQueryParameter(params));
    return res;
  }, []);

  return { fetchNotices };
};
