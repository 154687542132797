import { memo } from 'react';
import Setting from '@/assets/coaching/setting.png';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/coaching.module.scss';

export const SettingStep: React.FC = memo(() => {
  return (
    <li>
      <div className={styles['img-wrapper']}>
        <img src={Setting} alt='使い方をもう一度見るには' />
      </div>
      <div className={styles.inner}>
        <p className={styles.title}>使い方をもう一度見るには</p>
        <div className={styles.block}>
          <p className={components['basic-text']}>
            この内容は「アカウント」＞「設定」＞「paddyの使い方」からご確認いただけます。
          </p>
          <p className={components['basic-text']}>使い方が分からないときはご活用ください。</p>
        </div>
      </div>
    </li>
  );
});
