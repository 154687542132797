import { useCallback } from 'react';
import { client } from '@/apis/client';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';

type Params = {
  id: number;
};

type Response = UserBasicInfo[];

const api = (params: Params): Promise<Response> => {
  return client.get(`v1/females/${params.id}/similars`);
};

type Props = Params;

/**
 * 似ている女性ユーザーを取得
 */

export const useGetFemaleSimilars = () => {
  const fetchFemaleSimilars = useCallback(async (props: Props) => {
    const res = await api(props);

    return res;
  }, []);

  return { fetchFemaleSimilars };
};
