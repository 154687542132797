import { memo, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { usePostUnregisterPremium, usePutUnsubscribeRoyal } from '@/apis';
import { Button } from '@/components/styles/uis/Button';
import { meAtom } from '@/contexts/atoms/me';
import { slideVariants } from '@/functions/constants/framerMotion';
import { formatDate } from '@/functions/helpers';
import { useBasicModal } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/unsubscribePlan.module.scss';

type Props = {
  onClose: () => void;
};

export const UnsubscribePlan: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const me = useAtomValue(meAtom);

  const premiumPlan = me.in_time_subscriptions.premium_subscription;
  const royalPlan = me.in_time_subscriptions.royal_subscription;

  const { onCloseModal, commonModal, unsubscibePlanModal } = useBasicModal();

  const { postUnregisterPremium } = usePostUnregisterPremium();
  const { putUnsubscribeRoyal } = usePutUnsubscribeRoyal();

  const onUnsubscribeRoyal = useCallback(async () => {
    onCloseModal();
    await putUnsubscribeRoyal();
  }, []);

  const handleClickUnsubscribeRoyal = useCallback((planName: string) => {
    unsubscibePlanModal(planName, onUnsubscribeRoyal);
  }, []);

  const onUnsubscribePremium = useCallback(async () => {
    onCloseModal();
    await postUnregisterPremium();
  }, []);

  const handleClickUnsubscribePremium = useCallback(
    (planName: string) => {
      if (royalPlan?.renewal_at && !royalPlan?.expired_at) {
        commonModal({
          title: 'ロイヤル会員の解約を行ってください',
          text: '有料会員解約の前に、ロイヤル会員の解約をする必要があります。'
        });
      } else {
        unsubscibePlanModal(planName, onUnsubscribePremium);
      }
    },
    [royalPlan]
  );

  const handleClickUnsubscribeProcedure = useCallback(() => {
    if (!wrapperRef.current) return;

    wrapperRef.current.scrollTo({
      top: wrapperRef.current.clientHeight,
      behavior: 'smooth'
    });
  }, []);

  const handleClickUnsubscribeProcedurePremium = useCallback(() => {
    if (royalPlan?.renewal_at) {
      commonModal({
        title: 'ロイヤル会員の解約を行ってください',
        text: '有料会員解約の前に、ロイヤル会員の解約をする必要があります。'
      });
    } else {
      handleClickUnsubscribeProcedure();
    }
  }, []);

  const getFormatDate = useCallback((date: string) => {
    return formatDate(new Date(date), { hideTime: true, hideWeekday: true });
  }, []);

  return (
    <div className={account.submodal}>
      <motion.div
        ref={wrapperRef}
        initial='right'
        animate='enter'
        exit='right'
        variants={slideVariants}
        className={account['submodal-wrapper']}
      >
        <div className={account['submodal-header']}>
          <div className={account['submodal-header-inner']}>
            <button
              type='button'
              onClick={onClose}
              className={account['submodal-header-back-button']}
              aria-label='close'
            />
            <p className={account['submodal-header-title']}>購読の解約</p>
          </div>
        </div>

        <div className={account['submodal-contents']}>
          <div className={styles.inner}>
            <div className={styles.content}>
              <h2 className={styles['content-heading']}>購読中のプラン</h2>

              {me.patch_status === 'force_royal' && (
                <div className={styles['plan-wrapper']}>
                  <p className={styles['plan-heading']}>ロイヤル会員プラン</p>
                  <p className={styles['plan-date']}>プランを運営より付与中です。</p>
                </div>
              )}

              {['force_premium', 'force_royal'].includes(me.patch_status) && (
                <div className={styles['plan-wrapper']}>
                  <p className={styles['plan-heading']}>有料会員プラン</p>
                  <p className={styles['plan-date']}>プランを運営より付与中です。</p>
                </div>
              )}

              {!['force_premium', 'force_royal'].includes(me.patch_status) && (
                <>
                  {royalPlan && (
                    <div className={styles['plan-wrapper']}>
                      <p className={styles['plan-heading']}>{royalPlan.name}</p>
                      {royalPlan.renewal_at && (
                        <p className={styles['plan-date']}>次の契約更新日 {getFormatDate(royalPlan.renewal_at)}</p>
                      )}
                      {royalPlan.expired_at && (
                        <p className={styles['plan-date']}>
                          解約済み（有効期限 {getFormatDate(royalPlan.expired_at)} ）
                        </p>
                      )}
                      <div className={styles['button-wrapper']}>
                        {royalPlan.payment_type === 'Web' && (
                          <Button
                            color='black'
                            size='min'
                            onClick={() => handleClickUnsubscribeRoyal(royalPlan.name)}
                            disabled={!!royalPlan.unrenewed_at}
                          >
                            解約
                          </Button>
                        )}
                        {royalPlan.payment_type !== 'Web' && (
                          <Button color='black' size='min' onClick={handleClickUnsubscribeProcedure}>
                            解約方法を確認
                          </Button>
                        )}
                      </div>
                    </div>
                  )}

                  {premiumPlan && (
                    <div className={styles['plan-wrapper']}>
                      <p className={styles['plan-heading']}>{premiumPlan.name}</p>
                      {premiumPlan.renewal_at && (
                        <p className={styles['plan-date']}>次の契約更新日 {getFormatDate(premiumPlan.renewal_at)}</p>
                      )}
                      {premiumPlan.expired_at && (
                        <p className={styles['plan-date']}>
                          解約済み（有効期限 {getFormatDate(premiumPlan.expired_at)} ）
                        </p>
                      )}
                      <div className={styles['button-wrapper']}>
                        {premiumPlan.payment_type === 'Web' && (
                          <Button
                            color='black'
                            size='min'
                            onClick={() => handleClickUnsubscribePremium(premiumPlan.name)}
                            disabled={!!premiumPlan.unrenewed_at}
                          >
                            解約
                          </Button>
                        )}
                        {premiumPlan.payment_type !== 'Web' && (
                          <Button color='black' size='min' onClick={handleClickUnsubscribeProcedurePremium}>
                            解約方法を確認
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={styles.content}>
              <h2 className={styles['content-heading']}>注意事項</h2>
              <div className={styles['text-wrapper']}>
                <p className={components['basic-text']}>
                  ・自動継続課金は期限が切れる24時間以内に自動的に更新される仕組みです。
                </p>
                <p className={components['basic-text']}>
                  ・継続利用を希望されない場合は、ご自身でのご解約が必要となります。
                </p>
                <p className={components['basic-text']}>・ご解約後も期限まで、プランの各種機能をご利用いただけます。</p>
                <p className={components['basic-text']}>
                  ・ロイヤル会員のご利用には、有料会員の登録が必須です。ロイヤル会員の契約期間中でも、有料会員を解約した場合、ロイヤル会員の機能が使用できなくなります。
                </p>
                <p className={components['basic-text']}>
                  ・サービス性質上、月割り・日割りでのご返金は行なっていません。
                </p>
              </div>
            </div>

            <div className={styles.content}>
              <h2 className={styles['content-heading']}>解約方法</h2>
              <div className={styles['link-item']}>
                <span className={styles['link-title']}>iOSアプリ</span>
                <a
                  className={components['link-underline']}
                  href='https://support.apple.com/ja-jp/HT202039'
                  target='_blank'
                  rel='noreferrer'
                >
                  Apple公式サイトで確認
                </a>
              </div>
              <div className={styles['link-item']}>
                <span className={styles['link-title']}>Androidアプリ</span>
                <a
                  className={components['link-underline']}
                  href='https://support.google.com/googleplay/answer/7018481?hl=ja&co=GENIE.Platform%3DAndroid'
                  target='_blank'
                  rel='noreferrer'
                >
                  Google公式サイトで確認
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
});
