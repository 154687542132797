import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  userId: number;
  userImageId: number;
  faceweight: number;
};

type Response = { success: 1 };

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/users/${params.userId}/user_images/${params.userImageId}/face_weight`, {
    face_weight: params.faceweight
  });
};

/**
 * faceweightの送信
 */

type Props = {
  userId: number;
  userImageId: number;
  faceweight: number;
};

export const usePutFaceweight = () => {
  const { openSnackbar } = useSnackbar();

  const putFaceweight = useCallback(async (props: Props) => {
    const res = await api(props);

    openSnackbar({
      type: 'toast',
      text: 'Faceweightを変更しました。'
    });

    return res;
  }, []);

  return { putFaceweight };
};
