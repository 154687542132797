import { useEffect } from 'react';
import Adjust from '@adjustcom/adjust-web-sdk';
import * as Sentry from '@sentry/react';
import { AppProvider } from '@/AppProvider';
import { AppRoutes } from '@/AppRoutes';

export const App: React.FC = () => {
  useEffect(() => {
    /**
     * Adjust SDKの初期化
     */
    Adjust.initSdk({
      appToken: import.meta.env.VITE_AD_APP_TOKEN,
      environment: import.meta.env.VITE_AD_ENVIRONMENT
      // NOTE: 以下のコメントアウトを外すと、Adjustの様々なログがコンソールに出力される
      // logLevel: 'verbose'
    });

    /**
     * Sentry SDKの初期化
     */
    Sentry.init({
      dsn: 'https://9f374aa03d44c8ff10ddb1b84ffdb8b1@o332120.ingest.us.sentry.io/4506239601934336',
      debug: import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'development',
      environment: import.meta.env.MODE
    });
  }, []);

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};
