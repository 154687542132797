import { useCallback, useRef, useState } from 'react';

const itemWidth = 345;

export const useModalSwipe = () => {
  const [step, setStep] = useState(1);

  const ref = useRef<HTMLUListElement>(null);

  const onScroll = useCallback(() => {
    if (!ref.current) return;

    const index = Math.round(ref.current.scrollLeft / itemWidth);
    setStep(index + 1);
  }, []);

  return { ref, step, onScroll };
};
