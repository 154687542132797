import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';
import { useSnackbar } from '@/functions/hooks';
import { Schema } from '@/functions/schemas/chatRooms/appointment';

type Params = string;

type Response = { id: number };

const api = (query: Params): Promise<Response> => {
  return client.post(`v1/me/appointments?${query}`);
};

/**
 * お約束を登録
 */

type Props = Schema;

export const usePostAppointments = () => {
  const { openSnackbar } = useSnackbar();

  const postAppointments = useCallback(async (props: Props) => {
    await api(generateQueryParameter(props));

    openSnackbar({
      type: 'toast',
      text: '約束を作成しました。',
      bottom: 152
    });
  }, []);

  return { postAppointments };
};
