import { Fragment, memo, useCallback } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import card1 from '@/assets/lp/point/card1.png';
import card2 from '@/assets/lp/point/card2.png';
import card3 from '@/assets/lp/point/card3.png';
import card4 from '@/assets/lp/point/card4.png';
import card5 from '@/assets/lp/point/card5.png';
import kv from '@/assets/lp/point/kv.png';
import { meAtom } from '@/contexts/atoms/me';
import { basePath } from '@/functions/constants/common';
import { slideVariants } from '@/functions/constants/framerMotion';
import styles from '@/styles/features/lp/point.module.scss';
import account from '@/styles/pages/account.module.scss';

const pointList = [
  {
    isRecommend: false,
    tag: '1番お得！',
    point: 500,
    yen: '52,500',
    greatValue: '21,500'
  },
  {
    isRecommend: true,
    tag: '人気No.1',
    point: 100,
    yen: '10,500',
    greatValue: '4,300'
  },
  {
    isRecommend: false,
    tag: 'まずはお試し',
    point: 30,
    yen: '3,840',
    greatValue: '600'
  },
  {
    isRecommend: false,
    tag: '',
    point: 10,
    yen: '1,480',
    greatValue: ''
  }
];

type PointListProps = {
  isColored?: boolean;
};

const PointList: React.FC<PointListProps> = (props) => {
  const { isColored } = props;

  const { id } = useAtomValue(meAtom);

  const handleClickPoint = useCallback((point: number) => {
    window.location.href = `${basePath}/stripe/points/${point}?user_id=${id}&new_app=true`;
  }, []);

  return (
    <div className={clsx(styles['plan-block'], isColored && styles['plan-colored-black'])}>
      <h2 className={styles['plan-heading']}>ポイントを選択</h2>
      <ul className={styles['plan-list']}>
        {pointList.map((v) => (
          <li key={v.point} className={styles['plan-item']} {...{ 'data-recommend': v.isRecommend }}>
            {v.tag && <span className={styles['plan-item-label']}> {v.tag}</span>}
            <button type='button' onClick={() => handleClickPoint(v.point)} className={styles['plan-item-content']}>
              <h3 className={styles['plan-item-heading']}>{v.point}</h3>
              <p className={styles['plan-item-price']}>
                <span className={styles['plan-item-price-num']}>{v.yen}</span>
                <span className={styles['plan-item-price-unit']}>円(税込)</span>
              </p>
              {v.greatValue && (
                <p className={styles['plan-item-discount-price']}>
                  <span>{v.greatValue}</span>円お得
                </p>
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

type CardProps = {
  img: string;
  title: string;
  text: string;
  subText: string;
};

const cardList: CardProps[] = [
  {
    img: card1,
    title: '「いいね!」',
    text: '気になる女性に\nいいね!」を送る',
    subText: 'お相手とマッチすれば会話がスタート！'
  },
  {
    img: card2,
    title: 'メッセージ付き「いいね!」',
    text: 'メッセージ付き「いいね!」で\nマッチ率を倍増',
    subText: 'ひとこと添えてお相手と距離を\n縮めよう！'
  },
  {
    img: card3,
    title: '募集の投稿',
    text: 'デートしたい日に会える\nお相手が見つかる',
    subText: '希望のシーンや条件で募集ができる！'
  },
  {
    img: card4,
    title: '募集への応募',
    text: '気になる募集に\n応募のメッセージを',
    subText: 'お相手の募集に応募ができる。\nデートがすぐ決まるかも！'
  },
  {
    img: card5,
    title: 'クオリティチェック',
    text: 'お相手の近況を\n知ることができる',
    subText: 'マナー違反がある場合は\n事前の把握が可能！'
  }
];

const Card: React.FC<CardProps> = (props) => {
  const { img, title, text, subText } = props;

  return (
    <li className={styles.card}>
      <div className={styles['card-img']}>
        <img src={img} alt={title} />
      </div>
      <div className={styles['card-contents']}>
        <span className={styles['card-label']}>{title}</span>
        <h3 className={styles['card-title']}>{text}</h3>
        <p>{subText}</p>
      </div>
    </li>
  );
};

const CardList: React.FC = () => (
  <div className={styles['card-block']}>
    <div className={styles['card-inner']}>
      <h2 className={styles['card-heading']}>
        ポイントで使える
        <br />
        paddyの機能！
      </h2>
      <ul className={styles['card-list']}>
        {cardList.map((card) => (
          <Fragment key={card.title}>
            <Card {...card} />
          </Fragment>
        ))}
      </ul>
    </div>
  </div>
);

const Notes: React.FC = () => (
  <div className={styles.notes}>
    <h2 className={styles['notes-heading']}>注意事項</h2>
    <p className={styles['notes-text']}>・ポイントの有効期限は、購入日を含め90日です。</p>
    <p className={styles['notes-text']}>
      ・クレジットカードのご利用明細には「paddy」ではなく「PDDAY」「ピーディーペイ」のいずれかが表示されます。
    </p>
    <p className={styles['notes-text']}>・保有できるポイントは最大10,000ポイントです。</p>
    <p className={styles['notes-text']}>・サービス性質上、ご返金は行なっておりません。</p>
    <div className={styles['notes-link']}>
      <a href='https://www.paddy67.today/terms' target='_blank' rel='noreferrer'>
        利用規約
      </a>
      <a href='https://www.paddy67.today/privacy_policy' target='_blank' rel='noreferrer'>
        プライバシーポリシー
      </a>
    </div>
  </div>
);

type Props = {
  onClose: () => void;
};

export const PointLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_point' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>ポイント</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.kv}>
              <img src={kv} alt='さまざまな機能を利用!paddyポイント' />
            </div>

            <PointList />

            <CardList />

            <PointList isColored />

            <Notes />
          </div>
        </motion.div>
      </div>
    </>
  );
});
