import { memo, useCallback } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { UserAttributes } from '@/apis/boards/getBoards';
import { ChipLabel } from '@/components/styles/projects/ChipLabel';
import { UserImg } from '@/components/styles/projects/UserImg';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { GENDER } from '@/functions/constants/enums';
import { getDefaultUserImage, getDiffTime, getObjValue } from '@/functions/helpers';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/pages/boards.module.scss';

export type BoardProperty = {
  aasm_state: 'before_reviewing' | 'passed' | 'rejected' | 'removed';
  area: string;
  area_detail: string;
  created_at: string;
  free_text: string;
  hide_when_matched: boolean;
  id: number;
  image_url: string;
  purpose: string;
  reserved_date: string;
  timeframe: string;
  user_id: number;
};

type Props = {
  onClickBoard?: (userId: number, boardProperty: BoardProperty) => void;
  boardProperty: BoardProperty;
  userData?: UserAttributes;
  onClickMenu?: (id: number) => void;
};

export const BoardCard: React.FC<Props> = memo((props) => {
  const { onClickBoard, boardProperty, userData, onClickMenu } = props;

  const masterTypes = useAtomValue(masterTypesAtom);
  const { isBeginner } = useAtomValue(meFlagAtom);

  const isRoyal =
    userData && !isBeginner && (userData.patch_status === 'force_royal' || userData.patch_status === 'royal');

  const getImg = useCallback((user?: UserAttributes) => {
    if (!user) return '';

    if (user.image_url.indexOf('assets') !== -1) {
      return getDefaultUserImage(
        user.gender === GENDER.MALE,
        !isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')
      );
    }
    return user.image_url;
  }, []);

  return (
    <li className={styles['card-item']}>
      <div className={styles['card-header']} data-royal={isRoyal}>
        <UserImg src={getImg(userData)} size={48} isRoyal={isRoyal} />
        <div className={components['flex-grow-wrapper']}>
          <span className={clsx(components['text-bold'], styles['card-item-name'])}>{userData?.nickname}</span>
          <div className={styles['card-info']}>
            <span>{userData?.age}歳</span>
            <span>{userData?.residence_location}</span>
          </div>
        </div>
        <span className={components['sub-text']}>{getDiffTime(boardProperty.created_at)}</span>
        {onClickMenu && (
          <button
            type='button'
            onClick={() => onClickMenu(boardProperty.id)}
            className={styles['edit-button']}
            aria-label='edit'
          />
        )}
      </div>
      <div className={styles['card-body']} data-reviewing={boardProperty.aasm_state === 'before_reviewing'}>
        {onClickBoard ? (
          <button type='button' onClick={() => onClickBoard(userData?.id!, boardProperty)}>
            {boardProperty.image_url && (
              <div className={styles['card-img']}>
                <img src={boardProperty.image_url} alt='' />
              </div>
            )}
            <div className={styles['card-inner']}>
              <div className={styles['card-area']}>
                <span>{getObjValue(masterTypes.board_areas, boardProperty.area)}</span>
                <span className={styles['card-area-text']}>{boardProperty.area_detail}</span>
              </div>
              <ul className={styles['card-label']}>
                <li>
                  <ChipLabel color='black' label={getObjValue(masterTypes.board_purposes, boardProperty.purpose)} />
                </li>
                <li>
                  <ChipLabel label={getObjValue(masterTypes.board_timeframes, boardProperty.timeframe)} />
                </li>
              </ul>
              <p className={clsx(components['text-break'], styles['card-free-text'])}>{boardProperty.free_text}</p>
            </div>
          </button>
        ) : (
          <>
            <div className={styles['card-img']} data-empty={!boardProperty.image_url}>
              {boardProperty.image_url && <img src={boardProperty.image_url} alt='' />}
              {boardProperty.hide_when_matched && (
                <span className={styles['card-matching-only-label']}>マッチング中のお相手</span>
              )}
              {boardProperty.aasm_state === 'before_reviewing' && (
                <span className={styles['card-reviewing-label']}>審査中</span>
              )}
            </div>
            <div className={styles['card-inner']}>
              <div className={styles['card-area']}>
                <span>{getObjValue(masterTypes.board_areas, boardProperty.area)}</span>
                <span className={styles['card-area-text']}>{boardProperty.area_detail}</span>
              </div>
              <ul className={styles['card-label']}>
                <li>
                  <ChipLabel color='black' label={getObjValue(masterTypes.board_purposes, boardProperty.purpose)} />
                </li>
                <li>
                  <ChipLabel label={getObjValue(masterTypes.board_timeframes, boardProperty.timeframe)} />
                </li>
              </ul>
              <p className={components['text-break']}>{boardProperty.free_text}</p>
            </div>
          </>
        )}
      </div>
    </li>
  );
});
