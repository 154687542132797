import { Variants } from 'framer-motion';

const width = 393;
export const animateDuration = 250; // ms

const transition = {
  ease: 'linear',
  duration: animateDuration / 1300 // s
};

export const slideVariants: Variants = {
  enter: { x: 0, y: 0, transition },
  left: { x: -width, transition },
  right: { x: width, transition },
  bottom: { y: 1000, transition },
  customLeft: (isLeft: boolean) => {
    return { x: isLeft ? -width : width, transition };
  },
  customRight: (isLeft: boolean) => {
    return { x: isLeft ? width : -width, transition };
  },
  customSwipeIn: (isSwipe: 'prev' | 'next' | 'left' | 'right' | null) => {
    if (isSwipe !== 'left' && isSwipe !== 'right') return {};
    return { x: isSwipe === 'right' ? width : -width, transition };
  },
  customSwipeOut: (isSwipe: 'prev' | 'next' | 'left' | 'right' | null) => {
    if (isSwipe !== 'prev' && isSwipe !== 'next') return {};
    return { x: isSwipe === 'prev' ? width : -width, transition };
  }
};
