import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  id: number;
  body: {
    id: number;
    setting: 'email_footprint' | 'email_stamp' | 'email_get_message' | 'email_other';
  };
};

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/notice_settings/${params.id}`, params.body);
};

type Props = Params;

/**
 * 通知設定を更新する
 */

export const usePutNoticeSettings = () => {
  const { openSnackbar } = useSnackbar();

  const putNoticeSetteings = useCallback(async (props: Props) => {
    const res = await api(props);

    openSnackbar({
      type: 'toast',
      text: '設定を変更しました。'
    });

    return res;
  }, []);

  return { putNoticeSetteings };
};
