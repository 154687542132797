import { memo } from 'react';
import styles from '@/styles/uis/switch.module.scss';

type Props = {
  id: string;
  isActive: boolean;
  onChange: () => void;
};

export const Switch: React.FC<Props> = memo((props) => {
  const { id, isActive, onChange } = props;

  return (
    <div className={styles.wrapper}>
      <label htmlFor={id} className={styles.label}>
        <input id={id} type='checkbox' checked={isActive} onChange={onChange} />
      </label>
    </div>
  );
});
