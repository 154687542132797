import { useCallback, useMemo, useRef } from 'react';
import { Drumroll } from '@/components/styles/uis/Drumroll';
import styles from '@/styles/projects/dateDrumroll.module.scss';

const rowHeight = 56;
const minAge = 18;
const maxAge = 99;
const initialYear = new Date().getFullYear() - 18;
const months = [...Array(12)].map((_, i) => i + 1);

type Props = {
  year: number;
  month: number;
  date: number;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
  setDate: (date: number) => void;
};

export const DateDrumroll: React.FC<Props> = (props) => {
  const { year, month, date, setYear, setMonth, setDate } = props;

  const yearDrumroll = useRef<HTMLUListElement>(null);
  const monthDrumroll = useRef<HTMLUListElement>(null);
  const dayDrumroll = useRef<HTMLUListElement>(null);

  const years = useMemo(() => {
    return [...Array(maxAge - minAge + 1)].map((_, i) => i + (initialYear - (maxAge - minAge)));
  }, []);

  const dates = useMemo(() => {
    const nextMonthDate = new Date(year, month, 1);
    const lastDate = new Date(nextMonthDate.getTime() - 1).getDate();

    return [...Array(lastDate)].map((_, i) => i + 1);
  }, [year, month]);

  const onScrollYearDrumroll = useCallback(() => {
    const setIndex = Math.round(yearDrumroll.current!.scrollTop / rowHeight);
    setYear(years[Math.max(0, Math.min(setIndex, years.length - 1))]);
  }, [setYear]);

  const onScrollMonthDrumroll = useCallback(() => {
    const setIndex = Math.round(monthDrumroll.current!.scrollTop / rowHeight);
    setMonth(months[Math.max(0, Math.min(setIndex, months.length - 1))]);
  }, [setMonth]);

  const onScrollDateDrumroll = useCallback(() => {
    const setIndex = Math.round(dayDrumroll.current!.scrollTop / rowHeight);
    setDate(dates[Math.max(0, Math.min(setIndex, dates.length - 1))]);
  }, [setDate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Drumroll
          drumrollRef={yearDrumroll}
          value={year}
          options={years}
          unit='年'
          onScroll={onScrollYearDrumroll}
          textAlign='left'
        />
        <Drumroll
          drumrollRef={monthDrumroll}
          value={month}
          options={months}
          unit='月'
          onScroll={onScrollMonthDrumroll}
          textAlign='center'
        />
        <Drumroll
          drumrollRef={dayDrumroll}
          value={date}
          options={dates}
          unit='日'
          onScroll={onScrollDateDrumroll}
          textAlign='right'
        />
      </div>
    </div>
  );
};
