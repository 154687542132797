import { useEffect, useState } from 'react';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import styles from '@/styles/uis/loadingImage.module.scss';

type Props = {
  src: string;
  alt?: string;
  imageAttributes?: { [key: string]: string | boolean | number | undefined };
  isSmall?: boolean;
};

export const LoadingImage: React.FC<Props> = ({ src, alt, imageAttributes, isSmall }) => {
  const [isLoading, setIsLoading] = useState(true);

  const sleep = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      sleep(1);
      setIsLoading(false);
    };

    return () => {
      image.onload = null;
    };
  }, [src]);

  return (
    <>
      {isLoading && (
        <span className={styles['loading-spinner']}>
          <LoadingSpinner isSmall={isSmall} />
        </span>
      )}
      {!isLoading && <img src={src} alt={alt} {...imageAttributes} />}
    </>
  );
};

export default LoadingImage;
