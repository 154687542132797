import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { client } from '@/apis/client';
import { meKeys } from '@/apis/queryKeys';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  token: string;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/email_auth', params);
};

type Props = Params;

/**
 * メールアドレス認証
 */

export const usePutEmailAuth = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const emailAuth = useCallback(async (props: Props) => {
    try {
      await api(props);
      queryClient.invalidateQueries(meKeys.all);

      openSnackbar({
        type: 'toast',
        text: 'メールアドレスを変更しました。'
      });
    } catch (err) {
      openSnackbar({
        type: 'toast',
        text: '認証コードが一致しません。\n再度お試しください。',
        level: 'error'
      });

      throw err;
    }
    return null;
  }, []);

  return { emailAuth };
};
