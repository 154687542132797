import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  image: Blob;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v2/me/age_verification_images', params, { headers: { 'Content-Type': 'multipart/form-data' } });
};

type Props = Params;

/**
 * 年齢確認書類を登録する
 */

export const usePostAgeVerificationImages = () => {
  const postAgeVerificationImages = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postAgeVerificationImages };
};
