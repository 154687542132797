import { useCallback } from 'react';
import { client } from '@/apis/client';

type Response = {
  success: 1;
};

const api = (): Promise<Response> => {
  return client.post(`v1/activities/read_all`);
};

/**
 * 足あとをすべて既読にする
 */

export const usePostFootprintsReadAll = () => {
  const postFootprintsReadAll = useCallback(async () => {
    await api();
  }, []);

  return { postFootprintsReadAll };
};
