import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Response = {
  success: 1;
  email_token: number;
};

const api = (): Promise<Response> => {
  return client.post('v1/me/resend_email_token');
};

/**
 * メールアドレス認証コードの再送
 */

export const usePostResendEmailToken = () => {
  const { openSnackbar } = useSnackbar();

  const resendEmailToken = useCallback(async () => {
    await api();

    openSnackbar({
      type: 'toast',
      text: '認証コードを再送しました。'
    });
  }, []);

  return { resendEmailToken };
};
