import { useCallback } from 'react';
import { client } from '@/apis/client';

type Response = {
  chat_rooms: {
    liked: number;
    matched: number;
    contacted: number;
  };
  activity: number;
  notice: number;
  appointments: {
    future: number;
  };
};

const api = (): Promise<Response> => {
  return client.get('v1/badges');
};

/**
 * バッチ情報の取得
 */

export const useGetBadges = () => {
  const fetchBadge = useCallback(async () => {
    const res = await api();

    return res;
  }, []);

  return { fetchBadge };
};
