import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  movieId: number;
};

const api = (params: Params): Promise<Response> => {
  return client.delete(`v1/me/profile_movies/${params.movieId}`);
};

type Props = Params;

/**
 * プロフィール動画の削除
 */

export const useDeleteMovies = () => {
  const deleteMovies = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { deleteMovies };
};
