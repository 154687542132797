import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  user: {
    is_secret: number;
  };
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me', params);
};

type Props = Params;

/**
 * シークレットモードを設定する
 */

export const usePutMe = () => {
  const { openSnackbar } = useSnackbar();

  const putMe = useCallback(async (props: Props) => {
    await api(props);

    openSnackbar({
      type: 'toast',
      text: '設定を変更しました。'
    });
  }, []);

  return { putMe };
};
