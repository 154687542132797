import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { scrollLockAtom } from '@/contexts/atoms/scrollLock';

export const useScrollLock = () => {
  const [ids, setIds] = useAtom(scrollLockAtom);

  useEffect(() => {
    if (ids.length) {
      if (!document.body.classList.contains('scroll-prevent')) {
        document.body.classList.add('scroll-prevent');
      }
    } else if (document.body.classList.contains('scroll-prevent')) {
      document.body.classList.remove('scroll-prevent');
    }
  }, [ids]);

  const addScrollPreventElement = useCallback(
    (id: string) => {
      setIds((prev) => [...prev, id]);
    },
    [ids]
  );

  const removeScrollPreventElement = useCallback(
    (id: string) => {
      setIds((prev) => prev.filter((v) => v !== id));
    },
    [ids]
  );

  return { addScrollPreventElement, removeScrollPreventElement };
};
