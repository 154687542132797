import { useCallback } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAtomValue } from 'jotai';
import { meAtom } from '@/contexts/atoms/me';

export const useUpload = () => {
  const me = useAtomValue(meAtom);

  const upload = useCallback(async (file: Blob, chatRoomId?: number) => {
    const timestamp = new Date().getTime();
    const storage = getStorage();

    const branch = chatRoomId ? `rooms/${chatRoomId}` : `profile/user-${me.id}`;
    const path = `/images/${branch}/${timestamp}`;

    const fileRef = ref(storage, path);
    const fileUrl = await uploadBytes(fileRef, file).then((snapshot) => {
      return getDownloadURL(snapshot.ref);
    });

    return fileUrl;
  }, []);

  return { upload };
};
