import { memo, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetMeBoards, usePutMe } from '@/apis';
import { meBoardKeys, meKeys } from '@/apis/queryKeys';
import { Switch } from '@/components/styles/uis/Switch';
import { meAtom } from '@/contexts/atoms/me';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useBasicModal, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/secretMode.module.scss';

const parentName = 'secretMode';

const SECRET_MODE = {
  DISABLE: 0,
  ENABLE: 1
} as const;

type Props = {
  onClose: () => void;
};

export const SecretMode: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const { is_secret: isSecret } = useAtomValue(meAtom);

  const queryClient = useQueryClient();

  const { onCloseModal, commonModal } = useBasicModal();

  const { putMe } = usePutMe();
  const { fetchMeBoards } = useGetMeBoards();

  const { parentElements, handleOpenSubElement } = useSubElement();

  const { data } = useQuery({
    queryKey: meBoardKeys.lists(),
    queryFn: () => fetchMeBoards()
  });

  const onSetting = useCallback(
    async (isRemove: boolean) => {
      await putMe({
        user: {
          is_secret: isSecret ? SECRET_MODE.DISABLE : SECRET_MODE.ENABLE
        }
      });
      queryClient.invalidateQueries(meKeys.all);

      if (isRemove) {
        queryClient.invalidateQueries(meBoardKeys.lists());
        onCloseModal();
      }
    },
    [isSecret]
  );

  const handleChangeValue = useCallback(async () => {
    if (data?.data.length) {
      commonModal({
        title: '公開中の募集が削除されます。',
        text: 'シークレットモードをONにすると公開中の募集が削除されます。よろしいですか？',
        onCloseLabel: 'キャンセル',
        onClickLabel: 'ONにする',
        onClick: () => onSetting(true)
      });
    } else {
      onSetting(false);
    }
  }, [isSecret, data]);

  return (
    <>
      <TrackPageView viewName='secredmode_settings' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>シークレットモード設定</p>
            </div>
          </div>

          <div className={components['parent-wrapper']} data-children-open={parentElements.third === parentName}>
            <div className={account['submodal-contents']}>
              <div className={styles.inner}>
                <div className={styles.item}>
                  <p>シークレットモード</p>
                  <Switch id='secretMode' isActive={isSecret} onChange={handleChangeValue} />
                </div>
                <div className={styles['description-block']}>
                  <p className={styles['description-heading']}>シークレットモードとは？</p>
                  <p className={styles['description-text']}>
                    シークレットモードを設定すると、お相手の検索にあなたが表示されなくなります。
                  </p>
                  <p className={styles['description-text']}>新たに「いいね!」やメッセージも届かなくなります。</p>
                  <div className={styles['description-link']}>
                    <button type='button' onClick={() => handleOpenSubElement('secretModeLp', parentName)}>
                      詳しくみる
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
