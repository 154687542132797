import { lazy, memo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppLayout } from '@/AppLayout';

const Top = lazy(async () => ({
  default: (await import('@/pages/Top')).Top
}));

const Signup = lazy(async () => ({
  default: (await import('@/pages/auth/Signup')).Signup
}));

const Login = lazy(async () => ({
  default: (await import('@/pages/auth/Login')).Login
}));

const Complete = lazy(async () => ({
  default: (await import('@/pages/auth/Complete')).Complete
}));

const Tutorial = lazy(async () => ({
  default: (await import('@/pages/auth/Tutorial')).Tutorial
}));

const Home = lazy(async () => ({
  default: (await import('@/pages/Home')).Home
}));

const Boards = lazy(async () => ({
  default: (await import('@/pages/Boards')).Boards
}));

const Good = lazy(async () => ({
  default: (await import('@/pages/Good')).Good
}));

const ChatRooms = lazy(async () => ({
  default: (await import('@/pages/ChatRooms')).ChatRooms
}));

const Account = lazy(async () => ({
  default: (await import('@/pages/Account')).Account
}));

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <Top />
        },
        {
          path: '/signup',
          element: <Signup />
        },
        {
          path: '/login',
          element: <Login />
        },
        {
          path: '/signup-complete',
          element: <Complete />
        },
        {
          path: '/register',
          element: <Tutorial />
        },
        {
          path: '/home',
          element: <Home />
        },
        {
          path: '/boards',
          element: <Boards />
        },
        {
          path: '/like',
          element: <Good />
        },
        {
          path: '/message',
          element: <ChatRooms />
        },
        {
          path: '/account',
          element: <Account />
        }
      ]
    }
  ],
  {
    basename: '/web'
  }
);

export const AppRoutes: React.FC = memo(() => {
  return <RouterProvider router={router} />;
});
