import { ReactNode, memo, useState } from 'react';

type ElementProps = JSX.IntrinsicElements['button'];

type Props = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  delay?: number;
  onClick?: () => void;
} & ElementProps;

export const ButtonClickDisabled: React.FC<Props> = memo((_props) => {
  const { children, className, disabled = false, delay = 3000, onClick, ...props } = _props;

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (!isClicked) {
      setIsClicked(true);
      if (onClick) {
        onClick();
      }
    }

    // 一定時間後にクリックを再度有効にする
    setTimeout(() => {
      setIsClicked(false);
    }, delay);
  };

  return (
    <button type='button' onClick={handleClick} disabled={disabled || isClicked} className={className} {...props}>
      {children}
    </button>
  );
});
