import { useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  firstSubElementAtom,
  fourthSubElementAtom,
  parentElementsAtom,
  secondSubElementAtom,
  thirdSubElementAtom
} from '@/contexts/atoms/subElement';
import {
  ConfigRouteTarget,
  LpRouteTarget,
  MypageRouteTarget,
  configRoutes,
  lpRoutes,
  mypageRoutes
} from '@/functions/constants/subElementRoutes';
import { useScrollLock } from '@/functions/hooks';

export const useSubElement = () => {
  const [parentElements, setParentElements] = useAtom(parentElementsAtom);
  const [firstSubElement, setFirstSubElement] = useAtom(firstSubElementAtom);
  const [secondSubElement, setSecondSubElement] = useAtom(secondSubElementAtom);
  const [thirdSubElement, setThirdSubElement] = useAtom(thirdSubElementAtom);
  const [fourthSubElement, setFourthSubElement] = useAtom(fourthSubElementAtom);

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  const handleOpenSubElement = useCallback(
    (key: MypageRouteTarget | ConfigRouteTarget | LpRouteTarget, parent?: string) => {
      const routes = [...mypageRoutes, ...configRoutes, ...lpRoutes];
      const route = routes.find((v) => v.target === key);

      if (
        !route ||
        firstSubElement?.target === key ||
        secondSubElement?.target === key ||
        thirdSubElement?.target === key
      )
        return;

      if (firstSubElement === null) {
        addScrollPreventElement('first');
        setFirstSubElement({ ...route });
        setParentElements({
          first: parent ?? '',
          second: '',
          third: '',
          fourth: ''
        });
      } else if (secondSubElement === null) {
        addScrollPreventElement('second');
        setSecondSubElement({ ...route });
        setParentElements({
          first: parentElements.first,
          second: parent ?? '',
          third: '',
          fourth: ''
        });
      } else if (thirdSubElement === null) {
        addScrollPreventElement('third');
        setThirdSubElement({ ...route });
        setParentElements({
          first: parentElements.first,
          second: parentElements.second,
          third: parent ?? '',
          fourth: ''
        });
      } else {
        addScrollPreventElement('fourth');
        setFourthSubElement({ ...route });
        setParentElements({
          first: parentElements.first,
          second: parentElements.second,
          third: parentElements.third,
          fourth: parent ?? ''
        });
      }
    },
    [parentElements, firstSubElement, secondSubElement, thirdSubElement, fourthSubElement]
  );

  const handleCloseSubElement = useCallback(
    (target: 'first' | 'second' | 'third' | 'fourth') => {
      removeScrollPreventElement(target);

      if (target === 'first') {
        setFirstSubElement(null);
        setParentElements({
          first: '',
          second: '',
          third: '',
          fourth: ''
        });
      } else if (target === 'second') {
        setSecondSubElement(null);
        setParentElements({
          first: parentElements.first,
          second: '',
          third: '',
          fourth: ''
        });
      } else if (target === 'third') {
        setThirdSubElement(null);
        setParentElements({
          first: parentElements.first,
          second: parentElements.second,
          third: '',
          fourth: ''
        });
      } else {
        setFourthSubElement(null);
        setParentElements({
          first: parentElements.first,
          second: parentElements.second,
          third: parentElements.third,
          fourth: ''
        });
      }
    },
    [parentElements, firstSubElement, secondSubElement, thirdSubElement, fourthSubElement]
  );

  return { parentElements, handleOpenSubElement, handleCloseSubElement };
};
