import { ReactNode, memo } from 'react';
import { useModalSwipe } from '@/functions/hooks';
import styles from '@/styles/features/modalSwipe.module.scss';

type Props = {
  children: ReactNode;
  stepLength: number;
  className?: string;
};

/**
 * モーダル内のスワイプ用コンポーネント
 * @param children スワイプさせたいli要素
 * @param stepLength スワイプさせたいli要素の数
 * @param className ラッパーのクラス
 */

export const ModalSwipe: React.FC<Props> = memo((props) => {
  const { children, stepLength, className } = props;
  const { ref, step, onScroll } = useModalSwipe();

  return (
    <div className={className}>
      <div className={styles['swipe-wrapper']}>
        <ul ref={ref} className={styles.items} onScroll={onScroll}>
          {children}
        </ul>
      </div>
      {stepLength > 1 && (
        <div className={styles['dots-wrapper']}>
          <div className={styles.dots} data-step={step}>
            {[...Array(stepLength)].map((_, i) => (
              // eslint-disable-next-line
              <span key={i} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
});
