import { useEffect } from 'react';

export const useVirtualViewportHeight = () => {
  useEffect(() => {
    const vv = window.visualViewport;

    if (!vv) {
      document.documentElement.style.removeProperty('--visual-viewport-height');
      return () => {};
    }

    const handleResize = () => document.documentElement.style.setProperty('--visual-viewport-height', `${vv.height}px`);

    vv.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      vv.removeEventListener('resize', handleResize);
    };
  }, []);
};
