import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  image_id: number;
  disclose: number;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/disclose', params);
};

type Props = Params;

/**
 * 自身のメイン・サブ画像の表示範囲を変更する
 */

export const usePutDiscloseImages = () => {
  const putDiscloseImages = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putDiscloseImages };
};
