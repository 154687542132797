import { BaseSyntheticEvent, memo, useCallback, useState } from 'react';
import { useAtomValue } from 'jotai';
import AssetsReport from '@/assets/icons/assets_report.svg';
import Heart from '@/assets/icons/heart_colored.svg';
import IncomeReport from '@/assets/icons/income_report.svg';
import { ImgModal } from '@/components/features/modal/ImgModal';
import { ChipLabel } from '@/components/styles/projects/ChipLabel';
import { OnlineStatus } from '@/components/styles/projects/OnlineStatus';
import { LoadingImage } from '@/components/styles/uis/LoadingImage';
import { Tooltip } from '@/components/styles/uis/Tooltip';
import { VideoModal } from '@/components/styles/uis/VideoModal';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { checkNew, getUserImage } from '@/functions/helpers';
import { useBasicModal, useDisclosure } from '@/functions/hooks';
import { UsersShow } from '@/functions/types/usersShow';
import styles from '@/styles/features/show/showInfo.module.scss';

type Props = {
  user?: UsersShow;
  isPreview?: boolean;
  isImgModalDisabled?: boolean;
};

export const ShowInfo: React.FC<Props> = memo((props) => {
  const { user, isPreview, isImgModalDisabled = false } = props;

  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [movie, setMovie] = useState(false);
  const [tooltipShow, setTooltipShow] = useState('');

  const { isMaleFree, isBeginner } = useAtomValue(meFlagAtom);

  const { commonModal } = useBasicModal();
  const videoModal = useDisclosure();
  const imgModal = useDisclosure();

  const handleClickThumbnail = useCallback(
    (index: number) => {
      setThumbnailIndex(index);
      setMovie(false);
    },
    [movie, thumbnailIndex, isImgModalDisabled]
  );

  const handleClickMovie = useCallback(() => {
    setThumbnailIndex(0);
    setMovie(true);
  }, [movie, thumbnailIndex, isImgModalDisabled]);

  const handleClickMain = useCallback(() => {
    // テキストエリアにフォーカスされている場合
    if (isImgModalDisabled) {
      return;
    }

    if (movie) {
      if (user?.profile_movie.mosaic) {
        commonModal({
          title: 'マッチしたお相手のみ閲覧が可能です。',
          text: 'マッチしたお相手のみに限定公開されています。まずは「いいね!」を送ってみましょう。'
        });

        return;
      }

      videoModal.open();
      return;
    }

    // デフォルト画像の場合
    if (user && !user.images[thumbnailIndex].id) {
      return;
    }

    if (user?.images[thumbnailIndex].is_mosaic) {
      commonModal({
        title: 'マッチしたお相手のみ閲覧が可能です。',
        text: 'マッチしたお相手のみに限定公開されています。まずは「いいね!」を送ってみましょう。'
      });
      return;
    }

    imgModal.open();
  }, [user, movie, thumbnailIndex, isImgModalDisabled]);

  const closeTooltip = useCallback(() => {
    setTooltipShow('');
  }, []);

  const handleClickTooltip = useCallback((e: BaseSyntheticEvent, name: 'point' | 'royal') => {
    setTooltipShow(name);
    e.stopPropagation();
  }, []);

  const imageSrc = useCallback(() => {
    if (movie) {
      return user?.profile_movie.passed_thumbnail_url;
    }

    return thumbnailIndex === 0 ? getUserImage(user, isBeginner) : user?.images[thumbnailIndex].image_url;
  }, [user, movie, thumbnailIndex]);

  return (
    <>
      <button type='button' className={styles.kv} onClick={handleClickMain}>
        <div className={styles['kv-img']}>
          <span data-video={movie} />
          <LoadingImage
            src={imageSrc()}
            imageAttributes={{
              'data-mosaic': movie ? user?.profile_movie.mosaic : user?.images[thumbnailIndex].is_mosaic
            }}
          />
        </div>
        <div className={styles['kv-bottom-container']}>
          {!movie && (
            <div className={styles['kv-info']}>
              {checkNew(user?.created_at!) && (
                <div className={styles['kv-chip-label']}>
                  <ChipLabel label='NEW' color='red' />
                </div>
              )}
              <p className={styles['kv-name']}>
                {user?.property.nickname}
                {!isBeginner && (user?.patch_status === 'force_royal' || user?.patch_status === 'royal') && (
                  <Tooltip
                    content='こちらのお相手はロイヤル会員です'
                    location='bottom'
                    isShow={tooltipShow === 'royal'}
                    onClose={closeTooltip}
                  >
                    <button
                      type='button'
                      className={styles['kv-royal-button']}
                      onClick={(e) => handleClickTooltip(e, 'royal')}
                    >
                      <span className={styles['kv-royal-badge']} />
                    </button>
                  </Tooltip>
                )}
              </p>
              <p>
                {user?.age}歳 {user?.property.residence_location}
              </p>

              {!isBeginner && user?.property.income_type && <p>年収 {user?.property.income_type}</p>}
              {user?.property.one_word && <p>{user?.property.one_word}</p>}
              {!isBeginner &&
                ((user?.asset_proof && user.asset_proof.status === 'pass') ||
                  (user?.income_proof && user.income_proof.status === 'pass')) && (
                  <ul className={styles['kv-verify-list']}>
                    {user?.asset_proof?.status === 'pass' && (
                      <li key='assets' className={styles['kv-verify-item']}>
                        <img src={AssetsReport} alt='資産証明済' width={18} height={18} />
                        資産証明済
                      </li>
                    )}
                    {user?.income_proof?.status === 'pass' && (
                      <li key='income' className={styles['kv-verify-item']}>
                        <img src={IncomeReport} alt='年収証明済' width={18} height={18} />
                        年収証明済
                      </li>
                    )}
                  </ul>
                )}

              <ul className={styles['kv-status-list']}>
                {isMaleFree && !isPreview ? (
                  <Tooltip
                    content='有料会員になるといいね!数がみれます'
                    location='top'
                    isShow={tooltipShow === 'point'}
                    onClose={closeTooltip}
                  >
                    <li
                      key='like'
                      className={styles['kv-status-item']}
                      onClick={(e) => handleClickTooltip(e, 'point')}
                      role='presentation'
                    >
                      <img src={Heart} alt='いいね!数' width={18} height={18} />
                      <button type='button'>??</button>
                    </li>
                  </Tooltip>
                ) : (
                  <li key='like_num' className={styles['kv-status-item']}>
                    <img src={Heart} alt='いいね!数' width={18} height={18} />
                    {user?.good_num.value}
                  </li>
                )}
                <li key='online_status' className={styles['kv-status-item']}>
                  <OnlineStatus time={user?.last_action_time ?? ''} isLabel isSmall />
                </li>
              </ul>
            </div>
          )}
          {!isPreview && user?.is_warning && (
            <div className={styles['kv-alert']}>
              <p>このお相手は過去に通報を受けています</p>
            </div>
          )}
        </div>
      </button>
      <div className={styles['thumbnail-wrapper']}>
        <ul className={styles['thumbnail-list']}>
          <li key='top_image' className={styles['thumbnail-item']}>
            <label htmlFor={`thumbnail-${user?.top_image.id}`}>
              <input
                id={`thumbnail-${user?.top_image.id}`}
                type='radio'
                name='thumbnail-list'
                checked={thumbnailIndex === 0 && !movie}
                onChange={() => handleClickThumbnail(0)}
              />
              <LoadingImage src={getUserImage(user, isBeginner)} isSmall />
            </label>
          </li>
          {!isBeginner && user?.profile_movie.passed_thumbnail_url && (
            <li key='movie' className={styles['thumbnail-item']} data-video>
              <label htmlFor='thumbnail-movie' data-mosaic={user?.profile_movie.mosaic}>
                <input
                  id='thumbnail-movie'
                  type='radio'
                  name='thumbnail-list'
                  checked={movie}
                  onChange={handleClickMovie}
                />
                <LoadingImage src={user.profile_movie.passed_thumbnail_url} isSmall />
              </label>
            </li>
          )}
          {user?.images.map(
            (image, i) =>
              i !== 0 && (
                <li key={image.id} className={styles['thumbnail-item']}>
                  <label htmlFor={`thumbnail-${image.id}`} data-mosaic={image.is_mosaic}>
                    <input
                      id={`thumbnail-${image.id}`}
                      type='radio'
                      name='thumbnail-list'
                      checked={i === thumbnailIndex}
                      onChange={() => handleClickThumbnail(i)}
                    />
                    <LoadingImage src={user.images[i].image_url} isSmall />
                  </label>
                </li>
              )
          )}
        </ul>
      </div>

      {user?.profile_movie.passed_movie_url && (
        <VideoModal isOpen={videoModal.isOpen} onClose={videoModal.close} src={user.profile_movie.passed_movie_url} />
      )}

      {user && user.images[thumbnailIndex].image_url && (
        <ImgModal img={user.images[thumbnailIndex].image_url} isOpen={imgModal.isOpen} onClose={imgModal.close} />
      )}
    </>
  );
});
