import { memo } from 'react';
import { Link } from 'react-router-dom';
import styles from '@/styles/uis/basicList.module.scss';

type Item = {
  key: string | number;
  label: string;
  subLabel?: string;
  noread?: boolean;
  // NOTE: linkとonClickはどちらか片方だけ
  link?: string;
  onClick?: () => void;
};

type Props = {
  items: Item[];
  isSeparateBorder?: boolean;
};

export const BasicList: React.FC<Props> = memo((props) => {
  const { items, isSeparateBorder } = props;

  return (
    <ul className={styles.list} data-border={isSeparateBorder}>
      {items.map((item) => (
        <li key={item.key} className={styles.item} data-noread={item.noread}>
          {item.link && (
            <Link to={item.link}>
              <p className={styles.label}>{item.label}</p>
              {item.subLabel && <span className={styles.sublabel}>{item.subLabel}</span>}
            </Link>
          )}

          {item.onClick && (
            <button type='button' onClick={item.onClick}>
              <p className={styles.label}>{item.label}</p>
              {item.subLabel && <span className={styles.sublabel}>{item.subLabel}</span>}
            </button>
          )}
        </li>
      ))}
    </ul>
  );
});
