import { memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetUsers } from '@/apis';
import { userKeys } from '@/apis/queryKeys';
import { Show } from '@/components/features/show/Show';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import styles from '@/styles/features/show/swipeShow.module.scss';

type Props = {
  userId: number;
  userIds: number[];
  setUserId: (id: number | null) => void;
  userIndex?: number;
  isVisibleSimilar?: boolean;
  isVisibleMenu?: boolean;
  snackbarBottom?: number;
  fetchNextPage?: () => void;
  isVisibleLike?: boolean;
};

export const SwipeShow: React.FC<Props> = memo((props) => {
  const {
    userId,
    userIds,
    setUserId,
    userIndex = 0,
    isVisibleSimilar = false,
    isVisibleMenu = true,
    snackbarBottom,
    fetchNextPage,
    isVisibleLike = true
  } = props;
  const [index, setIndex] = useState<number>(userIndex);
  const [prevId, setPrevId] = useState<number | null>(null);
  const [nextId, setNextId] = useState<number | null>(null);
  const [isSwipe, setIsSwipe] = useState<'prev' | 'next' | 'left' | 'right' | null>(null);

  const { fetchUser } = useGetUsers();

  useQuery({
    queryKey: userKeys.detail(prevId),
    queryFn: () => (prevId ? fetchUser({ id: prevId }) : null)
  });

  useQuery({
    queryKey: userKeys.detail(nextId),
    queryFn: () => (nextId ? fetchUser({ id: nextId }) : null)
  });

  useEffect(() => {
    (async () => {
      // FIXME:
      // 足跡一覧から渡されるuserIdsは重複のあるIDの配列のための対応
      // 非常にわかりづらくしてるのでより良い改善策を考える
      if (index === 0) {
        setIndex(userIds.findIndex((v) => v === userId));
      }

      const prevId = index !== 0 ? userIds[index - 1] : null;
      const nextId = index !== userIds.length - 1 ? userIds[index + 1] : null;

      setPrevId(prevId);
      setNextId(nextId);
    })();
  }, [userId, index, userIds]);

  useEffect(() => {
    if (index === userIds.length - 3 && fetchNextPage) {
      fetchNextPage();
    }
  }, [userIds, index]);

  const handleClickPrev = useCallback(() => {
    setIsSwipe('prev');
    setIndex(index - 1);
    setUserId(prevId);

    setTimeout(() => {
      setIsSwipe('left');
    }, animateDuration + 50);
  }, [prevId, index]);

  const handleClickNext = useCallback(() => {
    setIsSwipe('next');
    setIndex(index + 1);
    setUserId(nextId);

    setTimeout(() => {
      setIsSwipe('right');
    }, animateDuration + 50);
  }, [nextId, index]);

  return (
    <>
      <TrackPageView viewName='user_detail' />
      <motion.div initial='bottom' animate='enter' exit='bottom' variants={slideVariants} className={styles.wrapper}>
        <AnimatePresence mode='wait' initial={false} custom={isSwipe}>
          {isSwipe !== 'next' && isSwipe !== 'prev' && (
            <motion.div
              custom={isSwipe}
              initial='customSwipeIn'
              animate='enter'
              exit='customSwipeOut'
              variants={slideVariants}
            >
              <Show
                userId={userId}
                onCloseShow={() => setUserId(null)}
                onClickPrev={prevId ? handleClickPrev : undefined}
                onClickNext={nextId ? handleClickNext : undefined}
                isVisibleSimilar={isVisibleSimilar}
                isVisibleMenu={isVisibleMenu}
                isVisibleLike={isVisibleLike}
                snackbarBottom={snackbarBottom}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
});
