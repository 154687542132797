import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';
import { Appointments } from '@/functions/types/appointments';

type Params = string;

type Response = Appointments[];

const api = (query: Params): Promise<Response> => {
  return client.get(`v1/me/appointments?${query}`);
};

/**
 * お約束一覧を取得
 */

type Props = { page: number; type: string };

export const useGetAppointments = () => {
  const fetchAppointments = useCallback(async (props: Props) => {
    const res = await api(generateQueryParameter(props));

    return res;
  }, []);

  return { fetchAppointments };
};
