import { useCallback } from 'react';
import { client } from '@/apis/client';

type AuthorParams = {
  start_at: string;
  end_at: string;
};

type ListenerParams = {
  id: number;
};

type Response = {
  board: boolean;
  appointment: boolean;
};

const api = (param: AuthorParams | ListenerParams): Promise<Response> => {
  return client.post('v1/me/appointments/check_duplicate', param);
};

/**
 * お約束日と自身の募集日の重複確認
 */

type Props = AuthorParams | ListenerParams;

export const usePostCheckDuplicateAppointments = () => {
  const postCheckDuplicate = useCallback(async (props: Props) => {
    const res = await api(props);

    return { board: res.board, appointment: res.appointment };
  }, []);

  return { postCheckDuplicate };
};
