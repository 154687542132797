import { memo } from 'react';
import styles from '@/styles/projects/showButton.module.scss';

type Props = {
  onCloseShow: () => void;
  onClickMenu?: () => void;
};

export const ShowButton: React.FC<Props> = memo((props) => {
  const { onCloseShow, onClickMenu } = props;

  return (
    <div className={styles.wrapper}>
      <button type='button' className={styles['close-button']} onClick={onCloseShow} aria-label='close' />
      {onClickMenu && (
        <button type='button' className={styles['menu-button']} onClick={onClickMenu} aria-label='menu' />
      )}
    </div>
  );
});
