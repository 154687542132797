import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';

type Params = string;

type Response = UserBasicInfo[];

const api = (query: Params): Promise<Response> => {
  return client.get(`v1/unsubscribes?${query}`);
};

type Props = {
  page?: number;
};

/**
 * 退会ユーザーリストの取得
 */

export const useGetQuitUsers = () => {
  const fetchQuitUsers = useCallback(async (props: Props) => {
    const { page = 1 } = props;

    const params = {
      page
    };

    const res = await api(generateQueryParameter(params));

    return res;
  }, []);

  return { fetchQuitUsers };
};
