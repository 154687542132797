import { memo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import { usePostIncomeProofImages } from '@/apis';
import { meKeys } from '@/apis/queryKeys';
import cipherImg from '@/assets/lp/common/cipher.png';
import japhicImg from '@/assets/lp/common/japhic.png';
import policeImg from '@/assets/lp/common/police.png';
import kv from '@/assets/lp/incomeProof/kv.png';
import merit1Img from '@/assets/lp/incomeProof/merit1.png';
import merit2Img from '@/assets/lp/incomeProof/merit2.png';
import proof1Img from '@/assets/lp/incomeProof/proof1.png';
import proof2Img from '@/assets/lp/incomeProof/proof2.png';
import proof3Img from '@/assets/lp/incomeProof/proof3.png';
import { UploadImgModal } from '@/components/features/modal/UploadImgModal';
import { Button } from '@/components/styles/uis/Button';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useBasicModal, useDisclosure } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/proof.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const IncomeProofLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const queryClient = useQueryClient();
  const uploadImgModal = useDisclosure();
  const { onCloseModal, uploadCompleteModal } = useBasicModal();

  const { postIncomeProofImages } = usePostIncomeProofImages();

  const onCloseUploadModal = useCallback(() => {
    onCloseModal();
    onClose();
  }, []);

  const onUpload = useCallback(async (file: Blob) => {
    await postIncomeProofImages({ image: file });
    queryClient.invalidateQueries(meKeys.all);

    uploadCompleteModal(onCloseUploadModal);
  }, []);

  return (
    <>
      <TrackPageView viewName='lp_submit_income' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={clsx(account['submodal-wrapper'], styles.wrapper)}
          data-proof='income'
        >
          <div className={clsx(account['submodal-header'], styles.header)} data-proof='income'>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={clsx(account['submodal-header-back-button'], styles['back-button'])}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>年収証明</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.kv}>
              <img src={kv} alt='ライバルと差がつく年収証明' />
            </div>

            <div className={styles.inner}>
              <div className={styles.section}>
                <span className={styles['section-label']}>MERIT</span>
                <h2 className={styles['section-title']}>年収証明のメリット</h2>
                <div className={styles['section-img']}>
                  <img src={merit1Img} alt='あなたのプロフィールに専用バッチを' />
                </div>

                <div className={styles['merit-block']}>
                  <span className={styles['merit-label']}>MERIT 1</span>
                  <h3 className={styles['merit-title']}>
                    あなたのプロフィールに
                    <br />
                    専用バッチを
                  </h3>
                  <div className={styles['section-text']}>
                    <p>資産証明をすると、運営公認を受けた信頼の証の専用バッチを贈呈。</p>
                    <p>あなたが信頼できる方として認識されるようになります。</p>
                    <p>お相手から圧倒的信用を得ることが可能です。</p>
                  </div>
                </div>

                <div className={styles['merit-block']}>
                  <div className={styles['section-img']}>
                    <img src={merit2Img} alt='お相手からより多くのアプローチを' />
                  </div>
                  <span className={styles['merit-label']}>MERIT 2</span>
                  <h3 className={styles['merit-title']}>
                    お相手からより多くの
                    <br />
                    アプローチを
                  </h3>
                  <div className={styles['section-text']}>
                    <p>
                      年収証明をすると、より多くのお相手の目に留まることで、たくさんのアプローチを受けられます。
                      <span className={styles.highlight}>いいね!数</span>は通常時より約
                      <span className={styles.highlight}>70%UP</span>！
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <span className={styles['section-label']}>FLOW</span>
                <h2 className={styles['section-title']}>書類の提出方法</h2>
                <div className={styles['flow-block']}>
                  <h3 className={styles['flow-title']}>提出可能な書類</h3>
                  <ul className={styles['row-imgs']}>
                    <li>
                      <img src={proof1Img} alt='前年度の源泉徴収票' />
                      <h4 className={styles['row-imgs-label']}>
                        前年度の
                        <br />
                        源泉徴収票
                      </h4>
                    </li>
                    <li>
                      <img src={proof2Img} alt='前年度の確定申告書' />
                      <h4 className={styles['row-imgs-label']}>
                        前年度の
                        <br />
                        確定申告書
                      </h4>
                    </li>
                    <li>
                      <img src={proof3Img} alt='前年度の納税証明書' />
                      <h4 className={styles['row-imgs-label']}>
                        前年度の
                        <br />
                        納税証明書
                      </h4>
                    </li>
                  </ul>
                </div>

                <div className={styles['flow-block']}>
                  <h3 className={styles['flow-title']}>提出時の注意点</h3>
                  <p className={styles['flow-text']}>
                    以下の情報が見えるように
                    <br />
                    原本を撮影してください
                  </p>
                  <ul className={styles['wrap-text']}>
                    <li>書類発行元</li>
                    <li>お名前</li>
                    <li>書類発行年月日</li>
                    <li>年収</li>
                  </ul>
                  <p className={styles.annotation}>※年収証明後はご自身で年収情報の編集ができなくなります。</p>
                </div>

                <div className={styles['button-wrapper']}>
                  <Button onClick={uploadImgModal.open}>年収証明をはじめる</Button>
                </div>
              </div>

              <div className={styles.section}>
                <span className={styles['section-label']}>PRIVACY</span>
                <h2 className={styles['section-title']}>
                  みなさまの情報を
                  <br />
                  厳重管理しています
                </h2>
                <div className={styles['section-text']}>
                  <p className={components['text-small']}>
                    お客様の個人情報は、個人情報保護法に基づき、厳正な管理・取り扱いを行います。ご提出書類は運営事務局にて審査の後、速やかに破棄いたします。
                  </p>
                </div>
                <ul className={styles['row-imgs']} style={{ marginTop: '32px' }}>
                  <li>
                    <img src={cipherImg} alt='通信を暗号化' />
                    <h3 className={styles['row-imgs-label']}>通信を暗号化</h3>
                  </li>
                  <li>
                    <img src={japhicImg} alt='JAPHICマーク取得' />
                    <h3 className={styles['row-imgs-label']}>
                      JAPHIC
                      <br />
                      マーク取得
                    </h3>
                  </li>
                  <li>
                    <img src={policeImg} alt='警視庁への届出済' />
                    <h3 className={styles['row-imgs-label']}>
                      警視庁への
                      <br />
                      届出済
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </motion.div>

        <UploadImgModal isOpen={uploadImgModal.isOpen} onClose={uploadImgModal.close} onUpload={onUpload} />
      </div>
    </>
  );
});
