import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';
import { User } from '@/functions/types/chatRoom';

type Params = string;

type Response = User[];

const api = (query: Params): Promise<Response> => {
  return client.get(`v1/hides?${query}`);
};

type Props = {
  page?: number;
};

/**
 * 非表示ユーザーリストの取得
 */

export const useGetHideUsers = () => {
  const fetchHideUsers = useCallback(async (props: Props) => {
    const { page = 1 } = props;

    const params = {
      page
    };

    const res = await api(generateQueryParameter(params));

    return res;
  }, []);

  return { fetchHideUsers };
};
