import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { client } from '@/apis/client';
import { meKeys } from '@/apis/queryKeys';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  birthday: string;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/birthday', params);
};

type Props = Params;

/**
 * 自身の誕生日を設定する
 */

export const usePutBirthday = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const putBirthday = useCallback(async (props: Props) => {
    await api(props);

    queryClient.invalidateQueries(meKeys.all);

    openSnackbar({
      type: 'toast',
      text: '生年月日を変更しました。'
    });
  }, []);

  return { putBirthday };
};
