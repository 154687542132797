import { FormEvent, memo, useEffect, useId, useRef, useState } from 'react';
import styles from '@/styles/uis/otpInput.module.scss';

type Props = {
  value: string;
  inputLength: number;
  isInvalid: boolean;
  onChange: (value: string) => void;
};

export const OtpInput: React.FC<Props> = memo((props) => {
  const { value, inputLength, onChange, isInvalid } = props;

  const id = useId();
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [activeInput, setActiveInput] = useState(0);

  // valueをsplit分割して配列にする
  const getOTPValue = () => (value ? value.toString().split('') : []);

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join('');
    onChange(otpValue);
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOTPValue();
    otp[activeInput] = value;
    handleOTPChange(otp);
  };

  /**
   * フォーカスを当てるInputを変更する
   * @param index フォーカスを当てるInputのindex
   */
  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(inputLength - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      setActiveInput(activeInput);
    }
  };

  /**
   * 指定された値が有効な入力値であるかどうかを判定する
   * 有効な入力値は、数値であり、かつ、空白を除いた長さが1である文字列
   *
   * @param {string} value - 検証する入力値
   * @returns {boolean} 入力値が有効であればtrue、そうでなければfalse
   */
  const isInputValueValid = (value: string) => {
    const isTypeValid = !Number.isNaN(Number(value));
    return isTypeValid && value.trim().length === 1;
  };

  /**
   * キーボードが押されたときの処理
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const otp = getOTPValue();
    if (isInputValueValid(event.key)) {
      event.preventDefault();
      changeCodeAtFocus(event.key);
      focusInput(activeInput + 1);
    } else if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  };

  /**
   * ペーストされた時の処理
   */
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const otp = getOTPValue();
    let nextActiveInput = activeInput;

    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, inputLength - activeInput)
      .split('');

    // 数値以外が含まれている場合は処理を中断
    if (pastedData.some((value) => Number.isNaN(Number(value)))) {
      return;
    }

    for (let pos = 0; pos < inputLength; pos += 1) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput += 1;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (!isInputValueValid(value)) {
      if (value.length === inputLength) {
        const hasInvalidInput = value.split('').some((cellInput) => !isInputValueValid(cellInput));
        if (!hasInvalidInput) {
          handleOTPChange(value.split(''));
          focusInput(inputLength - 1);
        }
      }
    }
  };

  useEffect(() => {
    // マウント時に最初のInputにフォーカスを当てる
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <ul className={styles.wrapper}>
      {[...Array(inputLength)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`codeInput-${id}-${i}`} className={styles.item}>
          <input
            value={getOTPValue()[i] ?? ''}
            ref={(element) => {
              inputRefs.current[i] = element;
            }}
            onFocus={() => setActiveInput(i)}
            onKeyDown={(event) => handleKeyDown(event)}
            onPaste={(event) => handlePaste(event)}
            onInput={(event) => handleInputChange(event)}
            data-invalid={isInvalid}
            placeholder=' '
            type='text'
            pattern='\d'
            inputMode='numeric'
          />
        </li>
      ))}
    </ul>
  );
});
