type ViewEvent = {
  event: 'pageView';
  viewName: string;
};

/**
 * 要素表示イベント
 */
type InViewEvent = {
  event: 'inView';
  in_view_label: string;
};

/**
 * クリックイベント
 */
type ClickEvent = {
  event: 'click';
  click_label: string;
};

/**
 * ユーザー識別イベント
 */
type IdentifyEvent = {
  event: 'identify';
  user_id: string;
  email: string;
  phone: string;
};

export type DataLayerType = ViewEvent | InViewEvent | ClickEvent | IdentifyEvent;

export const pushDataLayer = (data: DataLayerType) => {
  /**
   * データレイヤーは、Google タグ マネージャーと gtag.js でタグに情報を渡すために使用するオブジェクト。
   * イベントまたは変数を、データレイヤーを介して渡したり、変数の値に基づいたトリガーを設定したりすることができる。
   */
  let { dataLayer }: any = window;

  dataLayer = dataLayer || [];
  dataLayer.push(data);
};
