import { useCallback } from 'react';
import { client } from '@/apis/client';
import { generateQueryParameter } from '@/functions/helpers';

type Params = string;

export type ActivityUser = {
  id: number;
  chat_room_id?: number;
  patch_status: 'normal' | 'force_premium' | 'premium' | 'admin' | 'force_royal' | 'royal';
  status: string;
  gender: string;
  is_cast: boolean;
  is_gyosha: boolean;
  age: number;
  created_at: string;
  is_unsubscribed: boolean;
  is_warning: boolean;
  is_freezed: boolean;
  last_action_time?: string;
  property: {
    nickname: string;
    business_type: string;
    one_word: string;
    self_introduction: string;
    residence_location: string;
    body_type: string;
    purpose: string;
  };
  income?: string;
  in_time_subscription: {
    royal_subscription: boolean;
    paid_subscription: boolean;
  };
  top_image: {
    id?: number;
    user_id: number;
    order: number;
    aasm_state: string;
    status: number;
    disclose: string;
    is_mosaic: boolean;
    image_url: string;
    face_weight: number;
  };
  images: [];
  profile_movie: {};
  talked?: boolean;
  na_text: 'message' | 'profile' | 'match' | 'question' | 'like' | 'question_match';
  questions: {
    id: number;
    content: string;
    other_text: string;
    require: boolean;
  }[];
};

type Activity = {
  id: number;
  status: 'unread' | 'read' | 'deleted';
  user_id: number;
  from_user_id: number;
  target_user_id?: number;
  content: string;
  icon_image_url: string;
  tap_action?: string;
  updated_at: string;
  created_at: string;
  user: ActivityUser;
};

type Response = Activity[];

const api = (query: Params): Promise<Response> => {
  return client.get(`v1/activities?${query}`);
};

/**
 * 足跡一覧取得
 */

type Props = { page: number };

export const useGetFootprints = () => {
  const fetchFootprints = useCallback(async (props: Props) => {
    const res = await api(generateQueryParameter(props));

    return res;
  }, []);

  return { fetchFootprints };
};
