import { memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import report from '@/assets/lp/common/report.png';
import safety from '@/assets/lp/common/safety.png';
import kv from '@/assets/lp/howTo/kv.png';
import step1Img from '@/assets/lp/howTo/step1.png';
import step2Img from '@/assets/lp/howTo/step2.png';
import step3Img from '@/assets/lp/howTo/step3.png';
import step4Img from '@/assets/lp/howTo/step4.png';
import step5Img from '@/assets/lp/howTo/step5.png';
import step6Img from '@/assets/lp/howTo/step6.png';
import step7Img from '@/assets/lp/howTo/step7.png';
import step8Img from '@/assets/lp/howTo/step8.png';
import { Button } from '@/components/styles/uis/Button';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/howTo.module.scss';
import account from '@/styles/pages/account.module.scss';

const parentName = 'howto';

const stepList = [
  {
    title: 'ご利用の前に',
    text: 'みなさまに安心してご利用いただくために、paddyでは電話番号認証と年齢確認が必須となります。',
    img: step1Img
  },
  {
    title: 'プロフィールを充実',
    text: 'プロフィール編集からあなたのアピール情報を登録しましょう。プロフィール画像と自己紹介文が特にお相手に見られるポイントです！',
    img: step2Img
  },
  {
    title: '好みのお相手を見つける',
    text: '「絞り込む」でタイプや年齢など、さまざまな条件を指定して希望のお相手を探すことができます。',
    img: step3Img
  },
  {
    title: '「いいね!」を送る',
    text: '好みのお相手を見つけたら「いいね!」を送ってアピールしましょう。お相手もあなたに「いいね!」を返すことで、マッチング成立です！',
    img: step4Img
  },
  {
    title: '「いいね!」を返す',
    text: 'お相手から「いいね!」が届くと、いいね画面に表示されます。気になるお相手に「マッチ」ボタンを押すことで、マッチング成立です！',
    img: step5Img
  },
  {
    title: 'メッセージでやりとり',
    text: 'マッチングをしたお相手にメッセージを送って仲を深めましょう。お相手と求めることが一致しなくても、暴言やお相手を傷つける発言は避けましょう。',
    img: step6Img
  },
  {
    title: 'デートのお約束',
    text: 'メッセージで仲を深が深まったら、デートの日程や場所を相談しましょう。約束機能を使うことでトラブルを防ぐことができます。',
    img: step7Img
  },
  {
    title: '日程を決めてデート',
    text: 'お会いする日程や場所が決まったら、いよいよお楽しみのデートです。お互いの時間がムダにならないよう、直前のキャンセルや音信不通は避けましょう。',
    img: step8Img
  }
];

type Props = {
  onClose: () => void;
};

export const HowToLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const { parentElements, handleOpenSubElement } = useSubElement();

  const handleClickSafetyLink = useCallback(() => {
    handleOpenSubElement('safetyLp', parentName);
  }, []);

  return (
    <>
      <TrackPageView viewName='lp_how_to_use_paddy' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>paddyの使い方</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={components['parent-wrapper']} data-children-open={parentElements.fourth === parentName}>
              <div className={styles.inner}>
                <div className={styles['kv-wrapper']}>
                  <img src={kv} alt='paddyの使い方' />
                </div>

                <ul className={styles.list}>
                  {stepList.map((step, index) => (
                    <li key={step.title}>
                      <span className={styles.step}>STEP {index + 1}</span>
                      <h2 className={components['heading-1']}>{step.title}</h2>
                      <p className={components['basic-text']}>{step.text}</p>
                      <div className={components['mt-gutter']}>
                        <img src={step.img} alt={step.title} />
                      </div>
                    </li>
                  ))}
                </ul>

                <div className={styles['other-img']}>
                  <img src={safety} alt='paddy安心・安全の取り組み' />
                  <img
                    src={report}
                    alt='paddyでは、安心安全なサービス運営を心がけていますが、以下のようなユーザーを見かけた際は運営事務局へお知らせください。'
                  />
                </div>

                <p className={components['basic-text']}>
                  paddyでは、みなさまに安心してご利用いただくために、さまざまな取り組みを実施しています。詳しくは以下よりご確認ください。
                </p>
                <div className={components['mt-gutter']}>
                  <Button color='black' onClick={handleClickSafetyLink}>
                    詳細を見る
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
