import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.delete(`v2/me/boards/${params.id}`);
};

type Props = Params;

/**
 * 募集削除
 */

export const useDeleteBoards = () => {
  const deleteBoard = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { deleteBoard };
};
