import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetMeBoards } from '@/apis';
import { meBoardKeys } from '@/apis/queryKeys';
import { ReactComponent as EmptyBoard } from '@/assets/icons/empty_board.svg';
import { BoardCard } from '@/components/features/BoardCard';
import { BoardCreateModal, EditBoardData } from '@/components/features/modal/BoardCreateModal';
import { BoardMenuModal } from '@/components/features/modal/BoardMenuModal';
import { Button } from '@/components/styles/uis/Button';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { formatDate, getBoardDayItems } from '@/functions/helpers';
import { useBasicModal, useDisclosure } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import board from '@/styles/pages/boards.module.scss';
import styles from '@/styles/pages/mypage/board.module.scss';

type Props = {
  onClose: () => void;
};

export const Board: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const [boardId, setBoardId] = useState<number | null>(null);
  const [boardData, setBoardData] = useState<EditBoardData | null>(null);

  const me = useAtomValue(meAtom);
  const { isMale, isMaleFree, isPhoneVerify, isAgeConfirm, basicPointLength, isFreezed } = useAtomValue(meFlagAtom);
  const masterTypes = useAtomValue(masterTypesAtom);

  const {
    commonModal,
    phoneVerificationModal,
    ageConfirmationModal,
    boardPremiumGuidanceMessageModal,
    pointLackModal,
    isMeFreezedModal
  } = useBasicModal();

  const createModal = useDisclosure();
  const boardMenuModal = useDisclosure();
  const queryClient = useQueryClient();

  const { fetchMeBoards } = useGetMeBoards();

  const dayItems = getBoardDayItems();

  const { isLoading, data } = useQuery({
    queryKey: meBoardKeys.lists(),
    queryFn: () => fetchMeBoards()
  });

  const boardDatas = useMemo(() => {
    if (data === undefined) return null;

    const groupDatas = data.data
      .flatMap((v) => v.attributes)
      .reduce((acc: { reservedDate: string; datas: any[] }[], cur) => {
        const date = cur.reserved_date;
        const target = acc.find((v) => v.reservedDate === date);
        if (target) {
          target.datas.push(cur);
        } else {
          acc.push({ reservedDate: date, datas: [cur] });
        }

        return acc;
      }, []);

    // 各日付に基づいてソート
    const sortedDatas = groupDatas.sort(
      (a, b) => new Date(a.reservedDate).getTime() - new Date(b.reservedDate).getTime()
    );

    // timeframes の順序を保持するためのマッピング
    const timeframeOrder: { [key: string]: number } = Object.keys(masterTypes.board_timeframes).reduce(
      (acc, key, index) => {
        acc[key] = index;
        return acc;
      },
      {} as { [key: string]: number }
    );

    // 各日付の中で timeframes に基づいてソート
    sortedDatas.forEach((group) => {
      group.datas.sort((a, b) => timeframeOrder[a.timeframe] - timeframeOrder[b.timeframe]);
    });

    return sortedDatas;
  }, [data]);

  const refetch = useCallback(async () => {
    queryClient.invalidateQueries(meBoardKeys.lists());
  }, []);

  const handleClickCreate = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (me.is_secret) {
      commonModal({
        title: '募集の投稿ができません。',
        text: 'シークレットモード設定中に募集の投稿はできません。投稿する場合は、シークレットモードを解除してください。'
      });
    } else if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (isMaleFree) {
      boardPremiumGuidanceMessageModal();
    } else if (data?.data.length === 3) {
      commonModal({
        title: '投稿できる募集は3件までです。',
        text: '投稿可能な募集数の上限に達しています。\n募集を削除した上で、再度お試しください。'
      });
    } else if (isMale && (basicPointLength ?? 0) < 10) {
      pointLackModal(
        <>募集を作成するためには10PTが必要です。ポイントをご購入の上、再度お試しください。</>,
        basicPointLength ?? 0
      );
    } else {
      createModal.open();
    }
  }, [data, isFreezed, isPhoneVerify, isAgeConfirm, basicPointLength, me.is_secret]);

  const handleClickMenu = useCallback((boardId: number) => {
    boardMenuModal.open();
    setBoardId(boardId);
  }, []);

  const onCloseCreateModal = useCallback(() => {
    createModal.close();
    setBoardData(null);
  }, []);

  const onCloseBoardMenuModal = useCallback(() => {
    boardMenuModal.close();
    setTimeout(() => {
      setBoardId(null);
    }, animateDuration);
  }, []);

  const handleClickEdit = useCallback(() => {
    if (!data || !boardId) return;

    if (isFreezed) {
      isMeFreezedModal();
      return;
    }

    onCloseBoardMenuModal();

    const boardData = data?.data.find((v) => v.id === String(boardId));
    if (!boardData) return;

    setBoardData({
      id: boardData.attributes.id,
      reservedDate: boardData.attributes.reserved_date,
      imgageUrl: boardData.attributes.image_url,
      area: boardData.attributes.area,
      area_detail: boardData.attributes.area_detail,
      purpose: boardData.attributes.purpose,
      timeframe: boardData.attributes.timeframe,
      freeText: boardData.attributes.free_text,
      hideWhenMatched: boardData.attributes.hide_when_matched
    });

    setTimeout(() => {
      createModal.open();
    }, animateDuration);
  }, [data, boardId]);

  const handleClickPremium = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else {
      boardPremiumGuidanceMessageModal();
    }
  }, [isPhoneVerify, isAgeConfirm]);

  return (
    <>
      <TrackPageView viewName='my_boards_top' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>募集</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={styles['center-wrapper']}>
                <LoadingSpinner />
              </div>
            )}

            {!isLoading && boardDatas?.length ? (
              <>
                <div className={styles.inner}>
                  <ul className={board['boards-list']}>
                    {boardDatas.map(({ reservedDate, datas }) => (
                      <li key={reservedDate}>
                        <div className={board['boards-header']} data-mypage>
                          <span className={board['day-label']}>{formatDate(reservedDate, { hideTime: true })}</span>
                        </div>
                        <ul className={board['card-list']}>
                          {datas.map((board) => (
                            <Fragment key={board.id}>
                              <BoardCard
                                boardProperty={board}
                                userData={{
                                  id: me.id,
                                  gender: me.gender,
                                  patch_status: me.patch_status,
                                  age: me.age,
                                  image_url: me.top_image.image_url,
                                  nickname: me.property.nickname,
                                  residence_location: me.property.residence_location
                                }}
                                onClickMenu={handleClickMenu}
                              />
                            </Fragment>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className={styles['create-button']}>
                  <button type='button' onClick={handleClickCreate} aria-label='create-button' />
                </div>
              </>
            ) : (
              <div className={styles['center-wrapper']}>
                <EmptyBoard />
                <p className={clsx(components['heading-1'], components['mt-gutter'])}>作成済みの募集はありません。</p>
                <p className={components['basic-text']}>
                  {isMaleFree ? (
                    <>
                      希望の日程と条件でデートの募集ができます。
                      <br />
                      有料会員に登録して、募集を投稿しましょう。
                    </>
                  ) : (
                    <>
                      募集を作成するとこちらに表示されます。
                      <br />
                      募集は一度に3件まで作成できます。
                    </>
                  )}
                </p>
                <div className={styles['button-wrapper']}>
                  <Button color='black' onClick={isMaleFree ? handleClickPremium : handleClickCreate}>
                    {isMaleFree ? '有料会員の詳細へ' : '募集を作成'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </motion.div>

        {boardId && (
          <BoardMenuModal
            isOpen={boardMenuModal.isOpen}
            onClose={onCloseBoardMenuModal}
            boardId={boardId}
            onClickEdit={handleClickEdit}
            refetch={refetch}
          />
        )}
        <BoardCreateModal
          isOpen={createModal.isOpen}
          onClose={onCloseCreateModal}
          dayItems={dayItems}
          isMypage
          refetch={refetch}
          boardData={boardData || undefined}
        />
      </div>
    </>
  );
});
