import { Admin } from '@/pages/config/Admin';
import { BlockUserList } from '@/pages/config/BlockUserList';
import { Contact } from '@/pages/config/Contact';
import { HideUserList } from '@/pages/config/HideUserList';
import { Howto } from '@/pages/config/Howto';
import { Notification } from '@/pages/config/Notification';
import { QuitUserList } from '@/pages/config/QuitUserList';
import { SecretMode } from '@/pages/config/SecretMode';
import { UnsubscribePlan } from '@/pages/config/UnsubscribePlan';
import { AgeVerificationLp } from '@/pages/lp/AgeVerification';
import { AssetProofLp } from '@/pages/lp/AssetProof';
import { HowToLp } from '@/pages/lp/HowTo';
import { IncomeProofLp } from '@/pages/lp/IncomeProof';
import { PointLp } from '@/pages/lp/Point';
import { PremiumLp } from '@/pages/lp/Premium';
import { QuestionLikeLp } from '@/pages/lp/QuestionLike';
import { RichLikeLp } from '@/pages/lp/RichLike';
import { RoyalLp } from '@/pages/lp/Royal';
import { SafetyLp } from '@/pages/lp/Safety';
import { SecretModeLp } from '@/pages/lp/SecretMode';
import { SelfIntroductionLp } from '@/pages/lp/SelfIntroduction';
import { Appointment } from '@/pages/mypage/Appointment';
import { BasicInfo } from '@/pages/mypage/BasicInfo';
import { Board } from '@/pages/mypage/Board';
import { Config } from '@/pages/mypage/Config';
import { Footprint } from '@/pages/mypage/Footprint';
import { FutureDream } from '@/pages/mypage/FutureDream';
import { News } from '@/pages/mypage/News';
import { PointHistory } from '@/pages/mypage/PointHistory';
import { Profile } from '@/pages/mypage/Profile';
import { Question } from '@/pages/mypage/Question';
import { SelfIntroduction } from '@/pages/mypage/SelfIntroduction';
import { Status } from '@/pages/mypage/Status';

// NOTE: マイページ用のroutes
export type MypageRouteTarget =
  | 'profile'
  | 'basicInfo'
  | 'selfIntroduction'
  | 'futureDream'
  | 'status'
  | 'news'
  | 'footprint'
  | 'appointment'
  | 'board'
  | 'question'
  | 'config';

type MypageRoutes = {
  target: MypageRouteTarget;
  element: JSX.Element;
};

export const mypageRoutes: MypageRoutes[] = [
  {
    target: 'profile',
    element: <Profile onClose={() => null} />
  },
  {
    target: 'basicInfo',
    element: <BasicInfo onClose={() => null} />
  },
  {
    target: 'selfIntroduction',
    element: <SelfIntroduction onClose={() => null} />
  },
  {
    target: 'futureDream',
    element: <FutureDream onClose={() => null} />
  },
  {
    target: 'status',
    element: <Status onClose={() => null} />
  },
  {
    target: 'news',
    element: <News onClose={() => null} />
  },
  {
    target: 'footprint',
    element: <Footprint onClose={() => null} />
  },
  {
    target: 'appointment',
    element: <Appointment onClose={() => null} />
  },
  {
    target: 'board',
    element: <Board onClose={() => null} />
  },
  {
    target: 'question',
    element: <Question onClose={() => null} />
  },
  {
    target: 'config',
    element: <Config onClose={() => null} />
  }
];

// NOTE: 設定ページ用のroutes
export type ConfigRouteTarget =
  | 'phone'
  | 'email'
  | 'notification'
  | 'secretMode'
  | 'pointHistory'
  | 'hideUserList'
  | 'blockUserList'
  | 'quitUserList'
  | 'admin'
  | 'stopHack'
  | 'faq'
  | 'media'
  | 'howto'
  | 'safetyLp'
  | 'contact'
  | 'unsubscribePlan'
  | 'terms'
  | 'privacyPolicy'
  | 'asct'
  | 'psa'
  | 'logout';

type ConfigRoutes = {
  target: ConfigRouteTarget;
  element: JSX.Element;
};

export const configRoutes: ConfigRoutes[] = [
  {
    target: 'notification',
    element: <Notification onClose={() => null} />
  },
  {
    target: 'secretMode',
    element: <SecretMode onClose={() => null} />
  },
  {
    target: 'pointHistory',
    element: <PointHistory onClose={() => null} />
  },
  {
    target: 'hideUserList',
    element: <HideUserList onClose={() => null} />
  },
  {
    target: 'blockUserList',
    element: <BlockUserList onClose={() => null} />
  },
  {
    target: 'quitUserList',
    element: <QuitUserList onClose={() => null} />
  },
  {
    target: 'admin',
    element: <Admin onClose={() => null} />
  },
  {
    target: 'howto',
    element: <Howto onClose={() => null} />
  },
  {
    target: 'safetyLp',
    element: <SafetyLp onClose={() => null} />
  },
  {
    target: 'contact',
    element: <Contact onClose={() => null} />
  },
  {
    target: 'unsubscribePlan',
    element: <UnsubscribePlan onClose={() => null} />
  }
];

// NOTE: LP用のroutes
export type LpRouteTarget =
  | 'ageVerificationLp'
  | 'assetProofLp'
  | 'howToLp'
  | 'incomeProofLp'
  | 'pointLp'
  | 'premiumLp'
  | 'questionLikeLp'
  | 'richLikeLp'
  | 'royalLp'
  | 'secretModeLp'
  | 'selfIntroductionLp';

type LpRoutes = {
  target: LpRouteTarget;
  element: JSX.Element;
};

export const lpRoutes: LpRoutes[] = [
  {
    target: 'ageVerificationLp',
    element: <AgeVerificationLp onClose={() => null} />
  },
  {
    target: 'assetProofLp',
    element: <AssetProofLp onClose={() => null} />
  },
  {
    target: 'howToLp',
    element: <HowToLp onClose={() => null} />
  },
  {
    target: 'incomeProofLp',
    element: <IncomeProofLp onClose={() => null} />
  },
  {
    target: 'pointLp',
    element: <PointLp onClose={() => null} />
  },
  {
    target: 'premiumLp',
    element: <PremiumLp onClose={() => null} />
  },
  {
    target: 'questionLikeLp',
    element: <QuestionLikeLp onClose={() => null} />
  },
  {
    target: 'richLikeLp',
    element: <RichLikeLp onClose={() => null} />
  },
  {
    target: 'royalLp',
    element: <RoyalLp onClose={() => null} />
  },
  {
    target: 'secretModeLp',
    element: <SecretModeLp onClose={() => null} />
  },
  {
    target: 'selfIntroductionLp',
    element: <SelfIntroductionLp onClose={() => null} />
  }
];
