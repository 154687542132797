import { ReactNode } from 'react';
import { atom } from 'jotai';
import { ViewName } from '@/functions/constants/viewEvent';

type BasicModalAtomType = {
  isOpen: boolean;
  onCloseModal: () => void;
  body: ReactNode;
  viewName?: ViewName;
};

const initialValue: BasicModalAtomType = {
  isOpen: false,
  onCloseModal: () => {},
  body: null
};

export const basicModalAtom = atom<BasicModalAtomType>(initialValue);

basicModalAtom.debugLabel = 'basicModalAtom';
