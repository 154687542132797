export const contactTemplates = {
  signIn: `アカウントに登録された以下情報をご入力ください。
【ニックネーム】

【性別】

【生年月日】

【電話番号】

【居住地】`,
  feature: `【どの機能・画面についてですか】

【アプリ・WEBどちらをご利用ですか】

【問い合わせしたい内容】

【ご利用端末機種（iPhone14等）】

【ご利用OS情報（iOS16等）】`,
  user: `【お相手の情報を記入してください】

ニックネーム：

年齢：

居住地：`
};
