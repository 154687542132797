import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Camera from '@/assets/icons/camera.svg';
import Photo from '@/assets/icons/photo.svg';
import { TrimmingModal } from '@/components/features/modal/TrimmingModal';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import { useDisclosure, useUpload } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';

const discloseOptions = {
  10: '全体に公開',
  30: 'マッチ済みのお相手のみ公開'
};

type Props = {
  from: 'profile' | 'board' | 'chat';
  chatRoomId?: number;
  setImg: (img: string | Blob, order?: number | null) => void;
  isOpen: boolean;
  onClose: () => void;

  // NOTE: プロフィール編集画面で使用
  imgId?: number | null;
  order?: number | null;
  disclose?: string | null;
  onDelete?: (imgId: number) => Promise<void>;
  onChangeDisclose?: (imgId: number, disclose: number) => void;
};

export const AddImgModal: React.FC<Props> = memo((props) => {
  const { from, chatRoomId, setImg, isOpen, onClose, imgId, order, disclose, onDelete, onChangeDisclose } = props;

  const [trimmingImg, setTrimmingImg] = useState('');
  const [temporarily, setTemporarily] = useState(10);

  const trimmingModal = useDisclosure();
  const discloseModal = useDisclosure();

  const { upload } = useUpload();

  const cameraRollInput = useRef<HTMLInputElement>(null);
  const cameraAppInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (disclose) {
      setTemporarily(disclose === 'to_public' ? 10 : 30);
    }
  }, [imgId]);

  const handleClickCameraRoll = useCallback(() => {
    if (!cameraRollInput.current) return;
    cameraRollInput.current.click();
  }, []);

  const handleClickCamera = useCallback(() => {
    if (!cameraAppInput.current) return;
    cameraAppInput.current.click();
  }, []);

  const onChangeImg = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const res = reader.result;
        if (res && typeof res === 'string') {
          setTrimmingImg(res);
          trimmingModal.open();
        }
      };

      reader.readAsDataURL(files[0]);
      onClose();
    }
  }, []);

  const handleCloseTrimmingModal = useCallback(() => {
    trimmingModal.close();
    setTrimmingImg('');
  }, []);

  const handleClickTrimming = useCallback(
    async (value: Blob) => {
      if (from === 'board') {
        setImg(value);
      } else {
        const imgUrl = await upload(value, chatRoomId);
        setImg(imgUrl, order);
      }
      handleCloseTrimmingModal();
    },
    [order]
  );

  const handleClickDisclose = useCallback(() => {
    if (!onChangeDisclose || !imgId) return;

    onChangeDisclose(imgId, temporarily);
    onClose();
    discloseModal.close();
  }, [temporarily, imgId]);

  return (
    <>
      <HalfModal id='addImgModal' isOpen={isOpen} onClose={onClose} title={imgId ? '編集' : '写真を選ぶ'} isInner>
        <div className={components['gap-wrapper']}>
          <Button color='white' onClick={handleClickCameraRoll} icon={Photo}>
            <input
              ref={cameraRollInput}
              type='file'
              accept='image/*'
              onChange={onChangeImg}
              style={{ display: 'none' }}
            />
            カメラロールから選択
          </Button>
          <Button color='white' onClick={handleClickCamera} icon={Camera}>
            <input
              ref={cameraAppInput}
              type='file'
              capture='user'
              accept='image/*'
              onChange={onChangeImg}
              style={{ display: 'none' }}
            />
            写真を撮影
          </Button>
          {imgId && from === 'profile' && onChangeDisclose && order !== 0 && (
            <Button color='white' onClick={discloseModal.open}>
              公開範囲を変更
            </Button>
          )}
          {imgId && onDelete && (
            <Button color='white' onClick={() => onDelete(imgId)}>
              <span className={components['red-text']}>削除</span>
            </Button>
          )}
          <Button color='clear' onClick={onClose}>
            <span className={components['black-text']}>キャンセル</span>
          </Button>
        </div>
      </HalfModal>

      {trimmingImg &&
        trimmingModal.isOpen &&
        createPortal(
          <TrimmingModal
            isSquare={from !== 'board'}
            imgSrc={trimmingImg}
            onClose={handleCloseTrimmingModal}
            onTrimming={handleClickTrimming}
          />,
          document.getElementById('root') as Element
        )}

      {imgId && onChangeDisclose && (
        <HalfModal
          id='discloseModal'
          isOpen={discloseModal.isOpen}
          onClose={discloseModal.close}
          title='公開範囲を変更'
          footer={
            <Button color='black' onClick={handleClickDisclose}>
              決定
            </Button>
          }
        >
          <RadioGroup
            selected={temporarily}
            options={discloseOptions}
            onChange={(e) => setTemporarily(Number(e.target.value))}
          />
        </HalfModal>
      )}
    </>
  );
});
