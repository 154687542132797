import { memo } from 'react';
import styles from '@/styles/uis/multiCheckBox.module.scss';

type ElementProps = JSX.IntrinsicElements['input'];

type Props = {
  selected: (string | number)[];
  options: Object;
} & ElementProps;

export const MultiCheckBox: React.FC<Props> = memo((_props) => {
  const { selected, options, ...props } = _props;

  return (
    <ul className={styles.list}>
      {Object.entries(options).map(([key, value]) => (
        <li key={key}>
          <label htmlFor={key} className={styles.label}>
            <input id={key} type='checkbox' value={key} checked={selected.some((v) => String(v) === key)} {...props} />
            {value}
          </label>
        </li>
      ))}
    </ul>
  );
});
