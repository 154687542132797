import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  email: string;
};

type Response = {};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/email_with_token', params);
};

type Props = Params;

/**
 * メールアドレスを更新する
 */

export const usePutEmailWithToken = () => {
  const putEmailWithToken = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putEmailWithToken };
};
