import { memo } from 'react';
import styles from '@/styles/projects/certificationStatus.module.scss';

export type StatusType = 'none' | 'complete' | 'progress' | 'stop';

type Props = {
  status: StatusType;
  label: string;
};

export const CertificationStatus: React.FC<Props> = memo((props) => {
  const { status, label } = props;

  return (
    <span className={styles.label} data-status={status}>
      {label}
    </span>
  );
});
