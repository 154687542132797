import { CSSProperties, ReactNode, memo, useEffect } from 'react';
import ReactModal from 'react-modal';
import { TrackPageView } from '@/analytics/TrackPageView';
import { ViewName } from '@/functions/constants/viewEvent';
import { useScrollLock } from '@/functions/hooks';
import styles from '@/styles/uis/basicModal.module.scss';

type Styles = {
  content?: CSSProperties;
  overlay?: CSSProperties;
};

type Props = {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  modalStyles?: Styles;
  viewName?: ViewName;
};

export const BasicModal: React.FC<Props> = memo((props) => {
  const { isOpen, children, onClose, modalStyles, viewName } = props;

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  useEffect(() => {
    if (document.getElementById('root')) {
      ReactModal.setAppElement('#root');
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      addScrollPreventElement('basicModal');
    } else {
      removeScrollPreventElement('basicModal');
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && viewName && <TrackPageView viewName={viewName} />}

      <ReactModal
        isOpen={isOpen}
        className={styles.content}
        style={modalStyles}
        overlayClassName={styles.overlay}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
        bodyOpenClassName=''
        closeTimeoutMS={200}
        portalClassName='basic-modal'
      >
        {children}
      </ReactModal>
    </>
  );
});
