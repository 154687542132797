import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  amount: number;
  purpose: string;
  to_user_id: number;
  user_id: number;
};

type Response = { success: 1 };

const api = (params: Params): Promise<Response> => {
  return client.post('v1/points/use_points', params);
};

type Props = Params;

/**
 * ポイントの使用
 */

export const usePostUsePoints = () => {
  const postPoints = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postPoints };
};
