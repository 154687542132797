import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

const api = (params: Params): Promise<Response> => {
  return client.delete(`v1/blocks/${params.id}`);
};

type Props = Params;

/**
 * ユーザーのブロックを解除する
 */

export const useDeleteBlockUser = () => {
  const deleteBlockUser = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { deleteBlockUser };
};
