import { atom } from 'jotai';

type BadgesType = {
  chat_rooms: {
    liked: number;
    matched: number;
    contacted: number;
  };
  activity: number;
  notice: number;
  appointments: {
    future: number;
  };
};

const initialValue: BadgesType = {
  chat_rooms: {
    liked: 0,
    matched: 0,
    contacted: 0
  },
  activity: 0,
  notice: 0,
  appointments: {
    future: 0
  }
};

export const badgesAtom = atom<BadgesType>(initialValue);

badgesAtom.debugLabel = 'badgesAtom';
