import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { SnackbarProps } from '@/components/styles/uis/Snackbar';
import { snackbarAtom } from '@/contexts/atoms/snackbar';
import { snackbarDuration } from '@/functions/constants/common';

export const useSnackbar = () => {
  const setSnackbar = useSetAtom(snackbarAtom);

  const initSnackbar = useCallback(() => {
    setSnackbar(null);
  }, []);

  const closeSnackbar = useCallback((props: SnackbarProps) => {
    setSnackbar({
      isOpen: false,
      ...props
    });

    setTimeout(() => {
      initSnackbar();
    }, 300);
  }, []);

  const openSnackbar = useCallback((props: SnackbarProps) => {
    setSnackbar({
      isOpen: true,
      ...props
    });

    setTimeout(() => {
      closeSnackbar(props);
    }, snackbarDuration);
  }, []);

  return { openSnackbar };
};
