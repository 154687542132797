import { atom } from 'jotai';
import { GENDER } from '@/functions/constants/enums';
import { Me } from '@/functions/types/me';

type MemberType = 'free' | 'premium' | 'royal' | 'admin';

type MeFlag = {
  member: MemberType | null;
  isMale: boolean | null;
  isMaleFree: boolean | null;
  basicPointLength: number | null;
  rickLikeLength: number | null;
  isPhoneVerify: boolean | null;
  isAgeConfirm: boolean | null;
  isFreezed: boolean | null;
  isBeginner: boolean | null;
};

export const meFlagAtom = atom<MeFlag>({
  member: null,
  isMale: null,
  isMaleFree: null,
  basicPointLength: null,
  rickLikeLength: null,
  isPhoneVerify: null,
  isAgeConfirm: null,
  isFreezed: null,
  isBeginner: null
});

meFlagAtom.debugLabel = 'meFlagAtom';

const getMember = (me: Me): MemberType => {
  const { patch_status: status } = me;

  if (status === 'premium' || status === 'force_premium') {
    return 'premium';
  }

  if (status === 'royal' || status === 'force_royal') {
    return 'royal';
  }

  if (status === 'admin') {
    return 'admin';
  }

  return 'free';
};

export const getMeFlag = (me: Me): MeFlag => {
  return {
    member: getMember(me),
    isMale: me.gender === GENDER.MALE,
    isMaleFree: me.gender === GENDER.MALE && getMember(me) === 'free',
    basicPointLength: me.points?.basic?.current_amount ?? null,
    rickLikeLength: me.points?.rich_like?.in_time_amount ?? null,
    isPhoneVerify: me.state === 'completed',
    isAgeConfirm: me.is_age_verified,
    isFreezed: me.is_freezed,
    isBeginner: me.beginner
  };
};
