import { memo, RefObject } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from '@/styles/uis/textareaInput.module.scss';

type ElementProps = JSX.IntrinsicElements['textarea'];

type Props = {
  id?: string;
  register?: UseFormRegisterReturn;
  rows?: number;
  placeholder?: string;
  annotation?: string;
  count?: number;
  isInvalid?: boolean;
  inputRef?: RefObject<HTMLTextAreaElement>;
} & ElementProps;

export const TextareaInput: React.FC<Props> = memo((_props) => {
  const { id, register, rows = 5, placeholder, annotation, count, isInvalid, ...props } = _props;

  return (
    <div className={styles.container}>
      <label className={styles.wrapper} htmlFor={id} data-no-label={!placeholder} data-invalid={isInvalid}>
        {placeholder && (
          <span className={styles.placeholder} data-invalid={isInvalid}>
            {placeholder}
          </span>
        )}
        <textarea
          id={id}
          {...register}
          className={styles.textarea}
          rows={rows}
          placeholder={placeholder}
          data-invalid={isInvalid}
          {...props}
        />
      </label>
      {(annotation || count) && (
        <div className={styles['annotation-wrapper']}>
          <span data-invalid={isInvalid}>{annotation}</span>
          {count && (
            <span data-invalid={isInvalid}>
              {String(props.value).length}/{count}
            </span>
          )}
        </div>
      )}
    </div>
  );
});
