import { atom } from 'jotai';
import { SnackbarProps } from '@/components/styles/uis/Snackbar';

type SnackbarAtomType = {
  isOpen: boolean;
} & SnackbarProps;

export const snackbarAtom = atom<SnackbarAtomType | null>(null);

snackbarAtom.debugLabel = 'snackbarAtom';
