import { memo, useCallback, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePutProperty } from '@/apis';
import { meKeys } from '@/apis/queryKeys';
import { Button } from '@/components/styles/uis/Button';
import { TextareaInput } from '@/components/styles/uis/TextareaInput';
import { meAtom } from '@/contexts/atoms/me';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useSubElement, useSnackbar } from '@/functions/hooks';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/profile.module.scss';

const parentName = 'selfIntroduction';

const schema = z.object({
  self_introduction: z.string().max(1000, '1000文字以内で入力してください')
});

type Schema = z.infer<typeof schema>;

type Props = {
  onClose: () => void;
};

export const SelfIntroduction: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const me = useAtomValue(meAtom);
  const queryClient = useQueryClient();

  const { putProperty } = usePutProperty();
  const { openSnackbar } = useSnackbar();

  const { handleOpenSubElement } = useSubElement();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      self_introduction: me.property.self_introduction ?? ''
    },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const onSave = useCallback(async (value: { [key: string]: string | number | null } | string[]) => {
    await putProperty({
      user_property: Array.isArray(value) ? {} : { ...value }
    });
    await queryClient.invalidateQueries(meKeys.all);

    openSnackbar({
      type: 'toast',
      text: '保存しました。',
      bottom: 100
    });
  }, []);

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    onSave(formData);
  };

  const [dataInput, setDataInput] = useState(false);
  const inputRefs = [useRef<HTMLTextAreaElement>(null)];

  const handleClick = () => {
    setDataInput(true);
  };

  const handleBlur = () => {
    // すべての入力欄がフォーカスされていないかチェック
    const anyFocused = inputRefs.some((ref) => ref.current === document.activeElement);
    if (!anyFocused) {
      setDataInput(false);
    }
  };

  return (
    <div className={account.submodal}>
      <motion.div
        initial='right'
        animate='enter'
        exit='right'
        variants={slideVariants}
        className={account['submodal-wrapper']}
      >
        <div className={account['submodal-header']}>
          <div className={account['submodal-header-inner']}>
            <button
              type='button'
              onClick={onClose}
              className={account['submodal-header-back-button']}
              aria-label='close'
            />
            <p className={account['submodal-header-title']}>自己紹介</p>
          </div>
        </div>

        <div className={clsx(account['submodal-contents'], styles['submodal-contents'])}>
          <div className={clsx(styles['between-wrapper'], styles['submodal-inner'])} data-input={dataInput}>
            <div>
              <TextareaInput
                id='selfIntroduction'
                value={watch('self_introduction')}
                register={register('self_introduction')}
                placeholder='自己紹介'
                annotation={'self_introduction' in { ...errors } ? errors.self_introduction?.message : ''}
                rows={12}
                count={1000}
                isInvalid={'self_introduction' in { ...errors }}
                inputRef={inputRefs[0]}
                onClick={handleClick}
                onBlur={handleBlur}
              />
              <button
                type='button'
                className={styles['self-introduction-annotation']}
                onClick={() => handleOpenSubElement('selfIntroductionLp', parentName)}
              >
                押さえておきたい自己紹介のポイント
              </button>
            </div>
            <div className={styles['adjust-button']} data-input={dataInput}>
              <Button color='black' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                保存
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
});
