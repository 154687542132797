import { memo } from 'react';
import clsx from 'clsx';
import { OnlineStatus } from '@/components/styles/projects/OnlineStatus';
import styles from '@/styles/projects/userImg.module.scss';

type Props = {
  src: string;
  id?: number;
  onClick?: (id: number) => void;
  size?: number;
  lastActionTime?: string;
  noWrap?: boolean;
  isNew?: boolean;
  isRoyal?: boolean;
  isWarnig?: boolean;
};

export const UserImg: React.FC<Props> = memo((props) => {
  const { src, id, onClick, size = 72, lastActionTime, noWrap = false, isNew, isRoyal, isWarnig } = props;

  return (
    <div
      className={clsx(styles.wrapper, noWrap && styles['no-wrap'])}
      data-royal={isRoyal}
      data-small={size < 48}
      data-medium={size < 64}
      data-warning={isWarnig}
    >
      {id && onClick ? (
        <button type='button' onClick={() => onClick(id)}>
          <img src={src} alt='' className={styles.img} width={size} height={size} />
          {isNew && !isWarnig && <span className={styles['new-label']}>NEW</span>}
          {lastActionTime && (
            <span className={styles['status-wrapper']}>
              <OnlineStatus time={lastActionTime} />
            </span>
          )}
        </button>
      ) : (
        <>
          <img src={src} alt='' className={styles.img} width={size} height={size} />
          {isNew && !isWarnig && <span className={styles['new-label']}>NEW</span>}
          {lastActionTime && (
            <span className={styles['status-wrapper']}>
              <OnlineStatus time={lastActionTime} />
            </span>
          )}
        </>
      )}
    </div>
  );
});
