import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { client } from '@/apis/client';
import { meKeys } from '@/apis/queryKeys';
import { useSnackbar } from '@/functions/hooks';

const params = {
  category: 'royal_option',
  content: 'royal_option'
};

type Response = {
  success: 1;
};

const api = (): Promise<Response> => {
  return client.put('v1/subscription', params);
};

/**
 * ロイヤルオプションを解約する
 */

export const usePutUnsubscribeRoyal = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const putUnsubscribeRoyal = useCallback(async () => {
    try {
      await api();

      queryClient.invalidateQueries(meKeys.all);
      openSnackbar({
        type: 'popup',
        title: '解約が完了しました。',
        text: 'またのご利用をお待ちしております。'
      });
    } catch {
      openSnackbar({
        type: 'popup',
        title: '解約に失敗しました。',
        text: 'エラーにより解約が完了しませんでした。再度お試しください。',
        level: 'error'
      });
    }
  }, []);

  return { putUnsubscribeRoyal };
};
