import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { client } from '@/apis/client';
import { meKeys } from '@/apis/queryKeys';
import { animateDuration } from '@/functions/constants/framerMotion';
import { useBasicModal, useSnackbar } from '@/functions/hooks';

type Params = {
  token: string;
};

type Response = {
  success: 1;
  point_granted: boolean;
  message: string;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/phone_auth', params);
};

type Props = Params;

/**
 * 電話番号認証 成功
 */

export const usePutPhoneAuth = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { phoneVerifyGrantPointModal } = useBasicModal();

  const putPhoneAuth = useCallback(async (props: Props) => {
    try {
      const { point_granted: pointGranted, message } = await api(props);
      queryClient.invalidateQueries(meKeys.all);

      setTimeout(() => {
        if (pointGranted) {
          phoneVerifyGrantPointModal();
        } else {
          openSnackbar({
            type: 'toast',
            text: message
          });
        }
      }, animateDuration);
    } catch (err) {
      openSnackbar({
        type: 'toast',
        text: '認証コードが一致しません。\n再度お試しください。',
        level: 'error'
      });

      throw err;
    }
  }, []);

  return { putPhoneAuth };
};
