import { useCallback } from 'react';
import { client } from '@/apis/client';

type Question = {
  content: string;
  other_text: string;
  require: boolean;
};

type Params = {
  questions: Question[];
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post('v1/me/questions', params);
};

type Props = Params;

/**
 * 質問付きいいね!の設定
 */

export const usePostQuestions = () => {
  const postQuestions = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postQuestions };
};
