import { memo, useState } from 'react';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { Button } from '@/components/styles/uis/Button';
import { formatDateRange } from '@/functions/helpers';
import { Appointment } from '@/functions/types/chatRoom';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/appointmentConfirmModal.module.scss';

type Props = {
  appointment: Appointment;
  isOpen: boolean;
  onClose: () => void;
  onCancel: (appointmentId: number) => Promise<void>;
};

export const AppointmentConfirmModal: React.FC<Props> = memo((props) => {
  const { appointment, isOpen, onClose, onCancel } = props;

  const [isConfirm, setIsConfirm] = useState(false);

  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>お約束の確認</div>
        <div className={styles.block}>
          {appointment.state === 'pending' && (
            <span className={styles.label} data-status='pending'>
              約束確認待ち
            </span>
          )}
          {appointment.state === 'agreed' && (
            <span className={styles.label} data-status='agreed'>
              約束済み
            </span>
          )}
          <p data-icon='calendar'>{formatDateRange(appointment.start_at, appointment.end_at)}</p>
          <p data-icon='map'>{appointment.place}</p>
        </div>
        <div className={components['mt-gutter']}>
          <div className={styles['button-wrapper']}>
            <Button color='white' size='min' onClick={onClose}>
              閉じる
            </Button>
            <Button color='red' size='min' onClick={() => (isConfirm ? onCancel(appointment.id) : setIsConfirm(true))}>
              {isConfirm ? 'キャンセルを確定' : 'お約束をキャンセル'}
            </Button>
          </div>
          {isConfirm && (
            <div className={styles.caution}>
              <p>お約束のキャンセルはお相手の了承を得てから行ってください。確定しますか？</p>
            </div>
          )}
        </div>
      </div>
    </BasicModal>
  );
});
