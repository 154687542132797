import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { client } from '@/apis/client';
import { chanceTimeAtom } from '@/contexts/atoms/chanceTime';

type Response = {
  id: number;
  counter: number;
  max_counter: number;
  end_at: string;
  just_started: boolean;
  just_completed: boolean;
};

const api = (): Promise<Response> => {
  return client.post(`v1/chance_times/state`);
};

/**
 * チャンスタイムの状態取得
 */

export const usePostState = () => {
  const setChanceTime = useSetAtom(chanceTimeAtom);

  const postState = useCallback(async () => {
    const res = await api();

    setChanceTime(res);

    return res;
  }, []);

  return { postState };
};
