import { memo, useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { usePostBlocks, usePostHide, usePostIdTalks } from '@/apis';
import Ban from '@/assets/icons/ban.svg';
import Edit from '@/assets/icons/edit.svg';
import Flag from '@/assets/icons/flag.svg';
import UnVisible from '@/assets/icons/un-visible.svg';
import { FaceweightModal } from '@/components/features/modal/FaceweightModal';
import { NicknameModal } from '@/components/features/modal/NicknameModal';
import { ReportModal } from '@/components/features/modal/ReportModal';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { meAtom } from '@/contexts/atoms/me';
import { animateDuration } from '@/functions/constants/framerMotion';
import { useBasicModal, useDisclosure, useSnackbar } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/menuModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  nickname: string;
  attachedName?: string;
  isAppointment?: boolean;
  onClosePage: () => void;
  userImageId?: number | null;
  faceweight?: number;
  refetch: () => void;

  // NOTE: チャットページで使用
  chatRoomId?: number;
  snackbarBottom?: number;
};

export const MenuModal: React.FC<Props> = memo((props) => {
  const {
    isOpen,
    onClose,
    onClosePage,
    userId,
    nickname,
    attachedName,
    isAppointment,
    userImageId,
    faceweight,
    chatRoomId,
    refetch,
    snackbarBottom = 152
  } = props;

  const me = useAtomValue(meAtom);

  const nicknameModal = useDisclosure();
  const reportModal = useDisclosure();
  const faceweightModal = useDisclosure();

  const { updateUsersCache } = useCache();

  const { openSnackbar } = useSnackbar();
  const { onCloseModal, commonModal } = useBasicModal();

  const { postIdTalks } = usePostIdTalks();
  const { postBlocks } = usePostBlocks();
  const { postHide } = usePostHide();

  const handleOpenNicknameModal = useCallback(() => {
    onClose();
    setTimeout(() => {
      nicknameModal.open();
    }, animateDuration);
  }, []);

  const handleOpenReportModal = useCallback(() => {
    onClose();
    setTimeout(() => {
      reportModal.open();
    }, animateDuration);
  }, []);

  const handleOpenFaceweightModal = useCallback(() => {
    onClose();
    setTimeout(() => {
      faceweightModal.open();
    }, animateDuration);
  }, []);

  const onSubmitBlock = useCallback(async () => {
    await postBlocks({ userId });

    updateUsersCache(userId, 'block');

    openSnackbar({
      type: 'toast',
      text: 'ブロックしました。',
      bottom: snackbarBottom
    });

    onClose();
    onCloseModal();
    setTimeout(() => {
      onClosePage();
    }, animateDuration);
  }, [attachedName, nickname]);

  const handleClickBlock = useCallback(() => {
    commonModal({
      title: 'ブロックしますか？',
      text: 'ブロックすると、お互いのプロフィールやメッセージのやり取りが確認できなくなります。',
      onCloseLabel: 'キャンセル',
      onClickLabel: 'ブロック',
      onClick: onSubmitBlock
    });
  }, [attachedName, nickname]);

  const onSubmitHide = useCallback(async () => {
    const chatRoomId = await postIdTalks({
      id: userId,
      body: {
        like_from: 'setting-hide'
      }
    });

    await postHide({ chatRoomId });

    updateUsersCache(userId, 'hide');

    openSnackbar({
      type: 'toast',
      text: '非表示にしました。',
      bottom: snackbarBottom
    });

    onClose();
    onCloseModal();
    setTimeout(() => {
      onClosePage();
    }, animateDuration);
  }, [attachedName, nickname]);

  const handleClickHide = useCallback(() => {
    commonModal({
      title: '非表示にしますか？',
      text: '非表示にすると、こちらのお相手があなたの画面上に表示されなくなります。',
      onCloseLabel: 'キャンセル',
      onClickLabel: '非表示',
      onClick: onSubmitHide
    });
  }, [attachedName, nickname]);

  return (
    <>
      <HalfModal id='menuModal' isOpen={isOpen} onClose={onClose} title='メニュー' isInner>
        <div className={components['gap-wrapper']}>
          {chatRoomId && (
            <Button color='white' icon={Edit} onClick={handleOpenNicknameModal}>
              ニックネームを変更
            </Button>
          )}
          <Button color='white' icon={Flag} onClick={handleOpenReportModal}>
            通報
          </Button>
          <div>
            <Button color='white' icon={Ban} onClick={handleClickBlock} disabled={isAppointment}>
              ブロック
            </Button>
            {isAppointment && <p className={styles['error-text']}>お約束があるお相手のブロックはできません。</p>}
          </div>
          <div>
            <Button color='white' icon={UnVisible} onClick={handleClickHide} disabled={isAppointment}>
              非表示
            </Button>
            {isAppointment && <p className={styles['error-text']}>お約束があるお相手の非表示はできません。</p>}
          </div>
          <Button color='clear' onClick={onClose}>
            <span className={components['black-text']}>キャンセル</span>
          </Button>

          {me.patch_status === 'admin' && userImageId !== null && (
            <>
              <span className={styles['admin-border']} />
              <p className={components['basic-text']}>Admin機能</p>
              <Button color='white' onClick={handleOpenFaceweightModal}>
                Faceweight設定（現在{typeof faceweight === 'number' ? faceweight : ' - '}）
              </Button>
            </>
          )}
        </div>
      </HalfModal>

      {chatRoomId && refetch && (
        <NicknameModal
          isOpen={nicknameModal.isOpen}
          onClose={nicknameModal.close}
          chatRoomId={chatRoomId}
          nickname={nickname}
          attachedName={attachedName}
          refetchChat={refetch}
        />
      )}

      <ReportModal
        isOpen={reportModal.isOpen}
        onClose={reportModal.close}
        onClosePage={onClosePage}
        userId={userId}
        shouldUpdateUsersCache
        snackbarBottom={snackbarBottom}
      />

      {me.patch_status === 'admin' && userImageId !== null && (
        <FaceweightModal
          isOpen={faceweightModal.isOpen}
          onClose={faceweightModal.close}
          userId={userId}
          userImageId={Number(userImageId)}
          faceweight={Number(faceweight)}
          refetch={refetch}
        />
      )}
    </>
  );
});
