import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { matchModalShownAtom } from '@/contexts/atoms/matchModal';

export const useMatchModal = () => {
  const [matchModalShown, setMatchModalShown] = useAtom(matchModalShownAtom);

  /**
   * It's Match 画面を表示するかどうかを返します
   * フラグをローカルストレージに保存して永続化しています
   * @param id - 該当ユーザーのID
   * @returns - 表示するかどうか
   */
  const isShownMatchModal = useCallback(
    (id: number) => {
      return matchModalShown[id] ?? true;
    },
    [matchModalShown]
  );

  /**
   * It's Match 画面を表示するかどうかを設定します
   * @param id - 該当ユーザーのID
   * @param value - 表示するかどうか（true: 表示する, false: 表示しない）
   */
  const setShownMatchModal = useCallback((id: number, value: boolean) => {
    setMatchModalShown((oldValue) => ({ ...oldValue, [id]: value }));
  }, []);

  return {
    isShownMatchModal,
    setShownMatchModal
  };
};
