import { memo } from 'react';
import styles from '@/styles/uis/sendSubmitButton.module.scss';

type ElementProps = JSX.IntrinsicElements['button'];

export const SendSubmitButton: React.FC<ElementProps> = memo((_props) => {
  const { onSubmit, ...props } = _props;

  return <button type='button' className={styles.button} aria-label='submit' onClick={onSubmit} {...props} />;
});
