import * as z from 'zod';

// NOTE: メールアドレス
export const emailSchema = z.string().email('@や.を含む正しい形式で入力してください').nonempty();

// NOTE: 電話番号
export const phoneSchema = z
  .string()
  .min(10, '有効な番号を入力してください')
  .max(12, '有効な番号を入力してください')
  .refine((v) => {
    return v.charAt(0) === '0';
  }, '有効な番号を入力してください')
  .refine((v) => {
    return !v.includes('-');
  }, 'ハイフンなしで入力してください')
  .refine((v) => {
    const regex = /^\d+$/;
    return regex.test(v);
  }, '数字で入力してください');

// NOTE: ニックネーム
export const nicknameSchema = z
  .string()
  .min(2, '2〜12文字で入力してください')
  .max(12, '2〜12文字で入力してください')
  .nonempty();

// NOTE: 会えるエリア
export const meetupLocationSchema = z.record(z.array(z.record(z.string())));
