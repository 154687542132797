import { memo } from 'react';
import styles from '@/styles/uis/select.module.scss';

type ElementProps = JSX.IntrinsicElements['button'];

type Props = {
  value?: string | number;
  placeholder?: string;
  isInvalid?: boolean;
} & ElementProps;

export const Select: React.FC<Props> = memo((_props) => {
  const { value, placeholder, isInvalid, ...props } = _props;

  return (
    <button type='button' className={styles.wrapper} data-select={!!value} data-invalid={isInvalid} {...props}>
      <span className={styles.placeholder}>{placeholder}</span>
      {value}
    </button>
  );
});
