import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  id: number;
  body: {
    note: string;
  };
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/chat_rooms/${params.id}/note`, params.body);
};

type Props = Params;

/**
 * チャットルームのメモ設定
 */

export const usePutNote = () => {
  const { openSnackbar } = useSnackbar();

  const putNote = useCallback(async (props: Props) => {
    await api(props);

    openSnackbar({
      type: 'toast',
      text: 'メモを保存しました。',
      bottom: 152
    });
  }, []);

  return { putNote };
};
