import { memo, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { TrackPageView } from '@/analytics/TrackPageView';
import { usePostContacts } from '@/apis';
import Send from '@/assets/icons/send.svg';
import { RadioPropertyModal } from '@/components/features/modal/RadioPropertyModal';
import { Button } from '@/components/styles/uis/Button';
import { FullModal } from '@/components/styles/uis/FullModal';
import { Select } from '@/components/styles/uis/Select';
import { TextareaInput } from '@/components/styles/uis/TextareaInput';
import { TextInput } from '@/components/styles/uis/TextInput';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { contactTemplates } from '@/functions/constants/contactTemplates';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { getObjValue } from '@/functions/helpers';
import { useDisclosure, useSnackbar, useBasicModal, useResignModal, useSubElement } from '@/functions/hooks';
import { emailSchema, phoneSchema } from '@/functions/schemas/common';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/contactModal.module.scss';

const schema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('phone'),
    contact_category: z.string().nonempty(),
    title: z.string().nonempty(),
    content: z.string().nonempty(),
    email: emailSchema,
    phone: phoneSchema
  }),
  z.object({
    type: z.literal('resign'),
    contact_category: z.string(),
    title: z.string(),
    content: z.string(),
    email: z.string(),
    phone: z.string()
  }),
  z.object({
    type: z.literal('other'),
    contact_category: z.string().nonempty(),
    title: z.string().nonempty(),
    content: z.string().nonempty(),
    email: emailSchema,
    phone: z.string()
  })
]);

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ConfigContactModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  const [step, setStep] = useState(1);
  const radioPropertyModal = useDisclosure();

  const { openSnackbar } = useSnackbar();
  const { onCloseModal, commonModal } = useBasicModal();
  const { handleCloseSubElement } = useSubElement();
  const { handleOpenResignModal } = useResignModal();

  const masterTypes = useAtomValue(masterTypesAtom);

  const { postContacts } = usePostContacts();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      type: 'other',
      contact_category: '',
      title: '',
      content: '',
      email: '',
      phone: ''
    },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    await postContacts(formData);

    onClose();
    openSnackbar({
      type: 'toast',
      text: 'お問い合わせを送信しました。'
    });
    setStep(1);
  };

  const onChangeCategory = useCallback((val: { contact_member_categories: string }) => {
    const value = val.contact_member_categories;

    radioPropertyModal.close();
    setValue('contact_category', value);

    if (value === 'phone') {
      setValue('type', 'phone');
    } else if (value === 'resign') {
      setValue('type', 'resign');
    } else {
      setValue('type', 'other');
    }

    if (value === 'feature') {
      setValue('content', contactTemplates.feature);
    } else if (value === 'irrelevant_user') {
      setValue('content', contactTemplates.user);
    } else {
      setValue('content', '');
    }
  }, []);

  const openResignModal = useCallback(() => {
    onCloseModal();
    onClose();
    setTimeout(() => {
      handleOpenResignModal();
    }, animateDuration);
    setTimeout(() => {
      handleCloseSubElement('second');
    }, animateDuration * 2);
  }, []);

  const handleClickResign = useCallback(() => {
    commonModal({
      title: '退会手続きへ移動します。よろしいですか？',
      onClickLabel: '退会手続きへ',
      onClick: openResignModal
    });
  }, []);

  return (
    <>
      <FullModal id='configContact' isOpen={isOpen} onClose={onClose}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles['header-inner']}>
              <button
                type='button'
                className={styles['close-button']}
                onClick={step === 1 ? onClose : () => setStep(1)}
                aria-label='close'
                data-prev={step !== 1}
              />
              <p>{step === 1 ? 'お問い合わせ' : 'お問い合わせ内容確認'}</p>
            </div>
          </div>

          <AnimatePresence mode='wait' initial={false}>
            {step === 1 && (
              <>
                <TrackPageView viewName='inquiry_top' />

                <motion.div key='step1' initial='left' animate='enter' exit='left' variants={slideVariants}>
                  <div className={styles.inner}>
                    <ul className={styles.items}>
                      <li>
                        <Select
                          value={getObjValue(masterTypes.contact_member_categories, getValues('contact_category'))}
                          onClick={radioPropertyModal.open}
                          placeholder='お問い合わせカテゴリ'
                        />
                      </li>
                      {getValues('type') !== 'resign' && (
                        <>
                          <li>
                            <TextInput
                              value={watch('email')}
                              register={register('email')}
                              placeholder='メールアドレス'
                              annotation={'email' in { ...errors } ? errors.email?.message : ''}
                              isInvalid={'email' in { ...errors }}
                            />
                          </li>
                          {getValues('type') === 'phone' && (
                            <li>
                              <TextInput
                                value={watch('phone') ?? ''}
                                register={register('phone')}
                                placeholder='登録したい電話番号'
                                annotation={'phone' in { ...errors } ? errors.phone?.message : ''}
                                isInvalid={'phone' in { ...errors }}
                              />
                            </li>
                          )}
                          <li>
                            <TextInput
                              value={watch('title')}
                              register={register('title')}
                              placeholder='お問い合わせタイトル'
                            />
                          </li>
                          <li>
                            <TextareaInput
                              value={watch('content')}
                              register={register('content')}
                              placeholder='お問い合わせ内容'
                              rows={10}
                            />
                          </li>
                        </>
                      )}
                    </ul>
                    <div className={styles['button-wrapper']}>
                      {getValues('type') === 'resign' ? (
                        <Button color='black' onClick={handleClickResign}>
                          退会手続きへ
                        </Button>
                      ) : (
                        <Button color='black' onClick={() => setStep(2)} disabled={!isValid}>
                          内容の確認へ
                        </Button>
                      )}
                    </div>
                  </div>
                </motion.div>
              </>
            )}

            {step === 2 && (
              <>
                <TrackPageView viewName='inquiry_preview' />

                <motion.div key='step2' initial='right' animate='enter' exit='right' variants={slideVariants}>
                  <div className={styles.inner}>
                    <ul>
                      <li className={styles['confirm-item']}>
                        <p className={styles['confirm-item-title']}>お問い合わせカテゴリ</p>
                        {/* @ts-ignore */}
                        <p>{getObjValue(masterTypes.contact_member_categories, getValues('contact_category'))}</p>
                      </li>
                      <li className={styles['confirm-item']}>
                        <p className={styles['confirm-item-title']}>メールアドレス</p>
                        <p className={styles['confirm-item-value']}>{getValues('email')}</p>
                      </li>
                      {getValues('type') === 'phone' && (
                        <li className={styles['confirm-item']}>
                          <p className={styles['confirm-item-title']}>登録したい電話番号</p>
                          <p className={styles['confirm-item-value']}>{getValues('phone')}</p>
                        </li>
                      )}
                      <li className={styles['confirm-item']}>
                        <p className={styles['confirm-item-title']}>お問い合わせタイトル</p>
                        <p className={styles['confirm-item-value']}>{getValues('title')}</p>
                      </li>
                      <li className={styles['confirm-item']}>
                        <p className={styles['confirm-item-title']}>お問い合わせ内容</p>
                        <p className={styles['confirm-item-value']}>{getValues('content')}</p>
                      </li>
                    </ul>

                    <div className={clsx(styles['button-wrapper'], components['row-button-wrapper'])}>
                      <Button color='white' onClick={() => setStep(1)}>
                        修正する
                      </Button>
                      <Button color='black' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                        <div className={styles['send-button-label']}>
                          <img src={Send} alt='送信' />
                          送信
                        </div>
                      </Button>
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </div>
      </FullModal>

      <RadioPropertyModal
        isOpen={radioPropertyModal.isOpen}
        onClose={radioPropertyModal.close}
        target='contact_member_categories'
        value={getObjValue(masterTypes.contact_member_categories, getValues('contact_category'))}
        onSave={(v) => onChangeCategory(v as { contact_member_categories: string })}
        submitLabel='決定'
      />
    </>
  );
});
