import { ChangeEvent, memo, useRef } from 'react';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { SendSubmitButton } from '@/components/styles/uis/SendSubmitButton';
import styles from '@/styles/uis/messageInput.module.scss';

type ElementProps = JSX.IntrinsicElements['textarea'];

type Props = {
  onSubmit: () => void;
  isLoading?: boolean;
  placeholder?: string;
  annotation?: string;
  count?: number;
  afterFocus?: boolean;
} & ElementProps;

export const MessageInput: React.FC<Props> = memo((_props) => {
  const { id, onChange, onSubmit, isLoading, placeholder, annotation, count, disabled, afterFocus, ...props } = _props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }

    // テキストエリアの高さを自動調整
    // maxHeightを設定しているため、scrollHeightがmaxHeightを超える場合はmaxHeightに固定
    textareaRef.current!.style.height = 'auto';
    textareaRef.current!.style.height = `${textareaRef.current!.scrollHeight}px`;
  };

  const onSubmitAndFocus = () => {
    onSubmit();

    if (afterFocus) {
      textareaRef.current!.focus();
    }

    // テキストエリアの高さをリセット
    textareaRef.current!.style.height = 'auto';
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <textarea
          id={id}
          ref={textareaRef}
          className={styles.textarea}
          rows={1}
          onChange={(e) => onChangeValue(e)}
          placeholder={placeholder}
          data-loading={!afterFocus && isLoading}
          {...props}
        />
        {isLoading ? (
          <div className={styles['loading-wrapper']}>
            <LoadingSpinner isSmall />
          </div>
        ) : (
          <SendSubmitButton onSubmit={onSubmitAndFocus} disabled={disabled} />
        )}
      </div>
      {annotation && (
        <div className={styles['annotation-wrapper']}>
          <span>{annotation}</span>
          {count && (
            <span>
              {String(props.value).length}/{count}
            </span>
          )}
        </div>
      )}
    </div>
  );
});
