import { memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import { AccountCoaching } from '@/components/features/coaching/AccountCoaching';
import { BoardCoaching } from '@/components/features/coaching/BoardCoaching';
import { HomeCoaching } from '@/components/features/coaching/HomeCoaching';
import { LikeCoaching } from '@/components/features/coaching/LikeCoaching';
import { MessageCoaching } from '@/components/features/coaching/MessageCoaching';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useDisclosure, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/config.module.scss';

const parentName = 'howTo';

type TargetType = 'home' | 'board' | 'like' | 'message' | 'account' | 'howToLp';

type Item = {
  target: TargetType;
  label: string;
};

const items: Item[] = [
  {
    target: 'home',
    label: 'ホームの使い方'
  },
  {
    target: 'board',
    label: '募集の使い方'
  },
  {
    target: 'like',
    label: 'いいね!の使い方'
  },
  {
    target: 'message',
    label: 'メッセージの使い方'
  },
  {
    target: 'account',
    label: 'アカウントの使い方'
  },
  {
    target: 'howToLp',
    label: 'paddyのご利用が初めての方へ'
  }
];

type Props = {
  onClose: () => void;
};

export const Howto: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const homeCoaching = useDisclosure();
  const boardCoaching = useDisclosure();
  const likeCoaching = useDisclosure();
  const messageCoaching = useDisclosure();
  const accountCoaching = useDisclosure();

  const { parentElements, handleOpenSubElement } = useSubElement();

  const handleClickButton = useCallback((target: TargetType) => {
    if (target === 'home') {
      homeCoaching.open();
    } else if (target === 'board') {
      boardCoaching.open();
    } else if (target === 'like') {
      likeCoaching.open();
    } else if (target === 'message') {
      messageCoaching.open();
    } else if (target === 'account') {
      accountCoaching.open();
    } else if (target === 'howToLp') {
      handleOpenSubElement('howToLp', parentName);
    }
  }, []);

  return (
    <>
      <TrackPageView viewName='how_to_use_paddy_top' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>paddyの使い方</p>
            </div>
          </div>

          <div className={components['parent-wrapper']} data-children-open={parentElements.third === parentName}>
            <div className={account['submodal-contents']}>
              <div className={styles.inner}>
                <ul className={styles['section-list']}>
                  {items.map(({ target, label }) => (
                    <li key={target}>
                      <button type='button' onClick={() => handleClickButton(target)}>
                        {label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>

        <HomeCoaching isOpen={homeCoaching.isOpen} onClose={homeCoaching.close} isMenu />
        <BoardCoaching isOpen={boardCoaching.isOpen} onClose={boardCoaching.close} isMenu />
        <LikeCoaching isOpen={likeCoaching.isOpen} onClose={likeCoaching.close} isMenu />
        <MessageCoaching isOpen={messageCoaching.isOpen} onClose={messageCoaching.close} isMenu />
        <AccountCoaching isOpen={accountCoaching.isOpen} onClose={accountCoaching.close} isMenu />
      </div>
    </>
  );
});
