import { useCallback } from 'react';
import { client } from '@/apis/client';
import { UsersShow } from '@/functions/types/usersShow';

type Params = {
  id: number;
};

type Response = UsersShow;

const api = (params: Params): Promise<Response> => {
  return client.get(`v1/users/${params.id}`);
};

type Props = Params;

/**
 * ユーザー詳細
 */

export const useGetUsers = () => {
  const fetchUser = useCallback(async (props: Props) => {
    const res = await api(props);

    return res;
  }, []);

  return { fetchUser };
};
