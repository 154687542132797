import { ReactNode, useCallback } from 'react';
import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';
import RichHeart from '@/assets/icons/heart_rich.svg';
import PhoneComplete from '@/assets/img_phone_complete.png';
import PhoneVerify from '@/assets/img_phone_verify.png';
import AppointmentPremiumGuidance from '@/assets/img_premium_guidance_appointment.png';
import BoardPremiumGuidance from '@/assets/img_premium_guidance_board.png';
import HomePremiumGuidance from '@/assets/img_premium_guidance_home.png';
import MessagePremiumGuidance from '@/assets/img_premium_guidance_mesage.png';
import ShowPremiumGuidanceLike from '@/assets/img_premium_guidance_show_like.png';
import ShowPremiumGuidanceRichMessage from '@/assets/img_premium_guidance_show_message.png';
import ShowPremiumGuidanceRichLike from '@/assets/img_premium_guidance_show_rich_like.png';
import MessageRoyalGuidance from '@/assets/img_roya_guidance_message.png';
import HomeRoyalGuidance from '@/assets/img_royal_guidance_home.png';
import { Button } from '@/components/styles/uis/Button';
import { basicModalAtom } from '@/contexts/atoms/basicModal';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration } from '@/functions/constants/framerMotion';
import { LpRouteTarget } from '@/functions/constants/subElementRoutes';
import { ViewName } from '@/functions/constants/viewEvent';
import { usePhoneVerify, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/basicModal.module.scss';

export const useBasicModal = () => {
  const setBasicModal = useSetAtom(basicModalAtom);

  const { isMale } = useAtomValue(meFlagAtom);

  const { handleOpenSubElement } = useSubElement();
  const { handleOpenPhoneVerify } = usePhoneVerify();

  const onCloseModal = () => {
    setBasicModal((val) => {
      return {
        ...val,
        isOpen: false
      };
    });
  };

  const onCloseAfterTransition = useCallback((target: LpRouteTarget) => {
    onCloseModal();
    setTimeout(() => {
      handleOpenSubElement(target);
    }, animateDuration / 2);
  }, []);

  const setBody = (body: ReactNode, viewName?: ViewName) => {
    setBasicModal({
      isOpen: true,
      onCloseModal,
      body,
      viewName
    });
  };

  type CommonModalProps = {
    title: string;
    text?: string;
    alertText?: string;
    onCloseLabel?: string;
    onClickLabel?: string;
    onClick?: () => void;
    viewName?: ViewName;
  };

  // NOTE: テキスト流し込み用のモーダル
  const commonModal = useCallback((props: CommonModalProps) => {
    const { title, text, alertText, onCloseLabel = '閉じる', onClickLabel = '決定', onClick, viewName } = props;

    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>{title}</div>
        {alertText && <p className={clsx(styles['alert-text'], components['basic-text'])}>{alertText}</p>}
        {text && <p className={components['basic-text']}>{text}</p>}
        {onClick ? (
          <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
            <Button color='white' size='min' onClick={onCloseModal}>
              {onCloseLabel}
            </Button>
            <Button color='black' size='small' onClick={onClick}>
              {onClickLabel}
            </Button>
          </div>
        ) : (
          <div className={clsx(components['align-right'], components['mt-gutter'])}>
            <Button color='black' size='small' onClick={onCloseModal}>
              {onCloseLabel}
            </Button>
          </div>
        )}
      </div>,
      viewName
    );
  }, []);

  // NOTE: 有料会員誘導モーダルのベース
  const basePremiumGuidanceModal = useCallback((img: string, onClick?: () => void) => {
    setBody(
      <>
        <div className={styles['img-wrapper']}>
          <img src={img} alt='有料会員の詳細' />
        </div>
        <div className={components['large-basic-wrapper']}>
          <div className={styles['vertical-button-wrapper']}>
            <Button color='black' onClick={() => (onClick ? onClick() : onCloseAfterTransition('premiumLp'))}>
              有料会員の詳細へ
            </Button>
            <Button color='clear' onClick={onCloseModal}>
              <span className={components['black-text']}>閉じる</span>
            </Button>
          </div>
        </div>
      </>,
      'popover_appeal_premium'
    );
  }, []);

  // NOTE: 有料会員誘導モーダル(ホーム)
  const homePremiumGuidanceModal = useCallback((onClick: () => void) => {
    basePremiumGuidanceModal(HomePremiumGuidance, onClick);
  }, []);

  // NOTE: 有料会員誘導モーダル(詳細・いいね!)
  const showPremiumGuidanceLikeModal = useCallback(() => {
    basePremiumGuidanceModal(ShowPremiumGuidanceLike);
  }, []);

  // NOTE: 有料会員誘導モーダル(詳細・リッチいいね!)
  const showPremiumGuidanceRichLikeModal = useCallback(() => {
    basePremiumGuidanceModal(ShowPremiumGuidanceRichLike);
  }, []);

  // NOTE: 有料会員誘導モーダル(詳細・メッセージ)
  const showPremiumGuidanceMessageModal = useCallback(() => {
    basePremiumGuidanceModal(ShowPremiumGuidanceRichMessage);
  }, []);

  // NOTE: 有料会員誘導モーダル(募集)
  const boardPremiumGuidanceMessageModal = useCallback(() => {
    basePremiumGuidanceModal(BoardPremiumGuidance);
  }, []);

  // NOTE: 有料会員誘導モーダル(メッセージ)
  const messagePremiumGuidanceMessageModal = useCallback(() => {
    basePremiumGuidanceModal(MessagePremiumGuidance);
  }, []);

  // NOTE: 有料会員誘導モーダル(約束)
  const appointmentPremiumGuidanceModal = useCallback(() => {
    basePremiumGuidanceModal(AppointmentPremiumGuidance);
  }, []);

  // NOTE: ロイヤル会員誘導モーダルのベース
  const baseRoyalGuidanceModal = useCallback((img: string, onClick?: () => void) => {
    setBody(
      <>
        <div className={styles['img-wrapper']}>
          <img src={img} alt='ロイヤル会員の詳細' />
        </div>
        <div className={components['large-basic-wrapper']}>
          <div className={styles['vertical-button-wrapper']}>
            <Button color='black' onClick={() => (onClick ? onClick() : onCloseAfterTransition('royalLp'))}>
              ロイヤル会員の詳細へ
            </Button>
            <Button color='clear' onClick={onCloseModal}>
              <span className={components['black-text']}>閉じる</span>
            </Button>
          </div>
        </div>
      </>,
      'popover_appeal_royal'
    );
  }, []);

  // NOTE: ロイヤル会員誘導モーダル(ホーム)
  const homeRoyalGuidanceModal = useCallback((onClick: () => void) => {
    baseRoyalGuidanceModal(HomeRoyalGuidance, onClick);
  }, []);

  // NOTE: ロイヤル会員誘導モーダル(メッセージ)
  const messageRoyalGuidanceModal = useCallback(() => {
    baseRoyalGuidanceModal(MessageRoyalGuidance);
  }, []);

  // NOTE: ポイント不足モーダル
  const pointLackModal = useCallback((text: ReactNode, point: number) => {
    const onClick = () => {
      onCloseModal();
      setTimeout(() => {
        handleOpenSubElement('pointLp');
      }, animateDuration / 2);
    };

    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>ポイントが不足しています。</div>
        <p className={components['basic-text']}>{text}</p>
        <div className={styles['point-block']}>
          <p>
            現在のPT数 <span>{point}</span>
          </p>
        </div>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            閉じる
          </Button>
          <Button color='black' size='small' onClick={onClick}>
            ポイント購入
          </Button>
        </div>
      </div>,
      'dialog_point_shortage'
    );
  }, []);

  // NOTE: リッチいいね!不足モーダル
  const richLikeLackModal = useCallback(() => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>「リッチいいね!」が不足しています。</div>
        <p className={components['basic-text']}>いいね!を送るにはリッチいいね!が必要です。</p>
        <div className={styles['point-block']}>
          <p>
            <img src={RichHeart} alt='リッチいいね!' width={20} height={20} style={{ marginRight: '8px' }} />
            リッチいいね!数 <span>0</span>
          </p>
        </div>
        <div className={clsx(components['align-right'], components['mt-gutter'])}>
          <Button color='black' size='small' onClick={onCloseModal}>
            閉じる
          </Button>
        </div>
      </div>,
      'dialog_richlike_shortage'
    );
  }, []);

  // NOTE: 電話番号認証モーダル（男性用）
  const phoneModalForMale = useCallback((onClick: () => void) => {
    setBody(
      <>
        <div className={styles['img-wrapper']}>
          <img src={PhoneVerify} alt='電話番号認証' />
        </div>
        <div className={components['large-basic-wrapper']}>
          <div className={styles['verify-recommend-block']}>
            <p>特定の機能のご利用は電話番号認証が必要となるため、今実施いただくのがおすすめです。</p>
          </div>
          <div className={styles['vertical-button-wrapper']}>
            <Button color='black' onClick={onClick}>
              電話番号認証
            </Button>
            <Button color='clear' onClick={onCloseModal}>
              <span className={components['black-text']}>閉じる</span>
            </Button>
          </div>
        </div>
      </>,
      'popover_appeal_phone_auth_start'
    );
  }, []);

  // NOTE: 電話番号認証モーダル（女性用）
  const phoneModalForFeMale = useCallback((onClick: () => void) => {
    commonModal({
      title: '電話番号認証が必要です。',
      text: '安全にご利用いただくために電話番号認証を必須としています。',
      onClickLabel: '電話番号認証',
      onClick,
      viewName: 'dialog_phone_verification'
    });
  }, []);

  // NOTE: 電話番号認証モーダル
  const phoneVerificationModal = useCallback(() => {
    const onClick = () => {
      onCloseModal();
      handleOpenPhoneVerify();
    };

    if (isMale) {
      phoneModalForMale(onClick);
    } else {
      phoneModalForFeMale(onClick);
    }
  }, []);

  // NOTE: 電話番号認証完了モーダル
  const phoneVerifyGrantPointModal = useCallback(() => {
    setBody(
      <>
        <div className={styles['img-wrapper']}>
          <img src={PhoneComplete} alt='電話番号認証完了' />
        </div>
        <div className={components['large-basic-wrapper']}>
          <p className={components['text-bold']}>電話番号認証ありがとうございました。20PTを付与しました。</p>
          <div className={components['mt-gutter']}>
            <Button color='black' onClick={onCloseModal}>
              閉じる
            </Button>
          </div>
        </div>
      </>,
      'popover_appeal_phone_auth_complete'
    );
  }, []);

  // NOTE: 電話番号認証が必要モーダル（会員ステータス用）
  const phoneVerificationModalForStatus = useCallback(() => {
    commonModal({
      title: '有料会員の加入には電話番号認証が必要です。',
      text: '安全にご利用いただくために電話番号認証を必須としています。'
    });
  }, []);

  // NOTE: 年齢確認が必要モーダル
  const ageConfirmationModal = useCallback(() => {
    commonModal({
      title: '年齢確認が必要です。',
      text: '関連法規に基づき、この機能の使用には年齢確認を必須としています。',
      onClickLabel: '年齢確認',
      onClick: () => onCloseAfterTransition('ageVerificationLp'),
      viewName: 'dialog_age_verification'
    });
  }, []);

  // NOTE: 年齢確認が必要モーダル（会員ステータス用）
  const ageConfirmationModalForStatus = useCallback(() => {
    commonModal({
      title: '有料会員の加入には年齢確認が必要です。',
      text: '関連法規に基づき、この機能の使用には年齢確認を必須としています。'
    });
  }, []);

  // NOTE: 自身が凍結されている場合のモーダル
  const isMeFreezedModal = useCallback(() => {
    commonModal({
      title: 'アカウントは停止されています。',
      text: 'ご利用中のアカウントは停止されているため一部機能が使用できません。',
      viewName: 'dialog_i_am_freezed'
    });
  }, []);

  // NOTE: 相手が凍結されている場合のモーダル
  const isUserFreezedModal = useCallback(() => {
    commonModal({
      title: 'アカウントは停止されています。',
      text: 'お相手のアカウントは停止されているため一部機能が使用できません。',
      viewName: 'dialog_opponent_is_freezed'
    });
  }, []);

  // NOTE: メッセージテンプレート削除モーダル
  const messageTemplateDeleteModal = useCallback((onClickDelete: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>削除しますか？</div>
        <p className={components['basic-text']}>一度削除すると元に戻すことはできません。よろしいですか？</p>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            キャンセル
          </Button>
          <Button color='red' size='small' onClick={onClickDelete}>
            削除
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: 募集削除モーダル
  const boardDeleteModal = useCallback((onDelete: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>削除しますか？</div>
        <p className={components['basic-text']}>一度削除すると元に戻すことはできません。よろしいですか？</p>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            キャンセル
          </Button>
          <Button color='red' size='small' onClick={onDelete}>
            削除
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: 約束提案モーダル
  const appointmentSuggestionModal = useCallback(
    (date: string, place: string, onAgree: () => void, onCancel: () => void, alertText?: string) => {
      setBody(
        <div className={components['large-basic-wrapper']}>
          <div className={components['heading-2']}>
            お相手から約束の提案があります。
            <br />
            確定しますか？
          </div>
          {alertText && <p className={clsx(styles['alert-text'], components['basic-text'])}>{alertText}</p>}
          <div className={styles['appointment-block']}>
            <p data-icon='calendar'>{date}</p>
            <p data-icon='map'>{place}</p>
          </div>
          <div className={clsx(styles['vertical-button-wrapper'], components['mt-gutter'])}>
            <Button color='black' onClick={onAgree}>
              確定
            </Button>
            <Button color='white' onClick={onCancel}>
              <span className={components['red-text']}>断る</span>
            </Button>
            <Button color='clear' onClick={onCloseModal}>
              <span className={components['gray-text']}>あとで</span>
            </Button>
          </div>
        </div>
      );
    },
    []
  );

  // NOTE: 約束確定モーダル
  const appointmentDecideModal = useCallback((img1: ReactNode, img2: ReactNode) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={styles['appointment-user-imgs']}>
          {img1}
          {img2}
        </div>
        <div className={components['heading-2']}>デートのお約束が決まりました。</div>
        <p className={components['basic-text']}>充実した時間を過ごせるようにお約束の時間や言葉遣いに配慮しましょう。</p>
        <div className={clsx(components['align-right'], components['mt-gutter'])}>
          <Button color='black' size='small' onClick={onCloseModal}>
            閉じる
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: アップロード完了モーダル
  const uploadCompleteModal = useCallback((onClose: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>画像をアップロードしました。</div>
        <p className={components['basic-text']}>
          審査結果はお知らせにてご案内いたします。完了までしばらくお待ちください。
        </p>
        <div className={clsx(components['align-right'], components['mt-gutter'])}>
          <Button color='black' size='small' onClick={onClose}>
            閉じる
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: 購読の解約モーダル
  const unsubscibePlanModal = useCallback((planName: string, onClick: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>解約を実行しますか？</div>
        <p className={components['basic-text']}>解約すると各種機能が使用できなくなります。よろしいですか？</p>
        <div className={clsx(styles['bg-gray'], components['mt-gutter'])}>
          <p>{planName}</p>
        </div>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            閉じる
          </Button>
          <Button color='red' size='small' onClick={onClick}>
            解約
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: のっとり機能モーダル
  const adminHackModal = useCallback((userId: number, userName: string, onHack: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>のっとり機能を実行しますか？</div>
        <p className={components['basic-text']}>以下のユーザーをのっとります。よろしいですか？</p>
        <div className={styles['hack-block']}>
          <span className={styles['hack-user-id']}>{userId}</span>
          <span className={styles['hack-user-name']}>{userName}</span>
        </div>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            閉じる
          </Button>
          <Button color='black' size='small' onClick={onHack}>
            実行
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: のっとり停止モーダル
  const adminStopHackModal = useCallback((userId: number, userName: string, onStopHack: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>のっとり機能を停止しますか？</div>
        <p className={components['basic-text']}>のっとりを停止します。よろしいですか？</p>
        <div className={styles['hack-block']}>
          <span className={styles['hack-user-id']}>{userId}</span>
          <span className={styles['hack-user-name']}>{userName}</span>
        </div>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            閉じる
          </Button>
          <Button color='black' size='small' onClick={onStopHack}>
            停止
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: 退会確認モーダル
  const resignConfirmModal = useCallback((onClick: () => void) => {
    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>退会を実行しますか？</div>
        <p className={components['basic-text']}>退会を実行すると元に戻すことはできません。よろしいですか？</p>
        <div className={clsx(styles['row-button-wrapper'], components['mt-gutter'])}>
          <Button color='white' size='min' onClick={onCloseModal}>
            閉じる
          </Button>
          <Button color='red' size='small' onClick={onClick}>
            退会する
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: 退会完了モーダル
  const resignCompleteModal = useCallback(() => {
    const onRedirect = () => {
      window.location.href = '/web';
    };

    setBody(
      <div className={components['large-basic-wrapper']}>
        <div className={components['heading-2']}>退会手続きが完了しました。</div>
        <p className={components['basic-text']}>
          ご利用ありがとうございました。またのご利用を心よりお待ちしております。
        </p>
        <div className={clsx(components['align-right'], components['mt-gutter'])}>
          <Button color='black' size='small' onClick={onRedirect}>
            閉じる
          </Button>
        </div>
      </div>
    );
  }, []);

  // NOTE: 年収/資産証明審査中モーダル
  const proofReviewingModal = useCallback((type: 'income' | 'asset') => {
    const title = type === 'income' ? '年収' : '資産';

    commonModal({
      title: `${title}審査中のため変更できません。`,
      text: `${title}証明を審査中のため変更できません。審査結果を今しばらくお待ちください。`
    });
  }, []);

  // NOTE: 年収/資産証明済みモーダル
  const proofCompletedModal = useCallback((type: 'income' | 'asset') => {
    const title = type === 'income' ? '年収' : '資産';

    commonModal({
      title: `${title}証明済みのため変更できません。`,
      text: '変更をご希望の場合は、運営事務局までお問い合わせください。'
    });
  }, []);

  return {
    onCloseModal,
    commonModal,
    phoneVerificationModal,
    phoneVerifyGrantPointModal,
    homePremiumGuidanceModal,
    showPremiumGuidanceLikeModal,
    showPremiumGuidanceRichLikeModal,
    showPremiumGuidanceMessageModal,
    boardPremiumGuidanceMessageModal,
    messagePremiumGuidanceMessageModal,
    appointmentPremiumGuidanceModal,
    homeRoyalGuidanceModal,
    messageRoyalGuidanceModal,
    pointLackModal,
    richLikeLackModal,
    ageConfirmationModal,
    phoneVerificationModalForStatus,
    ageConfirmationModalForStatus,
    isMeFreezedModal,
    isUserFreezedModal,
    messageTemplateDeleteModal,
    boardDeleteModal,
    appointmentSuggestionModal,
    appointmentDecideModal,
    uploadCompleteModal,
    adminHackModal,
    adminStopHackModal,
    resignConfirmModal,
    resignCompleteModal,
    unsubscibePlanModal,
    proofReviewingModal,
    proofCompletedModal
  };
};
