import * as z from 'zod';

export const boardCreateSchema = z.object({
  reserved_date: z.string().nonempty(),
  timeframe: z.string().nonempty(),
  area: z.string().nonempty(),
  area_detail: z.string().max(16, '16文字以内で入力してください'),
  purpose: z.string().nonempty(),
  free_text: z.string().max(140).nonempty(),
  hide_when_matched: z.boolean(),
  image: z.any()
});

export type Schema = z.infer<typeof boardCreateSchema>;

export const defaultValue: Schema = {
  reserved_date: '',
  timeframe: '',
  area: '',
  area_detail: '',
  purpose: '',
  free_text: '',
  hide_when_matched: false,
  image: null
};
