import { useCallback } from 'react';
import { client } from '@/apis/client';
import { ChatRoom } from '@/functions/types/chatRoom';

type Params = {
  chatRoomId: number;
};

type Response = ChatRoom;

const api = (params: Params): Promise<Response> => {
  return client.get(`v1/chat_rooms/${params.chatRoomId}`);
};

type Props = Params;

/**
 * チャットルーム詳細取得
 */

export const useGetChatRoom = () => {
  const fetchChatRoom = useCallback(async (props: Props) => {
    const res = await api(props);

    return res;
  }, []);

  return { fetchChatRoom };
};
