import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Drumroll } from '@/components/styles/uis/Drumroll';
import { zeroPadding } from '@/functions/helpers';
import styles from '@/styles/projects/timeDrumroll.module.scss';

const rowHeight = 56;

const hours = [...Array(24)].map((_, i) => zeroPadding(i, 2));
const minutes = [...Array(12)].map((_, i) => zeroPadding(i * 5, 2));

type Props = {
  time: string;
  setTime: (time: string) => void;
};

export const TimeDrumroll: React.FC<Props> = memo((props) => {
  const { time, setTime } = props;

  const [hour, setHour] = useState(time ? time.split(':')[0] : '00');
  const [minute, setMinute] = useState(time ? time.split(':')[1] : '00');

  const hourDrumroll = useRef<HTMLUListElement>(null);
  const minuteDrumRoll = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (hourDrumroll.current) {
      const index = hours.findIndex((v) => v === hour);
      hourDrumroll.current.scrollTo({ top: rowHeight * index });
    }
    if (minuteDrumRoll.current) {
      const index = minutes.findIndex((v) => v === minute);
      minuteDrumRoll.current.scrollTo({ top: rowHeight * index });
    }
  }, []);

  useEffect(() => {
    setTime([hour, minute].join(':'));
  }, [hour, minute]);

  const onScrollHourDrumroll = useCallback(() => {
    const setIndex = Math.round(hourDrumroll.current!.scrollTop / rowHeight);
    setHour(hours[setIndex]);
  }, [hour]);

  const onScrollMinuteDrumRoll = useCallback(() => {
    const setIndex = Math.round(minuteDrumRoll.current!.scrollTop / rowHeight);
    setMinute(minutes[setIndex]);
  }, [minute]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <Drumroll
            drumrollRef={hourDrumroll}
            value={time}
            options={hours}
            onScroll={onScrollHourDrumroll}
            textAlign='right'
          />
          <Drumroll
            drumrollRef={minuteDrumRoll}
            value={time}
            options={minutes}
            onScroll={onScrollMinuteDrumRoll}
            textAlign='left'
          />
        </div>
      </div>
    </div>
  );
});
