import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { phoneVerifyModalAtom } from '@/contexts/atoms/phoneVerifyModal';

export const usePhoneVerify = () => {
  const setPhoneVerify = useSetAtom(phoneVerifyModalAtom);

  const handleOpenPhoneVerify = useCallback(() => {
    setPhoneVerify({ isOpen: true });
  }, []);

  const handleClosePhoneVerify = useCallback(() => {
    setPhoneVerify({ isOpen: false });
  }, []);

  return { handleOpenPhoneVerify, handleClosePhoneVerify };
};
