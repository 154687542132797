import { memo, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useGetAdminManagedUsers } from '@/apis';
import { adminKeys } from '@/apis/queryKeys';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { LOCAL_STORAGE } from '@/functions/constants/enums';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { useBasicModal } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/admin.module.scss';

type Props = {
  onClose: () => void;
};

export const Admin: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const { onCloseModal, adminHackModal } = useBasicModal();

  const { fetchAdminManagedUsers } = useGetAdminManagedUsers();

  const { isLoading, data } = useQuery({
    queryKey: adminKeys.managedUsers(),
    queryFn: () => fetchAdminManagedUsers()
  });

  const onHack = useCallback((userId: number) => {
    onCloseModal();
    setTimeout(() => {
      localStorage.setItem(LOCAL_STORAGE.PARIV_MANAGED_USER_ID.KEY, String(userId));
      window.location.href = '/web/home';
    }, animateDuration);
  }, []);

  const handleClickUser = useCallback((userId: number, nickname: string) => {
    adminHackModal(userId, nickname, () => onHack(userId));
  }, []);

  return (
    <div className={account.submodal}>
      <motion.div
        initial='right'
        animate='enter'
        exit='right'
        variants={slideVariants}
        className={account['submodal-wrapper']}
      >
        <div className={account['submodal-header']}>
          <div className={account['submodal-header-inner']}>
            <button
              type='button'
              onClick={onClose}
              className={account['submodal-header-back-button']}
              aria-label='close'
            />
            <p className={account['submodal-header-title']}>のっとり機能</p>
          </div>
        </div>

        <div className={account['submodal-contents']}>
          {isLoading && (
            <div className={styles['center-wrapper']}>
              <LoadingSpinner />
            </div>
          )}

          {!isLoading && data?.length ? (
            <>
              <div className={styles.header}>
                <span>ユーザーID</span>
                <span>ユーザー名</span>
              </div>
              <ul>
                {data.map(({ user_id, nickname }) => (
                  <li key={user_id} className={styles.item}>
                    <button type='button' onClick={() => handleClickUser(user_id, nickname)}>
                      <span className={styles['item-user-id']}>{user_id}</span>
                      <span className={styles['item-nickname']}>{nickname}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div className={styles['center-wrapper']}>
              <p className={components['basic-text']}>のっとり可能なユーザーがいません。</p>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
});
