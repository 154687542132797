import { useCallback } from 'react';
import { client } from '@/apis/client';

type Response = {
  user_id: number;
  nickname: string;
}[];

const api = (): Promise<Response> => {
  return client.get('v1/admin_managed_users');
};

/**
 * 乗っ取りユーザーIDの取得
 */

export const useGetAdminManagedUsers = () => {
  const fetchAdminManagedUsers = useCallback(async () => {
    const res = await api();

    return res;
  }, []);

  return { fetchAdminManagedUsers };
};
