import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { resignModalAtom } from '@/contexts/atoms/resignModal';

export const useResignModal = () => {
  const setResignModal = useSetAtom(resignModalAtom);

  const handleOpenResignModal = useCallback(() => {
    setResignModal({
      isOpen: true
    });
  }, []);

  const handleCloseResignModal = useCallback(() => {
    setResignModal({
      isOpen: false
    });
  }, []);

  return { handleOpenResignModal, handleCloseResignModal };
};
