import { memo, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePutFaceweight } from '@/apis';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';

const schema = z.object({
  faceweight: z.string()
});

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  userImageId: number;
  faceweight: number;
  refetch: () => void;
};

export const FaceweightModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, userId, userImageId, faceweight, refetch } = props;

  const { putFaceweight } = usePutFaceweight();

  const {
    register,
    watch,
    handleSubmit,
    formState: { isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    values: { faceweight: faceweight ? faceweight.toString() : '' },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    await putFaceweight({ userId, userImageId, faceweight: Number(formData.faceweight) });
    onClose();
    refetch();
  };

  const faceweightOptions = useMemo(() => {
    const arr: Record<string, number> = {};

    // 数値の降順にしようとしたが構造上できなかったので書き方はそのままになっている
    for (let i = 100; i >= 0; i -= 10) {
      arr[i.toString()] = i;
    }

    return arr;
  }, []);

  return (
    <HalfModal
      id='faceweightModal'
      isOpen={isOpen}
      onClose={onClose}
      title='Faceweight設定'
      isInner
      footer={
        <Button color='black' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          変更
        </Button>
      }
    >
      <RadioGroup
        selected={watch('faceweight') === '' || watch('faceweight') === null ? faceweight : watch('faceweight')}
        options={faceweightOptions}
        register={register('faceweight')}
      />
    </HalfModal>
  );
});
