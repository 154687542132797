import axios, { AxiosError, AxiosResponse } from 'axios';
import UAParser from 'ua-parser-js';
import { v4 as uuidV4 } from 'uuid';
import { apiPath, publicPaths } from '@/functions/constants/common';
import { CLIENT_HEADER, LOCAL_STORAGE } from '@/functions/constants/enums';
import { removeLocalStorage } from '@/functions/helpers';

const generateUuid = () => {
  const uuid = uuidV4();
  localStorage.setItem(LOCAL_STORAGE.PARIV_UUID.KEY, uuid);

  return uuid;
};

const createInstance = () => {
  const parser = new UAParser();
  const instance = axios.create({
    baseURL: apiPath
  });

  instance.interceptors.request.use((request) => {
    request.headers[CLIENT_HEADER.TOKEN] = localStorage.getItem(LOCAL_STORAGE.PARIV_TOKEN.KEY) ?? '';
    request.headers[CLIENT_HEADER.UUID] = localStorage.getItem(LOCAL_STORAGE.PARIV_UUID.KEY) ?? generateUuid();
    request.headers[CLIENT_HEADER.MANAGED_USER_ID] =
      localStorage.getItem(LOCAL_STORAGE.PARIV_MANAGED_USER_ID.KEY) ?? undefined;
    request.headers[CLIENT_HEADER.APP_ID] = 'web';
    request.headers[CLIENT_HEADER.APP_VERSION] = '4.0.0';
    request.headers[CLIENT_HEADER.APP_VERSION_CODE] = '1';
    request.headers[CLIENT_HEADER.OS] = parser.getOS().name ?? '';
    request.headers[CLIENT_HEADER.OS_VERSION] = parser.getOS().version ?? '';
    request.headers[CLIENT_HEADER.MODEL] = parser.getDevice().model ?? 'pc';

    return request;
  });

  return instance;
};

export const client = createInstance();

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  (error: AxiosError) => {
    /**
     * 認証エラーの時はローカルストレージの情報を消して、/webにリダイレクトさせる
     */
    if (error.response && error.response.status === 401) {
      const currentPath = window.location.pathname.replace('/web', '');
      if (!publicPaths.includes(currentPath)) {
        removeLocalStorage('logout');
        window.location.href = '/web';
      }
    }

    return Promise.reject(error.response);
  }
);
