import { memo, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useGetPhrases } from '@/apis';
import { phraseKeys } from '@/apis/queryKeys';
import { MessageTemplateModal } from '@/components/features/modal/MessageTemplateModal';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { replaceMessageTemplates } from '@/functions/helpers';
import { useDisclosure } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/messageTemplate.module.scss';

type Props = {
  userName: string;
  setMessageText: (text: string) => void;
};

export const MessageTemplate: React.FC<Props> = memo((props) => {
  const { userName, setMessageText } = props;

  const me = useAtomValue(meAtom);
  const { isMale } = useAtomValue(meFlagAtom);

  const queryClient = useQueryClient();

  const messageTemplateModal = useDisclosure();

  const { fetchPhrases } = useGetPhrases();

  const { data } = useQuery({
    queryKey: phraseKeys.lists(),
    queryFn: () => fetchPhrases()
  });

  const messageTemplates = replaceMessageTemplates(userName, me.property.nickname);

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(phraseKeys.lists());
  }, []);

  return (
    <>
      <div className={styles['template-block']}>
        <p className={components['basic-text']}>メッセージテンプレート</p>
        <button
          type='button'
          className={styles['template-edit']}
          onClick={messageTemplateModal.open}
          aria-label='edit'
        />
        <div className={styles['template-list-wrapper']}>
          <ul className={styles['template-list']}>
            {data?.length
              ? data.map((phrase) => (
                  <li key={phrase.id}>
                    <button type='button' onClick={() => setMessageText(phrase.message)}>
                      <p>{phrase.message}</p>
                    </button>
                  </li>
                ))
              : !isMale && (
                  <li data-empty>
                    <p>テンプレートはありません</p>
                  </li>
                )}

            {isMale &&
              messageTemplates.map((v) => (
                <li key={v}>
                  <button type='button' onClick={() => setMessageText(v)}>
                    <p>{v}</p>
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <MessageTemplateModal
        isOpen={messageTemplateModal.isOpen}
        onClose={messageTemplateModal.close}
        messageTemplates={messageTemplates}
        data={data}
        refetch={refetch}
      />
    </>
  );
});
