import { memo } from 'react';
import styles from '@/styles/uis/loadingSpinner.module.scss';

type Props = {
  isSmall?: boolean;
};

export const LoadingSpinner: React.FC<Props> = memo((props) => {
  const { isSmall } = props;

  return (
    <div className={styles.wrapper}>
      {isSmall ? (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M22 12.2509C22 10.3604 21.49 8.50671 20.5264 6.8948C19.5628 5.2829 18.1831 3.97554 16.5399 3.11728C14.8967 2.25902 13.054 1.88328 11.2154 2.0316C9.37687 2.17993 7.61416 2.84654 6.12215 3.95774C4.63014 5.06894 3.46696 6.58144 2.76121 8.32803C2.05546 10.0746 1.83462 11.9872 2.12312 13.8544C2.41162 15.7217 3.19822 17.4707 4.39594 18.9083C5.59367 20.3458 7.15587 21.4158 8.90983 22L9.52787 20.0502C8.1247 19.5828 6.87494 18.7268 5.91676 17.5768C4.95857 16.4267 4.3293 15.0275 4.0985 13.5337C3.8677 12.04 4.04436 10.5099 4.60897 9.11259C5.17357 7.71533 6.10412 6.50533 7.29772 5.61637C8.49133 4.72741 9.9015 4.19412 11.3723 4.07545C12.8432 3.95679 14.3174 4.25739 15.6319 4.944C16.9465 5.63061 18.0502 6.67649 18.8211 7.96601C19.592 9.25554 20 10.7385 20 12.2509H22Z'
            fill='#1F1F1F'
          />
        </svg>
      ) : (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M37.1666 20.4178C37.1666 17.267 36.3166 14.1775 34.7106 11.491C33.1046 8.8045 30.8051 6.62558 28.0664 5.19514C25.3277 3.7647 22.2565 3.13847 19.1923 3.38568C16.128 3.63289 13.1902 4.74391 10.7035 6.59591C8.21683 8.44791 6.27819 10.9687 5.10193 13.8797C3.92568 16.7907 3.55762 19.9784 4.03845 23.0904C4.51929 26.2024 5.83028 29.1175 7.82649 31.5134C9.8227 33.9093 12.4264 35.6927 15.3496 36.6663L16.3797 33.4166C14.0411 32.6377 11.9581 31.211 10.3612 29.2943C8.76421 27.3776 7.71541 25.0455 7.33075 22.5559C6.94608 20.0663 7.24053 17.5161 8.18153 15.1873C9.12254 12.8586 10.6734 10.8419 12.6628 9.36028C14.6521 7.87868 17.0024 6.98987 19.4538 6.7921C21.9052 6.59433 24.3622 7.09532 26.5531 8.23967C28.7441 9.38402 30.5837 11.1272 31.8685 13.2764C33.1533 15.4256 33.8333 17.8971 33.8333 20.4178H37.1666Z'
            fill='#1F1F1F'
          />
        </svg>
      )}
    </div>
  );
});
