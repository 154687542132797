import { BaseSyntheticEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import {
  useGetFemaleSimilars,
  useGetMaleSimilars,
  useGetUsers,
  usePostFootPrints,
  usePostIdTalks,
  usePostState,
  usePostTalks
} from '@/apis';
import { userKeys } from '@/apis/queryKeys';
import { ReactComponent as EmptyHome } from '@/assets/icons/empty_home.svg';
import Heart from '@/assets/icons/heart_colored.svg';
import HeartQuestion from '@/assets/icons/heart_question.svg';
import RichHeart from '@/assets/icons/heart_rich.svg';
import Message from '@/assets/icons/message_colored.svg';
import { ChanceTimeCompleteModal } from '@/components/features/modal/chanceTimeModal';
import { MenuModal } from '@/components/features/modal/MenuModal';
import { MessageModal } from '@/components/features/modal/MessageModal';
import { QuestionLikeModal } from '@/components/features/modal/QuestionLikeModal';
import { ShowInfo } from '@/components/features/show/ShowInfo';
import { ShowProfile } from '@/components/features/show/ShowProfile';
import { SwipeShow } from '@/components/features/show/SwipeShow';
import { UserList } from '@/components/features/UserList';
import { ShowButton } from '@/components/styles/projects/ShowButton';
import { TabSwitch } from '@/components/styles/uis/TabSwitch';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { getUserImage } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useScrollLock, useSnackbar, useSubElement } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import { useChanceTime } from '@/functions/hooks/useChanceTime';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/show/show.module.scss';

type Props = {
  userId: number;
  onCloseShow: () => void;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  isVisibleSimilar?: boolean;
  isVisibleMenu?: boolean;
  snackbarBottom?: number;
  isVisibleLike?: boolean;
};

export const Show: React.FC<Props> = memo((props) => {
  const {
    userId,
    onCloseShow,
    onClickPrev,
    onClickNext,
    isVisibleSimilar = false,
    isVisibleMenu = true,
    snackbarBottom,
    isVisibleLike = true
  } = props;

  const {
    member,
    isMale,
    isMaleFree,
    basicPointLength,
    rickLikeLength,
    isPhoneVerify,
    isAgeConfirm,
    isBeginner,
    isFreezed
  } = useAtomValue(meFlagAtom);

  const showTabs = useMemo(() => {
    if (isVisibleSimilar) {
      return [
        {
          value: 'profile',
          label: 'プロフィール'
        },
        {
          value: 'similar',
          label: '似ているお相手'
        }
      ];
    }

    return [
      {
        value: 'profile',
        label: 'プロフィール'
      }
    ];
  }, []);

  const { openSnackbar } = useSnackbar();
  const {
    pointLackModal,
    richLikeLackModal,
    showPremiumGuidanceRichLikeModal,
    showPremiumGuidanceMessageModal,
    phoneVerificationModal,
    ageConfirmationModal,
    isMeFreezedModal
  } = useBasicModal();
  const { parentElements } = useSubElement();

  const { updateUsersCache, updatePointsCache, updateRichLikePointsCache } = useCache();

  const { fetchUser } = useGetUsers();
  const { fetchMaleSimilars } = useGetMaleSimilars();
  const { fetchFemaleSimilars } = useGetFemaleSimilars();
  const { postFootPrints } = usePostFootPrints();
  const { postIdTalks } = usePostIdTalks();
  const { postTalks } = usePostTalks();
  const { postState } = usePostState();

  const { isChanceTime } = useChanceTime();

  const [similarUserId, setSimilarUserId] = useState<number | null>(null);
  const [activeShowTab, setActiveShowTab] = useState(showTabs[0].value);
  const [isSwipeLeft, setIsSwipeLeft] = useState(true);

  const queryClient = useQueryClient();

  const menuModal = useDisclosure();
  const messageModal = useDisclosure();
  const questionModal = useDisclosure();
  const chanceTimeCompleteModal = useDisclosure();

  const { addScrollPreventElement, removeScrollPreventElement } = useScrollLock();

  useEffect(() => {
    (async () => {
      await postFootPrints({ user_id: userId });
    })();

    addScrollPreventElement('userShow');

    return () => {
      removeScrollPreventElement('userShow');
    };
  }, [userId]);

  const { data: user } = useQuery({
    queryKey: userKeys.detail(userId),
    queryFn: () => fetchUser({ id: userId })
  });

  const { data: similarUsers } = useQuery({
    queryKey: userKeys.similar(userId),
    queryFn: () => (isMale ? fetchFemaleSimilars({ id: userId }) : fetchMaleSimilars({ id: userId }))
  });

  const refetch = useCallback(async () => {
    queryClient.invalidateQueries(userKeys.detail(userId));
  }, []);

  const fetchChanceTime = useCallback(async () => {
    if (!isChanceTime) return;

    const { just_completed: justCompleted } = await postState();
    if (justCompleted) chanceTimeCompleteModal.open();
  }, [isChanceTime]);

  const handleClickMenu = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else {
      menuModal.open();
    }
  }, []);

  const handleClickMessage = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (isMaleFree) {
      showPremiumGuidanceMessageModal();
    } else if (!isChanceTime && isMale && basicPointLength === 0) {
      pointLackModal(
        <>「いいね!」を送る前にメッセージを開始するためには1PTが必要です。ポイントをご購入の上、再度お試しください。</>,
        basicPointLength
      );
    } else {
      messageModal.open();
    }
  }, [isFreezed, isPhoneVerify, isAgeConfirm, isChanceTime, member, basicPointLength]);

  const handleClickLike = useCallback(async () => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (isMale && !isPhoneVerify && !isChanceTime && basicPointLength === 0) {
      phoneVerificationModal();
    } else if (isMale && basicPointLength === 0 && !isChanceTime) {
      pointLackModal(
        <>「いいね!」を送るには1PTが必要です。ポイントをご購入の上、再度お試しください。</>,
        basicPointLength
      );
    } else {
      const chatRoomId = await postIdTalks({
        id: userId,
        body: {
          like_from: 'user_profile'
        }
      });
      await postTalks({
        chat_room_id: chatRoomId,
        content: 'like',
        stamp_id: 1
      });

      refetch();
      updateUsersCache(userId, 'like');
      updatePointsCache(1);
      fetchChanceTime();
      openSnackbar({
        type: 'toast',
        text: 'いいね!を送信しました。',
        bottom: 96
      });
    }
  }, [userId, basicPointLength, isFreezed, isPhoneVerify, isChanceTime]);

  const handleClickQuestionLike = useCallback(async () => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (!isChanceTime && isMale && basicPointLength === 0) {
      pointLackModal(
        <>「質問付きいいね!」を送るには1PTが必要です。ポイントをご購入の上、再度お試しください。</>,
        basicPointLength
      );
    } else {
      questionModal.open();
    }
  }, [userId, isFreezed, isPhoneVerify, isAgeConfirm, isChanceTime, basicPointLength]);

  const onSubmitQuestionLike = useCallback(
    async (text: string) => {
      const chatRoomId = await postIdTalks({
        id: userId,
        body: {
          like_from: 'user_profile'
        }
      });
      await postTalks({
        chat_room_id: chatRoomId,
        content: 'question',
        free_text: text
      });

      refetch();
      updateUsersCache(userId, 'like');
      updatePointsCache(1);
      fetchChanceTime();
      openSnackbar({
        type: 'toast',
        text: '質問付きいいね!を送信しました。',
        bottom: 96
      });
    },
    [isChanceTime]
  );

  const handleClickRichLike = useCallback(async () => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (!isPhoneVerify) {
      phoneVerificationModal();
    } else if (!isAgeConfirm) {
      ageConfirmationModal();
    } else if (member === 'free') {
      showPremiumGuidanceRichLikeModal();
    } else if (rickLikeLength === 0) {
      richLikeLackModal();
    } else {
      const chatRoomId = await postIdTalks({
        id: userId,
        body: {
          like_from: 'user_profile'
        }
      });
      await postTalks({
        chat_room_id: chatRoomId,
        content: 'rich_like',
        stamp_id: 5
      });

      refetch();
      updateUsersCache(userId, 'richLike');
      updateRichLikePointsCache(1);
      openSnackbar({
        type: 'toast',
        text: 'リッチいいね!を送信しました。',
        bottom: 96
      });
    }
  }, [userId, isFreezed, isPhoneVerify, isAgeConfirm, member, rickLikeLength]);

  const onChangeTab = useCallback(
    (e: BaseSyntheticEvent) => {
      const { value } = e.target;

      setActiveShowTab(value);
      if (value === 'profile') {
        setIsSwipeLeft(true);
      } else {
        setIsSwipeLeft(false);
      }
    },
    [activeShowTab]
  );

  const onClickSimilarUser = useCallback((id: number | null) => {
    setSimilarUserId(id);
  }, []);

  return (
    <>
      <ShowButton onCloseShow={onCloseShow} onClickMenu={isVisibleMenu ? handleClickMenu : undefined} />

      <div className={components['parent-wrapper']} data-children-open={parentElements.first === 'show'}>
        <ShowInfo user={user} />

        <TabSwitch name='show' tabs={showTabs} isActive={activeShowTab} onChange={onChangeTab} />

        <div className={styles['basic-panel-inner']}>
          <AnimatePresence mode='wait' initial={false} custom={isSwipeLeft}>
            {activeShowTab === 'profile' && (
              <motion.div key='profile' initial='left' animate='enter' exit='left' variants={slideVariants}>
                <ShowProfile user={user} />
              </motion.div>
            )}

            {/* NOTE: タグ実装時に追加 */}
            {/* {activeTab === 'tag' && (
              <>
                <h2 className={components['heading-1']}>タグはまだ追加されていません。</h2>
                <p className={components['basic-text']}>こちらのお相手がタグを追加すると表示されます。</p>
              </>
            )} */}

            {activeShowTab === 'similar' && (
              <motion.div key='similar' initial='right' animate='enter' exit='right' variants={slideVariants}>
                {similarUsers && similarUsers.length ? (
                  <ul className={components['basic-wrap-list']}>
                    <UserList data={similarUsers} setUserId={onClickSimilarUser} />
                  </ul>
                ) : (
                  <div className={styles['empty-state-div']}>
                    <EmptyHome />
                    <p className={clsx(components['heading-1'], components['mt-gutter'])}>
                      お相手が見つかりませんでした。
                    </p>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className={styles['float-button']}>
          {onClickPrev && (
            <button type='button' onClick={onClickPrev} className={styles['float-button-prev']} aria-label='prev' />
          )}
          {!user?.liked && isVisibleLike && (
            <div className={styles['float-button-action']}>
              {user?.questions.length ? (
                <button type='button' onClick={handleClickQuestionLike}>
                  <img src={HeartQuestion} alt='質問付きいいね!' width={32} height={32} />
                </button>
              ) : (
                <>
                  <button type='button' onClick={handleClickMessage}>
                    <img src={Message} alt='メッセージ' width={32} height={32} />
                  </button>
                  <button type='button' onClick={handleClickLike}>
                    <img src={Heart} alt='いいね!' width={32} height={32} />
                  </button>
                  {isMale && (
                    <button type='button' onClick={handleClickRichLike}>
                      <img src={RichHeart} alt='リッチいいね!' width={32} height={32} />
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          {onClickNext && (
            <button type='button' onClick={onClickNext} className={styles['float-button-next']} aria-label='next' />
          )}
        </div>
      </div>

      <MessageModal
        isOpen={messageModal.isOpen}
        onClose={messageModal.close}
        userId={userId}
        userName={user?.property.nickname ?? ''}
        refetch={refetch}
        fetchChanceTime={fetchChanceTime}
      />

      {user && user.questions && (
        <QuestionLikeModal
          isOpen={questionModal.isOpen}
          onClose={questionModal.close}
          onSubmit={onSubmitQuestionLike}
          userId={user.id}
          userName={user.property.nickname}
          userImg={getUserImage(user, isBeginner)}
          questions={user.questions}
          isMatch={false}
        />
      )}

      <MenuModal
        isOpen={menuModal.isOpen}
        onClose={menuModal.close}
        userId={userId}
        userImageId={user?.top_image.id}
        faceweight={user?.top_image.face_weight}
        nickname={user?.property.nickname!}
        isAppointment={!!user?.appointment}
        onClosePage={onCloseShow}
        snackbarBottom={snackbarBottom}
        refetch={refetch}
      />

      <ChanceTimeCompleteModal isOpen={chanceTimeCompleteModal.isOpen} onClose={chanceTimeCompleteModal.close} />

      <AnimatePresence mode='wait' initial={false}>
        {similarUsers && similarUserId && (
          <SwipeShow userId={similarUserId} userIds={similarUsers.map((v) => v.id)} setUserId={onClickSimilarUser} />
        )}
      </AnimatePresence>
    </>
  );
});
