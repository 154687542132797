import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.post(`v1/chat_rooms/${params.id}/read_all`);
};

/**
 * チャットルームのメッセージ既読
 */

type Props = {
  id: number;
};

export const usePostReadAll = () => {
  const postReadAll = useCallback(async (props: Props) => {
    const { id } = props;

    await api({ id });
  }, []);

  return { postReadAll };
};
