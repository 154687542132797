import { useCallback } from 'react';
import { client } from '@/apis/client';
import { Me } from '@/functions/types/me';

type Response = Me;

const api = (): Promise<Response> => {
  return client.get('v1/me');
};

/**
 * 自身のユーザー情報を取得する
 */

export const useGetMe = () => {
  const fetchtMe = useCallback(async () => {
    const res = await api();

    return res;
  }, []);

  return { fetchtMe };
};
