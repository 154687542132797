import { memo } from 'react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { ConfigContactModal } from '@/components/features/modal/ConfigContactModal';
import { Accordion } from '@/components/styles/uis/Accordion';
import { Button } from '@/components/styles/uis/Button';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { faqs } from '@/functions/constants/faq';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useDisclosure } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/contact.module.scss';

type Props = {
  onClose: () => void;
};

export const Contact: React.FC<Props> = memo((props) => {
  const { onClose } = props;
  const { isBeginner } = useAtomValue(meFlagAtom);

  const contactModal = useDisclosure();

  return (
    <div className={account.submodal}>
      <motion.div
        initial='right'
        animate='enter'
        exit='right'
        variants={slideVariants}
        className={account['submodal-wrapper']}
      >
        <div className={account['submodal-header']}>
          <div className={account['submodal-header-inner']}>
            <button
              type='button'
              onClick={onClose}
              className={account['submodal-header-back-button']}
              aria-label='close'
            />
            <p className={account['submodal-header-title']}>お問い合わせ</p>
          </div>
        </div>

        <div className={account['submodal-contents']}>
          <div className={styles.inner}>
            <h2 className={components['heading-1']}>お問い合わせについて</h2>
            <p className={components['basic-text']}>
              paddyをご利用いただき、誠にありがとうございます。
              <br />
              ご利用に分からない点がある場合、お問い合わせ前によくある質問をご確認ください。
            </p>
            <div className={styles.divider} />
            <h2 className={components['heading-1']}>よくある質問</h2>
            <ul className={styles['faq-list']}>
              {faqs.map((faq) => (
                <li key={faq.title}>
                  <Accordion {...faq} />
                </li>
              ))}
            </ul>
            <div className={styles['more-link']}>
              <a
                href={isBeginner ? 'https://paddy67.super.site/' : 'https://paddy.super.site/'}
                className={styles.link}
                target='_blank'
                rel='noreferrer'
              >
                その他の「よくある質問」をみる
              </a>
            </div>

            <div className={styles.divider} />
            <h2 className={components['heading-1']}>お問い合わせについて</h2>
            <p className={components['basic-text']}>
              よくある質問を確認しても解決しない場合は、以下よりお問い合わせください。
            </p>
            <p className={components['basic-text']}>⚫︎お問い合わせ受付：24時間365日</p>
            <p className={components['basic-text']}>⚫︎返信対応時間：10時〜20時</p>
            <div className={components['mt-gutter']}>
              <Button color='black' size='small' onClick={contactModal.open}>
                お問い合わせへ
              </Button>
            </div>
          </div>
        </div>
      </motion.div>

      <ConfigContactModal isOpen={contactModal.isOpen} onClose={contactModal.close} />
    </div>
  );
});
