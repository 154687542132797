import { memo } from 'react';
import { useAtomValue } from 'jotai';
import Female01 from '@/assets/coaching/message_female_01.png';
import Female02 from '@/assets/coaching/message_female_02.png';
import Female03 from '@/assets/coaching/message_female_03.png';
import Female04 from '@/assets/coaching/message_female_04.png';
import Male01 from '@/assets/coaching/message_male_01.png';
import Male02 from '@/assets/coaching/message_male_02.png';
import Male03 from '@/assets/coaching/message_male_03.png';
import Male04 from '@/assets/coaching/message_male_04.png';
import { SettingStep } from '@/components/features/coaching/SettingStep';
import { ModalSwipe } from '@/components/features/ModalSwipe';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { Button } from '@/components/styles/uis/Button';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/coaching.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isMenu?: boolean;
};

export const MessageCoaching: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, isMenu } = props;

  const stepLength = isMenu ? 4 : 5;
  const { isMale } = useAtomValue(meFlagAtom);

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='how_to_use_message'>
      <ModalSwipe stepLength={stepLength}>
        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male01 : Female01} alt='マッチしたらメッセージを送る' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>マッチしたらメッセージを送る</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>最初は、ご挨拶と簡単な自己紹介をするのがおすすめです。</p>
              <p className={components['basic-text']}>気持ちの良いやりとりのために、丁寧な言葉遣いを心がけましょう。</p>
              {isMale && <p className={components['sub-text']}>※ご利用には有料会員登録が必要です</p>}
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male02 : Female02} alt='約束機能を使って日時を決める' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>約束機能を使って日時を決める</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>会う日程が決まったら、約束機能を使って日時の合意をしましょう。</p>
              <p className={components['basic-text']}>日時が近づくと、お互いにリマインドが届くようになります。</p>
              {isMale && <p className={components['sub-text']}>※ご利用には有料会員登録が必要です</p>}
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male03 : Female03} alt='お気に入りやメモを活用' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>お気に入りやメモを活用</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                お相手をお気に入り設定したり、やりとり内容をメモで残したりすることができます。
              </p>
              <p className={components['basic-text']}>あとから見返したり、メッセージを絞り込むのに便利な機能です。</p>
              {isMale && <p className={components['sub-text']}>※メッセージのご利用には有料会員登録が必要です</p>}
            </div>
          </div>
        </li>

        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male04 : Female04} alt='並べ替えや絞り込みでより見やすく' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>並べ替えや絞り込みでより見やすく</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                たくさんのメッセージから特定のお相手を探しづらい場合は、並べ替えや絞り込みを活用しましょう。
              </p>
              <p className={components['basic-text']}>未返信のまま、埋もれているお相手がいるかもしれません。</p>
              {isMale && <p className={components['sub-text']}>※メッセージのご利用には有料会員登録が必要です</p>}
            </div>
          </div>
        </li>
        {!isMenu && <SettingStep />}
      </ModalSwipe>
      <div className={styles['button-wrapper']}>
        <Button color='black' onClick={onClose}>
          {isMenu ? '閉じる' : 'さっそく使ってみる'}
        </Button>
      </div>
    </BasicModal>
  );
});
