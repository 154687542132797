import { ReactNode, memo } from 'react';
import clsx from 'clsx';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import styles from '@/styles/uis/button.module.scss';

type ColorType = 'black' | 'white' | 'red' | 'clear' | 'pink';
type SizeType = 'small' | 'min' | 'submin';

const getColor = (color?: ColorType) => {
  switch (color) {
    case 'black':
      return styles['color-black'];
    case 'white':
      return styles['color-white'];
    case 'red':
      return styles['color-red'];
    case 'clear':
      return styles['color-clear'];
    case 'pink':
      return styles['color-pink'];
    default:
      return styles['color-default'];
  }
};

const getSize = (size?: SizeType) => {
  switch (size) {
    case 'small':
      return styles['size-small'];
    case 'min':
      return styles['size-min'];
    case 'submin':
      return styles['size-submin'];
    default:
      return styles['size-default'];
  }
};

type ElementProps = JSX.IntrinsicElements['button'];

type Props = {
  children: ReactNode;
  color?: ColorType;
  size?: SizeType;
  icon?: string;
  isLoading?: boolean;
} & ElementProps;

export const Button: React.FC<Props> = memo((_props) => {
  const { children, color, size, icon, isLoading, ...props } = _props;

  return isLoading ? (
    <button type='button' className={clsx(styles.button, getColor(color), getSize(size))} data-loading={isLoading}>
      <LoadingSpinner isSmall />
    </button>
  ) : (
    <button type='button' className={clsx(styles.button, getColor(color), getSize(size))} {...props}>
      {icon && <img className={styles['button-icon']} src={icon} alt='' width={20} height={20} />}
      {children}
    </button>
  );
});
