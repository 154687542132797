import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { DevTools } from 'jotai-devtools'
import { HelmetProvider } from 'react-helmet-async';
import { AppSetup } from '@/AppSetup';
import 'jotai-devtools/styles.css';

type Props = {
  children: ReactNode;
};

export const AppProvider: React.FC<Props> = (props) => {
  const { children } = props;

  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 20 } }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <AppSetup>
          {/* <DevTools /> */}
          {/* <ReactQueryDevtools /> */}
          {children}
        </AppSetup>
      </HelmetProvider>
    </QueryClientProvider>
  );
};
