import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

type Response = { success: 1 };

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/user_notices/${params.id}/read`);
};

type Props = Params;

/**
 * お知らせを既読にする
 */

export const usePutNoticesRead = () => {
  const readNotices = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { readNotices };
};
