import { useCallback } from 'react';
import { client } from '@/apis/client';
import { BoardData } from '@/functions/types/boardData';

type Response = {
  data: BoardData[];
  included: {
    attributes: {
      id: number;
      age: number;
      image_url: string;
      nickname: string;
      residence_location: string;
    };
    id: string;
    relationships: {};
    type: string;
  }[];
  links: {
    current: string;
    self: string;
  };
};

const api = (): Promise<Response> => {
  return client.get('v2/me/boards');
};

/**
 * 自分が作成した募集の一覧を取得
 */

export const useGetMeBoards = () => {
  const fetchMeBoards = useCallback(async () => {
    const res = await api();

    return res;
  }, []);

  return { fetchMeBoards };
};
