import { useCallback } from 'react';
import { client } from '@/apis/client';
import { UserPhrase } from '@/functions/types/phrase';

type Params = {
  phrase: {
    message: string;
    order: number;
  };
};

type Response = UserPhrase[];

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/phrases/1', params);
};

type Props = Params;

/**
 * 定型文を登録・更新する
 */

export const usePutPhrases = () => {
  const putPhrases = useCallback(async (props: Props) => {
    const res = await api(props);

    return res;
  }, []);

  return { putPhrases };
};
