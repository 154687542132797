import React, { RefObject, memo, useEffect } from 'react';
import clsx from 'clsx';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/uis/drumroll.module.scss';

type TextAlignType = 'left' | 'center' | 'right';

const getAlign = (value?: TextAlignType) => {
  switch (value) {
    case 'left':
      return components['align-left'];
    case 'center':
      return components['align-center'];
    case 'right':
      return components['align-right'];
    default:
      return '';
  }
};

type Props = {
  drumrollRef: RefObject<HTMLUListElement>;
  value?: string | number;
  options: (string | number)[];
  unit?: string;
  onScroll: () => void;
  textAlign?: TextAlignType;
};

export const Drumroll: React.FC<Props> = memo((props) => {
  const { drumrollRef, value, options, unit = '', onScroll, textAlign } = props;

  useEffect(() => {
    if (!drumrollRef.current) return;

    const items = drumrollRef.current.querySelectorAll('[data-value]');
    const target = drumrollRef.current.querySelector(`[data-value="${value}"]`);
    const index = [...items].indexOf(target!);

    drumrollRef.current.scrollTop = index * 56;
  }, []);

  return (
    <ul ref={drumrollRef} className={clsx(styles.drumroll, getAlign(textAlign))} onScroll={onScroll}>
      {options.map((option) => (
        <li key={option + unit} data-value={option}>
          {option}
          {unit}
        </li>
      ))}
    </ul>
  );
});
