import { useCallback } from 'react';
import { client } from '@/apis/client';

type Response = {
  success: 1;
};

const api = (): Promise<Response> => {
  return client.post(`v1/notices/read_all`);
};

/**
 * お知らせをすべて既読にする
 */

export const usePostNoticesReadAll = () => {
  const fetchNoticesReadAll = useCallback(async () => {
    await api();
  }, []);

  return { fetchNoticesReadAll };
};
