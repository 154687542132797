import { useCallback, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { onValue, ref, getDatabase } from 'firebase/database';
import { weekDays } from '@/functions/constants/common';
import { zeroPadding } from '@/functions/helpers';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FB_API_KEY,
  authDomain: import.meta.env.VITE_FB_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FB_DATABASE_URL,
  projectId: import.meta.env.VITE_FB_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FB_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FB_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FB_APP_ID,
  measurementId: import.meta.env.VITE_FB_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

type Message = {
  content?: string;
  created_at?: string;
  read_at?: string;
  id: number;
  image_url?: string;
  message?: string;
  sender: number;
  stamp_id?: number;
};

type RoomData = {
  [key: string]: Message[];
};

const getDate = (createdAt: string) => {
  const messageDate = new Date(createdAt);
  const month = messageDate.getMonth() + 1;
  const date = messageDate.getDate();
  const day = messageDate.getDay();
  const dateStr = `${zeroPadding(month, 2)}/${zeroPadding(date, 2)}(${weekDays[day]})`;

  return dateStr;
};

export const useDatabase = () => {
  const [roomData, setRoomData] = useState<RoomData>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchRoomData = useCallback((id: number) => {
    const room = ref(database, `rooms/${id}`);
    onValue(room, (snapshot) => {
      const val = snapshot.val();
      const data = Object.entries(val).map(([, value]) => value) as Message[];

      /**
       * keyが日付、valueがその日のメッセージの配列になるように整形
       */
      const groupedData = data.reduce((acc, cur) => {
        if (!cur.created_at || cur.stamp_id || cur.content === 'match' || cur.message === '♥ いいねありがとう') {
          return acc;
        }

        const key = getDate(cur.created_at);

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(cur);

        return acc;
      }, {} as RoomData);

      setRoomData(groupedData);
      setIsLoading(false);
    });
  }, []);

  return { isLoading, roomData, fetchRoomData, setRoomData };
};
