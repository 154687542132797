import { memo, useCallback, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { optionElements } from '@/functions/constants/optionElements';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  target: string;
  value: string | null;
  onSave: (value: { [key: string]: string }) => void;
  submitLabel?: string;
};

export const RadioPropertyModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, target, value, onSave, submitLabel = '保存' } = props;

  const [element, setElement] = useState<{ title: string; option: {} }>({ title: '', option: {} });
  const [temporarily, setTemporarily] = useState<string | null>(null);

  const { isMale } = useAtomValue(meFlagAtom);
  const masterTypes = useAtomValue(masterTypesAtom);

  useEffect(() => {
    if (!isOpen || !target) return;

    const getTarget = (target: string) => {
      if (target === 'body_type') {
        return isMale ? 'male_body_type' : 'female_body_type';
      }

      return target;
    };

    const element = optionElements.find((v) => v.key === getTarget(target));

    if (!element) return;

    const option = element.option ? element.option : masterTypes[element.type!];

    const getKey = (option: { [key: string]: string }, value: string) => {
      if (!value) return null;

      // eslint-disable-next-line
      for (const key in option) {
        if (option[key] === value) {
          return key;
        }
      }

      return null;
    };

    setTemporarily(getKey(option, value ?? ''));
    setElement({ title: element.title, option });
  }, [isOpen, target]);

  const handleClickSave = useCallback(() => {
    onSave({ [target]: temporarily! });
  }, [temporarily]);

  return (
    <HalfModal
      id='radioPropertyModal'
      isOpen={isOpen}
      onClose={onClose}
      title={element.title}
      footer={
        <Button color='black' onClick={handleClickSave} disabled={!temporarily}>
          {submitLabel}
        </Button>
      }
    >
      <RadioGroup selected={temporarily} options={element.option} onChange={(e) => setTemporarily(e.target.value)} />
    </HalfModal>
  );
});
