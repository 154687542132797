import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  id: number;
};

export type QualityCheck = {
  name: string;
  small_category: {
    name: string;
    value: number;
  }[];
}[];

type Response = QualityCheck;

const api = (params: Params): Promise<Response> => {
  return client.post('v1/quality_checks', params);
};

/**
 * クオリティチェックの作成
 */

type Props = {
  userId: number;
};

export const usePostQualityCheck = () => {
  const postQualityCheck = useCallback(async (props: Props) => {
    const { userId } = props;
    const res = await api({ id: userId });

    return res;
  }, []);

  return { postQualityCheck };
};
