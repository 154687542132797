import { useCallback } from 'react';
import { client } from '@/apis/client';

type Params = {
  image: {
    image_url: string;
    order: number;
  };
};

// NOTE: user_image 必要ならtypesファイルに移行
type Response = {
  id: number;
  user_id: number;
  order: number;
  aasm_state: string;
  status: number;
  disclose: string;
  is_mosaic: true;
  image_url: string;
  description: string;
};

const api = (params: Params): Promise<Response> => {
  return client.put('v1/me/images', params);
};

type Props = Params;

/**
 * 自身のメイン・サブ画像を登録する
 */

export const usePutImages = () => {
  const putImages = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { putImages };
};
