type BannerFilters = {
  member: string | null;
  isPhoneVerify: boolean | null;
  isAgeConfirm: boolean | null;
};

// Activities
export const activityKeys = {
  all: ['activities'] as const,
  lists: () => [...activityKeys.all, 'list'] as const
};

// Admin
export const adminKeys = {
  all: ['admin'] as const,
  managedUsers: () => [...adminKeys.all, 'managedUsers'] as const
};

// Appointments
export const appointmentKeys = {
  all: ['appointments'] as const,
  lists: () => [...appointmentKeys.all, 'list'] as const,
  list: (type: string) => [...appointmentKeys.lists(), type] as const,
  remind: () => [...appointmentKeys.all, 'remind'] as const
};

// Badge
export const badgeKeys = {
  all: ['badge'] as const
};

// Banner
export const bannerKeys = {
  all: ['banners'] as const,
  lists: () => [...bannerKeys.all, 'list'] as const,
  list: (filters: BannerFilters) => [...bannerKeys.lists(), filters] as const
};

// Boards
export const boardKeys = {
  all: ['boards'] as const,
  lists: () => [...boardKeys.all, 'list'] as const,
  list: (searchValue: any, currentDate: string) => [...boardKeys.lists(), searchValue, currentDate] as const
};

export const meBoardKeys = {
  all: ['meBoards'] as const,
  lists: () => [...meBoardKeys.all, 'list'] as const
};

// ChanceTime
export const chanceTimeKeys = {
  all: ['chanceTimes'] as const,
  state: () => [...chanceTimeKeys.all, 'state'] as const
};

// ChatRooms
export const chatRoomKeys = {
  all: ['chatRooms'] as const,
  lists: () => [...chatRoomKeys.all, 'list'] as const,
  list: (searchValue: any) => [...chatRoomKeys.lists(), searchValue] as const,
  details: () => [...chatRoomKeys.all, 'details'] as const,
  detail: (chatRoomId: number | null) => [...chatRoomKeys.details(), chatRoomId] as const
};

// Good
export const goodKeys = {
  all: ['goods'] as const,
  receive: () => [...goodKeys.all, 'receive'] as const,
  give: () => [...goodKeys.all, 'give'] as const
};

// Me
export const meKeys = {
  all: ['me'] as const
};

// Notice
export const noticeKeys = {
  all: ['notices'] as const,
  lists: () => [...noticeKeys.all, 'list'] as const
};

// Phrases
export const phraseKeys = {
  all: ['phrases'] as const,
  lists: () => [...phraseKeys.all, 'list'] as const
};

// Points
export const pointKeys = {
  all: ['points'] as const,
  histories: () => [...pointKeys.all, 'histories'] as const
};

export const dailyPointKeys = {
  all: ['dailyPoints'] as const
};

// Settings
export const settingKeys = {
  all: ['settings'] as const,
  notices: () => [...settingKeys.all, 'notices'] as const
};

// Users
export const userKeys = {
  all: ['users'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
  list: (searchValue: any) => [...userKeys.lists(), searchValue] as const,
  cast: () => [...userKeys.all, 'cast'] as const,
  meetupLocation: () => [...userKeys.all, 'meetupLocation'] as const,
  dailySwipe: () => [...userKeys.all, 'dailySwipe'] as const,
  block: () => [...userKeys.all, 'block'] as const,
  hide: () => [...userKeys.all, 'hide'] as const,
  quit: () => [...userKeys.all, 'quit'] as const,
  similar: (userId: number | null) => [...userKeys.all, 'similar', userId] as const,
  details: () => [...userKeys.all, 'details'] as const,
  detail: (userId: number | null) => [...userKeys.details(), userId] as const
};
