import { useCallback } from 'react';
import { client } from '@/apis/client';
import { useSnackbar } from '@/functions/hooks';

type Params = {
  id: number;
  body: {
    attached_name: string | null;
  };
};

type Response = {
  success: 1;
};

const api = (params: Params): Promise<Response> => {
  return client.put(`v1/chat_rooms/${params.id}/attach_name`, params.body);
};

type Props = Params;

/**
 * チャットルームのニックネーム設定
 */

export const usePutAttachName = () => {
  const { openSnackbar } = useSnackbar();

  const putAttachName = useCallback(async (props: Props) => {
    await api(props);

    openSnackbar({
      type: 'toast',
      text: 'ニックネームを変更しました。',
      bottom: 92
    });
  }, []);

  return { putAttachName };
};
