import { memo, useCallback, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePutProperty } from '@/apis';
import { meKeys } from '@/apis/queryKeys';
import { Button } from '@/components/styles/uis/Button';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import { Select } from '@/components/styles/uis/Select';
import { TextInput } from '@/components/styles/uis/TextInput';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meAtom } from '@/contexts/atoms/me';
import { slideVariants } from '@/functions/constants/framerMotion';
import { getObjValue } from '@/functions/helpers';
import { useDisclosure, useSnackbar } from '@/functions/hooks';
import { nicknameSchema } from '@/functions/schemas/common';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/profile.module.scss';

const schema = z.object({
  one_word: z.string().max(30, '30文字以内で入力してください').nullable(),
  nickname: nicknameSchema,
  residence_location: z.string().nullable()
});

type Schema = z.infer<typeof schema>;

type Props = {
  onClose?: () => void;
};

export const BasicInfo: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const me = useAtomValue(meAtom);
  const queryClient = useQueryClient();

  const [temporarily, setTemporarily] = useState<string | null>(null);

  const residenceModal = useDisclosure();

  const masterTypes = useAtomValue(masterTypesAtom);

  const { putProperty } = usePutProperty();
  const { openSnackbar } = useSnackbar();

  const getKey = useCallback((option: { [key: string]: string }, value: string) => {
    if (!value) return null;

    // eslint-disable-next-line
    for (const key in option) {
      if (option[key] === value) {
        return key;
      }
    }

    return null;
  }, []);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      one_word: me.property.one_word ?? '',
      nickname: me.property.nickname ?? '',
      residence_location: getKey(masterTypes.residence_locations, me.property.residence_location)
    },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const onSave = useCallback(async (value: { [key: string]: string | number | null } | string[]) => {
    await putProperty({
      user_property: Array.isArray(value) ? {} : { ...value }
    });
    await queryClient.invalidateQueries(meKeys.all);

    openSnackbar({
      type: 'toast',
      text: '保存しました。',
      bottom: 100
    });
  }, []);

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    onSave(formData);
  };

  const handleOpenResidence = useCallback(() => {
    setTemporarily(getValues('residence_location') ?? null);
    residenceModal.open();
  }, []);

  const handleSetResidence = useCallback(() => {
    setValue('residence_location', temporarily!);
    residenceModal.close();
  }, [temporarily]);

  const [dataInput, setDataInput] = useState(false);
  const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

  const handleClick = () => {
    setDataInput(true);
  };

  const handleBlur = () => {
    // すべての入力欄がフォーカスされていないかチェック
    const anyFocused = inputRefs.some((ref) => ref.current === document.activeElement);
    if (!anyFocused) {
      setDataInput(false);
    }
  };

  return (
    <div className={account.submodal}>
      <motion.div
        initial='right'
        animate='enter'
        exit='right'
        variants={slideVariants}
        className={account['submodal-wrapper']}
      >
        <div className={account['submodal-header']}>
          <div className={account['submodal-header-inner']}>
            <button
              type='button'
              onClick={onClose}
              className={account['submodal-header-back-button']}
              aria-label='close'
            />
            <p className={account['submodal-header-title']}>基本情報</p>
          </div>
        </div>

        <div className={clsx(account['submodal-contents'], styles['submodal-contents'])}>
          <div className={clsx(styles['between-wrapper'], styles['submodal-inner'])} data-input={dataInput}>
            <div className={styles['gap-wrapper']}>
              <TextInput
                id='one_word'
                value={watch('one_word')!}
                register={register('one_word')}
                placeholder='今日のひとこと'
                annotation={'one_word' in { ...errors } ? errors.one_word?.message : '今の気分や伝えたいことなど'}
                count={30}
                isInvalid={'one_word' in { ...errors }}
                inputRef={inputRefs[0]}
                onClick={handleClick}
                onBlur={handleBlur}
              />
              <TextInput
                id='nickname'
                value={watch('nickname')}
                register={register('nickname')}
                placeholder='ニックネーム'
                annotation={'nickname' in { ...errors } ? errors.nickname?.message : '2〜12文字で入力してください'}
                count={12}
                isInvalid={'nickname' in { ...errors }}
                inputRef={inputRefs[1]}
                onClick={handleClick}
                onBlur={handleBlur}
              />
              <div className={styles['basic-info-select']}>
                <Select
                  value={getObjValue(masterTypes.residence_locations, watch('residence_location')!)}
                  onClick={handleOpenResidence}
                  placeholder='居住地'
                />
              </div>
            </div>

            <div className={styles['adjust-button']} data-input={dataInput}>
              <Button color='black' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                保存
              </Button>
            </div>
          </div>
        </div>
      </motion.div>

      <HalfModal
        id='residenceModal'
        isOpen={residenceModal.isOpen}
        onClose={residenceModal.close}
        title='居住地'
        footer={
          <Button color='black' onClick={handleSetResidence} disabled={!temporarily}>
            決定
          </Button>
        }
      >
        <RadioGroup
          selected={temporarily}
          options={masterTypes.residence_locations}
          onChange={(e) => setTemporarily(e.target.value)}
        />
      </HalfModal>
    </div>
  );
});
