import { useCallback } from 'react';
import { client } from '@/apis/client';
import { ChatRoom } from '@/functions/types/chatRoom';

type Params = {
  chat_room_id: number;
  content?: string;
  free_text?: string;
  stamp_id?: number;
  image_url?: string;
};

type Response = ChatRoom;

const api = (params: Params): Promise<Response> => {
  return client.post('v1/talks', params);
};

type Props = Params;

/**
 * メッセージ送信 or いいね!
 */

export const usePostTalks = () => {
  const postTalks = useCallback(async (props: Props) => {
    await api(props);
  }, []);

  return { postTalks };
};
